import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { ProfileProvider } from '../../contexts/ProfileContext'
import WebSocketProvider from '../../contexts/WebSocketContext';
// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + (collapseClick.verticalLayout ? 24 : 0),
  paddingBottom: HEADER.MOBILE_PADDING_BOTTOM,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + (collapseClick.verticalLayout ? 24 : 0),
    paddingBottom: HEADER.DASHBOARD_PADDING_BOTTOM,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(true && {
      marginLeft: collapseClick.collapseClick ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : 0,
    }),
  },
}));

// ----------------------------------------------------------------------
const MainStyleItem = (collapseClick) => {
  try
  {
    // console.log(collapseClick);
    return (
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    );
  }
  catch(e)
  {
    console.error('MainStyleItem error: ');
    console.error(e);
  }
  return (<>Error</>);
}

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        <ProfileProvider>
        <WebSocketProvider>

          <Box
            sx={{
              display: { lg: 'flex' },
              minHeight: { lg: 1 },
            }}
          >
            <DashboardHeader isCollapse={isCollapse} verticalLayout={verticalLayout} onOpenSidebar={() => setOpen(true)} />

            <MainStyleItem collapseClick={collapseClick} verticalLayout={verticalLayout} />
          </Box>
          </WebSocketProvider>
        </ProfileProvider>
      </>
    );
  }

  return (
    <ProfileProvider>
      <WebSocketProvider>

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <DashboardHeader isCollapse={isCollapse} verticalLayout={verticalLayout} onOpenSidebar={() => setOpen(true)} />
        <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyleItem collapseClick={(collapseClick && !verticalLayout)} verticalLayout={verticalLayout} />
      </Box>
      </WebSocketProvider>
    </ProfileProvider>
  );
}
