import { createSlice, current } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
// import i18n from '../../locales/i18n';
// import { CREATE_ROOM_SUCCESS, JOIN_ROOM_SUCCESS, SET_USERNAME, JOIN_NOTIFY_SUCCESS} from '../../utils/ws_actions';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { newEventConstracts } from './service.contracts';

const initialState = {
  isLoading: false,
  room: null,
  chatLog: [],
  // notify: null,
  notifyLog: [],
  username: null,
  openNotify: false,
  subscribe: []
};

const slice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    NotifySuccess(state, action) {
        state.notifyLog = action.payload;
    },
    DelNotifySuccess(state, action) {
      /* const a =[];
      state.notifyLog.map((item) => {
        if (item.idNotify !== action.payload) a.push(item);
        return null;
      }); */
      state.notifyLog = state.notifyLog.filter((item) => item.idNotify !== action.payload);
    },
    MarkNotifySuccess(state, action) {
      state.notifyLog = state.notifyLog.map((notification) => ({
        ...notification,
        isUnRead: notification.idNotify === action.payload ? false : notification.isUnRead,
      }));
    },
    OpenSuccess(state, action) {
      state.openNotify = action.payload;
    },
    NotifyItem(state, action) {
        state.notifyLog.push(action.payload);
        try
        {
          /*
            "type": "notify",
            "sourceEvent": 2,
            "typeEvent": 4,
            "ic_type": 220,
            "object_type": 1,
            "method": ""
          */
          const find = state.subscribe.filter((item) => 
            (item.type === null || item.type === action.payload.type) &&
            (item.sourceEvent === null || item.sourceEvent === action.payload.sourceEvent) &&
            (item.typeEvent === null || item.typeEvent === action.payload.typeEvent) &&
            (item.typeService  === null || item.object_type === action.payload.object_type)  
          );
          if(find !== null && find.length > 0)
          {
            const ev = action.payload;
            find?.forEach(
              (item, index) => { 
                // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                const _item = current(item)
                // setTimeout(_item.method)
                const updateInfo = async () => {
                  await console.log('income message from socket')
                  _item.method()
                }
                updateInfo()
              }
            );
          }
        }
        catch (error) {
          toast.error(error);
        }
    },
    UserProfile(state, action) {
        state.username = action.username;
    },
    subscribeSuccess(state, action) {
      const p = state.subscribe.map((subscribe) => ({
        ...subscribe
      })); // state.subscribe;
      const find = state.subscribe.filter((item) => item.method === action.payload.method);
      console.log(find, action.payload);
      if(find == null || find.length === 0)
      {
        p.push(action.payload);
        state.subscribe = p;
      }
      console.log(p, state.subscribe);
      
    },
    unSubscribeSuccess(state, action) {
      state.subscribe = state.subscribe.filter((item) => item.method !== action.payload.method);
    }


  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  NotifySuccess,
  DelNotifySuccess,
  MarkNotifySuccess,
  subscribeSuccess,
  unSubscribeSuccess,
  NotifyItem,
  OpenSuccess,
  UserProfile
} = slice.actions;

export const setUserProfile = async user => {
    dispatch(UserProfile(user));
};

export const setNotifySuccess = ob => {
  dispatch(NotifySuccess(ob));
};
export const setOpenSuccess = ob => {
  dispatch(OpenSuccess(ob));
};
export const setNotifyItem = async (ob) => {
  try {
    console.log(ob);
    toast.info(ob?.data?.text || 'Notify');
    dispatch(NotifyItem(ob));
  } catch (error) {
    toast.error(error);
  }
};
export const delNotifySuccess = async id => {
  dispatch(DelNotifySuccess(id));
}; 
export const markNotifySuccess = async id => {
  dispatch(MarkNotifySuccess(id));
}; 

// ---------------------------------------------
export const subscribeItem = async (ob) => {
  console.log(ob);
  dispatch(subscribeSuccess(ob));
};
export const unSubscribeItem = ob => {
  dispatch(unSubscribeSuccess(ob));
};
// ---------------------------------------------
