import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import { Settings } from '@mui/icons-material';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import useSettings from '../../../hooks/useSettings';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR, defaultSettings } from '../../../config';
// components
// import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import LogoProfile from '../../../components/LogoProfile';
import MenubarService from './MenubarService';
import useProfile from '../../../hooks/useProfile';
import { NavSectionHorizontal } from '../../../components/nav-section';
import NavbarHorizontal from './NavbarHorizontal';
// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  paddingRight: '10px',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `100%`,
    ...(isCollapse && {
      width: `100%`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { profiles, currentProileInd, currentCabinetInd, vMenu } = useProfile();
  const isDesktop = useResponsive('up', 'lg');
  const { themeMode, themeDirection, themeColorPresets, themeStretch, themeLayout, openSetting, onOpenSetting } = useSettings();
  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch;
  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={!verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 0 },
        }}
      >
        {!verticalLayout && (
          <>
          {isDesktop && verticalLayout && <LogoProfile sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          <Searchbar />
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 0.5 }} sx={{ marginRight: '15px' }}>
            <LanguagePopover />
            <IconButtonAnimate
              onClick={onOpenSetting}
              sx={{
                width: 40,
                height: 40,
                bgcolor: 'action.selected',
                ...(openSetting && { bgcolor: 'action.selected' }),
              }}
            >
              <Settings />
            </IconButtonAnimate>
            <NotificationsPopover />
            <ContactsPopover />
            <AccountPopover />
            <Stack sx={{ width: '10px' }}>&nbsp;</Stack>
          </Stack>

          </>
        )} 
        {verticalLayout && (
          <>
          <Stack direction="row" alignItems="start" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LogoProfile isCollapse={isCollapse} />
        </Stack>
        <Stack direction="row" alignItems="start" spacing={{ xs: 0.5, sm: 0.5 }}  
            sx={{ width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
                ...(isCollapse && {
                      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,}) }}>
          <NavbarHorizontal isCollapse={isCollapse} />
        </Stack>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="end" spacing={{ xs: 0.5, sm: 0.5 }} sx={{ marginRight: '20px' }}>
          <LanguagePopover />
          <IconButtonAnimate
              onClick={onOpenSetting}
              sx={{
                width: 40,
                height: 40,
                bgcolor: 'action.selected',
                ...(openSetting && { bgcolor: 'action.selected' }),
              }}
            >
              <Settings />
            </IconButtonAnimate>
          <NotificationsPopover />
          <ContactsPopover />
          <AccountPopover />
          <Stack sx={{ width: '10px' }}>&nbsp;</Stack>
        </Stack>
        </>
        )}
        
      </Toolbar>
    </RootStyle>
  );
}
