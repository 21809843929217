import { createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
// import i18n from '../../locales/i18n';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { loaderEnd, loaderStart } from './loader';
import { showConfirmationModal } from './confirmation';

const initialState = {
  isLoading: false,
  error: null,
  filiya: null,
  enterprise: null,
  editModalOpen: false,
  enterprises: [],
  vagCount: null,
  isCreateCodeModalOpen: false,
  isEditCodeModalOpen: false,
  isCreateSubCodeModalOpen: false,
  selectedCode: null,
  selectedSubCode: null,
  selectedRwAgr: null,
  codesResponce: []
};

const slice = createSlice({
  name: 'enterprise',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = action.payload
    },
    setEnterprises(state, action) {
      state.enterprises = action.payload
    },
    setEnterprise(state, action) {
      state.enterprise = action.payload;
      state.filiya = action.payload.filiya;
    },
    setModalState(state, action) {
      state.editModalOpen = action.payload;
    },
    setFiliya(state, action) {
      const e = {...state.enterprise};
      e.filiya = action.payload;
      state.enterprise = e;
      state.filiya = action.payload;
    },
    setVagCount(state, action) {
      state.vagCount = action.payload
    },
    setCreateCodeModalOpen(state, action) {
      state.isCreateCodeModalOpen = action.payload;
    },
    setEditCodeModalOpen(state, action) {
      state.isEditCodeModalOpen = action.payload;
    },
    setCreateSubCodeModalOpen(state, action) {
      state.isCreateSubCodeModalOpen = action.payload;
    },
    setSelectedCode(state, action) {
      state.selectedCode = action.payload;
    },
    setSelectedSubCode(state, action) {
      state.selectedSubCode = action.payload;
    },
    setSelectedRwAgr(state, action) {
      state.selectedRwAgr = action.payload;
    },
    setCodesResponce(state, action) {
      state.codesResponce = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  setEnterprises,
  setEnterprise,
  setModalState,
  setFiliya,
  setVagCount,
  setCreateCodeModalOpen,
  setEditCodeModalOpen,
  setCreateSubCodeModalOpen,
  setSelectedCode,
  setSelectedSubCode,
  setSelectedRwAgr,
  setCodesResponce
} = slice.actions;

export const getAllEnterprises = async (params = '') => {
  loaderStart()
  await axios.get(`/api-sys/pidpr/get_all${params}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setEnterprises(res.data));
      }
    });
  loaderEnd()
}

export const getEnterprise = async enterpriseId => {
  loaderStart()
  await axios.get(`/api-sys/pidpr/get_pidpr?pId=${enterpriseId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setEnterprise(res.data));
      }
    });
  loaderEnd();
};

export const openEditModal = isOpen => {
  dispatch(setModalState(isOpen));
  if (!isOpen) {
    dispatch(setVagCount(null));
  }
};

export const update = async data => {
  dispatch(startLoading(true));
  await axios.post('/api-sys/pidpr/update_pidpr', data)
    .then(res => {
      if (res.status === 200) {
        // dispatch(setFiliya(data));
        getEnterprise(data.idVsp);
        dispatch(setModalState(false));
        toast.success('Філію оновлено!')
      } else {
        // need talk about errors from backend
      }
    });
  dispatch(startLoading(false));
};

export const getVagCount = async idVsp => {
  dispatch(startLoading(true));
  await axios.get('/api-sys/pidpr/vagcount', { idVsp })
    .then(res => {
      if (res.status === 200) {
       dispatch(setVagCount(res.data));
      } else {
        // need talk about errors from backend
      }
    });
  dispatch(startLoading(false));
};

export const openCreateCodeModal = (isOpen) => {
  dispatch(setCreateCodeModalOpen(isOpen));
}

export const openEditCodeModal = (isOpen, code) => {
  dispatch(setEditCodeModalOpen(isOpen));
  dispatch(setSelectedCode(code));
}

export const openCreateSubCodeModal = (isOpen, code, subCode = null) => {
  dispatch(setCreateSubCodeModalOpen(isOpen));
  dispatch(setSelectedCode(code));
  dispatch(setSelectedSubCode(subCode));
}

export const selectRwAgr = agr => {
  dispatch(setSelectedRwAgr(agr));
}

export const createRwAgreement = async (data, rwagreements) => {
  const agrData = {
    id_pidpr: data.id_pidpr,
    id_carrier: data.id_carrier,
    agree_num: data.agree_num,
    agree_date: data.agree_date,
    agree_date_beg: data.agree_date_beg,
    agree_date_end: data.agree_date_end,
  };
  const codeData = {
    code_type: data.code_type,
    id_pidpr: data.id_pidpr,
    rw_code: data.rw_code
  };
  dispatch(startLoading(true));
  await axios.post(`/api-sys/pidpr/rw/agreement`, agrData)
  .then(res => {
    if (res.status === 200) {
      codeData.id_agree = res.data[res.data.length - 1].id_agree
      createCode(codeData);
    }
  })
}

export const updateRwAgreement = async data => {
  dispatch(startLoading(true));
  await axios.put(`/api-sys/pidpr/rw/agreement`, data)
  .then(res => {
    if (res.status === 200) {
      dispatch(setCodesResponce(res.data));
      dispatch(setSelectedRwAgr(null));
      toast.success('Договір оновлено');
    }
  })
  dispatch(startLoading(false));
}

export const createCode = async (data, runLoader = false) => {
  if (runLoader) {
    dispatch(startLoading(true));
  }
  await axios.post(`/api-sys/pidpr/rw/code`, data)
  .then(res => {
    if (res.status === 200) {
      dispatch(setCodesResponce(res.data));
      toast.success('Код створено');
      dispatch(setCreateCodeModalOpen(false));
    }
  })
  dispatch(startLoading(false));
}

export const selectCode = code => {
  dispatch(setSelectedCode(code));
}

export const changeCode = async (data) => {
  dispatch(startLoading(true));
  await axios.put(`/api-sys/pidpr/rw/code`, data)
  .then(res => {
    if (res.status === 200) {
      dispatch(setCodesResponce(res.data));
      toast.success('Код змінено');
      dispatch(setEditCodeModalOpen(false));
      dispatch(setSelectedCode(null));
    }
  })
  dispatch(startLoading(false));
}

export const deleteCode = async (codeId, code) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити код №${code}`,
    onSubmit: () => submitDelete(codeId),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
}

const submitDelete = async codeId => {
  loaderStart();
  await axios.delete(`/api-sys/pidpr/rw/code?id_rwcode=${codeId}`)
  .then(res => {
    if (res.status === 200) {
      dispatch(setCodesResponce(res.data));
      toast.success('Код видалено');
      showConfirmationModal({ isOpen: false });
    }
  })
  loaderEnd();
}

export const createSubCode = async data => {
  dispatch(startLoading(true));
  await axios.post(`/api-sys/pidpr/rw/subcode`, data)
  .then(res => {
    if (res.status === 200) {
      dispatch(setCodesResponce(res.data));
      toast.success('Підкод створено');
      dispatch(setCreateSubCodeModalOpen(false));
      dispatch(setSelectedCode(null));
    }
  })
  dispatch(startLoading(false));
}

export const editSubCode = async data => {
  dispatch(startLoading(true));
  await axios.put(`/api-sys/pidpr/rw/subcode`, data)
  .then(res => {
    if (res.status === 200) {
      dispatch(setCodesResponce(res.data));
      toast.success('Підкод оновлено');
      dispatch(setCreateSubCodeModalOpen(false));
      dispatch(setSelectedCode(null));
      dispatch(setSelectedSubCode(null));
    }
  })
  dispatch(startLoading(false));
}

export const deleteSubCode = (subcodeId, subCode) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити код №${subCode}`,
    onSubmit: () => submitDeleteSubCode(subcodeId),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
}

const submitDeleteSubCode = async subcodeId => {
  loaderStart();
  await axios.delete(`/api-sys/pidpr/rw/subcode?id_rw_subcode=${subcodeId}`)
  .then(res => {
    if (res.status === 200) {
      dispatch(setCodesResponce(res.data));
      toast.success('Підкод видалено');
      showConfirmationModal({ isOpen: false });
    }
  })
  loaderEnd();
}