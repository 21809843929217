// License
import { LicenseInfo } from '@mui/x-license-pro';
// routes
import { ToastContainer } from "react-toastify";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ConfirmationModal from './components/modals/ConfirmationModal';
import Loader from './components/Loader';
import 'react-toastify/dist/ReactToastify.css';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
LicenseInfo.setLicenseKey(  
  'b1034f3275ca49613042525b1e84da80T1JERVI6NDI1NTksRVhQSVJZPTE2ODI1OTk4MDcwMDAsS0VZVkVSU0lPTj0x'
);

export default function App() {   
  return (
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  <ChartStyle />
                  <Settings />
                  <ScrollToTop />
                  <Router />
                  <ToastContainer position="bottom-right" />
                  <ConfirmationModal />
                  <Loader />
                </MotionLazyContainer>
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider>
  );
}
