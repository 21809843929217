export const planStatusSettings =
{
    id: 'plan_status',
    label: 'Статус',
    multiple: true,
    table: 'E_PLAN_SOGLAS',
    title: 'NAME',
    value: 'CODE',
}

export const vidPerevezSettings =
{
    id: 'vid_perevez',
    label: 'Вид перевезення',
    multiple: true,
    table: 'E_PLAN_PEREVEZ',
    title: 'NAME',
    value: 'CODE',
}

export const ozvVlSettings =
{
    id: 'ozv_vl',
    label: 'Ознака власності',
    multiple: false,
    table: 'E_PLAN_TARA',
    title: 'NAME',
    value: 'CODE',
}

export const elevatorSettings =
{
    id: 'elevator',
    label: 'Елеватор',
    multiple: false,
    table: 'a_el_stations',
    title: 'name',
    value: 'esr',
}

export const nomenklatSettings =
{
    id: 'nomenklat',
    label: 'Номенклатура вантажу',
    multiple: true,
    table: 'E_MESPLAN_NGR',
    title: 'NAME',
    value: 'CODE',
}

export const etsnvSettings =
{
    id: 'etsnv',
    label: 'ЕТСНГ',
    multiple: true,
    table: 'E_TV_ETSNV',
    title: 'NAME',
    value: 'CODE',
}

export const zalOtprSettings =
{
    id: 'zal_otpr',
    label: 'Залізниця відправлення',
    multiple: false,
    table: 'E_D_ZALSND',
    title: 'N_DORU',
    value: 'KD',
}

export const stranOtprSettings =
{
    id: 'strn_otpr',
    label: 'Держава відправлення',
    multiple: false,
    table: 'E_D_STRN',
    title: 'NAME',
    value: 'K_STRN',
}

export const klientSettings =
{
    id: 'klient',
    label: 'Вантажовідправник',
    multiple: true,
    table: 'EKK_GR_OTPR',
    title: 'NAME_SHORT_EEKK',
    value: 'SENDER_ID',
    edrpou: 'KOD_EDRPOU',
    code: 'CODE_LIST',
    isConsignee: true
}

export const poluchUkrSettings =
{
    id: 'poluch_ukr',
    label: 'Одержувач',
    multiple: true,
    table: 'EKK_GR_POLUCH',
    title: 'NAME',
    value: 'KLIENT_ID',
    edrpou: 'KOD_EDRPOU',
    code: 'CODE',
    isConsignee: true
}

export const esrOtprSettings =
{
    id: 'esr_otpr',
    label: 'Станція відправки',
    multiple: true,
    table: 'E_T_RP',
    title: 'N_RPU',
    value: 'K_RP',
}

export const soglasCodeSettings =
{
    id: 'soglas_code',
    label: 'Результат узгодження',
    multiple: false,
    table: 'E_PLAN_SOGLAS',
    title: 'NAME',
    value: 'CODE',
}

export const vagRpsSettings =
{
    id: 'vag_rps',
    label: 'Рід вагона',
    multiple: true,
    table: 'E_MESPLAN_OBLIK_ROD',
    title: 'NRPS',
    value: 'RPS',
}

export const esrPribSettings =
{
    id: 'esr_prib',
    label: 'Станція призначення',
    multiple: true,
    table: 'E_T_RP',
    title: 'N_RPU',
    value: 'K_RP',
}

export const esrPribOszSettings =
{
    id: 'esr_prib_osz',
    label: 'Станція призначення ОСЖД	',
    multiple: false,
    table: 'E_T_RP',
    title: 'N_RPU',
    value: 'K_RP',
}

export const zalPribSettings =
{
    id: 'zal_prib',
    label: 'Залізниця призначення',
    multiple: false,
    table: 'E_D_ZALSND',
    title: 'N_DORU',
    value: 'KD',
}

export const stranPribSettings =
{
    id: 'strn_prib',
    label: 'Держава призначення',
    multiple: false,
    table: 'E_D_STRN',
    title: 'NAME',
    value: 'K_STRN',
}

export const esrOsgdSettings =
{
    id: 'esr_osgd',
    label: 'Станція призначення ОСЖД',
    multiple: true,
    table: 'E_T_RP',
    title: 'N_RPU',
    value: 'K_RP',
}

export const portPribSettings = {
    id: 'port_prib',
    label: 'Порт призначення',
    multiple: false,
    table: 'a_ports',
    title: 'name',
    value: 'esr'
  };

  export const vagTypeSettings = {
    id: 'tip_vag',
    label: 'Тип вагона',
    multiple: false,
    table: 'E_T_VAGGR5',
    title: 'name',
    value: 'tip_vag'
  };

export const idEekkSettings =
  {
    id: 'id_ekbs',
    label: 'Вантажоотримувач ЄЕКК',
    multiple: true,
    table: 'EKK_GR_POLUCH',
    title: 'NAME',
    value: 'KLIENT_ID',
    edrpou: 'KOD_EDRPOU',
    code: 'CODE',
    isConsignee: true
  }
  