import { createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';

//
import { dispatch } from '../store';
import { newEvent, newEventKeyObj, setEvent, sourceEnum, typeEventEnum } from './service.events';
import formatYYYYMMDDHHMISS from '../../utils/formatYYYYMMDDHHMISS';
import { CommonAlertHolder } from '../../common/CommonAlert/CommonAlertHolder';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  dicEsrOtpr: [],
  dicEsrPrib: [],
  dicStrn: [],
  dicCargoETSNV:[],
  dicRps:[],
  dicElevator:[],
 // params: { stationFrom: null,  stationTo: null, ownCarr: '3', codeRps: null,  countAxis: 4,  weightCarr: '',  typeShpt: '1',  codeEtsnv: null,  token:'' },
  params: {EsrOtpr: '', EsrPrib: '',  iPrVagon: '', Rps: '', iCountOs: '', iVes:'', iPrOtpr: '', iTypeOtpr: '', Etsng: '', iCountVag: '', Elevator: '', dopSbor: '', token:''}, 
  resultTarif:{s1:'', s2:'', name: '', vl: '', dist: 0, timeRuh: '', ves: 0, plata: 0, tarif : 0, nds: 0, tarifPervez: 0, tarifUse:0, tarifPor: 0, dzCustoms: 0, dzLviv: 0, dzCust2: 0, dzLviv2: 0, pkoIl: 0, pkoV:0, roIl: 0, roV:0, tarifEmpty:0, gnrTimeBack:'',
  chemeName:'', countVag: '',iElevator: '' , errorOpis: null},
  distTarif: 0,
  openTarifModal: false,
  openSborModal: false,
  // Ознака готовності даних розрахунку
  isReadyData: false,
  resultSbor: { esr: '', countVag: '', prVag: '', elevator: '', dSbor: 0},
  ptarif: {
    p1: '',
    p2: '',
    p3: '',
    p4: '',
    p5: '',
    p6: '',
    p7: '',
    p8: '',
    token: ''
  },
  labels: [],
 
};


const slice = createSlice({
  name: 'tarif',
  initialState,
  reducers: {
    // START LOADING
   
    startLoading(state) {
      state.isLoading = true;
    }, 

    /* setLoading(state, action) {
      state.isLoading = action.payload;
    }, */
   
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MAILS
    setTarifParams(state, action) {
      const params = action.payload;
      state.isLoading = false;
      state.params = action.payload;
    },
   
    setDicEsrOtpr(state, action) {
      const esrOtpr = action.payload;
      const st = esrOtpr.filter(x=>x.code==='22');
    //  console.log('esrOtpr  ' ,st);
      state.dicEsrOtpr = st;
    },     

    setDicEsrPrib(state, action) {
      const esrPr = action.payload;
      const stPr = esrPr.filter(x=>x.code==='22');
     // console.log('esrOtpr  ' ,stPr);
    
       state.dicEsrPrib = stPr;
    }, 
   
    setDicRps(state, action) {
      const rps = action.payload;
      // console.log('rps  ' ,rps);
      const k = rps.filter(r=>r.value >= 20);
   
      // console.log('rps_new  ' ,k);
      state.dicRps = k;
      // = action.payload;
    },   

    setDicCargoETSNV(state, action) {
      state.dicCargoETSNV = action.payload;
    },

    setDicElevator(state, action) {
      const elev = action.payload;
      
      const el = elev.filter(r=> r.value !== '');
       
      state.dicElevator = el;
      // state.dicElevator = action.payload;
    }, 

    setTarifModalState(state, action) {
      state.openTarifModal = action.payload;
    },

    setTarifReady(state, action) {
      state.isReadyData = action.payload;
    },

    setSborModalState(state, action) {
      state.openSborModal = action.payload;
    },
    
   
     /* setDSbor(state, action) {
          state.dSbor = action.payload;
          },
    */
    // GET MAILS
    getTarifSuccess(state, action) {
      const result = action.payload;
      console.log('result Tarif' ,result);
      state.isLoading = false;
     // console.log('state.params' ,state.params);s    
     
     const k = { 
      s1: state.params.EsrOtpr.title, 
      s2: state.params.EsrPrib.title, 
      countVag: state.params.iCountVag,
      name: state.params.Etsng.title,
      vl: state.params.iPrVagon,
      dist: result?.dist,
      timeRuh: result?.time_ruh, 
      ves: state.params.iVes, 
      plata: (result?.tarif+result?.nds), nds: result?.nds, tarif: result?.tarif, tarifPerevez: result?.tarifPerevez, tarifUse: result?.tarifUse, 
      tarifPor: result?.tarifPor,
      gnrTimeBack: result?.gnrTimeBack,
      dzCustoms: result?.dz_customs, 
      dzLviv: result?.dz_lviv, 
      dzCust2: result?.dz_cust2, 
      dzLviv2: result?.dz_lviv2, 
      pkoIl: result?.pko_il, 
      pkoV: result?.pko_v, 
      roIl: result?.ro_il, 
      roV: result?.ro_v, 
      tarifEmpty: result?.tarifEmpty,
      chemeName: result?.cheme_name, 
      errorOpis: result?.error_opis
      };
      console.log('result Tarif ' ,k);

    state.resultTarif = k;
  },

   /*  getDopSborSuccess(state, action) {
      const result = action.payload;
     // console.log('state.params.iPrVagon.name' ,state.params.iPrVagon);
      state.isLoading = false;
      const k = { 
        esr: state.params.EsrOtpr.title, 
        dSbor: result,
        Elevator: state.params.Elevator.title,
        countVag: state.params.iCountVag, 
        prVag: state.params.iPrVagon
        
         };
        console.log('result Sbor ' ,k);

      state.resultSbor = k;
    },  */ 

  },
});



// Reducer
export default slice.reducer;

export const {
   startLoading,
  // setLoading,
  setTarifParams,
  hasError,   
  setDicEsrOtpr,
  setDicEsrPrib,
  setDicStrn,
  setDicCargoETSNV,
  setDicRps,
  setDicElevator,
  setTarifModalState,
  setTarifReady,
  setSborModalState,
  setOpenCalcViewModal,
    
} = slice.actions;

// ----------------------------------------------------------------------

export const openTarifModal = isOpen => {
  dispatch(setTarifModalState(isOpen));
};

export const openSborModal = isOpen => {
  dispatch(setSborModalState(isOpen));
};

export function getTarif(params) {
  return async () => {
    console.log('params ',params);
     dispatch(startLoading());
    try 
    {
      dispatch(slice.actions.setTarifParams(params));

      const response = await axios.get(['/api-tarif/api/v4/Tariff?',
      // const response = await axios.get(['https://localhost:44361/api/v4/Tariff?',
      'iEsrOtpr=',params.EsrOtpr.value,'&iEsrPrib=', params.EsrPrib.value,'&iPrVagon=',params.iPrVagon, '&iRps=',params.Rps.value,'&iCountOs=',params.iCountOs,'&iVes=',params.iVes,
      '&iPrOtpr=',params.iPrOtpr,'&cEtsng=',params.Etsng.value.toString().padStart(6, '0'),
       ].join(''));       
         console.log('response.status', response.status);
        /*  const resp = await axios.get(['/api-tarif/v3/Dist?',
      'iEsrOtpr=',params.EsrOtpr.value,'&iEsrPrib=', params.EsrPrib.value,
     ].join(''));
             
                if (resp.status === 200)
                {
                   dispatch(setDistTarif(resp.data));
                } */
                if (response.status === 200)
                {
                   dispatch(slice.actions.getTarifSuccess(response.data));
                   dispatch(setTarifModalState(true));
                   dispatch(setTarifReady(true));
                 //  const dt = formatYYYYMMDDHHMISS(new Date());  
                }   else { 
                    toast.warn(`${i18n.t('service.errors.error')}: ${i18n.t('service.sigfox.errors.no-data-found')}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
                    dispatch(setTarifReady(false));
                }
        }
     
        catch(error) {      
          dispatch(slice.actions.hasError(error));
          console.log('error', error);
          dispatch(setTarifReady(false));
        } 
      };    
};

/* export function getDopSbor(params) {
  return async () => {
    
     dispatch(startLoading());
    try {
      dispatch(slice.actions.setTarifParams(params));
      console.log('params ',params);
      const response = await axios.get(['/api-tarif/Tarif/v2/DopSbor?',
      'iEsrOtpr=',params.EsrOtpr.value,'&iPrVagon=',params.iPrVagon, '&iCountVag=',params.iCountVag,'&iElevatorCode=',params.Elevator.value,
       ].join(''));
       if(response.status === 200) {  
       console.log('response.data ',response.data);
       dispatch(slice.actions.getDopSborSuccess(response.data));
      // dispatch(slice.actions.setDSbor(response.data));
      dispatch(setSborModalState(true));
       }
      const dt = formatYYYYMMDDHHMISS(new Date());

    
    }
     
    catch(error) {      
      dispatch(slice.actions.hasError(error));
      console.log('error', error);
    }   
  };   
};
*/
/* export const getDicStrn = async () => {
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_D_STRN&fieldOutOne=NAME_SHORT&fieldOutTwo=K_STRN&fieldOutThree=K_ADMI&all=true`)
    // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_ADM&titleOut=N_ADMR&valueOut=K_ADMI&all=true')
      .then(res => {
        if(res.status === 200) {
          const newData = [];        
          res.data.forEach(item => {            
            // const newItem = {'value': item.value, 'title': `${item.title} (${item.value})`};
            const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            }                 
          });         
          dispatch(setDicStrn(newData));
        }
      }) 
      .catch((error) => {    
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      }); 
    };  
  */
  // -------- НДІ Станції
  export const getDicEsrPrib = async () => {
    await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Stations&fieldOutOne=display_info&fieldOutTwo=code_station&fieldOutThree=code_adm&all=true`)  
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.field2, 'title': item.field1, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });  
          // newData.push( {value: 0, title: ''} );       
          dispatch(setDicEsrPrib(newData));
          dispatch(setDicEsrOtpr(newData));
        }
        else {          
          throw new Error(res.statusText || res.status);          
        }  
      }) 
      .catch((error) => {    
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });       
  };
  
/*  export const getDicEsrOtpr = async () => {
  await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU&valueOut=K_RP&all=true')
    // await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU&valueOut=K_RP&&value=СТ&searchField=M_SPRPR&all=false&limit=2000`)
    .then(res => {
      if (res.status === 200) {
        const newData = [];
        res.data.forEach(item => {
          const newItem = { 'value': item.value, 'title': `${item.title.trim()} (${item.value})` };
          if (!newData.includes(newItem)) {
            newData.push(newItem);
          }
        });
        // newData.push( {value: 0, title: ''} );        
        dispatch(setDicEsrOtpr(newData));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, { autoClose: false, hideProgressBar: false, closeOnClick: true });
    });
}
 */
  // --------НДІ Вантаж
  export const getDicCargoETSNV = async () => {
    await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_TV_ETSNV&titleOut=name&valueOut=code&all=true`)
    .then(res => {
      if(res.status === 200) {      
          const newData = [];        
          res.data.forEach(item => {            
            const newItem = {'value': item.value, 'title': `${item.title.trim()} (${item.value.toString().padStart(6, '0')})`};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            }                 
          });         
          dispatch(setDicCargoETSNV(newData));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
  };

   
    // --------НДІ Вагон 
  export const getDicRps = async () => {
    await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_MESPLAN_OBLIK_ROD&fieldOutOne=NRPS&fieldOutTwo=MNRPS&fieldOutThree=RPS&all=true`)
    .then(res => {
      if(res.status === 200) {      
          const newData = [];        
          res.data.forEach(item => {            
            const newItem = {'value': item.field3, 'title': `${item.field1.trim()} (${item.field2})(${item.field3})`};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            }                 
          });         
          dispatch(setDicRps(newData));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
  }; 

  
   // --------НДІ elevator 
   export const getDicElevator = async () => {
    // await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=views.nsi_elevator&titleOut=pidpr_name_&valueOut=code&all=true`)
    await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.v_nsi_elevator&fieldOutOne=pidpr_name_short&fieldOutTwo=esr_adjoin&fieldOutThree=code_4&all=true`)
    .then(res => {
      if(res.status === 200) {      
          const newData = [];        
          res.data.forEach(item => {            
            const newItem = {'value': item.field3, 'value1': item.field2, 'title': `${item.field1.trim()} (${item.field2})(${item.field3})`};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            }                 
          });         
          dispatch(setDicElevator(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {    
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
  }; 

  // Вартість послуги
export const getCostService = (row, inputData) => {
  const codeUnit  = row.codeUnit;   
  const price = Number.isInteger(row.price) ? row.price : 0;
  // Якщо не зазначена ціна => вартість = 0
  if (price === 0)
      return NaN;

  let cost = 0;       
  switch (codeUnit) {
      // вагон
      case 1: 
          cost = 
              Number.isInteger(inputData.countCarrs) 
              ? inputData.countCarrs * price 
              : NaN;   
          break;
      // вагоно/доба
      case 4:  
          cost = 
              Number.isInteger(inputData.countCarrs) && Number.isInteger(inputData.countDays) 
              ? inputData.countCarrs * inputData.countDays * price 
              : NaN;   
          break;
      
      default:
          cost = price;        
  }

  return (cost);
};
  
/**
 * 
 *    const e = await axios.post(['/api-sys/events/set_event',''].join(''), {
      type_events_id: 1,
      type_source_id: 6,
      event_date: '2022-01-08 22:21:16',
      parent_object_id: 0,
      event_text: 'Розрахенок тарифу',
      opers_id: 0,
      profile_id: 1,
      is_public: 0,
      keys:[{ event_date: '2022-01-08 22:21:16', event_key: params, event_result: response.data, event_extended: '{"a": 1, "b": 2}' }],
      objects: []
    });
 * 
 */

