import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import ListResults from '../../sections/@dashboard/services/mesplan/soglReestr/SoglReestrListResults';
import ListToolbar from '../../sections/@dashboard/services/mesplan/soglReestr/SoglReestrListToolbar';
import CommonAlert from '../../common/CommonAlert/CommonAlert';
import { CommonAlertHolder } from '../../common/CommonAlert/CommonAlertHolder';

const SoglReestrList = () => (
  <>
    <Helmet>
      <title>Sogl Reestr</title>
    </Helmet>

    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <CommonAlert showFunc={(ref) => {
          CommonAlertHolder.setAlert(ref)
        }} />
        <ListToolbar />
        <Box sx={{ pt: 3 }}>
          <ListResults />
        </Box>
      </Container>
    </Box>

  </>
);

export default SoglReestrList;
