import React from "react";

import axios from 'axios';
import { isValidToken, getUserSession, setUserSession, setSession } from './jwt';
// config
import { HOST_API } from '../config';
// ----------------------------------------------------------------------
const axiosInstance = axios.create({
  baseURL: HOST_API,
  validateStatus: () => true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

const _getToken = () => {

  let token = window.localStorage.getItem('accessToken');
  // console.log(token);
  return async () => {
      if (token && isValidToken(token)) return token;
      const lUser = JSON.parse(getUserSession());
      if (lUser && lUser.email && lUser.password) {
      // console.log(lUser);  
      const response = await axios.post(`${HOST_API}/api-sys/auth/getUser`, {
        username: lUser.email,
        password: lUser.password,
      });
      const { accessToken, refreshToken, user } = {accessToken: response.data.access_token, refreshToken: response.data.refresh_token, user: response.data.user};
      setUserSession(user);
      setSession(accessToken);

      token = accessToken || ''
    }
    else token = '';
      return token
  }
}
const getToken = _getToken()

axiosInstance.interceptors.request.use(async (config) => {
  const token =  await getToken()
  return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}`}
  }
})



export default axiosInstance;
