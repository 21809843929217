// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    page: 1,
    cnt: 10,
    open: false,
    findUnit: 0,
    findSost: 0,
    itemsBugs: {},
    rowBugs: {}
};

const slice = createSlice({
    name: 'bugs',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getBugsSuccess(state, action) {
            state.itemsBugs = action.payload;
        },
        getBugsItemSuccess(state, action) {
            state.rowBugs = action.payload;
        },

        setPageSuccess(state, action) {
            state.page = action.payload;
        },
        setCntSuccess(state, action) {
            state.cnt = action.payload;
        },
        setOpenSuccess(state, action) {
            state.open = action.payload;
        },
        setItemUnitSuccess(state, action) {
            state.findUnit = action.payload;
        },
        setItemSostSuccess(state, action) {
            state.findSost = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBugs(page, cnt, exc, st) {
    return async () => {
        try {
            const response = await axios.get(`/api-sys/ppaw/bugs?executer=${exc}&page=${page}&count=${cnt}&status=${st}`);
            dispatch(slice.actions.getBugsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getBugsItem(page, cnt, exc) {
    return async () => {
        try {
            const response = await axios.get(`/api/get_bugs_item.php?executer=${exc}&page=${page}&count=${cnt}`);
            dispatch(slice.actions.getBugsItemSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// =====================================================
export function setPage(obj) {
    console.log(obj);
    return async () => {
        try {
            dispatch(slice.actions.setPageSuccess(obj));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function setCnt(obj) {
    console.log(obj);
    return async () => {
        try {
            dispatch(slice.actions.setCntSuccess(obj));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
// =====================================================
export function setOpen(obj) {
    console.log(obj);
    return async () => {
        try {
            dispatch(slice.actions.setOpenSuccess(obj));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function setItemUnit(obj) {
    console.log(obj);
    return async () => {
        try {
            dispatch(slice.actions.setItemUnitSuccess(obj));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function setItemSost(obj) {
    console.log(obj);
    return async () => {
        try {
            dispatch(slice.actions.setItemSostSuccess(obj));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
