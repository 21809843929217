
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { CONFIG_MESPLAN as config } from '../../../../../../config';
import allActions from '../../../../../../redux/actions/index';
import { defaultStorage } from './defaultStorage';
import { CommonAlertHolder } from '../../../../../../common/CommonAlert/CommonAlertHolder';

export default function searchReestr(data, dispatch, keycloak, handleClose, oldData = null, loadBlock = 1, defaultMonth = 202111) {

    const nomenklatArr = data.nomenklat && data.nomenklat.length > 0 ? data.nomenklat.map((el) => {
        return el?.value;
    }) : null;

    const esrArr = data.esr_otpr && data.esr_otpr.length > 0 ? data.esr_otpr.map((el) => {
        return el?.value;
    }) : null;

    const klientArr = data.klient && data.klient.length > 0 ? data.klient.map((el) => {
        return el?.value;
    }) : null;

    let _order = '';
    if (data.sort) {
        data.sort.map((el) => (
            _order += `${Object?.keys(el)[0]}, `
        ))
    }

    if (oldData && oldData?.plan) {
        if (oldData?.plan.length % 300 === 0) {
            loadBlock = oldData?.plan.length / 300 + 1;
        }
    }

    const requestData =
    {
        month: defaultMonth,
        block: loadBlock,
        vid_plan: data.vid_plan,
        vid_perevez: data.vid_perevez ? data.vid_perevez?.value : null,
        plan_status: data.plan_status ? data.plan_status?.value : null,
        nomenklat: nomenklatArr,
        esr_otpr: esrArr,
        dir_otpr: data.dir_otpr,
        zal_otpr: data.zal_otpr ? data.zal_otpr?.value : null,
        stran_otpr: data.stran_otpr ? data.stran_otpr?.value : null,
        klient: klientArr,
        elevator: data.elevator ? data.elevator.value : null,
        plan_number: data.plan_number,
        plan_number_snd: data.plan_number_snd,
        ozv_vl: data.ozv_vl ? data.ozv_vl?.value : null,
        order: _order,
    }

    if (loadBlock === 1) {
        dispatch(allActions.reestrActions.setReestr([], true))
    }
    else {
        dispatch(allActions.reestrActions.setReestr(oldData, true))
    }
    console.log("config", config);
    axios({
        method: 'post',
        url: `${config.nsiUrl}/api/v1/proxyPost`,
        params: { gatewayUrlEnd : `Reestr/inpReestr` },
        // url: `${config.apiUrl}/api/v1/Reestr/inpReestr`,
        data: requestData,
        headers: { "Access-Control-Allow-Origin": "*" }
    }).then((response) => {
        //
        if (loadBlock === 1) {
            dispatch(allActions.reestrActions.setReestr(response?.data, false))
            // write to session storage
            // searh values
            sessionStorage.setItem(defaultStorage, JSON.stringify({ ...data, month: defaultMonth, block: loadBlock }));
            // write default values to bd
            axios({
                method: 'post',
                url: `${config.nsiUrl}/api/v1/DefaultValues/SetValues?keycloakGuid=${keycloak}&sKey=${defaultStorage}`,
                data: { ...data, month: defaultMonth, block: loadBlock },
                headers: { "Access-Control-Allow-Origin": "*" },
            })
        }
        else if (oldData && response?.data?.plan) {
            const tempReestr = cloneDeep(oldData);
            tempReestr.plan = [...oldData?.plan, ...response?.data?.plan];
            dispatch(allActions.reestrActions.setReestr(tempReestr, false));
        }


        if (handleClose) {
            handleClose();
        }
        // CommonAlertHolder.alert('success', 'Дані знайдено! (реєстр вводу)')
    }, (error) => {
        console.log(error);
        if (loadBlock === 1) {
            dispatch(allActions.reestrActions.setReestr([], false))
        }
        // CommonAlertHolder.alert('error', 'Помилка пошуку за заданими параметрами')
    });
}