/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Stack, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import useLocales from '../../hooks/useLocales';

export default function ConfirmationModal() {

  const { translate } = useLocales();

  const {
    isOpen,
    title,
    content,
    onSubmit,
    onCancel
  } = useSelector(state => state.confirmation);

  return (
      <Dialog open={isOpen || false} onClose={onCancel}>
          <DialogTitle sx={{ marginBottom: '24px' }}>{title}</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>{content}</DialogContent>
          <DialogActions>
              <Stack direction="row" spacing={2} alignItems="flex-end">
                  <Button onClick={onCancel}>
                    {translate('confirmationModal.cancel')}
                  </Button>
                  <Button onClick={onSubmit}>
                    {translate('confirmationModal.submit')}
                  </Button>
              </Stack>
          </DialogActions>
      </Dialog>
  );

}