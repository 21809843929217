import { createSlice } from '@reduxjs/toolkit';
// utils
import { toast } from "react-toastify";
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { newEventKeyObj, sourceEnum, typeEventEnum } from './service.events';
import { loaderEnd, loaderStart } from './loader';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  rcError: null,
  eventsForCustomer: null,
  eventsForOwner: null,
  isReserveResourceModalOpened: false,
  selectedDateFrom: null,
  dynamicOptionsList: {},
  autocompleteLoader: false
};

export const slice = createSlice({
  name: 'rcalendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    startAutoCompleteLoader(state, action) {
      state.autocompleteLoader = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.rcError = action.payload;
    },
    setEventsForCustomer(state, action) {
      state.eventsForCustomer = action.payload
    },
    setEventsForOwner(state, action) {
      state.eventsForOwner = action.payload
    },
    setIsReserveResourceModalOpened(state, action) {
      state.isReserveResourceModalOpened = action.payload
    },
    setSelectedDateFrom(state, action) {
      state.selectedDateFrom = action.payload
    },
    updateEventsForOwner(state, action) {
      state.eventsForOwner = action.payload;
    },
    setDynamicOptionsList(state, action) {
      const { data, key } = action.payload;
      state.dynamicOptionsList[key] = data;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { 
  startLoading, 
  openModal, 
  closeModal, 
  setEventsForCustomer, 
  setEventsForOwner,
  setIsReserveResourceModalOpened, 
  setSelectedDateFrom,
  updateEventsForOwner,
  setDynamicOptionsList,
  startAutoCompleteLoader
} = slice.actions;

export const openReserveResourceModal = (isOpen, date) => {
  dispatch(setIsReserveResourceModalOpened(isOpen));
  dispatch(setSelectedDateFrom(date));
};

export const getEventsForCustomer = async (partnerId, officeId, month, year, typeId) => {
  loaderStart();
  await axios.get(`api-sys/rcalendar/month/customer?idp=${partnerId}&idc=${officeId}&m=${month}&y=${year}&code=${(typeId || '')}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setEventsForCustomer(res.data));
      }
    })
  loaderEnd();
};

export const getEventsForOwner = async (officeId, month, year, typeId) => {
  loaderStart();
  await axios.get(`api-sys/rcalendar/month/vlasni?idp=${officeId}&m=${month}&y=${year}&code=${(typeId || '')}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setEventsForOwner(res.data));
      }
    })
  loaderEnd();
};

export const reserveResource = async data => {
  const reserveData = {};
  const {idVlasnyk, idProfile, ...newData} = data;
  reserveData.idVlasnyk = idVlasnyk;
  reserveData.month = new Date(data.dateFrom).getMonth() + 1;
  reserveData.year = new Date(data.dateFrom).getFullYear();
  reserveData.days = [newData];
  reserveData.code_resource = newData.code_resource;
  reserveData.attributes = newData.attributes;
  dispatch(startLoading(true))
  await axios.post(`api-sys/rcalendar/month/set`, reserveData)
    .then(res => {
      if (res.status === 200) {
        dispatch(setEventsForCustomer(res.data));
        dispatch(setIsReserveResourceModalOpened(false));
        const id = dispatch(
          newEventKeyObj(typeEventEnum.query, sourceEnum.USER, 0, 'Запит ресурсу', idProfile, idVlasnyk, 0, '/ekbs/services/rcalendar/rcalendar_resource', reserveData, res.data, null, [])
        );
        toast.success('Бронювання успішно створено.')
      }
    }).catch(err => {
      toast.error('Виникла помилка!')
    });
    dispatch(startLoading(false))
};

export const reserveMyResource = async data => {
  const reserveData = {};
  const {idVlasnyk, idProfile, ...newData} = data;
  reserveData.idVlasnyk = idVlasnyk;
  reserveData.month = new Date(data.dateFrom).getMonth() + 1;
  reserveData.year = new Date(data.dateFrom).getFullYear();
  reserveData.days = [newData];
  reserveData.code_resource = newData.code_resource;
  dispatch(startLoading(true))
  await axios.post(`api-sys/rcalendar/month/set`, reserveData)
    .then(res => {
      if (res.status === 200) {
        dispatch(setEventsForOwner(res.data));
        dispatch(setIsReserveResourceModalOpened(false));
        const id = dispatch(
          newEventKeyObj(typeEventEnum.query, sourceEnum.USER, 0, 'Бронювання свого ресурсу', idProfile, idVlasnyk, 0, '/ekbs/services/rcalendar/rcalendar_resource', reserveData, res.data, null, [])
        );
        toast.success('Бронювання успішно створено.')
      }
    }).catch(err => {
      toast.error('Виникла помилка!')
    });
    dispatch(startLoading(false))
};

export const confirmReserve = async (data) => {
  loaderStart();
  await axios.post(`api-sys/rcalendar/month/set`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(updateEventsForOwner(res.data));
      }
    })
  loaderEnd();
};

export const getAutocompleteOptionsList = async (nameTable, nameField, keyField, nameFilter, searchValue) => {
  dispatch(startAutoCompleteLoader(true));
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=${nameTable}&titleOut=${nameField}&valueOut=${keyField}&value=${searchValue}&searchField=${nameField}&all=false&limit=2000`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setDynamicOptionsList({ data: res.data, key: nameTable }));
        dispatch(startAutoCompleteLoader(false));
      }
    });
}

export const getChildOptionList = async (nameTable, nameField, keyField, valueAttr, nameFilter) => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=${nameTable}&titleOut=${nameField}&valueOut=${keyField}&value=${valueAttr}&searchField=${nameFilter}&all=false&limit=2000`)
  .then(res => {
    if (res.status === 200) {
      dispatch(setDynamicOptionsList({ data: res.data, key: nameTable }));
      dispatch(startAutoCompleteLoader(false));
    }
  });
}
