export default function createStrSearcParams(data) 
{
    const vidPerevezArr = data.vid_perevez && data.vid_perevez?.length > 0 ? data.vid_perevez.map( (el) => {
      return el?.title;
    }) : null;

    const planStatusArr = data.plan_status && data.plan_status?.length > 0 ? data.plan_status.map( (el) => {
      return el?.title;
    }) : null;

    const nomenklatArr = data.nomenklat && data.nomenklat?.length > 0 ? data.nomenklat.map( (el) => {
      return el?.title;
    }) : null;

    const etsnvArr = data.etsnv && data.etsnv?.length > 0 ? data.etsnv.map( (el) => {
      return el?.title;
    }) : null;

    const esrArr = data.esr_otpr && data.esr_otpr?.length > 0 ? data.esr_otpr.map( (el) => {
      return el?.title;
    }) : null;

    const klientArr = data.klient && data.klient?.length > 0 ? data.klient.map( (el) => {
      return el?.title;
    }) : null;

    const esrPribArr = data.esr_prib && data.esr_prib?.length > 0 ? data.esr_prib.map( (el) => {
      return el?.title;
    }) : null;

    const esrOsgdArr = data.esr_osgd && data.esr_osgd?.length > 0 ? data.esr_osgd.map( (el) => {
      return el?.title;
    }) : null;

    const poluchUkrArr = data.poluch_ukr && data.poluch_ukr?.length > 0 ? data.poluch_ukr.map( (el) => {
      return el?.title;
    }) : null;

    const ekspedUkrArr = data.eksped_ukr && data.eksped_ukr?.length > 0 ? data.eksped_ukr.map( (el) => {
      return el?.title;
    }) : null;

    const ekspedFormirArr = data.eksped_formir && data.eksped_formir?.length > 0 ? data.eksped_formir.map( (el) => {
      return el?.title;
    }) : null;

    const soglasOrgArr = data.soglas_org && data.soglas_org?.length > 0 ? data.soglas_org.map( (el) => {
      return el?.title;
    }) : null;

    const vagIdArr = data.vag_id && data.vag_id?.length > 0 ? data.vag_id.map( (el) => {
      return el?.title;
    }) : null;

    const vagRpsArr = data.vag_rps && data.vag_rps?.length > 0 ? data.vag_rps.map( (el) => {
      return el?.title;
    }) : null;

    let str = 'Параметри пошуку: ';
    str += data.vid_plan ? `Вид плану: '${data.vid_plan}, '` : '';
    str += planStatusArr && planStatusArr?.length > 0 ? `, Статус: '${planStatusArr.join()}'` : '';
    str += vidPerevezArr && vidPerevezArr?.length > 0 ? `, Вид перевезення: '${vidPerevezArr.join()}'` : '';
    str += data.ozv_vl ? `, Ознака власності: '${data.ozv_vl?.title}'` : '';
    str += data.perevez_smech ? `, Ознака змішаного перевезення: '${data.perevez_smech}'` : '';
    str += data.perevez_marshrut ? `, Признак маршрута: '${data.perevez_marshrut}'` : '';
    str += data.elevator ? `, Елеватор: '${data.elevator}'` : '';
    str += nomenklatArr && nomenklatArr?.length > 0 ? `, Номенклатура вантажу: '${nomenklatArr.join()}'` : '';
    str += etsnvArr && etsnvArr?.length > 0 ? `, ЕТСНГ: '${etsnvArr.join()}'` : '';
    str += esrArr   && esrArr?.length > 0 ? `, Cтанція відправлення: '${esrArr.join()}'` : '';
    str += data.dir_otpr ? `, Дирекція відправлення: '${data.dir_otpr}'` : '';
    str += data.zal_otpr ? `, Залізниця відправлення: '${data.zal_otpr}'` : '';
    str += data.strn_otpr ? `, Держава відправлення: '${data.strn_otpr?.title}'` : '';
    str += klientArr && klientArr?.length > 0 ? `, Клієнт: '${klientArr.join()}'` : '';
    str += esrPribArr && esrPribArr?.length > 0 ? `, Cтанція призначення: '${esrPribArr.join()}'` : '';
    str += data.dir_prib ? `, Дирекція призначення: '${data.dir_prib}'` : '';
    str += data.zal_prib ? `, Залізниця призначення: '${data.zal_prib}'` : '';
    str += data.strn_prib ? `, Держава призначення: '${data.strn_prib?.title}'` : '';
    str += esrOsgdArr && esrOsgdArr?.length > 0 ? `, Cтанція призначення ОСЖД: '${esrOsgdArr.join()}'` : '';
    str += poluchUkrArr && poluchUkrArr?.length > 0 ? `, Одержувач: '${poluchUkrArr.join()}'` : '';
    str += ekspedUkrArr && ekspedUkrArr?.length > 0 ? `, Експедитор: '${ekspedUkrArr.join()}'` : '';
    str += ekspedFormirArr && ekspedFormirArr?.length > 0 ? `, Платник плану формування: '${ekspedFormirArr.join()}'` : '';
    str += data.soglas_code ? `, Результат узгодження: '${data.soglas_code?.title}'` : '';
    str += soglasOrgArr && soglasOrgArr?.length > 0 ? `, Організація узгодження: '${soglasOrgArr.join()}'` : '';
    str += data.soglas_uz ? `, Погодження УЗ: '${data.soglas_uz}'` : '';
    str += data.pereviznyk ? `, Перевізник: '${data.pereviznyk}'` : '';
    str += vagIdArr && vagIdArr?.length > 0 ? `, Ідентифікатор вагона: '${vagIdArr.join()}'` : '';
    str += vagRpsArr && vagRpsArr?.length > 0 ? `, РПС: '${vagRpsArr.join()}'` : '';
    str += data.vag_type ? `, Тип вагона: '${data.vag_type}'` : '';
    str += data.plan_number ? `, Номер плану: '${data.plan_number}'` : '';
    str += data.plan_number_snd ? `, Номер плану СНД: '${data.plan_number_snd}'` : '';
    str += data.loc_pr ? `, Наявність локомотивної складової: '${data.loc_pr}'` : '';
    str += data.loc_id ? `, Ідентифікатор власника локомотива: '${data.loc_id}'` : '';
    
    return str;
  }