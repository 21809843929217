import React from 'react';
import {
    Grid,
    FormControl,
    TextField,
    useMediaQuery,
    Tooltip,
    Typography,
    Checkbox,
} from '@mui/material';
import { Controller } from "react-hook-form";

export default function Plan(props) {
    const matches = useMediaQuery('(min-width:720px)');
    //
    const register = props._register;
    const control = props._control;
    const data = props.data;
    const disableElement = data && data?.soglasElz && (data?.soglasElz?.soglas_code === '0' || data?.soglasElz?.soglas_code === '');
    //
    return (
        <>
            <Grid item xs={11} md={11}>

                <Tooltip title={
                    <>
                        {data ?
                            <>
                               {data?.k_count   !== '' ? <Typography color="inherit" variant="h6">Узгоджено: {data?.k_count}</Typography> : <></>}
                               {data?.dop_count !== '' ? <Typography color="inherit" variant="h6">Додаткові: {data?.dop_count}</Typography> : <></>}
                               {data?.za_count  !== '' ? <Typography color="inherit" variant="h6">Заявлено: {data?.za_count}</Typography> : <></>}
                               {data?.na_count  !== '' ? <Typography color="inherit" variant="h6">Навантажено: {data?.na_count}</Typography> : <></>}
                               {disableElement ? <Typography color="inherit" variant="h6">Редагуванян не можливе, немає узгодження</Typography> : <></>}
                            </>
                            : <></>}
                    </>
                } >
                    <FormControl fullWidth>
                        <TextField
                            type="number"
                            id="plan_vag_id"
                            name="plan_vag"
                            {...register("plan_vag")}
                            label="Кількість вагонів"
                            variant="outlined"
                            disabled={disableElement}
                            size={matches ? '' : 'small'} />
                    </FormControl>
                </Tooltip>
            </Grid>
            <Grid item xs={11} md={1}>
                <FormControl>
                    <Controller
                        name={`plan_zdv`}
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                size={matches ? '' : 'small'}
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value}
                                disabled={disableElement}
                            // sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
        </>
    )
}