import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const { translate } = useLocales();
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: 'demo@vp.co.ua', // 'demo@minimals.cc',
    password: 'EKBS_Test00', // 'demo1234',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  // const { setFieldValue } = methods;

  const onSubmit = async (data) => {
    try {
      if (data.remember)
      {
        localStorage.setItem('login_email', data.email);
        localStorage.setItem('login_password', data.password);
      }
      else
      {
        localStorage.removeItem('login_email');
        localStorage.removeItem('login_password');
      }
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error.message);
      }
    }
  };

  /*
  onSubmit: async (data) => {
      const u = await login(data.email, data.password);
      if (u.code === 0) {
        navigate('/app/app', { replace: true });
      } else if (u.code === 1) setUError(translate('login.error'));
      else setUError(translate('login.already'));
    }
   */
    useEffect(() => {
      const v1 = localStorage.getItem('login_email');
      const v2 = localStorage.getItem('login_password');
      setValue('email', v1 || 'demo@vp.co.ua');
      setValue('password', v2 || 'EKBS_Test00');
    }, []);

  // ==========================================================================================
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <Typography variant="body2">(demo@vp.co.ua - demo user)</Typography>
        <RHFTextField name="email" label={translate('login.t6')} />

        <RHFTextField
          name="password"
          label={translate('login.t7')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label={translate('login.t8')} />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
        {translate('login.t9')}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
      {translate('login.t10')}
      </LoadingButton>
    </FormProvider>
  );
}
