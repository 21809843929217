import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';
// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = (Date.now()/ 1000) -30;
  // console.log(decoded.exp, currentTime);
  return decoded.exp > currentTime;
};

// post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
// const axiosPost = async (url, data?, config? ) => {
//   axios.post()
// };

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

// ----------------------------------------------------------------------
const addHeaderHttp = (name, value) => {
  // --
  axios.defaults.headers.common[name] = value;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
const setUserSession = (accessUser) => {
  if (accessUser) {
    localStorage.setItem('accessUser', JSON.stringify(accessUser));
    // console.log('setUserSession', accessUser);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessUser');
    // delete axios.defaults.headers.common.Authorization;
  }
};
const getUserSession = () => {
  const accessUser = localStorage.getItem('accessUser');
  // console.log('getUserSession', accessUser);
  return accessUser;
};

export { isValidToken, setSession, setUserSession, getUserSession, verify, sign, addHeaderHttp };

