import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { loaderStart, loaderEnd } from './loader';
import { showConfirmationModal } from './confirmation'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: { data: [] },
  offers: null,
  offer: null,
  orders: { data: [] },
  order: null,
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: null,
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    quantity: 0,
    dateCheckout: new Date(), 
    idPidpr: 0,
    accept: [],
  },
  cart: {},
  dicPidprServices: [],
  dicPidprTypeResources: [],
  mainPageFilters: {
    page: 1,
    count: 20,
    search: '',
    dateBeg: '',
    dateEnd: '',
    category: '',
    idResource: '',
    cntItems: '',
    priceMin: '',
    priceMax: '',
    // activeStep: ''
  },
  offersFilters: {
    page: 1,
    count: 20,
    search: '',
    dateBeg: '',
    dateEnd: '',
    category: '',
    idResource: '',
    cntItems: '',
    priceMin: '',
    priceMax: '',
    // activeStep: ''
  },
  checkoutMessages: [],
  favouritesList: [],
  isComparisonModalOpened: false,
  productsToShow: 'offers',
  // tariff calc
  showTariffCalc: false,
  rodVagByCargo: null,
  averageCarring: null
};

const slice = createSlice({
  name: 'toloka',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getLoadCartSuccess(state, action) {
      console.log(action.payload)
      state.isLoading = false;
      state.checkout = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },

    getPidprServicesSuccess(state, action) {
      state.isLoading = false;
      state.dicPidprServices = action.payload;
    },    
    getPidprTypeResourcesSuccess(state, action) {
      state.isLoading = false;
      state.dicPidprTypeResources = action.payload;
    },
    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload || [];

      const subtotal = cart.cntItems * cart.price;
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      // state.checkout.cart = cart;
      // state.checkout.discount = discount;
      // state.checkout.shipping = shipping;
      // state.checkout.billing = billing;
      // state.checkout.subtotal = subtotal;
      // state.checkout.total = subtotal; // - discount;
    },

    addCart(state, action) {
      const product = action.payload;
        state.checkout.cart = product;
        state.checkout.quantity = product.cntItems;
        state.checkout.total = product.cntItems * product.price;
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const updateCart = {
        ...state.checkout,
        quantity: state.checkout.quantity + 1,
        total: (state.checkout.quantity + 1) * action.payload
      }

      state.checkout = updateCart;
    },

    decreaseQuantity(state, action) {
      const updateCart = {
        ...state.checkout,
        quantity: state.checkout.quantity - 1,
        total: (state.checkout.quantity - 1) * action.payload
      }

      state.checkout = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },

    // marukhno.v

    _setMainPageFilters: (state, action) => ({
      ...state,
      mainPageFilters: { ...state.mainPageFilters, ...action.payload }
    }),
    _setOffersPageFilters: (state, action) => ({
      ...state,
      offersFilters: { ...state.offersFilters, ...action.payload }
    }),
    setOffers: (state, action) => ({
      ...state,
      offers: action.payload
    }),
    setCheckoutMessages: (state, action) => ({
      ...state,
      checkoutMessages: action.payload
    }),
    setComparisonModal: (state, action) => ({
      ...state,
      isComparisonModalOpened: action.payload
    }),
    setFavouritesList: (state, action) => ({
      ...state,
      favouritesList: action.payload
    }),
    _setProductsToShow: (state, action) => ({
      ...state,
      productsToShow: action.payload
    }),
    _setShowTariffCalc: (state, action) => ({
      ...state,
      showTariffCalc: action.payload
    }),
    setRodVagByCargo: (state, action) => ({
      ...state,
      rodVagByCargo: action.payload
    }),
    setAverageCarring: (state, action) => ({
      ...state,
      averageCarring: action.payload
    })
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  getProductSuccess,
  _setMainPageFilters,
  setOffers,
  _setOffersPageFilters,
  setCheckoutMessages,
  setComparisonModal,
  setFavouritesList,
  _setProductsToShow,
  _setShowTariffCalc,
  setRodVagByCargo,
  setAverageCarring
} = slice.actions;

// ----------------------------------------------------------------------

export const setShowTariffCalc = (isOpen) => {
  dispatch(_setShowTariffCalc(isOpen))
}

export const getVagonDetails = (codeCargo) => {
  const sqlRequests = [
    `" SELECT v.ROD_VAG FROM nsi_ekbs.Dic_Cargo_Group_Vag v WHERE v.CODE_GROUP = ` +
    ` (SELECT d.code_group FROM nsi_ekbs.Dic_Cargo_Group_Diapaz d WHERE ${codeCargo} BETWEEN d.min_cargo_code AND d.max_cargo_code) "`,

    `" SELECT viewRV.max_carrying as maxCarring, viewRV.min_carrying as minCarring FROM views.V_ROD_VAGS viewRV              ` +
    ` INNER JOIN nsi_ekbs.Dic_Cargo_Group_Vag dicGroup on viewRV.code_rps=dicGroup.rod_vag                ` +
    ` INNER JOIN nsi_ekbs.Dic_Cargo_Group_Diapaz dicDiapaz on dicDiapaz.code_group=dicGroup.code_group    ` +  
    ` WHERE ${codeCargo} BETWEEN dicDiapaz.min_cargo_code AND dicDiapaz.max_cargo_code "`
  ]
  const axiosArr = sqlRequests.map(req => axios.post(`/api-vantazh/NsiData`, req, { headers: {  'Content-Type': 'text/json' } } ))
  Promise.all(axiosArr).then(responces => {
    if (responces[0].data.length) {
      dispatch(setRodVagByCargo(responces[0].data[0].ROD_VAG))
    }
    if (responces[1].data.length) {
      const { maxCarring, minCarring } = responces[1].data[0]
      dispatch(setAverageCarring((maxCarring + minCarring) / 2))
    }
  })
}

export const clearVagonDetails = () => {
  dispatch(setRodVagByCargo(null))
  dispatch(setAverageCarring(null))
}

export const setProductsToShow = (show) => {
  dispatch(_setProductsToShow(show))
}

export const openComparisonModal = (isOpen) => {
  dispatch(setComparisonModal(isOpen))
}

const getSearchCondition = params => {
  const last = Object.keys(params)[Object.keys(params).length - 1]
  return Object.keys(params).map((key) => `${key}=${params[key]}${key === last ? '' : '&'}`).join().split(',').join('')
}

export const setMainPageFilters = (params) => {
  dispatch(_setMainPageFilters(params))
}

export const setOffersPageFilters = params => {
  dispatch(_setOffersPageFilters(params));
}

export const getProducts = async (params, callBack = undefined) => {
  loaderStart()
  await axios.get(`/api-toloka/toloka/items?${getSearchCondition(params)}`)
  .then(res => {
    if(res.status === 200) {
      setMainPageFilters(params)
      dispatch(slice.actions.getProductsSuccess(res.data))
      if (callBack) {
        callBack()
      }
    }
  }).catch(error => {
    dispatch(slice.actions.hasError(error));
  })
  loaderEnd();
}

export const getFavouritesList = async () => {
  loaderStart()
  await axios.get(`/api-toloka/toloka/compare`)
  .then(res => {
    if(res.status === 200) {
      dispatch(setFavouritesList(res.data));
    }
  }).catch(error => {
    dispatch(slice.actions.hasError(error));
  })
  loaderEnd();
}

export const getOffers = async (params) => {
  loaderStart()
  await axios.get(`/api-toloka/toloka/offers?${getSearchCondition(params)}`)
  .then(res => {
    if(res.status === 200) {
      setOffersPageFilters(params)
      dispatch(setOffers(res.data));
    }
  }).catch(error => {
    dispatch(slice.actions.hasError(error));
  })
  loaderEnd();
}

// -----------------------------------------------------

export const getLoadCart = async (id) => {
  loaderStart()
  await axios.get(`/api-toloka/toloka/checkout/${id}`)
  .then(res => {
    if(res.status === 200) {
      dispatch(slice.actions.getLoadCartSuccess(res.data));
    }
  }).catch(error => {
    dispatch(slice.actions.hasError(error));
  })
  loaderEnd()
}


export const getPidprServices = async (all = 0) => {
  await axios.get(`/api-toloka/toloka/pidpr/services?all=${all}`)
  .then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.getPidprServicesSuccess(res.data));
    }
  }).catch(e => {
    dispatch(slice.actions.hasError(e));
  })
}

export const getPidprTypeResources = async (code) => {
  await axios.get(`/api-toloka/toloka/pidpr/resources?code_service=${code}`)
  .then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.getPidprTypeResourcesSuccess(res.data));
    }
  }).catch(e => {
    dispatch(slice.actions.hasError(e));
  })
}
// ----------------------------------------------------------------------

export function getProduct(id) {
  loaderStart()
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api-toloka/toloka/products/${id}`);
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
    loaderEnd()
  };
}


export const setReview = async (data) => {
  await axios.post(`/api-toloka/toloka/product/review`, data)
}

export const checkoutProduct = async (data, callBack) => {
  loaderStart()
  await axios.post(`/api-toloka/toloka/checkout`, data)
  .then(res => {
    if(res.status === 200) {
      callBack();
      toast.success('Заява на оголошення сформована');
    }
  })
  loaderEnd()
}

export const approveRejectRequest = async (data, callBack) => {
  loaderStart()
  await axios.post(`/api-toloka/toloka/checkout/accept`, data)
  .then(res => {
    if(res.status === 200) {
      callBack()
      if(data.status === 1) {
        toast.success('Заяву погоджено');
        return;
      }
      toast.warning('Заяву відхилено');
    }
  })
  loaderEnd()
}

export const completeCooperation = async (data, callBack) => {
  loaderStart()
  await axios.put(`/api-toloka/toloka/checkout`, data)
  .then(res => {
    if(res.status === 200) {
      callBack()
      toast.success('Співпрацю успішно завершено');
    }
  })
  loaderStart()
}

export const getCheckoutMessages = async (idCheckout) => {
  await axios.get(`/api-toloka/toloka/checkout/message?idCheckout=${idCheckout}`)
  .then(res => {
    if(res.status === 200) {
      dispatch(setCheckoutMessages(res.data))
    }
  })
}

export const sendMessage = async (data) => {
  await axios.post(`/api-toloka/toloka/checkout/message`, data)
  // .then(res => {
  //   if(res.status === 200) {
  //   }
  // })
}

export const addToFavorites = async (data, callBack) => {
  await axios.post(`/api-toloka/toloka/product/favorites`, data)
  .then(res => {
    if (res.status === 200) {
      callBack()
      getFavouritesList()
    }
  })
}

export const removeFromFavorites = async (id, callBack) => {
  await axios.delete(`/api-toloka/toloka/product/favorites?idrec=${id}`)
  .then(res => {
    if (res.status === 200) {
      callBack()
      getFavouritesList()
    }
  })
}

export const confirmDoubleOrder = (handleConfirm) => showConfirmationModal({
  isOpen: true,
  title: 'Подача заявки',
  content: `Заявка на оголошення вже створена раніше. Подати повторно заявку?`,
  onSubmit: () => {handleConfirm();showConfirmationModal({ isOpen: false })},
  onCancel: () => showConfirmationModal({ isOpen: false })
})