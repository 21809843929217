import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// hooks
import useLocales from '../../hooks/useLocales'
// utils
import { customDateFormat } from '../../utils/formatTime';

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string
};

export default function RHFDatePicker({ name, required, size,  ...other }) {
  const { control } = useFormContext();
  const { currentLang } = useLocales();

  const handleChange = (e, onChange) => {
    const date = customDateFormat(e, 'yyyy-MM-dd').concat('T00:00:00Z');
    onChange(date);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const {onChange, ...props} = field;
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            error={!!error}
            helperText={error?.message}
            {...props}
            {...other}
            inputFormat={currentLang.value === 'uk' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}   
            onChange={e => { try { handleChange(e, onChange); } catch(e) { console.error(e.message) } }  }
            renderInput={(params) => 
              <TextField 
                {...field} 
                fullWidth 
                {...params} 
                required={required} 
                size={size}
                error={!!error}
                helperText={error?.message}
              />
            }

          />
        </LocalizationProvider>
      }}
    />
  );
}
