import {
  Box,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  IconButton,
  TextField,
  Grid,
  Tooltip,
  Typography 
} from '@mui/material';
import React, { useCallback, Suspense } from "react";
import { 
  Search as SearchIcon, 
  Cached as LoaderIcon, 
  FilterAlt as FilterIcon 
} from '@mui/icons-material';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
//
import { headCellsSoglReestr as _headCells} from '../../../../../common/constants/headerReestrSogl';
import { CONFIG_MESPLAN as config } from '../../../../../config';
import allActions from '../../../../../redux/actions/index';
import searchReestr from './utils/search';
import { defaultStorage, tableSettingsStorage } from './utils/defaultStorage';
import createStrSearcParams from './utils/createSearchShowStr';
import { getUserSession } from '../../../../../utils/jwt';
import useLocales from '../../../../../hooks/useLocales';

const SoglReestrListToolbar = (props) => {
  const SearchComponent = React.lazy(() => import('./SoglReestrSearchModal'));
  const ShowSettingComponent = React.lazy(() => import('../../../../../common/components/SettingShowModalDataGrid'));
  const [openSearchSogl, setOpenSearchSogl] = React.useState(false);
  const [openSettingShow, setOpenSettingShow] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [searchOptions, setSearchOptions] = React.useState('');
  const handleOpen = () => setOpenSearchSogl(true);
  const handleClose = () => setOpenSearchSogl(false);
  const { translate } = useLocales();
  const handleOpenSettingShow = () => setOpenSettingShow(true);
  const dispatch = useDispatch();
  const user = JSON.parse(getUserSession());
  const selectedMonth = useSelector(state => state.userMesplan?.month);
  const debounceFilter = useCallback(_debounce(handleDebounceFilter, 1000), []);

  const soglReestrText = `${translate('mesplan.request_coordination')} (${selectedMonth})`;

  function handleDebounceFilter(inputValue) {
    dispatch(allActions.soglReestrActions.filterSoglReestr(inputValue));
    setFilter(inputValue);
  }

  const handleChange = (event) => {
    debounceFilter(event.target.value);
  };

  const oldData = useSelector(state => state.soglReestr?.data);
  function handleLoadMore() {  
    const allowLoad = oldData?.length % 300 === 0;
    const block = oldData?.length / 300;
    const loadBlock = block + 1;
    if (allowLoad) {
      alert(`loadBlock is ${loadBlock}`);
      const cachedData = JSON.parse(sessionStorage.getItem(defaultStorage));
      if (cachedData) {
        searchReestr(cachedData, dispatch, user?.sub, null, oldData, loadBlock, selectedMonth);
      }
    }
  }

  function saveShowData(data) {
    dispatch(allActions.soglReestrActions.setHeaderSoglReestr(data));
  }

  React.useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem(defaultStorage));

    if (cachedData) {
      const str = createStrSearcParams(cachedData);
      setSearchOptions(str);
    }
    else {
      axios({
        method: 'get',
        url: `${config.nsiUrl}/api/v1/DefaultValues/GetValues?keycloakGuid=${user?.sub}&sKey=${defaultStorage}`,
      }).then((response) => {
        if (response.data) {
          const str = createStrSearcParams(response.data);
          setSearchOptions(str);
        }
      }).catch((error) => {
        console.log(error.toJSON());
      });
    }
  }, [JSON.parse(sessionStorage.getItem(defaultStorage))]);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // paddingTop: 6,
        }}
      >
         <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpen}
          >
            Пошук
          </Button>

          {/* <Button
            sx={{ mx: 1 }}
            color="primary"
            variant="contained"
            disabled
          >
            Додати план
          </Button> */}

          <Tooltip title="Налаштування параметрів відображення">
            <IconButton
              color="primary"
              variant="contained"
              onClick={handleOpenSettingShow}
            >
              <FilterIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Завантажити наступний блок">
            <IconButton
              color="primary"
              variant="contained"
              onClick={() => { handleLoadMore() }}
              disabled={oldData?.length % 300 !== 0 || !oldData}
            >
              <LoaderIcon />
            </IconButton>
          </Tooltip>

          <Typography variant="h5" component="h5">
            {soglReestrText}
          </Typography>

        </Grid>

        {/* <Button sx={{ mx: 1 }}
        >
          Експорт
        </Button> */}

      </Box>
      <Box sx={{ mt: 1 }}>
        <Card>
          <Grid sx={{ p: 1 }} container>
            {/* <Box sx={{ flexDirection: 'row', alignItems: 'flex-start' }}> */}
            {/* <Box sx={{ maxWidth: 500 }}> */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Фільтр ... введіть дані фільтрації"
                variant="outlined"
                onChange={handleChange}
              />
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} md={8} sx={{ p: 1 }}>
              {searchOptions}
            </Grid>
            {/* </Box> */}
          </Grid>
        </Card>
      </Box>
      <Suspense fallback={<div>Завантаження компоненти пошуку ...</div>}>
        < SearchComponent props={{ open: openSearchSogl, setOpen: setOpenSearchSogl }} />
      </Suspense>
      <Suspense fallback={<div>Завантаження компоненти налаштувань відображення ...</div>}>
        <ShowSettingComponent props={
          {
            open: openSettingShow,
            setOpen: setOpenSettingShow,
            storage: tableSettingsStorage,
            headCells: _headCells,
            setData: saveShowData,
          }
        } />
      </Suspense>
    </Box>
  );
}

export default SoglReestrListToolbar;
