import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------
const d1 = {
  "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
  "gender": "Kids",
  "publish": "draft",
  "category": "Accessories",
  "available": 0,
  "priceSale": 83.74,
  "taxes": 10,
  "quantity": 80,
  "sizes": [
    "6",
    "7",
    "8",
    "8.5",
    "9",
    "9.5",
    "10",
    "10.5",
    "11",
    "11.5",
    "12",
    "13"
  ],
  "inventoryType": "out of stock",
  "images": [
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_1.jpg",
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_2.jpg",
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_3.jpg",
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_4.jpg",
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_5.jpg",
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_6.jpg",
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_7.jpg",
    "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_8.jpg"
  ],
  "ratings": [
    {
      "name": "1 Star",
      "starCount": 9911,
      "reviewCount": 1947
    },
    {
      "name": "2 Star",
      "starCount": 1947,
      "reviewCount": 9124
    },
    {
      "name": "3 Star",
      "starCount": 9124,
      "reviewCount": 6984
    },
    {
      "name": "4 Star",
      "starCount": 6984,
      "reviewCount": 8488
    },
    {
      "name": "5 Star",
      "starCount": 8488,
      "reviewCount": 2034
    }
  ],
  "reviews": [
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
      "name": "Jayvion Simon",
      "postedAt": "2023-06-18T11:59:52.022Z",
      "comment": "The sun slowly set over the horizon, painting the sky in vibrant hues of orange and pink.",
      "isPurchased": true,
      "rating": 4.2,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg",
      "helpful": 9911,
      "attachments": []
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
      "name": "Lucian Obrien",
      "postedAt": "2023-06-17T10:59:52.022Z",
      "comment": "She eagerly opened the gift, her eyes sparkling with excitement.",
      "isPurchased": true,
      "rating": 3.7,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg",
      "helpful": 1947,
      "attachments": [
        "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_1.jpg"
      ]
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
      "name": "Deja Brady",
      "postedAt": "2023-06-16T09:59:52.022Z",
      "comment": "The old oak tree stood tall and majestic, its branches swaying gently in the breeze.",
      "isPurchased": true,
      "rating": 4.5,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_3.jpg",
      "helpful": 9124,
      "attachments": []
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
      "name": "Harrison Stein",
      "postedAt": "2023-06-15T08:59:52.022Z",
      "comment": "The aroma of freshly brewed coffee filled the air, awakening my senses.",
      "isPurchased": false,
      "rating": 3.5,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_4.jpg",
      "helpful": 6984,
      "attachments": [
        "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_3.jpg",
        "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_4.jpg"
      ]
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
      "name": "Reece Chung",
      "postedAt": "2023-06-14T07:59:52.022Z",
      "comment": "The children giggled with joy as they ran through the sprinklers on a hot summer day.",
      "isPurchased": false,
      "rating": 0.5,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_5.jpg",
      "helpful": 8488,
      "attachments": []
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
      "name": "Lainey Davidson",
      "postedAt": "2023-06-13T06:59:52.022Z",
      "comment": "He carefully crafted a beautiful sculpture out of clay, his hands skillfully shaping the intricate details.",
      "isPurchased": true,
      "rating": 3,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_6.jpg",
      "helpful": 2034,
      "attachments": [
        "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_6.jpg",
        "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_7.jpg",
        "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_8.jpg"
      ]
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
      "name": "Cristopher Cardenas",
      "postedAt": "2023-06-12T05:59:52.022Z",
      "comment": "The concert was a mesmerizing experience, with the music filling the venue and the crowd cheering in delight.",
      "isPurchased": false,
      "rating": 2.5,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_7.jpg",
      "helpful": 3364,
      "attachments": []
    },
    {
      "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
      "name": "Melanie Noble",
      "postedAt": "2023-06-11T04:59:52.022Z",
      "comment": "The waves crashed against the shore, creating a soothing symphony of sound.",
      "isPurchased": false,
      "rating": 2.8,
      "avatarUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_8.jpg",
      "helpful": 8401,
      "attachments": []
    }
  ],
  "tags": [
    "Technology",
    "Marketing",
    "Design",
    "Photography",
    "Art"
  ],
  "code": "38BEE270",
  "description": "\n<h6>Specifications</h6>\n<br/>\n<ol>\n  <li>Category</li>\n  <li>Shoes</li>\n</ol>\n\n<br/>\n<ol>\n  <li>Manufacturer</li>\n  <li>Nike</li>\n</ol>\n\n<br/>\n<ol>\n  <li>Serial Number</li>\n  <li>358607726380311</li>\n</ol>\n\n<br/>\n<ol>\n  <li>Ships From</li>\n  <li>United States</li>\n</ol>\n\n<br/>\n<br/>\n\n<h6>Product Details</h6>\n<br/>\n<ul>\n  <li><p>The foam sockliner feels soft and comfortable</p></li>\n  <li><p>Pull tab</p></li>\n  <li><p>Not intended for use as Personal Protective Equipment</p></li>\n  <li><p>Colour Shown: White/Black/Oxygen Purple/Action Grape</p></li>\n  <li><p>Style: 921826-109</p></li>\n  <li><p>Country/Region of Origin: China</p></li>\n</ul>\n\n<br/>\n<br/>\n\n<h6>Benefits</h6>\n<br/>\n<ul>\n  <li>\n    <p>Mesh and synthetic materials on the upper keep the fluid look of the OG while adding comfort</p>\n    and durability.\n  </li>\n  <li>\n    <p>Originally designed for performance running, the full-length Max Air unit adds soft, comfortable cushio</p>\n    ning underfoot.\n  </li>\n  <li><p>The foam midsole feels springy and soft.</p></li>\n  <li><p>The rubber outsole adds traction and durability.</p></li>\n</ul>\n\n<br/>\n<br/>\n\n<h6>Delivery and Returns</h6>\n<br/>\n<p>Your order of $200 or more gets free standard delivery.</p>\n<br/>\n<ul>\n  <li><p>Standard delivered 4-5 Business Days</p></li>\n  <li><p>Express delivered 2-4 Business Days</p></li>\n</ul>\n<br/>\n<p>Orders are processed and delivered Monday-Friday (excluding public holidays)</p>\n\n",
  "newLabel": {
    "enabled": false,
    "content": "NEW"
  },
  "sku": "WW75K5210YW/SV",
  "createdAt": "2023-06-18T11:59:52.022Z",
  "saleLabel": {
    "enabled": false,
    "content": "SALE"
  },
  "name": "Nike Air Force 1 NDESTRUKT",
  "price": 83.74,
  "coverUrl": "https://api-prod-minimal-v510.vercel.app/assets/images/m_product/product_1.jpg",
  "totalRatings": 4.2,
  "totalSold": 763,
  "totalReviews": 1947,
  "subDescription": "Featuring the original ripple design inspired by Japanese bullet trains, the Nike Air Max 97 lets you push your style full-speed ahead.",
  "colors": [
    "#00AB55",
    "#000000"
  ]
};
export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products');
      const data = [ d1 ];
      dispatch(slice.actions.getProductsSuccess(data)); // response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name },
      });
      dispatch(slice.actions.getProductSuccess(d1)); // response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
