import * as React from 'react';
import { Controller } from "react-hook-form";
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { CONFIG_MESPLAN as config } from '../../config';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  // if (dataSet.hasOwnProperty('group')) {
  if (Object.prototype.hasOwnProperty.call(dataSet, "group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].value} - ${dataSet[1].title}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    // if (child.hasOwnProperty('group')) {
    if (Object.prototype.hasOwnProperty.call(child, "group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function Virtualize({ props }) {

  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { id, label, multiple, size, setValue, control, table, title, value, storage, watch, isConsignee, edrpou, code } = props;
  const [defaultVal, setDefaultVal] = React.useState(multiple ? [] : null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const idPopover = open ? 'filter-popover' : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    (async () => {
      let response = [];
      if (isConsignee === true) {
        response = await fetch(`${config.nsiUrl}/api/DataNsi/GetConsignorValues?table=${table}&titleOut=${title}&valueOut=${value}&edrpouOut=${edrpou}&codeOut=${code}&all=true`);
      }
      else {
        response = await fetch(`${config.nsiUrl}/api/DataNsi/GetAllValues?table=${table}&titleOut=${title}&valueOut=${value}&all=true`);
      }
      const responseResult = await response.json();
      if (responseResult) {
        // set options to autocomplete          
        setOptions(responseResult);
        setLoading(false);
        // find default value              
        const cachedData = JSON.parse(sessionStorage.getItem(storage));
        if (cachedData) {
          if (multiple && Array.isArray(cachedData[id]) && cachedData[id]) {
            setDefaultVal(cachedData[id]);
            setValue(id, cachedData[id]);
          }
          if (!multiple && cachedData[id]) {
            setDefaultVal(cachedData[id]);
            setValue(id, cachedData[id]);
          }
          setValue(`${id}RadioGroup`, cachedData[`${id}RadioGroup`]);
        }
      }
      else {
        setOptions([]);
      }
    })();
  }, []);

  const defaultData = watch(id);
  React.useEffect(() => {
    // const temp = watch(id);
    if (defaultData) {
      setDefaultVal(defaultData);
    }
    else if (multiple) { setDefaultVal([]) } else {
      setDefaultVal(null)
    }
  }, [defaultData]);

  const filterOptionsCode = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.code + option.title,
  });

  const filterOptionsEdrpou = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.edrpou + option.title,
  });

  const filterOptionsValue = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.value + option.title,
  });

  const filterOptionsAll = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.value + option.code + option.edrpou + option.title,
  });

  let filterOptions;
  const defaultDataFilter = watch(`${id}RadioGroup`);
  if (defaultDataFilter === '1') {
    filterOptions = filterOptionsCode;
  }
  if (defaultDataFilter === '2') {
    filterOptions = filterOptionsEdrpou;
  }
  if (defaultDataFilter === '3') {
    filterOptions = filterOptionsValue;
  }
  if (!defaultDataFilter || defaultDataFilter === '0' || defaultDataFilter === '') {
    filterOptions = filterOptionsAll;
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={1} md={1}>
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton >
          <Popover
            id={idPopover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <FormControl>
              <FormLabel id="filter-radio-buttons-group-label">Фільтр:</FormLabel>
              <Controller
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="filter-radio-buttons-group-label"
                    name="filter-buttons-group"
                    {...field}
                  >
                    <FormControlLabel value="0" control={<Radio />} label="Усі" />
                    <FormControlLabel value="1" control={<Radio />} label="Код 4 знаки" />
                    <FormControlLabel value="2" control={<Radio />} label="Код ЕДРПОУ" />
                    <FormControlLabel value="3" control={<Radio />} label="Ідентифікатор" />
                  </RadioGroup>
                )}
                name={`${id}RadioGroup`}
                control={control}
              />
            </FormControl>
          </Popover>
        </Grid>
        <Grid item xs={11} md={11}>
          <Autocomplete
            id={id}
            name={id}
            value={defaultVal}
            multiple={multiple}
            size={size}
            fullWidth
            disableListWrap
            loading={loading}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            options={options}
            filterOptions={filterOptions}
            onChange={(event, val) => {
              if (val !== null && val !== undefined) {
                setValue(id, val);
                setDefaultVal(val);
              }
              else {
                setValue(id, null);
                if (multiple) { setDefaultVal([]) } else { setDefaultVal(null) };
              }
            }}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'clear') {
                if (multiple) { setDefaultVal([]) } else { setDefaultVal(null) };
                setValue(id, null);
                // return;
              }
            }}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <TextField {...params} label={label} />}
            renderOption={(props, option) => [props, option]}
          />
        </Grid>
      </Grid>
    </>
  );
}