import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Menu,
  MenuItem,
  Paper,
  TableContainer
} from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//
import { headCellsSoglReestr } from '../../../../../common/constants/headerReestrSogl';
import { headCellsPlanHistory } from '../../../../../common/constants/headersHistory';
import { headCellsPlanImplementation } from '../../../../../common/constants/headersPlanImplementation';
import compare from '../../../../../common/functions/comapre';
import contains from '../../../../../common/functions/customFilter';
import { CONFIG_MESPLAN as config } from '../../../../../config';
import { getUserSession } from '../../../../../utils/jwt';
import HistoryModalElz from '../commonComponents/InfoModalComponent';
import PlanImplementationModal from '../commonComponents/PlanImplementationModal';
import RowColorStyle from './utils/colorReestrStyle';
import { defaultStorage } from './utils/defaultStorage';
import searchReestr from './utils/search';
// import PerevezEditModal from '../../common/components/MoreEditInfo/PerevezEditModal';

const RowListResults = () => {
  const [reestr, setReestr] = React.useState([]);
  const dataRedux = useSelector(state => state.soglReestr);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  // const [headCellsReestr, setheadCellsReestr] = useState(headCells);
  const reestrHeaderRedux = useSelector(state => state?.soglReestr?.headerOptions);
  const [columns, setColumns] = useState(headCellsSoglReestr);
  const dispatch = useDispatch();
  const user = JSON.parse(getUserSession());
  const selectedMonth = useSelector(state => state.userMesplan?.month);
  //
  const openMenu = Boolean(anchorEl);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [openImplementation, setOpenImplementation] = React.useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  //
  const handleClick = (event, row) => {
    setAnchorEl(event.target);
    setCurrentRow(row);
  };

  // close menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // open history
  const handleHistory = () => {
    setOpenHistory(true);
  }

  // open implementation
  const handleImplementation = () => {
    setOpenImplementation(true);
  }

  // navigate to more info page
  const navigate = useNavigate();
  const handleEditInfo = (row) => {
    // navigate('/app/SoglReestrEdit', { state: { uid_plan: currentUid } });
    setOpenEdit(true);
  }

  // update reestr data if redux change value
  useEffect(() => {
    if (dataRedux.data) {
      setReestr(dataRedux.data)
    } else {
      setReestr([])
    };
  }, [dataRedux.data]);

  // filter reestr data
  useEffect(() => {
    if (dataRedux.data) {
      setReestr(dataRedux.data.filter((el) => { return contains(el, dataRedux.searchVal) }))
    }
    else {
      setReestr([]);
    }
  }, [dataRedux.searchVal]);

  // hook call one time or when month changed
  useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem(defaultStorage));
    // find data if cached search values and plan is empty or month changed
    if (cachedData && (!reestr || cachedData.month !== selectedMonth)) {
      searchReestr(cachedData, dispatch, user?.sub, null, null, 1, selectedMonth);
    }
    // find last search data in bd and search values
    else if (!dataRedux.data) {
      axios({
        method: 'get',
        url: `${config.nsiUrl}/api/v1/DefaultValues/GetValues?keycloakGuid=${user?.sub}&sKey=${defaultStorage}`,
      }).then((response) => {
        // and find reestr data
        searchReestr(response.data, dispatch, user?.sub, null, null, 1, selectedMonth);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [selectedMonth]);

  // use this hook to update order and visibility
  useEffect(() => {
    if (reestrHeaderRedux) {
      const tempInVisible = reestrHeaderRedux.sort(compare).filter((row) => !row.order).map((row) => row.field);
      const objInVisible = tempInVisible.reduce((a, v) => ({ ...a, [v]: false }), {});
      setColumnVisibilityModel(objInVisible);
      //
      const tempVisible = reestrHeaderRedux.sort(compare).filter((row) => row.order);
      setColumns(tempVisible);
    }
  }, [reestrHeaderRedux]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', margin: 0, padding: 0, }}>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}

      >
        {/* <MenuItem onClick={handleEditInfo}>
          Корегування заявки
        </MenuItem> */}
        {currentRow?.uid_plan && currentRow?.uid_perevez ? <MenuItem onClick={handleHistory}>Історія роботи</MenuItem> : null}
        {currentRow?.uid_plan && currentRow?.uid_perevez ? <MenuItem onClick={handleImplementation}>Виконання плану</MenuItem> : null}
      </Menu>
      {/* <PerevezEditModal
        props={{
          open: openEdit,
          setOpen: setOpenEdit,
          uid_plan: currentUid,
          uid_perevez: curPerevez
        }}
      /> */}

      < HistoryModalElz props={{
        open: openHistory,
        setOpen: setOpenHistory,
        _data: { planIdf: currentRow?.uid_plan, perevezIdf: currentRow?.uid_perevez, month: selectedMonth },
        _handleMenuClose: handleMenuClose,
        path: 'Reestr/inpReestrHistory',
        tableCell: headCellsPlanHistory,
        title: 'Історія роботи'
      }}
      />
      < PlanImplementationModal props={{
        open: openImplementation,
        setOpen: setOpenImplementation,
        _planIdf: currentRow?.uid_plan,
        _perevezIdf: currentRow?.uid_perevez,
        _handleMenuClose: handleMenuClose,
        path: 'Reestr/inpReestrPlanImplementation',
        tableCell: headCellsPlanImplementation,
      }}
      />

      <TableContainer sx={RowColorStyle}>
        <DataGrid
          rows={reestr}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          pageSize={30}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowId={(row) => row.uid_perevez}
          loading={dataRedux.loading}
          rowHeight={33}
          getRowClassName={(params) => `reestr-row-theme--${params.row.soglas_code}`}
          onRowClick={(params, event) => handleClick(event, params.row)}
        />
      </TableContainer >
    </Paper>
  );
};

export default RowListResults;
