import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  IconButton,
  ListItem,
  styled
} from '@mui/material';
// utils
import { DeleteOutline } from '@mui/icons-material';
import DraftsIcon from '@mui/icons-material/Drafts';
import { toast } from 'react-toastify';
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales from '../../../hooks/useLocales';
import { setNotifySuccess, setOpenSuccess, delNotifySuccess, markNotifySuccess } from '../../../redux/slices/ws_reducers';
import { getStaticNotification, readNoti, readAllNoti } from '../../../redux/slices/service.events';
// ----------------------------------------------------------------------


export default function NotificationsPopover() {
  // const [notifications, setNotifications] = useState(_notifications);


  const [open, setOpen] = useState(null);
  // const { notifyLog, openNotify } = useSelector(state => state.websocket);
  const [openNotify, setOpenNotify] = useState(false);
  const { notifications } = useSelector(state => state.events);
  // const totalUnRead = // notifyLog.length; // notifications.filter((item) => item.isUnRead === true).length;
  // notifyLog.filter((item) => item.isUnRead === true).length;
  const { translate } = useLocales();

  useEffect(() => {
    const params = {
      page: 1,
      count: 5,
      order: 'desc'
    }
    getStaticNotification(params);
  }, [])

  const handleOpen = (event) => {
    setOpen(event.target)
    setOpenSuccess(true);
  };

  const handleClose = () => {
    setOpen(null);
    setOpenSuccess(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifySuccess(
      notifications?.data?.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };
  const navigate = useNavigate();
  const handleGoLenta = () => {
    setOpenSuccess(false);
    navigate("/ekbs/services/events/events_view");
  };

  // ================================
  return (
    <>
      <IconButtonAnimate color={openNotify ? 'primary' : 'default'} onClick={() => setOpenNotify(!openNotify)} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={notifications?.cntUnRead || 0} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(openNotify)}
        anchorEl={open}
        onClose={() => setOpenNotify(!openNotify)}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75, left: 'unset !important', right: '138px !important', top: '50px !important' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notification.title')} </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {translate('notification.text1')}{notifications?.count || 0}{translate('notification.text2')}
            </Typography>
          </Box>

          {notifications?.cntUnRead > 0 && (
            <Tooltip title={translate('notification.text6')}>
              <IconButtonAnimate color="primary" onClick={() => readAllNoti()}>
                  <DraftsIcon/>
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {translate('notification.text4')}
              </ListSubheader>
            }
          >
            {notifications?.data?.map((notification) => (
              <NotificationItem key={notification.idNotify} notification={notification} />
            ))}
          </List>

          {/* {true && (
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {translate('notification.text5')}
              </ListSubheader>
            }
          >
            {notifications?.data?.slice(3).map((notification) => (
              <NotificationItem key={notification.idNotify} notification={notification} />
            ))}
          </List>
          )} */}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1, textTransform: 'none' }}>
          <Button fullWidth disableRipple onClick={handleGoLenta}>
            {translate('notification.text3')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    dateEvent: PropTypes.string,
    idNotify: PropTypes.number,
    isUnRead: PropTypes.bool,
    text: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, text } = renderContent(notification);
  const navigate = useNavigate();
  const handleGoUrl = (url, id) => {
    if (url) {
      setOpenSuccess(false);
      markNotifySuccess(id);
      navigate(url);
      return;
    }
    toast.warning('Посилання на подію відсутнє!')
  };
  const handleDelete = (id) => {
    const params = {
      page: 1,
      count: 5,
      order: 'desc'
    }
    readNoti(id, params);

  };
  return (
    <ListItem
      sx={{
        py: 0,
        px: 0,
        mt: '0px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
    <ListItemButton
      sx={{
        py: 1,
        px: 1,
        mt: '1px'
      }}
      onClick={() => handleGoUrl(notification.data?.app_url, notification.idNotify)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={text}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.dateEvent)}
          </Typography>
        }
      />
    </ListItemButton>
    <Tooltip title='Прочитати'>
      <ListItemButton
        sx={{ 'flex-grow': 0, cursor: 'pointer' }}
        onClick={() => handleDelete(notification.idEvent)}
      >
          <DraftsIcon/>
      </ListItemButton>
    </Tooltip>
    </ListItem>
  );
}

// ----------------------------------------------------------------------

NotificationItem2.propTypes = {
  notification: PropTypes.shape({
    dateEvent: PropTypes.instanceOf(Date),
    idNotify: PropTypes.number,
    isUnRead: PropTypes.bool,
    text: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem2({ notification }) {
  const { icon, text } = renderContent2(notification);
  const navigate = useNavigate();
  const handleGoUrl = (url, id) => {
    setOpenSuccess(false);
    markNotifySuccess(id);
    navigate(url);
  };
  const handleDelete = (id) => {
    // -
    delNotifySuccess(id);

  };

  return (
    <ListItem
      sx={{
        py: 0,
        px: 0,
        mt: '0px',
      ...(notification.isUnRead && {
        bgcolor: 'action.selected',
      }),
    }}
>
    <ListItemButton
      sx={{
        py: 1,
        px: 2,
        mt: '1px'
      }}
      onClick={() => handleGoUrl(notification.data?.app_url, notification.idNotify)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={text}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.dateEvent)}
          </Typography>
        }
      />
    </ListItemButton>
    <ListItemButton
      sx={{ 'flex-grow': 0 }}
      onClick={() => handleDelete(notification.idNotify)}
    >
        <DeleteOutline/>
    </ListItemButton>
    </ListItem>

  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const text = (
    <Tooltip title={notification.text}>
      <Typography variant="subtitle2" sx={{ width: '230px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {notification.text}
      </Typography>
    </Tooltip>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.text}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      text,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.text}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      text,
    };
  }
  if (notification.type === 'notify') {
    return {
      avatar: (
        <img
          alt={notification.text}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      text,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.text}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      text,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.text} src={notification.avatar} /> : null,
    text,
  };
}

// ----------------------------------------------------------------------

function renderContent2(notification) {
  const text = (
    <Typography variant="subtitle2">
      {notification?.data?.text}
    </Typography>
  );

  
  return {
    icon: <img
      alt={notification?.data?.text}
      src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
    />,
    text,
  };
}