import { createSlice } from '@reduxjs/toolkit';
import Enumerable from "linq";
// utils
import { HOST_API } from '../../config';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';
// ==========================================================================
const initialState = {
    items: [],
    itemsDisplayOnMap: [],
    itemsFilter: [],
    datetimeLoad: new Date(),
    selectedLayer: {
        id: -1,
        type: "",
        tid: "",
        name: ""
    },
    timeOut: null,
    selectedItem: {
        id: "", 
        tid: "",
        detailId: "",
        isDetail: false,
        datetimeClick: new Date()
    },
    parentItem: {
        id: "",
        tid: "",
        detailId: "",
        dateBegPeriod: "",
        dateEndPeriod: ""
    }
};

const slice = createSlice({
    name: 'mapLayers',
    initialState,
    reducers: {

        setItems(state, action) {
          state.items = action.payload;
          state.datetimeLoad = new Date();

          // При просмотре детальной информации по объекту в список объектов на карте сразу заносим 
          // обновленную информацию из items, выбранную по объектам из множества itemsDisplayOnMap
          if (state.selectedItem.isDetail) {
            state.itemsDisplayOnMap = Enumerable.from(state.items)
                .join(state.itemsDisplayOnMap, 
                    pk => pk.id, 
                    fk => fk.id, 
                    (dataAll) => (dataAll))
                .toArray();              

            console.log(`Map onDisplay (auto refresh) = ${state.itemsDisplayOnMap.length}`); 
          } 
        },   

        setItemsDisplayOnMap(state, action) {
            if (window.google && action.payload !== undefined) {
                const ne = action.payload.getNorthEast();
                const sw = action.payload.getSouthWest();

                state.itemsDisplayOnMap = Enumerable.from(state.items)
                    .where(x => x.lat >= sw.lat() && x.lat <= ne.lat() &&
                        x.lng >= sw.lng() && x.lng <= ne.lng())
                    .toArray();

                // console.log(`Map onDisplay = ${state.itemsDisplayOnMap.length}`); 
            }
        },

        setItemsFilter(state, action) {
            if (action.payload !== "")
                state.itemsFilter = state.itemsDisplayOnMap.filter(item => 
                     item.tid.toLowerCase().includes(action.payload)
                );
            else
                state.itemsFilter = state.itemsDisplayOnMap;
        },

        setSelectedItem(state, action) {
            const selectedItem = action.payload
            state.selectedItem = {
                id: selectedItem.id, 
                tid: selectedItem.tid,
                detailId: selectedItem.detailId,
                isDetail: selectedItem.isDetail,
                datetimeClick: new Date()                
            }
        },

        setSelectedLayer(state, action) { 
            if (state.timeOut)           
                clearTimeout(state.timeOut);   

            state.items = [];
            state.timeOut = null;
            state.itemsDisplayOnMap = [];
            state.itemsFilter = [];
            // state.datetimeLoad = new Date();
            state.selectedItem = {
                id: "", 
                tid: "",
                detailId: "",
                isDetail: false,
                datetimeClick: new Date()
            }            
            state.selectedLayer = action.payload;
        },

        setTimeOut(state, action) {   
            if (state.timeOut)         
                clearTimeout(state.timeOut);   
            state.timeOut = action.payload;
        },

        clearItems(state, action) {
            state.items = [];
            state.itemsDisplayOnMap = [];
            state.itemsFilter = [];
            // state.datetimeLoad = new Date();
            state.selectedItem = {
                id: "", 
                tid: "",
                isDetail: false,
                datetimeClick: new Date()
            }        
        },


        // setChildSelectedLayer(state, action) {
        //     const { parentItem, selectedLayer } = action.payload;
        //     state.parentItem = {
        //         id: parentItem.id, 
        //         tid: parentItem.tid,
        //         dateBegPeriod: parentItem.dateBegPeriod,
        //         dateEndPeriod: parentItem.dateEndPeriod         
        //     };
        //     state.items = [];
        //     state.itemsDisplayOnMap = [];
        //     state.itemsFilter = [];
        //     state.selectedItem = {
        //         id: "", 
        //         tid: "",
        //         detailId: "",
        //         isDetail: false,
        //         datetimeClick: new Date()
        //     }            
        //     state.selectedLayer = selectedLayer;        
        // },  

        setParentItem(state, action) {
            const parentItem = action.payload;
            state.parentItem = {
                id: parentItem.id, 
                tid: parentItem.tid,
                detailId: parentItem.detailId,
                dateBegPeriod: parentItem.dateBegPeriod,
                dateEndPeriod: parentItem.dateEndPeriod         
            };        
        },      
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setItems,
    setItemsDisplayOnMap,
    setItemsFilter,
    setSelectedLayer,
    setSelectedItem,
    setTimeOut,
    clearItems,
    // setChildSelectedLayer,
    setParentItem
} = slice.actions;


