import { findAction, clearAction, filterAction, headerAction } from "../constants/sogl-reestr-action-types";

const setSoglReestr = (_data, isLoading) => {
    return {
        type: findAction,
        data: _data,
        loading: isLoading,
        searchVal: ''
    };
}

const clearSoglReestr = () => {
    return {
        type: clearAction,
        data: [],
        loading: false,
        searchVal: ''   
    };
}

const filterSoglReestr = (_searchVal) => {
    return {
        type: filterAction,      
        searchVal: _searchVal 
    };
}

const setHeaderSoglReestr = (_headerOptions) => {
    return {
        type: headerAction,      
        headerOptions: _headerOptions 
    };
}

export default {
    setSoglReestr,
    clearSoglReestr,
    filterSoglReestr,
    setHeaderSoglReestr
}