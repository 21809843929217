import moment from 'moment';
//
export default function createEventList(day) {
    let events = [];
    const radix = 10;
    if (day?.lot && day?.lot?.length !== 0) {
        const color = '#ffa726';
        for (let i = 0; i < day.lot.length; i += 1) {
            const formatDate = moment(day.lot[i]?.day, "DD.MM.YYYY").format('YYYY-MM-DD');
            for (let j = 0; j < day.lot[i]?.auction_num.length; j += 1) {
                const kCount = day.lot[i]?.k_count[j] && day.lot[i]?.k_count[j] !== '' ? parseInt(day.lot[i]?.k_count[j], radix) : 0;
                const dopCount = day.lot[i]?.dop_count[j] && day.lot[i]?.dop_count[j] !== '' ? parseInt(day.lot[i]?.dop_count[j], radix) : 0;
                const zaCount = day.lot[i]?.za_count[j] && day.lot[i]?.za_count[j] !== '' ? parseInt(day.lot[i]?.za_count[j], radix) : 0;
                const naCount = day.lot[i]?.na_count[j] && day.lot[i]?.na_count[j] !== '' ? parseInt(day.lot[i]?.na_count[j], radix) : 0;
                //
                const auctioNumStr = day.lot[i]?.auction_num[j];
                const _description = `Узгоджені: ${kCount} <br/> Додаткові: ${dopCount} <br/> Заявлено: ${zaCount} <br/> Виконані: ${naCount} <br/> ${auctioNumStr}`;
                const temp = { title: `Лот: ${kCount} ${dopCount} ${zaCount} *${naCount}`, date: formatDate, backgroundColor: color, description: _description };
                events = [...events, temp];
            }
        }
    }

    if (day?.plan) {
        const color = '#66bb6a';
        for (let i = 0; i < day.plan.length; i += 1) {
            const formatDate = moment(day.plan[i]?.day, "DD.MM.YYYY").format('YYYY-MM-DD');
            const kCount = day?.plan[i]?.k_count && day.plan[i]?.k_count !== '' ? parseInt(day?.plan[i]?.k_count, radix) : 0;
            const dopCount = day?.plan[i]?.dop_count && day.plan[i]?.dop_count !== '' ? parseInt(day?.plan[i]?.dop_count, radix) : 0;
            const zaCount = day?.plan[i]?.za_count && day.plan[i]?.za_count !== '' ? parseInt(day?.plan[i]?.za_count, radix) : 0;
            const naCount = day?.plan[i]?.na_count && day.plan[i]?.na_count !== '' ? parseInt(day?.plan[i]?.na_count, radix) : 0;
            const _description = `Узгоджені: ${kCount} <br/> Додаткові: ${dopCount} <br/> Заявлено: ${zaCount} <br/> Виконані: ${naCount}`;
            const temp = { title: `План: ${kCount} ${dopCount} ${zaCount} *${naCount}`, date: formatDate, backgroundColor: color, description: _description };
            events = [...events, temp];
        }
    }

    if (day?.lotLong && day?.lotLong?.length !== 0) {
        const color = '#29b6f6';
        for (let i = 0; i < day.lotLong.length; i += 1) {
            const formatDate = moment(day.lotLong[i]?.day, "DD.MM.YYYY").format('YYYY-MM-DD');
            for (let j = 0; j < day.lotLong[i]?.auction_num.length; j += 1) {
                const kCount = day.lotLong[i]?.k_count[j] && day.lotLong[i]?.k_count[j] !== '' ? parseInt(day.lotLong[i]?.k_count[j], radix) : 0;
                const dopCount = day.lotLong[i]?.dop_count[j] && day.lotLong[i]?.dop_count[j] !== '' ? parseInt(day.lotLong[i]?.dop_count[j], radix) : 0;
                const zaCount = day.lotLong[i]?.za_count[j] && day.lotLong[i]?.za_count[j] !== '' ? parseInt(day.lotLong[i]?.za_count[j], radix) : 0;
                const naCount = day.lotLong[i]?.na_count[j] && day.lotLong[i]?.na_count[j] !== '' ? parseInt(day.lotLong[i]?.na_count[j], radix) : 0;
                //
                const auctioNumStr = day.lotLong[i]?.auction_num[j];
                const _description = `Узгоджені: ${kCount} <br/> Додаткові: ${dopCount} <br/> Заявлено: ${zaCount} <br/> Виконані: ${naCount} <br/> ${auctioNumStr}`;
                const temp = { title: `Довгостоковий лот: ${kCount} ${dopCount} ${zaCount} *${naCount}`, date: formatDate, backgroundColor: color, description: _description };
                events = [...events, temp];
            }
        }
    }

    return events;
}