export const items = [
    {
      href: '/ekbs/handBook/getShippers',
      icon: null,
      title: "Відправники ЄЕКК"
    },
    {
      href: '/ekbs/handBook/getConsignees',
      icon: null,
      title: "Отримувачі ЄЕКК"
    },
    {
      href: '/ekbs/handBook/getStationNazn',
      icon: null,
      title: "Вантажні станції (стики, порти) СНД"
    },
    {
      href: '/ekbs/handBook/getZD',
      icon: null,
      title: "Залізниці СНД"
    },
    {
      href: '/ekbs/handBook/getCountry',
      icon: null,
      title: "Країни"
    },
    {
      href: '/ekbs/handBook/getShippers',
      icon: null,
      title: "Відправники ЄЕКК"
    },
    {
      href: '/ekbs/handBook/getAdm',
      icon: null,
      title: "Адміністрації"
    },
    {
      href: '/ekbs/handBook/getNomenklat',
      icon: null,
      title: "Номеклатурні групи"
    },
    {
      href: '/ekbs/handBook/getETSNV',
      icon: null,
      title: "Вантажі ЄТСНВ"
    },
    {
      href: '/ekbs/handBook/getVagRod',
      icon: null,
      title: "Рода вагонів"
    },
    {
      href: '/ekbs/handBook/getSobstnvVag',
      icon: null,
      title: "Власники рухомого складу"
    },
    {
      href: '/ekbs/handBook/getPort',
      icon: null,
      title: "Порти СНД"
    },
    {
      href: '/ekbs/handBook/getPoluchatelPort',
      icon: null,
      title: "Отримувачі в порту"
    },
    {
      href: '/ekbs/handBook/getElevator',
      icon: null,
      title: "Елеватори"
    },
    {
      href: '/ekbs/handBook/getNZagr',
      icon: null,
      title: "Малодіяльні станції"
    },
    {
      href: '/ekbs/handBook/getEkspedUkr',
      icon: null,
      title: "Експедитори України"
    },
    {
      href: '/ekbs/handBook/getEkspedFormir',
      icon: null,
      title: "Експедитори плану формування"
    },
    {
      href: '/ekbs/handBook/getPerevizn',
      icon: null,
      title: "Перевізники"
    },
    {
      href: '/ekbs/handBook/getTransPlan',
      icon: null,
      title: "Узгоджені транспортні плани"
    },
    {
      href: '/ekbs/handBook/Get_pp_mesplan',
      icon: null,
      title: "Дані АС ПЕК ПК"
    },
    {
      href: '/ekbs/handBook/getNomenPEK',
      icon: null,
      title: "Дані АС ПЕК ПК згідно номенклатур"
    },
    {
      href: '/ekbs/handBook/getProzzoroLot',
      icon: null,
      title: "Актуальні лоти Prozzoro"
    },
    {
      href: '/ekbs/handBook/getProzzoroLotDlg',
      icon: null,
      title: "Актуальні лоти Prozzoro (довгострокові)"
    },
    {
      href: '/ekbs/handBook/getMarshrutEsr',
      icon: null,
      title: "Перелік маршрутних станцій"
    },
  ];