import { Snackbar, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CommonAlert({ showFunc = () => { } }) {

    const [open, setOpen] = useState(false);
    const [type, setType] = useState('');
    const [text, setText] = useState('');
    const [closeTime, setCloseTime] = useState(3000);

    useEffect(() => {
        showFunc(alertWithType);
    }, [open]);

    const alertWithType = (type, text, closeTime = 3000) => {
        setText(text);
        setType(type);
        setCloseTime(closeTime);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    return <>
        <Snackbar open={open} autoHideDuration={closeTime} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert variant="filled" severity={type} onClose={handleClose}>
                <span style={{ whiteSpace: 'pre-line' }}>
                    {text}
                </span>
            </Alert>
        </Snackbar>
    </>
}
