import React, { useEffect } from "react";
import {
    Grid,
    FormControl,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    InputLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext, Controller } from "react-hook-form";
import {
    etsnvSettings,
    esrOsgdSettings,
    stranPribSettings,
    portPribSettings,
    esrPribSettings,
} from '../../../../../../../common/settings/autocompleteSettings';
import AutocompleteVirtualizad from '../../../../../../../common/components/AutocompleteVirtualizad';
import setDefault from "../utils/setDefaultsFormationAppointment";

const FormationAppointmentComponent = ({ data }) => {
    const { control: _controlPerevez, setValue: _setValuePerevez, register: _registerPerevez, watch: _watchPerevez, handleSubmit: _handleSubmitPerevez } = useFormContext();
    const [expanded, setExpanded] = React.useState(true);
    const handleChangeExpanded = () => setExpanded(!expanded);
    const matches = useMediaQuery('(min-width:720px)');
    const commonAutocompleteSettings =
    {
        control: _controlPerevez,
        register: _registerPerevez,
        setValue: _setValuePerevez,
        watch: _watchPerevez,
        multiple: false,
        size: 'small',
    }

    useEffect(() => {
        if (data) {
            setDefault(data, _setValuePerevez);
        }
    }, [data]);

    return (
        <Grid item xs={12} md={12}>
            <Accordion sx={{ m: 0 }} expanded={expanded} onChange={handleChangeExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Формування призначення</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={matches ? 1.3 : 1.1}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="ozn_marshrut-label">Відправницький маршрут</InputLabel>
                                <Controller
                                    render={
                                        ({ field }) =>
                                            <Select
                                                labelId="ozn_marshrut-label"
                                                id="ozn_marshrut"
                                                label="Відправницький маршрут"
                                                size={'small'}
                                                {...field}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={'0'}>Ні</MenuItem>
                                                <MenuItem value={'1'}>Так</MenuItem>
                                            </Select>
                                    }
                                    control={_controlPerevez}
                                    name="ozn_marshrut"
                                    defaultValue={null}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizad props={{ ...etsnvSettings, ...commonAutocompleteSettings }} />
                            </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizad props={{ ...etsnvSettings, ...commonAutocompleteSettings }} />
                            </FormControl>
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizad props={{ ...esrPribSettings, ...commonAutocompleteSettings }} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="ozn_marshrut-label">Колія здачі</InputLabel>
                                <Controller
                                    render={
                                        ({ field }) =>
                                            <Select
                                                labelId="ozn_marshrut-label"
                                                id="ozn_marshrut"
                                                label="Колія здачі"
                                                size={'small'}
                                                {...field}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={'0'}>Ні</MenuItem>
                                                <MenuItem value={'1'}>Так</MenuItem>
                                            </Select>
                                    }
                                    control={_controlPerevez}
                                    name="ozn_marshrut"
                                    defaultValue={null}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizad props={{ ...esrOsgdSettings, ...commonAutocompleteSettings }} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Typography>Стик виходу з України: {data?.da}</Typography>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizad props={{ ...stranPribSettings, ...commonAutocompleteSettings }} />
                            </FormControl>
                        </Grid>

                        
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default FormationAppointmentComponent;
