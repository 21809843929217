export const headCellsDayBalance = [
  { id: 'POR_INDEX', numeric: true, disablePadding: false, label: 'Тип', show: true, order: 1, width: 100  },
  { id: 'OZN_VL', numeric: false, disablePadding: true, label: 'Вагони', show: true, order: 3, width: 200 },
  { id: 'ZA_COUNT', numeric: true, disablePadding: false, label: 'Залишок на поточну добу', show: true, order: 3, width: 300  },
];

export const headCellsTotalDays = [
  { id: 'DAY', numeric: true, disablePadding: false, label: 'День', show: true, order: 1, width: 70  },
  { id: 'SO_COUNT', numeric: false, disablePadding: true, label: 'Узгоджено', show: true, order: 3, width: 80 },
  { id: 'ZA_COUNT', numeric: true, disablePadding: false, label: 'Заявлено', show: true, order: 3, width: 80 },
  { id: 'NA_COUNT', numeric: true, disablePadding: false, label: 'Навантажено', show: true, order: 3, width: 80 },
];
