import { createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
// import i18n from '../../locales/i18n';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';
import { loaderStart, loaderEnd } from './loader';

const initialState = {
  isLoading: true,
  error: null,
  mypublications: {},
  publications: null,
  singlePublication: null
};

const slice = createSlice({
  name: 'publications',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMyPublications(state, action) {
      state.mypublications = action.payload;
    },
    setPublications(state, action) {
      state.publications = action.payload;
    },
    setSinglePublication(state, action) {
      state.singlePublication = action.payload
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setMyPublications,
  setSinglePublication,
  setPublications
} = slice.actions;

export const getMyPublications = async userId => {
  loaderStart();
  await axios.get(`/api-sys/blog/myposts/${userId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyPublications(res.data));
      }
    })
  loaderEnd();
};

export const getPublications = async (params = '') => {
  loaderStart();
  await axios.get(`/api-sys/blog/posts${params}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setPublications(res.data));
      }
    })
  loaderEnd();
};

export const getSinglePublication = async idRec => {
  loaderStart()
  await axios.get(`/api-sys/blog/post/${idRec}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setSinglePublication(res.data));
      }
    })
  loaderEnd();
};

const blobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

// eslint-disable-next-line arrow-body-style
const convertBlobToBase64 = async (blob) => { 
  // eslint-disable-next-line no-return-await
  return await blobToBase64(blob);
}

export const createPublication = async (data, navigate) => {
  loaderStart()
  if (typeof(data.cover) !== 'string') {
    const b64 = convertBlobToBase64(data.cover);
    await b64.then((result) => { data.cover = result; } );
  }
  await axios.post(`/api-sys/blog/post`, data)
    .then(res => {
      if (res.status === 200) {
        navigate(res.data.idrec);
      }
    });
  loaderEnd();
};

export const deletePublication = async (id, navigate) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити цю публікацію?`,
    onSubmit: () => submitPublicationDelete(id, navigate),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
}

const submitPublicationDelete = async (id, navigate) => {
  loaderStart()
  await axios.delete(`/api-sys/blog/post/${id}`)
    .then(res => {
      if (res.status === 200) {
        if (navigate) {
          navigate()
        } else {
          dispatch(setMyPublications(res.data));
        }
        showConfirmationModal({ isOpen: false });
        toast.success('Публікацію успішно видалено.')
      }
    });
  loaderEnd();
};

export const addToFavorites = async (postId, userId) => {
  await axios.post(`/api-sys/blog/favorite?post=${postId}&user=${userId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setSinglePublication(res.data));
      }
    })
};

export const removeFromFavorites = async (postId, userId) => {
  await axios.delete(`/api-sys/blog/favorite?post=${postId}&user=${userId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setSinglePublication(res.data));
      }
    })
};

export const createComment = async (data, clearForm) => {
  loaderStart()
  const request = data.idrec ? axios.put(`/api-sys/blog/comment`, data) : axios.post(`/api-sys/blog/comment`, data);
  await request.then(res => {
    if (res.status === 200) {
      dispatch(setSinglePublication(res.data));
      clearForm();
    }
  });
  loaderEnd()
};

export const deleteComment = commentId => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити коментар?`,
    onSubmit: () => submitCommentDelete(commentId),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

export const submitCommentDelete = async commentId => {
  loaderStart();
  await axios.delete(`/api-sys/blog/comment?idrec=${commentId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setSinglePublication(res.data));
        showConfirmationModal({ isOpen: false })
      }
    });
  loaderEnd();
}