import {
    Card,
    Divider,
    CardHeader,
} from '@mui/material';
import NavItem from './NavItem';

const MainLinkForm = ({ props }) => {
    const { items, title } = props;
    return (
        <Card >
            <CardHeader title={title} />
            <Divider />
            {items.map((item) => (
                <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                />
            ))}
            <Divider />
        </Card>
    )
};

export default MainLinkForm;