import React from 'react';
import {
    Box,
    Button,
    Grid,
    Typography
} from '@mui/material';
import XMLViewer from 'react-xml-viewer';
import { modalStyle } from '../../../../../common/style/modalStyle';

const ShowXml = (props) => {
    // eslint-disable-next-line react/prop-types
    const _handleCloseXml = props?._handleCloseXml;
    // eslint-disable-next-line react/prop-types
    const _xml = props?._xml;
    //
    return (
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h3" component="h3" sx={{ marginBottom: '8px' }}>
                Квитанція
            </Typography>
            <XMLViewer xml={_xml} />
            <Grid item xs={12} md={12}>
                <Button onClick={_handleCloseXml} fullWidth color="text" variant="contained">Закрити</Button>
            </Grid>
        </Box>
    );
}

export default ShowXml;
