import moment from 'moment';

export default function createUzgod(data, checkedDay) {
    const kalend = {
        "kalends": []
    }
    if ((data?.planElevatorUzgod || data?.planRecipientUzg) && data?.uzgodPlan) {
        const _soglas = {
            "AuctionId": "",
            "ElzType": "0",
            "OrgCode": data?.dataPlan?.soglasElz?.org_code,
            "SoglasCode": data?.uzgodPlan,
            "SoglasDate": moment().format('DD.MM.YYYY HH:mm'),
            "DayD": checkedDay,
        }
        kalend.kalends.push({
            "elzType": "0",
            "change": "3",
            "prInvent": data?.dataPlan?.pr_invent,
            "kCount": data?.dataPlan?.k_count,
            "auctionId": "",
            "auctionNum": "",
            "naCount": data?.dataPlan?.na_count,
            "dayD": checkedDay,
            "datePrib": '',
            "zaCount": data?.vagCountPlan,
            "soglas": _soglas
        })
    }

    for (let i = 0; i < data.auctionUzgod.length; i += 1) {
        const _soglas = {
            "AuctionId": data.auctionUzgod[i].info.auction_id,
            "ElzType": "1",
            "OrgCode": data.auctionUzgod[i]?.soglasElz?.org_code,
            "SoglasCode": data.auctionUzgod[i]?.uzgod,
            "SoglasDate": data.auctionUzgod[i]?.info.day,
            "DayD": data.auctionUzgod[i]?.info.day,
        }

        kalend.kalends.push({
            "elzType": "1",
            "change": "3",
            "prInvent": data.auctionUzgod[i]?.info.pr_invent,
            "kCount": `${data.auctionUzgod[i]?.info.k_count}`,
            "auctionId": data.auctionUzgod[i]?.info.auction_id,
            "auctionNum": data.auctionUzgod[i]?.info.auction_num,
            "naCount": `${data.auctionUzgod[i]?.info.na_count}`,
            "dayD": checkedDay,
            "datePrib": "",
            "zaCount": `${data.auctionUzgod[i]?.info.za_count}`,
            "soglas": _soglas
        })
    }

    for (let i = 0; i < data.auctionLongUzgod.length; i += 1) {
        const _soglas = {
            "AuctionId": data.auctionLongUzgod[i].info.auction_id,
            "ElzType": "2",
            "OrgCode": data.auctionLongUzgod[i]?.soglasElz?.org_code,
            "SoglasCode": data.auctionLongUzgod[i]?.uzgod,
            "SoglasDate": data.auctionLongUzgod[i]?.info.day,
            "DayD": data.auctionLongUzgod[i]?.info.day,
        }

        kalend.kalends.push({
            "elzType": "2",
            "change": "3",
            "prInvent": data.auctionLongUzgod[i]?.info.pr_invent,
            "kCount": data.auctionLongUzgod[i]?.info.k_count,
            "auctionId": data.auctionLongUzgod[i]?.info.auction_id,
            "auctionNum": data.auctionLongUzgod[i]?.info.auction_num,
            "naCount": data.auctionLongUzgod[i]?.info.na_count,
            "dayD": checkedDay,
            "datePrib": "",
            "zaCount": data.auctionLongUzgod[i]?.vagCount,
            "soglas": _soglas
        })
    }

    return kalend;
}