import { ContactsOutlined } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';
import { nullFormat } from 'numeral';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';


function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
    isLoading: false,
    error: null,
    regulatorChains: [],
    dicCargoETSNV:[],
    dicCargoGNG:[],
    dicCountries: [],
    dicAdm: [],
    dicStations: [],
    dicStationsUkr: [],
    dicStationsAll: [],

    chainMain: null,
    sections: [],
    section: null,
    isOpenSectionModal: false,

    chainInfo: null,
    isOpenChainViewModal: false,

    chainActions: [],
    chainAction: null,
    isOpenActionModal: false,
    actionServices: [],
    actionService: null, 
    isOpenServiceModal: false,   

    activeStep: 0, 

    dicActions: [],
    dicServices: [],
    dicUnit: [],
    dicCurrency: [],
    // dicLinkAS: [],
    // dicLinkASSum: [],
    // dicLinkASDuration: [],
    dicLinkServices: [],
    dicLinkServicesAll: [],
    isOpenSubServiceModal: false,
    subService: null,
    isMoved: false,

    // isCreateChainModalOpen: false,
    isExpanded: false,

    maxActiveStep: 0,
    
};

const slice = createSlice({
  name: 'regulatorChain',
  initialState,
  reducers: {
      // START LOADING
      setLoading(state, action) {
      state.isLoading = action.payload;
      }, 
      setMoved(state, action) {
        state.isMoved = action.payload;
      },               
      setRegulatorChains(state, action) {
      state.regulatorChains = action.payload;
      },
      setDicCargoETSNV(state, action) {
        state.dicCargoETSNV = action.payload;
      },
      setDicCargoGNG(state, action) {
        state.dicCargoGNG = action.payload;
      },
      setDicCountries(state, action) {
        state.dicCountries = action.payload;
      },  
      setDicAdm(state, action) {
        state.dicAdm = action.payload;
      }, 
      setDicStations(state, action) {
        state.dicStations = action.payload;
      }, 
      setDicStationsUkr(state, action) {
        state.dicStationsUkr = action.payload;
      },
      setDicStationsAll(state, action) {
        state.dicStationsAll = action.payload;
      },      
      setChainMain(state, action) {
        state.chainMain = action.payload;
      },
      setSections(state, action) {
        state.sections = action.payload;
      },
      setSection(state, action) {
        state.section = action.payload;
      }, 
      setSectionModal(state, action) {
        state.isOpenSectionModal = action.payload;
      },      
            
      
      setChainInfo(state, action) {
      state.chainInfo = action.payload;
      },   
      setOpenChainViewModal(state, action) {
        state.isOpenChainViewModal = action.payload;
      },     
      setChainActions(state, action) {
      state.chainActions = action.payload;
      },        
      setChainAction(state, action) {
      state.chainAction = action.payload;
      },
      setActionModal(state, action) {
        state.isOpenActionModal = action.payload;
      },   
      setActionServices(state, action) {
        state.actionServices = action.payload;
      }, 
      setActionService(state, action) {
        state.actionService = action.payload;
      }, 
      setServiceModal(state, action) {
        state.isOpenServiceModal = action.payload;
      },        
      /*
      setCreateChainModal(state, action) {
        state.isCreateChainModalOpen = action.payload;
      },              
      */     
      setDicActions(state, action) {
        state.dicActions = action.payload;
        },       
      setDicServices(state, action) {
      state.dicServices = action.payload;
      },        
      setDicUnit(state, action) {
      state.dicUnit = action.payload;
      },
      setDicCurrency(state, action) {
        state.dicCurrency = action.payload;
      }, 
      /*    
      setDicLinkAS(state, action) {
        state.dicLinkAS = action.payload;
      },
      setDicLinkASSum(state, action) {
        state.dicLinkASSum = action.payload;
      },
      setDicLinkASDuration(state, action) {
        state.dicLinkASDuration = action.payload;
      },
      */
      setDicLinkServices(state, action) {
        state.dicLinkServices = action.payload;
      },
      setDicLinkServicesAll(state, action) {
        state.dicLinkServicesAll = action.payload;
      },
      setSubServiceModal(state, action) {
        state.isOpenSubServiceModal = action.payload;
      },  
      setSubService(state, action) {
        state.subService = action.payload;
      },  



      setActiveStep(state, action) {
        state.activeStep = action.payload;
      },   
      setExpanded(state, action) {
        state.isExpanded = action.payload;
      },   
      setMaxActiveStep(state, action) {
        state.maxActiveStep = action.payload;
      },               
  },        
      
});

  // Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,
    setRegulatorChains,
    setDicCargoETSNV,
    setDicCargoGNG,
    setDicCountries,
    setDicAdm,
    setDicStations,
    setDicStationsUkr,
    setDicStationsAll,
    setChainMain,
    setSections,
    setSection,
    setSectionModal,
    
    setChainInfo,
    setOpenChainViewModal,
    setChainActions,
    setChainAction,
    setActionModal,
    setActionServices, 
    setActionService, 
    setServiceModal,  
    
    // setCreateChainModal,    
    setDicActions,
    setDicServices,
    setDicUnit,
    setDicCurrency,
    // setDicLinkAS,
    // setDicLinkASSum,
    // setDicLinkASDuration,
    setDicLinkServices,
    setDicLinkServicesAll,
    setSubServiceModal,
    setSubService,
    setMoved,

    /*
    getBoardSuccess,

    setBoard,
    setInitialData,
    */    
    setActiveStep,
    setExpanded,
    setMaxActiveStep,
    
  } = slice.actions;

/*
  // --------ActiveStep
  export const getActiveStep = async () => {
    dispatch(setActiveStep(0));
  };
  export const persistActiveStep = async (step) => {
    dispatch(setActiveStep(step));
  };
*/

// --------Реєстр нормативних логістичних ланцюжків 
export const getRegulatorChains = async () => {
  dispatch(setLoading(true));
  await axios.get(`api-vantazh/Rlcs`)
  .then(res => {
    if(res.status === 200) {  
      // console.log("getRegulatorChains", res.data);     
      dispatch(setRegulatorChains(res.data));
      dispatch(setMoved(false));                               
    }
  }) 
  .catch((error) => {
    // console.log(error);
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })       
  .finally(() => dispatch(setLoading(false)) ); 
  
}

// --------видалення нормативного логістичного ланцюжка
export const deleteRLC = (idRLC, nameRLC) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.regulatorChains.titleDelete'),
    content: `${i18n.t('service.regulatorChains.answerDelete')} ${nameRLC}?`,
    onSubmit: () => submitDelete(idRLC),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDelete = async (idRLC) => { 
  await axios.delete(`api-vantazh/Rlcs/${idRLC}`)
  .then(res => {
    if(res.status === 200) {      
      getRegulatorChains();      
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.regulatorChains.deleted'));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
};


/*
// --------НДІ Вантаж
export const getDicCargo = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_MESPLAN_NGR&titleOut=name&valueOut=code&all=true`)
  .then(res => {
    if(res.status === 200) {
      dispatch(setDicCargo(res.data));
    } 
  }) 
};
*/
// --------НДІ Вантаж
export const getDicCargoETSNV = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_TV_ETSNV&titleOut=name&valueOut=code&all=true`)
  .then(res => {
    if(res.status === 200) {  
      // console.log('getDicCargoETSNV', res.data) ;   
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.value, 'title': `${item.title.trim()} (${item.value})`};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });         
        dispatch(setDicCargoETSNV(newData));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });   
};

export const getDicCargoGNG = async () => {
  // await axios.get(`/api-nsi/api/v1/DataNsi/GetConsignorValues?table=nsi.E_TV_GNV&titleOut=NVS_G&valueOut=KV_GNG&edrpouOut=KV_GNG&codeOut=KV&all=true`)
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_TV_GNV&fieldOutOne=NVS_G&fieldOutTwo=KV_GNG&fieldOutThree=KV&all=true`)
  .then(res => {
    if(res.status === 200) {
      // console.log('getDicCargoGNG', res.data) ;  
        const newData = [];        
        res.data.forEach(item => {           
          const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });         
        dispatch(setDicCargoGNG(newData));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------НДІ Країни
export const getDicCountries = async () => {
  const f=[{
    'value': '804',
    'title': 'Україна'
    },
    {
      'value': '616',
      'title': 'Польща'
    }
  ];
  dispatch(setDicCountries(f));
};

// --------НДІ адміністрація призначення
export const getDicAdm = async () => {  
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_D_ADM&fieldOutOne=N_ADMR&fieldOutTwo=K_ADMI&fieldOutThree=k_strn&all=true`)
  // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_ADM&titleOut=N_ADMR&valueOut=K_ADMI&all=true')
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicAdm', res.data);
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });         
        dispatch(setDicAdm(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });         
};
// --------НДІ Станції
export const getDicStationsAll = async () => {
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Stations&fieldOutOne=display_info&fieldOutTwo=code_station&fieldOutThree=code_adm&all=true`)  
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicStationsAll', res.data);
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.field2, 'title': item.field1, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });  
        dispatch(setDicStationsAll(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};

export const getDicStations = async () => {
  await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP_OSZ_GR &titleOut=N_RPL&valueOut=K_RP&all=true')
    .then(res => {
      if(res.status === 200) {
        const newData = [];        
        res.data.forEach(item => {            
          const newItem = {'value': item.value, 'title': `${item.title.trim()} (${item.value})`};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        });  
        // newData.push( {value: 0, title: ''} );       
        dispatch(setDicStations(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });         
};

export const getDicStationsUkr = async () => {
  // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU&valueOut=K_RP&all=true')  
  // await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_T_RP&titleOut=N_RPU&valueOut=K_RP&&value=СТ&searchField=M_SPRPR&all=false&limit=2000`)
  
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=nsi.E_T_RP&fieldOutOne=N_RPU&fieldOutTwo=K_RP&fieldOutThree=K_ADMI&searchColumn=M_SPRPR&searchValue=СТ&all=false&limit=2000`)
    .then(res => {
      if(res.status === 200) {
        // console.log(res.data);
        const newData = [];        
        res.data.forEach(item => {            
          // const newItem = {'value': item.value, 'title': `${item.title} (${item.value})`};
          const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
          if (!newData.includes(newItem)) {
            newData.push(newItem)
          }                 
        }); 
        // newData.push( {value: 0, title: ''} );        
        dispatch(setDicStationsUkr(newData));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })  
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });       
};
// --------Відображення тітульної інформації про ланцюжок
export const getNewChainMain = async () => {
  // console.log('getNewChainMain');
  dispatch(setChainMain(null));  
};

export const getChainMain = async (idRlc) => { 
  // https://dev.vantazhplus.co.ua/api-vantazh/Rlcs/1?withChildren=false
  await axios.get(`api-vantazh/Rlcs/${idRlc}?withChildren=false`)
  .then(res => {
    if(res.status === 200) {  
      // console.log("getChainMain", res.data);     
      dispatch(setChainMain(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });   
};

export const getChainMainAll = async (idRlc) => {   
  // https://dev.vantazhplus.co.ua/api-vantazh/Rlcs/1?withChildren=false
  await axios.get(`api-vantazh/Rlcs/${idRlc}`)
  .then(res => {
    if(res.status === 200) {  
      // console.log("getChainMainAll", res.data);     
      dispatch(setChainMain(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};


// --------збереження тітульної інформації про ланцюжок
export const saveChainMain = async (data) => {
  await axios.put(`api-vantazh/Rlcs`, data)
    .then(res => {
      if(res.status === 200) {
        dispatch(setChainMain(res.data));   
        toast.success(i18n.t('service.regulatorChains.page.saved'));    
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження  тітульної інформації про ланцюжок (NEW)
export const saveNewChainMain = async (data) => {
  await axios.post(`api-vantazh/Rlcs`, data)
    .then(res => {
      if(res.status === 200) {
        dispatch(setChainMain(res.data));   
        toast.success(i18n.t('service.regulatorChains.saved'));    
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------Реєстр дільниць перевезення 
export const getSections = async (idRlc) => {
  dispatch(setLoading(true));  
  await axios.get(`api-vantazh/Rlcs/${idRlc}/Carriers`)
  .then(res => {
    if(res.status === 200) {   
      // console.log('getSections', res.data);    
      dispatch(setSections(res.data));      
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })  
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })
  .finally(() => dispatch(setLoading(false)) );
}


// --------Дільниця перевезення 
export const getSection = async (IdSection) => {
  await axios.get(`api-vantazh/RlcCarriers/${IdSection}`)
  .then(res => {
    if(res.status === 200) { 
      // console.log("getSection", res.data);
      dispatch(setSection(res.data));  
  }
  else {
    throw new Error(res.statusText || res.status);          
  }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  }); 
};


export const getNewSection = async (idRlc, section = null) => {
  getChainMain(idRlc);
  // getChainMainAll(idRlc);
  dispatch(setSection({section}));  
};

// --------видалення Дільниці перевезення 
export const deleteSection = (idSection, name, idRlc) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.regulatorChains.titleDelete'),
    content: `${i18n.t('service.regulatorChains.sections.answerDelete')} ${name}?`,
    onSubmit: () => submitDeleteSection(idSection, idRlc),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteSection = async (idSection, idRlc) => { 
  await axios.delete(`api-vantazh/RlcCarriers/${idSection}`)
  .then(res => {
    if(res.status === 200) {      
      getSections(idRlc);      
      showConfirmationModal({ isOpen: false });
      // toast.success(`Дільницю перевезення  ${name} успішно видалено.`);
      toast.success(i18n.t('service.regulatorChains.sections.deleted'));
    }else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------модальное окно для создания/редактирования Дільниці перевезення
export const openSectionModal = async (idSection) => {
  getSection(idSection);
  dispatch(setSectionModal(true));
}; 

export const closeSectionModal = async () => { 
  dispatch(setSectionModal(false));
};

export const openNewSectionModal = async (idRlc) => {
  getNewSection(idRlc);
  dispatch(setSectionModal(true));
};

// --------збереження Дільниці перевезення
export const saveSection = async (data) => {
  await axios.put(`api-vantazh/RlcCarriers`, data)
    .then(res => {
      if(res.status === 200) {
        getSections(data.idRlc);
        dispatch(setSection(res.data));   
        // toast.success(`Дільницю ${data.tidSection} успішно збережено.`);     
        toast.success(i18n.t('service.regulatorChains.sections.saved'));     
        dispatch(setSectionModal(false));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження НОВОЇ Дільниці перевезення
export const saveNewSection = async (data) => {  
    await axios.post(`api-vantazh/RlcCarriers`, data)
    .then(res => {
      if(res.status === 200) {
        getSections(res.data.idRlc);
        // console.log('saveNewSection', res.data);
        dispatch(setSection(res.data));   
        toast.success(i18n.t('service.regulatorChains.sections.created'));     
        dispatch(setSectionModal(false));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};


// ---------------------------------
export const openChainViewModal = async (idRlc) => {  
  getChainInfo(idRlc);
  dispatch(setOpenChainViewModal(true));
};

export const closeChainViewModal = async () => { 
  dispatch(setOpenChainViewModal(false));
};

// --------Відображення загальної інформації про ланцюжок
export const getChainInfo = async (idRlc) => {  
  // console.log('idRlc', idRlc);
  await axios.get(`api-vantazh/Rlcs/${idRlc}?withChildren=false`)
  .then(res => {
    if(res.status === 200) {  
      // console.log("ChainInfo", res.data);     
      dispatch(setChainInfo(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });      
};

// --------Події
export const getChainActions = async (idRlc) => {
  dispatch(setLoading(true));  
  
  await axios.get(`api-vantazh/Rlcs/${idRlc}/Actions`)
  .then(res => {
    if(res.status === 200) {   
      // console.log('getChainActions', res.data);   
      dispatch(setChainActions(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })  
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })
  .finally(() => dispatch(setLoading(false)) );
};

export const persistActions = async (actions) => {
  dispatch(setChainActions(actions)); 
  dispatch(setMoved(true));    
}

// --------НДІ Події
export const getDicActions = async () => {
  /*
  const data = "\"SELECT l.code_action AS value, concat(l.name_short,' (',l.code_action,')') AS title " +
               " FROM nsi_ekbs.Dic_Actions_Form l " +
               " WHERE " +
              " (SELECT count(code_action) " +
              " FROM views.V_Link_Actions_Services v WHERE l.code_action=v.code_action ) > 0 \"";
  */
  const data = "\" SELECT l.code_action AS value, concat(l.name_short,' (',l.code_action,')') AS title" +
              " FROM nsi_ekbs.Dic_Actions_Form l " +
              " WHERE l.code_action in " +
              " (SELECT  distinct code_action " +
              " FROM views.V_Link_Actions_Services v WHERE l.code_action=v.code_action) \"";            
  // await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Actions_Form&titleOut=name_full&valueOut=code_action&all=true')  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )  
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicActions', res.data);        
        dispatch(setDicActions(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ Послуги
export const getDicServices = async () => {
  await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Pidpr_Services&titleOut=name_full&valueOut=code_service&all=true')
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicServices(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ Код об’єкта застосування
export const getDicUnit = async () => {
  await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Services_Unit&titleOut=name_short&valueOut=code_unit&all=true')
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicUnit(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ валюта
export const getDicCurrency = async () => {
  await axios.get('/api-nsi/api/DataNsi/GetAllValues?table=nsi.E_D_VALUT&titleOut=K_VALC&valueOut=K_VALN&all=true')
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicCurrency(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------НДІ лінковочна події-послуги по idAction
export const getDicLinkServicesByIdAction = async (id) => { 
  console.log('getDicLinkServicesByIdAction id = ', id);

  const data =  `" SELECT order_service, a.code_action, code_service as value, name_service as title, ` + 
                ` code_obj_sum as codeObjSum, name_obj_sum as nameObjSum, code_obj_duration as codeObjDuration, name_obj_duration as nameObjDuration, is_key as isKey ` +
                ` FROM views.V_Link_Actions_Services las, IC.Rlc_Actions a ` +
                ` WHERE a.code_action = las.code_action ` +
                ` and a.id_action = ${id} ` +
                ` order by order_service "`;
  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
    .then(res => {
      if(res.status === 200) {  
        // console.log('getDicLinkServicesByIdAction', res.data); 
        dispatch(setDicLinkServices(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};

// --------НДІ лінковочна події-послуги всі
export const getDicLinkServicesAll = async () => { 
  
  const data =  "\" SELECT code_action as codeAction, order_service as orderService, " +
                " 0 as idService, 0 as idAction, 0 as idRlc," +
                " code_service as codeService, name_service as tidService, " +
                " code_obj_sum as codeObjSum, name_obj_sum as nameObjSum, " +
                " code_obj_duration as codeObjDuration, name_obj_duration as nameObjDuration, is_key as isKey, " +
                " '' as nameService, 0 as price,  '' as nameCurrency, 980 as codeCurrency, 0 as duration " +
                " FROM views.V_Link_Actions_Services " +
                " order by code_action, order_service \"";
  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
    .then(res => {
      if(res.status === 200) {  
        // console.log('getDicLinkServicesAll', res.data);         
        dispatch(setDicLinkServicesAll(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }        
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------Видалення Послуги
export const deleteService = (idService, name, idRlc) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.regulatorChains.titleDelete'),
    content: `${i18n.t('service.regulatorChains.chainActions.answerDeleteService')} ${name}?`,
    onSubmit: () => submitDeleteService(idService, idRlc),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteService = async (idService, idRlc) => { 
  await axios.delete(`api-vantazh/RlcServices/${idService}`)
  .then(res => {
    if(res.status === 200) {      
      getChainActions(idRlc);      
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.regulatorChains.chainActions.deletedService'));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });  
};

// --------Видалення Події
export const deleteAction = (idAction, name, idRlc) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.regulatorChains.titleDelete'),
    content: `${i18n.t('service.regulatorChains.chainActions.answerDelete')} ${name}?`,
    onSubmit: () => submitDeleteAction(idAction, idRlc),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteAction = async (idAction, idRlc) => {  
  await axios.delete(`api-vantazh/RlcActions/${idAction}`)
  .then(res => {
    if(res.status === 200) {      
      getChainActions(idRlc);      
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.regulatorChains.chainActions.deleted'));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });
 };


// --------модальное окно для создания/редактирования Послуги
export const openServiceModal = async (idService) => {
  getActionService(idService);
  dispatch(setServiceModal(true));
}; 

export const closeServiceModal = async () => { 
  dispatch(setServiceModal(false));
};

export const getNewService = async (action, service = null) => {
  // getChainAction(action.idAction);
  dispatch(setChainAction(action));
  dispatch(setActionService({service}));  
};

export const openNewServiceModal = async (action) => {
  getNewService(action);
  dispatch(setServiceModal(true));
};



// --------Послуга
export const getActionService = async (idService) => {  
  await axios.get(`api-vantazh/RlcServices/${idService}`)
  .then(res => {
    if(res.status === 200) {  
      // console.log("getActionService", res.data);     
      dispatch(setActionService(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
    })   
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження Послуги
export const saveActionService = async (data) => {
  await axios.put(`api-vantazh/RlcServices`, data)
    .then(res => {
      if(res.status === 200) {
        getChainActions(res.data.idRlc);
        dispatch(setActionService(res.data));   
        toast.success(i18n.t('service.regulatorChains.chainActions.savedService'));     
        dispatch(setServiceModal(false));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------збереження НОВОЇ Послуги
export const saveNewActionService = async (data) => {  
  await axios.post(`api-vantazh/RlcServices`, data)
  .then(res => {
    if(res.status === 200) {
      getChainActions(res.data.idRlc);      
      dispatch(setActionService(res.data));   
      toast.success(i18n.t('service.regulatorChains.chainActions.createdService'));     
      dispatch(setServiceModal(false));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });
};


// --------модальное окно для создания/редактирования Події
export const openActionModal = async (idAction) => {
  getChainAction(idAction);
  dispatch(setActionModal(true));
}; 

export const closeActionModal = async () => { 
  dispatch(setActionModal(false));
};

export const getNewChainAction = async (idRlc, action = null) => {
  getChainMain(idRlc);
  // getChainMainAll(idRlc);
  getChainActions(idRlc);
  getDicAdmAction(idRlc);
  getDicStationsAction(idRlc);  
  dispatch(setChainAction({action}));  
};

export const openNewActionModal = async (idRlc) => {
  getNewChainAction(idRlc);  
  dispatch(setActionModal(true));
};

// --------Подія
export const getChainAction = async (idAction) => {  
  
  await axios.get(`api-vantazh/RlcActions/${idAction}`)
  .then(res => {
    if(res.status === 200) {  
      // console.log("getChainAction", res.data);  
      getDicAdmAction(res.data.idRlc, res.data.idAction);  
      getDicStationsAction(res.data.idRlc, res.data.idAction);  
      dispatch(setChainAction(res.data));
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------збереження Події
export const saveChainAction = async (data) => {  
  await axios.put(`api-vantazh/RlcActions`, data)
    .then(res => {
      if(res.status === 200) {
        getChainActions(res.data.idRlc);
        dispatch(setChainAction(res.data));   
        toast.success(i18n.t('service.regulatorChains.chainActions.saved'));
        dispatch(setActionModal(false));     
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};
// --------збереження НОВОЇ Події
export const saveNewChainAction = async (data) => {
  await axios.post(`api-vantazh/RlcActions`, data)
    .then(res => {
      if(res.status === 200) {
        getChainActions(res.data.idRlc);        
        dispatch(setChainAction(res.data));   
        toast.success(i18n.t('service.regulatorChains.chainActions.created'));     
        dispatch(setActionModal(false));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// --------збереження порядка
export const saveOrder = async (idRlc, data) => {
  await axios.patch(`api-vantazh/Rlcs/Reorder/${idRlc}`, data)
    .then(res => {
      if(res.status === 200) {
        // getChainActions(data.idRlc);
        dispatch(setChainActions(res.data));   
        dispatch(setMoved(false));
        toast.success(i18n.t('service.regulatorChains.savedOrder'));     
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------модальное окно для создания/редактирования SUB Послуги
export const openSubServiceModal = async (subService) => {
  dispatch(setSubService(subService));
  dispatch(setSubServiceModal(true));
}; 

export const closeSubServiceModal = async () => { 
  dispatch(setSubServiceModal(false));
};





export const persistActiveStep = async (step) => {
  dispatch(setActiveStep(step));
};

export const persistExpanded = async (expandedState) => {
  dispatch(setExpanded(expandedState));    
}

// --------метод додавання дільниць перевезення НЛЛ у зворотному порядку відносно існуючих (згідно заув. 35)
export const addReverseSections = async (idRlc) => {
  await axios.get(`api-vantazh/Rlcs/AddReverseSections/${idRlc}`)
    .then(res => {
      if(res.status === 200) {
        // console.log("addReverseSections", res.data);   
        dispatch(setSections(res.data)); 
        toast.success(i18n.t('service.regulatorChains.sections.createdRevers'));     
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    }); 
};

// --------методи формування переліку адміністрацій і станцій для вибору даних 
// --------при введенні/коригуванні станцій здійснення подій НЛЛ/ЛЛГ (згідно заув. 35)

// --------НДІ адміністрація призначення
export const getDicAdmAction = async (idRlc, idAction = '') => {  
  await axios.get(`api-vantazh/Rlcs/${idRlc}/Adms/${idAction}`)  
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicAdmAction', res.data);
        dispatch(setDicAdm(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });         
};
// --------НДІ Станції
export const getDicStationsAction = async (idRlc, idAction = '') => {
  await axios.get(`api-vantazh/Rlcs/${idRlc}/Stations/${idAction}`) 
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicStationsAction', res.data);        
        dispatch(setDicStations(res.data));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });        
};

export const getMaxActiveStep = async (idSlc) => { 

  if (!idSlc) {
    dispatch(setMaxActiveStep(1));
    return;
  } 

  dispatch(setLoading(true));    
  await axios.get(`api-vantazh/Rlcs/Step/${idSlc}`)
  .then(res => {
    if(res.status === 200) {      
     console.log('getMaxActiveStep', res.data);         
     dispatch(setMaxActiveStep( res.data ));                               
    }
    else {
      throw new Error(res.statusText || res.status);          
    }  
 })
 .catch((error) => {
   toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
 });
};
 