
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { CommonAlertHolder } from '../../../../../../common/CommonAlert/CommonAlertHolder';
import { CONFIG_MESPLAN as config } from '../../../../../../config';
import allActions from '../../../../../../redux/actions/index';
import { defaultStorage } from './defaultStorage';

export default function searchElz(data, dispatch, keycloak, handleClose, oldData = null, loadBlock = 1, defaultMonth = 202201) {
    const esrArr = data.esr_otpr && data.esr_otpr?.length > 0 ? data.esr_otpr.map((el) => {
        return el?.value;
    }) : null;

    const klientArr = data.klient && data.klient?.length > 0 ? data.klient.map((el) => {
        return el?.value;
    }) : null;

    const nomenklatArr = data.nomenklat && data.nomenklat?.length > 0 ? data.nomenklat.map((el) => {
        return el?.value;
    }) : null;

    const eekkArr = data.id_eekk && data.id_eekk?.length > 0 ? data.id_eekk.map((el) => {
        return el?.value;
    }) : null;

    const esrPribArr = data.esr_prib && data.esr_prib?.length > 0 ? data.esr_prib.map((el) => {
        return el?.value;
    }) : null;

    const vagRpsArr = data.vag_rps && data.vag_rps?.length > 0 ? data.vag_rps.map((el) => {
        return el?.value;
    }) : null;

    let _order = '';
    if (data.sort) {
        data.sort.map((el) => (
            _order += `${Object?.keys(el)[0]}, `
        ))
    }

    const requestData =
    {
        month: defaultMonth,
        block: loadBlock,
        esr_otpr: esrArr,
        klient: klientArr,
        group_ozn: data.group_ozn,
        export_type: data.export_type,
        application_type: data.application_type,
        elevator: data.elevator ? data.elevator?.value : null,
        dir_otpr: data.dir_otpr,
        zal_otpr: data.zal_otpr ? data.zal_otpr?.value : null,
        strn_otpr: data.strn_otpr ? data.strn_otpr?.value : null,
        nomenklat: nomenklatArr,
        ozn_marshrut: data.ozn_marshrut ? data.ozn_marshrut?.value : null,
        id_eekk: eekkArr,
        esr_prib: esrPribArr,
        dir_prib: data.dir_prib ? data.dir_prib : null,
        zal_prib: data.zal_prib ? data.zal_prib?.value : null,
        strn_prib: data.strn_prib ? data.strn_prib?.value : null,
        ozv_vl: data.ozv_vl ? data.ozv_vl?.value : null,
        amount_elz: data.amount_elz,
        with_plan: data.with_plan,
        agreed_recipient: data.agreed_recipient,
        long_contract: data.long_contract,
        evacuation: data.evacuation,
        vag_rps: vagRpsArr,
        humanitarianHelp: data.humanitarianHelp,
        esr_prib_osz: data.esr_prib_osz ? data.esr_prib_osz?.value : null,
    }

    // 
    if (loadBlock === 1) {
        dispatch(allActions.elzActions.setElz([], true))
    }

    axios({
        method: 'post',
        url: `${config.nsiUrl}/api/v1/proxyPost`,
        params: { gatewayUrlEnd : `Elz/ElzReestrList` },
        // url: `${config.apiUrl}/api/v1/Elz/ElzReestrList`,
        data: requestData,
        headers: { "Access-Control-Allow-Origin": "*" }
    }).then((response) => {
        //
        if (loadBlock === 1) {
            dispatch(allActions.elzActions.setElz(response?.data?.elzList, false));
            dispatch(allActions.elzActions.setTotalDays(response?.data?.totalDaysData));
            // write to session storage
            // searh values
            sessionStorage.setItem(defaultStorage, JSON.stringify({ ...data, month: defaultMonth, block: loadBlock }));
            // write default values to bd
            axios({
                method: 'post',
                url: `${config.nsiUrl}/api/v1/DefaultValues/SetValues?keycloakGuid=${keycloak}&sKey=${defaultStorage}`,
                data: { ...data, month: defaultMonth, block: loadBlock },
                headers: { "Access-Control-Allow-Origin": "*" },
            })
        }
        else if (oldData && response?.data) {
            let tempReestr = cloneDeep(oldData);
            tempReestr = [...oldData, ...response?.data?.elzList];
            dispatch(allActions.elzActions.setElz(tempReestr, false));
        }

        if (handleClose) {
            handleClose();
        }
        CommonAlertHolder.alert('success', 'Дані знайдено! (реєстр електронних заявок)');
    }, (error) => {
        console.log(error);
        if (loadBlock === 1) {
            dispatch(allActions.elzActions.setElz([], false));
            dispatch(allActions.elzActions.setTotalDays(null))
        } else {
            dispatch(allActions.elzActions.setElz(oldData, false))
        }
        CommonAlertHolder.alert('error', 'Помилка пошуку за заданими параметрами');
    });
}