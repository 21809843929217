import DeleteIcon from '@mui/icons-material/Delete';
import {
    Checkbox, FormControl, Grid, IconButton, TextField, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import { Controller } from "react-hook-form";
import { disabledEl } from '../../../../../../common/style/disabledEl';

export default function Auction(props) {
    const matches = useMediaQuery('(min-width:720px)');
    //
    const i = props.i;
    const item = props.item;
    const name = props.name;
    const handleDelete = props.handleDelete;
    const edit = props.edit;
    const register = props._register;
    const control = props._control;
    const data = props._auLong;
    const disableElement = data && data?.soglasElz && (data?.soglasElz[i]?.soglas_code === '0' || data?.soglasElz[i]?.soglas_code === '');
    //
    const elzType = 'довгостроковий';
    return (
        <>
            <Grid key={`lotCount${i}${name}`} item xs={12} md={7}>
                <Tooltip title={
                    <>
                        {item?.k_count && item?.k_count !== '' ? <Typography color="inherit" variant="h6">Узгоджені вагони: {item?.k_count} </Typography> : <></>}
                        {item?.za_count && item?.za_count !== '' ? <Typography color="inherit" variant="h6">Заявдені вагони: {item?.za_count} </Typography> : <></>}
                        {item?.na_count && item?.na_count !== '' ? <Typography color="inherit" variant="h6">Навантажені вагони: {item?.na_count} </Typography> : <></>}
                        <Typography color="inherit" variant="h6">Тип аукціону: {elzType}</Typography>
                        <Typography color="inherit" variant="h6">id аукціону: {item?.id_auction}</Typography>
                        <Typography color="inherit" variant="h6">Номер аукціону: {item?.num}</Typography>
                    </>
                } arrow>
                    <FormControl fullWidth>
                        <TextField
                            id={`${name}[${i}]all_infoId`}
                            sx={disabledEl}
                            name={`${name}[${i}]all_info`}
                            {...register(`${name}.${i}.all_info`)}
                            label="Довгостроковий аукціон"
                            variant="outlined"
                            disabled={disableElement}
                            size={matches ? '' : 'small'} />
                    </FormControl>
                </Tooltip>
            </Grid>
            <Grid key={`lotVags${i}${name}`} item xs={8} md={3}>
                <Tooltip title={
                    <>
                        <Typography color="inherit" variant="h6">Заявлені вагони: {item?.za_count} </Typography>
                    </>
                } arrow>
                    <FormControl fullWidth>
                        <TextField
                            id={`${name}[${i}]za_count`}
                            name={`${name}[${i}]za_count`}
                            {...register(`${name}.${i}.za_count`)}
                            label="Вагони"
                            variant="outlined"
                            type="number"
                            disabled={disableElement}
                            size={matches ? '' : 'small'} />
                    </FormControl>
                </Tooltip>
            </Grid>
            {edit ?
                <>
                    <Grid key={`lotPr${i}`} item xs={2} md={1}
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center"
                    >
                        <Tooltip title="Ознака ЗДВ" arrow>
                            <FormControl>
                                <Controller
                                    id={`${name}[${i}]zdv`}
                                    name={`${name}[${i}]zdv`}
                                    {...register(`${name}.${i}.zdv`)}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            size={matches ? '' : 'small'}
                                            // sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            checked={field.value}
                                            disabled={disableElement}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={2} md={1}
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center"
                    >
                        <Tooltip title="Видалення" arrow>
                            <IconButton aria-label="delete" color="primary" onClick={() => handleDelete(i, item?.id_auction)} disabled={disableElement}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                </>
                : <></>}
        </>
    )
}