export const headCells = [
  { id: 'klient_code', numeric: true, disablePadding: false, label: 'Відправник', show: true, order: 1, width: 300  },
  { id: 'elevator', numeric: false, disablePadding: true, label: 'Елеватор', show: true, order: 2, width: 200 },
  { id: 'esr_otpr', numeric: true, disablePadding: false, label: 'Станція відпр.', show: true, order: 3, width: 200  },
  { id: 'etsng', numeric: true, disablePadding: false, label: 'Вантаж', show: true, order: 4, width: 200  },
  { id: 'esr_prib', numeric: true, disablePadding: false, label: 'Стан. призн.', show: true, order: 5, width: 200  },
  { id: 'poluch_code', numeric: true, disablePadding: false, label: 'Отримувач', show: true, order: 6, width: 300  },
  { id: 'ozn_vl_name', numeric: true,  disablePadding: false, label: 'Тип вл.ваг', show: true, order: 7, width: 120  },
  { id: 'id_vl', numeric: true, disablePadding: false, label: 'Оп-р', show: true, order: 8, width: 70  },
  { id: 'rod_vag', numeric: true, disablePadding: false, label: 'РРС', show: true, order: 9, width: 70  },
  { id: 'so_count', numeric: true, disablePadding: false, label: 'Узг', show: true, order: 10, width: 70  },
  { id: 'elz', numeric: true, disablePadding: false, label: 'ЕЛЗ', show: true, order: 11, width: 100 },
  // { id: 'pr_vipoln', numeric: true, disablePadding: false, label: 'Елз', show: true, order: 11, width: 100  },
  // { id: 'pr_marshrut_k', numeric: true, disablePadding: false, label: 'Ознака', show: true, order: 12, width: 100  },
];