const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? `${color}90 !important` : `${color} !important`;

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? `${color}80 !important` : `${color}90 !important`;

const style = {
    height: 660,
    margin: 0,
    padding: 0,
    '& .reestr-row-theme--1': {
        bgcolor: (theme) =>
            getBackgroundColor('#d5ffea', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#d5ffea', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--6': {
        bgcolor: (theme) =>
            getBackgroundColor('#81f781', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#81f781', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--3': {
        bgcolor: (theme) =>
            getBackgroundColor('#a6ffff', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#a6ffff', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--0': {
        bgcolor: (theme) =>
            getBackgroundColor('#ffffdf', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#ffffdf', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--5': {
        bgcolor: (theme) =>
            getBackgroundColor('#ff6090', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#ff6090', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--4': {
        bgcolor: (theme) =>
            getBackgroundColor('#ffcb97', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#ffcb97', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--97': {
        bgcolor: (theme) =>
            getBackgroundColor('#f0b27a', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#f0b27a', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--2': {
        bgcolor: (theme) =>
            getBackgroundColor('#ff6aff', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#ff6aff', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--99': {
        bgcolor: (theme) =>
            getBackgroundColor('#e6e6e6', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#e6e6e6', theme.palette.mode),
        },
    },
    '& .reestr-row-theme--98': {
        bgcolor: (theme) =>
            getBackgroundColor('#c158dc', theme.palette.mode),
        '&:hover': {
            bgcolor: (theme) =>
                getHoverBackgroundColor('#c158dc', theme.palette.mode),
        },
    },
}

export default style;