import {
  Cached as LoaderIcon,
  FilterAlt as FilterIcon, Search as SearchIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card, Grid, IconButton, InputAdornment,
  SvgIcon, TextField, Tooltip,
  Typography
} from '@mui/material';
import axios from 'axios';
import _debounce from 'lodash/debounce';
import React, { Suspense, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { headCellsReestr as _headCells } from '../../../../../common/constants/headerReestr';
import { CONFIG_MESPLAN as config } from '../../../../../config';
import useLocales from '../../../../../hooks/useLocales';
import allActions from '../../../../../redux/actions/index';
import { getUserSession } from '../../../../../utils/jwt';
import createStrSearcParams from './utils/createSearchShowStr';
import { defaultStorage, tableSettingsStorage } from './utils/defaultStorage';
import searchReestr from './utils/searchReestr';

const ReestrListToolbar = (props) => {
  const SearchComponent = React.lazy(() => import('./SearchModal'));
  const ShowSettingComponent = React.lazy(() => import('../../../../../common/components/SettingShowModalDataGrid'));
  const { translate } = useLocales();

  const [openSettings, setOpenSettongs] = React.useState(false);
  const [openSettingShow, setOpenSettingShow] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [searchOptions, setSearchOptions] = React.useState('');

  const handleOpen = () => setOpenSettongs(true);
  const handleOpenSettingShow = () => setOpenSettingShow(true); 
  const dispatch = useDispatch();
  const user = JSON.parse(getUserSession());
  const selectedMonth = useSelector(state => state.userMesplan?.month);

  const debounceFilter = useCallback(_debounce(handleDebounceFilter, 1000), []);
  function handleDebounceFilter(inputValue) {
    dispatch(allActions.reestrActions.filterReestr(inputValue));
    setFilter(inputValue);
  }

  const handleChange = (event) => {
    debounceFilter(event.target.value);
  };

  const oldData = useSelector(state => state.reestr?.reestr);
  const reestrText = `${translate('mesplan.enter_request')} (${selectedMonth}) `;
  const dataInfo = `Блок: ${Math.ceil(oldData?.plan?.length/300)}; Заявок в блок:300 ; Заявок на стор:${oldData?.plan?.length}`;

  function handleLoadMore() {
    const allowLoad = oldData?.plan?.length % 300 === 0;
    const block = oldData?.plan?.length / 300;
    const loadBlock = block + 1;
    if (allowLoad) {
      alert(`loadBlock is ${loadBlock}`);
      const cachedData = JSON.parse(sessionStorage.getItem(defaultStorage));
      if (cachedData) {
        searchReestr(cachedData, dispatch, user?.sub, null, oldData, loadBlock, selectedMonth);
      }
    }
  }

  function saveShowData(data)
  {
    dispatch(allActions.reestrActions.setHeaderReestr(data));
  }

  React.useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem(defaultStorage));
    if (cachedData) 
    {
      const str = createStrSearcParams(cachedData);
      setSearchOptions(str);
    }
    else {
      axios({
        method: 'get',
        url: `${config.nsiUrl}/api/v1/DefaultValues/GetValues?keycloakGuid=${user?.sub}&sKey=${defaultStorage}`,
      }).then((response) => {
        if (response.data) 
        {
          const str = createStrSearcParams(response.data)
          setSearchOptions(str);
        }
      }).catch((error) => {
        console.log(error.toJSON());
      });
    }
  }, [JSON.parse(sessionStorage.getItem(defaultStorage))]);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // paddingTop: 6,
        }}
      >
       <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpen}
          >
            Пошук
          </Button>

          {/* <Button
            sx={{ mx: 1 }}
            color="primary"
            variant="contained"
            disabled
          >
            Додати план
          </Button> */}

          <Tooltip title="Налаштування параметрів відображення">
          <span>
          <IconButton
            color="primary"
            variant="contained"
            onClick={() => {handleOpenSettingShow() }}
          >
            <FilterIcon />
          </IconButton>
          </span>
          </Tooltip>

          <Tooltip title="Завантажити наступний блок">
          <span>
          <IconButton
            color="primary"
            variant="contained"
            onClick={() => { handleLoadMore() }}
            disabled={oldData?.plan?.length % 300 !== 0 || !oldData?.plan}
          >
            <LoaderIcon />
          </IconButton>
          </span>
          </Tooltip>

          <Typography variant="h6" component="h6">
            {reestrText}
          </Typography>
          <Typography >
            {dataInfo}
          </Typography>
        </Grid>

        {/* <Button sx={{ mx: 1 }}>
          Експорт
        </Button> */}

      </Box>
      <Box sx={{ mt: 1 }}>
        <Card>
          <Grid sx={{ p: 1 }} container>
            {/* <Box sx={{ flexDirection: 'row', alignItems: 'flex-start' }}> */}
            {/* <Box sx={{ maxWidth: 500 }}> */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Фільтр ... введіть дані фільтрації"
                variant="outlined"
                onChange={handleChange}
              />
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} md={8} sx={{ p: 1 }}>
              {searchOptions}
            </Grid>
            {/* </Box> */}
          </Grid>
        </Card>
      </Box>
      {/* <SearchModal props={{ open: open, setOpen: setOpen }} /> */}
      <Suspense fallback={<div>Wait ...</div>}>
      < SearchComponent props={{ open: openSettings, setOpen: setOpenSettongs }} />
      </Suspense>
      <Suspense fallback={<div>Wait ...</div>}>
      <ShowSettingComponent props={
        { 
          open: openSettingShow, 
          setOpen: setOpenSettingShow, 
          storage: tableSettingsStorage, 
          headCells: _headCells, 
          setData: saveShowData, 
          }
          } />
      </Suspense>
    </Box>
  );
}

export default ReestrListToolbar;
