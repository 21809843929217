import moment from 'moment';
import {setMonthAction, setRightsAction} from '../constants/user-action-types';

const now = moment().format('YYYYMM');
const userSettings = (state = { month: now, rights: null}, action) => {
    switch(action.type){
        case setMonthAction:
            return {
                ...state, 
                month: action.month,           
            }
        case setRightsAction:
            return {
                ...state,
                rights: action.month,              
            }
        default: 
            return state
    }
}

export default userSettings