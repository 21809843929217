// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // SERVICE
  // ----------------------------------------------------------------------
  /*
  {
    subheader: 'Сервіси',
    items: [
      { title: 'Події', path: '/ekbs/services/events', icon: ICONS.calendar },
      {
        title: 'Тарифи',
        path: '/ekbs/services/tarifs/',
        icon: ICONS.ecommerce,
        children: [
          { title: 'calc', path: '/ekbs/services/tarifs/calc' },
          { title: 'info', path: '/ekbs/services/tarifs/info' }
        ],
      },
      {
        title: 'ПД',
        path: '/ekbs/services/pdocument/',
        icon: ICONS.booking,
        children: [
          { title: 'vpor', path: '/ekbs/services/pdocument/vpor' },
          { title: 'info', path: '/ekbs/services/pdocument/info' }
        ],
      },
      { title: 'SigFox', path: '/ekbs/services/sigfox', icon: ICONS.booking },
    ],
  },
  */
  // PROFILE
  // ----------------------------------------------------------------------
  {
    subheader: 'ПРОФАЙЛИ',
    items: [
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Профайл', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          // { title: 'list', path: PATH_DASHBOARD.user.list },
          // { title: 'create', path: PATH_DASHBOARD.user.newUser },
          { title: 'Користувач', path: PATH_DASHBOARD.user.account },
          { title: 'Договори', path: PATH_DASHBOARD.user.agreements },
          { title: 'Підприємства', path: PATH_DASHBOARD.user.enterprises },
          { title: 'Мої публікації', path: PATH_DASHBOARD.user.mypublications },
          { title: 'Всі публікації', path: PATH_DASHBOARD.user.allpublications },
        ],
      },
    ],
  },
  // {
  //   subheader: 'Месплан',
  //   items: [
  //     {
  //       title: 'Реєстри',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Ввід заявок', path: "/ekbs/mesplan/reestr" },
  //         { title: 'Узгодження заявок', path: "/ekbs/mesplan/soglReestr" },
  //         { title: 'Електронна заявка/графік навантаження', path: "/ekbs/mesplan/elzReestr" },
  //       ],
  //     },
  //     { title: 'Довідники', path: "/ekbs/mesplan/handbookMesplan", icon: ICONS.settings },
  //     { title: 'Налаштування', path: "/ekbs/mesplan/mesplanSettings", icon: ICONS.settings },
  //   ],
  // },
  /*
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Приклади',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [

      // MANAGEMENT : E-COMMERCE
      {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
        ],
      },

      // MANAGEMENT : BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts },
          { title: 'post', path: PATH_DASHBOARD.blog.postById },
          { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: (
          <Label variant="outlined" color="error">
            +32
          </Label>
        ),
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'kanban',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban,
      },
    ],
  },
  */
];

export default navConfig;
