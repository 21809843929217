export default function createStrSearcParams(data) {
  const esrArr = data.esr_otpr && data.esr_otpr?.length > 0 ? data.esr_otpr.map( (el) => {
    return el?.title;
  }) : null;

  const klientArr = data.klient && data.klient?.length > 0 ? data.klient.map( (el) => {
    return el?.title;
  }) : null;

  const nomenklatArr = data.nomenklat && data.nomenklat?.length > 0 ? data.nomenklat.map( (el) => {
    return el?.title;
  }) : null;

  const eekkArr = data.id_eekk && data.id_eekk?.length > 0 ? data.id_eekk.map( (el) => {
    return el?.title;
  }) : null;

  const esrPribArr = data.esr_prib && data.esr_prib?.length > 0 ? data.esr_prib.map( (el) => {
    return el?.title;
  }) : null;

  const vagRpsArr = data.vag_rps && data.vag_rps?.length > 0 ? data.vag_rps.map( (el) => {
    return el?.title;
  }) : null;

  let str = 'Параметри пошуку - ';
  str += data.group_ozn ? `, Ознака групування: '${data.group_ozn}'` : '';
  str += data.export_type ? `, Тип експорту: '${data.export_type}'` : '';
  str += data.application_type || data.application_type === 0 ? `, Вид заявки: '${data.application_type}'` : '';
  str += klientArr && klientArr?.length > 0 ? `, Вантажовідправник: '${klientArr?.join()}'` : '';
  str += data.elevator ? `, Елеватор: '${data.elevator?.title}'` : '';
  str += esrArr && esrArr?.length > 0 ? `, Станція відправки: '${esrArr?.join()}'` : '';
  str += data.dir_otpr ? `, Дирекція відправки: '${data.dir_otpr}'` : '';
  str += data.zal_otpr ? `, Залізниця відправки: '${data.zal_otpr?.title}'` : '';
  str += nomenklatArr && nomenklatArr?.length > 0 ? `, Номенклатура вантажу: '${nomenklatArr?.join()}'` : '';
  str += data.ozn_marshrut ? `, Ознака маршруту: '${data.ozn_marshrut}'` : '';
  str += data.id_eekk ? `, Вантажоотримувач ЄЕКК: '${eekkArr?.join()}'` : '';
  str += data.ukraine_out ? `, Стик виходу з України: '${data.ukraine_out?.title}'` : '';
  str += data.ozn_vl ? `, Ознака власності: '${data.ozn_vl?.title}'` : '';
  str += data.vag_id_name ? `, Назва власника оператора рухомого складу: '${data.vag_id_name?.title}'` : '';
  str += vagRpsArr && vagRpsArr?.length > 0 ? `, Рід вагона: '${vagRpsArr?.join()}'` : '';
  str += data.vag_type ? `, Тип вагона: '${data.vag_type?.title}'` : '';
  str += data.ozn_vag_spv_rps ? `, Ознака підзаміни: '${data.ozn_vag_spv_rps}'` : '';
  str += data.rod_spec_vag ? `, Рід спец. вагона: '${data.rod_spec_vag?.title}'` : '';
  str += data.vag_count ? `, Кількість вагонів: '${data.vag_count}'` : '';
  str += esrPribArr && esrPribArr?.length > 0 ? `, Станція призначення: '${esrPribArr?.join()}'` : '';
  str += data.dir_prib ? `, Дирекція призначення: '${data.dir_prib}'` : '';
  str += data.strn_prib ? `, Країна призначення: '${data.strn_prib}'` : '';
  str += data.zal_prib ? `, Залізниця призначення: '${data.zal_prib?.title}'` : '';
  str += data.lot ? `, Лот довгостроковий: '${data.lot}'` : '';
  str += data.amount_elz ? `, Обсяги без ЕЛЗ: '${data.amount_elz}'` : '';
  str += data.agreed_recipient ? `, З узгодженням отримувача: '${data.agreed_recipient}'` : '';
  str += data.long_contract ? `, Довгостроковий: '${data.long_contract}'` : '';
  str += data.inventar ? `, Підсил інвентаря: '${data.inventar}'` : '';
  str += data.evacuation ? `, Ознака евакуації: '${data.evacuation}'` : '';
  str += data.humanitarianHelp ? `, Гуманітарна допомога: '${data.humanitarianHelp}'` : '';
  str += data.esr_prib_osz ? `, Станція призначення ОСЖД: '${data.esr_prib_osz?.title}'` : '';

  return str;
}