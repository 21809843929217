import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Paper } from '@mui/material';
// config
import { HEADER } from '../../../config';
// components
import NavSectionHorizontal from '../../../components/nav-section/horizontalEkbs/index';
//
import navConfig from '../navbar/NavConfig';
import useProfile from '../../../hooks/useProfile';
// ----------------------------------------------------------------------
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;
const color = 'primary';

const RootStyle = styled('div')(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal({ isCollapse }) {
  const { vMenu, currentServiceInd, currentFunctionInd, setIndService } = useProfile();
  return (
    <Paper
      elevation={3}
      sx={{
        bgcolor: (theme) => theme.palette[color].lighter,
        width: `calc(100% - ${10}px)`,
        ...(isCollapse && {
          width: `calc(100% - ${10}px)`,
        }),
      }}
    >
      <Box sx={{ color: 'black' }}>
        <NavSectionHorizontal navConfig={vMenu} />
      </Box>
    </Paper>
  );
}

export default memo(NavbarHorizontal);
