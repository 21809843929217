import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import useProfile from '../../hooks/useProfile';
import formatYYYYMMDDHHMISS from '../../utils/formatYYYYMMDDHHMISS';
// ----------------------------------------------------------------------

function objFromArray(array, key = 'event_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  isSaving: false,
  error: null,
  events: { byId: {}, allIds: [] },
  labels: [],
  notifications: null,
  newEvents: null
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startSaving(state) {
      state.isSaving = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsEventsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET MAILS
    getEventsSuccess(state, action) {
      const events = action.payload;
      // console.log(events);
      state.isLoading = false;
      // state.events.byId = objFromArray(events);
      state.events.allIds = events; //  Object.keys(state.events.byId);
      // console.log(state.events);
    },

    // GET MAIL
    getEventSuccess(state, action) {
      const event = action.payload;

      state.events.byId[event.event_id] = event;
      if (!state.events.allIds.includes(event.event_id)) {
        state.events.allIds.push(event.event_id);
      }
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setNewEvents(state, action) {
      state.newEvents = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLabelsEvents() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api-sys/events/labels');
      dispatch(slice.actions.getLabelsEventsSuccess(response.data.labels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export const getEvents = async (params, pidpr, start, cnt) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(['/api-sys/events/get_events?profile_id=',params,'&pidpr_id=',pidpr,'&start=',start,'&cnt=',cnt].join(''));
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
}

export function getNotifyEvents(ev) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = 1;
      const pidpr = 153;
      const start = 1;
      const cnt = 10;
      const response = await axios.get(['/api-sys/events/get_events?profile_id=',params,'&pidpr_id=',pidpr,'&start=',start,'&cnt=',cnt].join(''));
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getEvent(eventId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(['/api-sys/events/get_event?event_id=',eventId].join(''));
      dispatch(slice.actions.getEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export const getEventsNewRequest = async params => {
  const {
    profileId,
    pidprId,
    page,
    count
  } = params;
  await axios.get(`/api-sys/v2/events/get_events?profile_id=${profileId}&pidpr_id=${pidprId}&page=${page}&count=${count}`)
  .then(res => {
    if(res.status === 200) {
      dispatch(slice.actions.setNewEvents(res.data))
    }
  })
}

export function setEvent(event) {
  return async () => {
    console.log('setEvent ', event);
    dispatch(slice.actions.startSaving());
    try {
      const response = await axios.post(['/api-sys/events/set_event_all',''].join(''),
      event );
      getEvents(event.profile_id);
      // dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log('setEvent er ', error);
    }
  };
}
// ===============================================================
export const sourceEnum = Object.freeze({
   'SYSTEM': 1, // 'Система'), 
   'USER': 2, // 'Користувач системи'), 
   'INFO': 3, // 'Інформація'), 
   'MSG': 4, // 'Повідомлення'), 
   'TASK': 5, // 'Фоновий процес'), 
   'APP': 6, // 'Застосунок'), 
   'REGLAMENT': 7 // , 'Регламент наповнення даних')
  });
export const typeEventEnum = Object.freeze({
    'tarif': 1, // 'Попередній розрахунок тарифу'), 
    'load': 2, // 'Первинне заповнення даними'), 
    'create': 3, // 'Створення об''єкту'), 
    'edit': 4, // 'Корегування об''єкту'), 
    'delete': 5, // 'Видалення об''єкту'), 
    'query': 6, // 'Запит узгодження'), 
    'confirm': 7, // 'Підтвердження запиту'), 
    'update': 8, // 'Оновлення даних')
    'refusal': 9,
    'addmsg': 10
  });
// eslint-disable-next-line camelcase
export function newEvent(type_events_id, type_source_id, parent_object_id, event_text, profile_id, pidpr_id, is_public)
{
  /*
  type_events_id - тип події по словнику dic_type_events
  type_source_id - тип джерела по словнику dic_type_source
  parent_object_id - ід запису батьківської події 
  event_text - текст для відображення
  profile_id - ід профайлу
  is_public - 0/1/2  = приватна/публічна/для партнерів
  app_url - посілання на функцію сервіса що породило подію
  params - клас параметрів події або null
  result - результат події або null
  extended - розширені дані або null 
  objects - які об'єкти змінено або додано по словнику Dic_Type_Ic
  */
  return async () => {
    try {
      const dt = formatYYYYMMDDHHMISS (new Date());
      const event = {type_events_id,
      type_source_id,
      event_date: dt,
      parent_object_id,
      event_text,
      profile_id,
      // eslint-disable-next-line camelcase
      pidpr_id: [pidpr_id],
      is_public,
      keys:[],
      objects: []};
      const response = await axios.post(['/api-sys/events/set_event',''].join(''),
      event );
      console.log(response);
      if(response.status===200)
      { return response.data; }
       return null; 
    } catch (error) {
      console.log('setEvent er ', error);
    }
  };

}
// eslint-disable-next-line camelcase
export function newEventKeyObj(type_events_id, type_source_id, parent_object_id, event_text, profile_id, pidpr_id, is_public, app_url, params, result, extended, objects)
{
  /*
  type_events_id - тип події по словнику dic_type_events
  type_source_id - тип джерела по словнику dic_type_source
  parent_object_id - ід запису батьківської події 
  event_text - текст для відображення
  profile_id - ід профайлу
  is_public - 0/1/2  = приватна/публічна/для партнерів
  app_url - посілання на функцію сервіса що породило подію
  params - клас параметрів події або null
  result - результат події або null
  extended - розширені дані або null 
  objects - які об'єкти змінено або додано по словнику Dic_Type_Ic
  */
  return async () => {
    try {
      const dt = formatYYYYMMDDHHMISS (new Date());
      const event = {type_events_id,
      type_source_id,
      event_date: dt,
      parent_object_id,
      event_text,
      profile_id,
      // eslint-disable-next-line camelcase
      pidpr_id: [pidpr_id],
      is_public,
      keys:[],
      objects: []};
      const response = await axios.post(['/api-sys/events/set_event',''].join(''),
      event );
      console.log(response);
      if(response.status===200)
      { 
        const id = response.data; 
        dispatch(addEventKey(id, dt, app_url, params, result, extended));
        dispatch(addEventObj(id, objects));
        return id;
      }
       return null; 
    } catch (error) {
      console.log('setEvent er ', error);
    }
  };

}

// eslint-disable-next-line camelcase
export function newEventKeyObj2(type_events_id, type_source_id, parent_object_id, event_text, profile_id, a_pidpr_id, is_public, app_url, params, result, extended, objects)
{
  /*
  type_events_id - тип події по словнику dic_type_events
  type_source_id - тип джерела по словнику dic_type_source
  parent_object_id - ід запису батьківської події 
  event_text - текст для відображення
  profile_id - ід профайлу
  is_public - 0/1/2  = приватна/публічна/для партнерів
  app_url - посілання на функцію сервіса що породило подію
  params - клас параметрів події або null
  result - результат події або null
  extended - розширені дані або null 
  objects - які об'єкти змінено або додано по словнику Dic_Type_Ic
  */
  return async () => {
    try {
      const dt = formatYYYYMMDDHHMISS (new Date());
      const event = {type_events_id,
      type_source_id,
      event_date: dt,
      parent_object_id,
      event_text,
      profile_id,
      // eslint-disable-next-line camelcase
      pidpr_id: a_pidpr_id,
      is_public,
      keys:[],
      objects: []};
      const response = await axios.post(['/api-sys/events/set_event',''].join(''),
      event );
      console.log(response);
      if(response.status===200)
      { 
        const id = response.data; 
        dispatch(addEventKey(id, dt, app_url, params, result, extended));
        dispatch(addEventObj(id, objects));
        return id;
      }
       return null; 
    } catch (error) {
      console.log('setEvent er ', error);
    }
  };

}


// eslint-disable-next-line camelcase
export function addEventKey(event_id, event_date, app_url, params, result, extended)
{
  /*
  event_id - ід запису батьківської події 
  event_date - 
  app_url - посілання на функцію сервіса що породило подію
  params - клас параметрів події або null
  result - результат події або null
  extended - розширені дані або null 
  */
  return async () => {
    try {
      const dt = formatYYYYMMDDHHMISS (new Date());
      const event = {event_id, event_date, app_url, event_key: JSON.stringify(params), event_result: JSON.stringify(result), event_extended: extended };
      console.log('eventKey',event);
      const response = await axios.post(['/api-sys/events/set_event_key',''].join(''),
      event );
    } catch (error) {
      console.log('setEvent er ', error);
    }
  };

}

// eslint-disable-next-line camelcase
export function addEventObj(event_id, object_type)
{
  /*
  event_id - ід запису батьківської події 
  object_type - масив типів Dic_Type_Ic
  */
  return async () => {
    try {
      
      const events = [];
      object_type.map((item) => events.push({event_id, object_type: item}));
      const response = await axios.post(['/api-sys/events/set_event_obj',''].join(''),
      events );
    } catch (error) {
      console.log('setEvent er ', error);
    }
  };

}


/*
class Enum {
  constructor(...keys) {
    keys.forEach((key, i) => {
      this[key] = i;
    });
    Object.freeze(this);
  }

  *[Symbol.iterator]() {
    for (let key of Object.keys(this)) yield key;
  }
}

const daysEnum = new Enum(
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
);

const days = [...daysEnum]; // Array of the enum values as strings
const a = days[''];
*/

export const getStaticNotification = async params => {
  const {
    page,
    count,
    order
  } = params;
  await axios.get(`/api-sys/events/notify?page=${page}&count=${count}&order=${order}&isread=false`)
  .then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.setNotifications(res.data));
    }
  })
}

export const readNoti = async (notiId, params) => {
  const {
    page,
    count,
    order
  } = params;
  await axios.post(`/api-sys/events/notify/read?idEvent=${notiId}&page=${page}&count=${count}&order=${order}&isread=false`)
  .then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.setNotifications(res.data));
    }
  })
}

export const readAllNoti = async () => {
  await axios.post(`/api-sys/events/notify/readall?page=${1}&count=${5}&order=desc&isread=false`)
  .then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.setNotifications(res.data));
    }
  })
}
