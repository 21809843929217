import counterActions from './counterActions';
import reestrActions from './reestrActions';
import soglReestrActions from './soglReestrActions';
import elzActions from './elzActions';
import userActions from './userActions';
//  import userA

const allActions = {
    counterActions,
    reestrActions,
    soglReestrActions,
    elzActions,
    userActions
}

export default allActions