// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import useProfile from '../hooks/useProfile';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useProfile();

  return (
    <>

    <Avatar
      src={user?.photo}
      alt={user?.fullName}
      color={user?.photo ? 'default' : createAvatar(user?.fullName).color}
      {...other}
    >
      {createAvatar(user?.fullName).name}
    </Avatar>
    </>
  );
}
