export default function setDefault(data, setValue) {
  if (data?.etsng && data?.etsng !== '') {
    setValue("etsnv", { title: data?.etsng_name, value: data?.etsng });
  }
  if (data?.gng && data?.gng !== '') {
    setValue("gng", { title: data?.gng_name, value: data?.gng });
  }
  if (data?.esr_prib && data?.esr_prib !== '') {
    setValue("esr_prib", { title: data?.esr_prib_name, value: data?.esr_prib });
  }
  if (data?.stran_prib && data?.stran_prib !== '') {
    setValue("strn_prib", { title: data?.stran_prib_name, value: data?.stran_prib });
  }
  if (data?.port_prib_code && data?.port_prib_code !== '') {
    setValue("port_prib", { title: data?.port_prib_code_name, value: data?.port_prib_code });
  }
}