export default function yyyymmddhhmiss(d) {
    const mm = d.getMonth() + 1; // getMonth() is zero-based
    const dd = d.getDate();
    const hh = d.getHours();
    const mi = d.getMinutes();
    const ss = d.getSeconds();
  // 2021-10-14T20:07:04
    return `${[d.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd ].join('-')}T${[
      (hh > 9 ? '' : '0') + hh,
      (mi > 9 ? '' : '0') + mi,
      (ss > 9 ? '' : '0') + ss
    ].join(':')}`;
  }
  