import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Accordion, AccordionDetails, AccordionSummary, Box,
  Card, FormControl, Grid, IconButton,
  Stack, TextField, Typography
} from '@mui/material';
import { DataGrid, GridToolbar, ukUA } from '@mui/x-data-grid';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import CommonAlert from '../../../../../../common/CommonAlert/CommonAlert';
import { CommonAlertHolder } from '../../../../../../common/CommonAlert/CommonAlertHolder';
import { headCellsGetCountry as _headCells } from '../../../../../../common/constants/headersHandBook';
import { CONFIG_MESPLAN as config } from '../../../../../../config';
import axios from '../../../../../../utils/axios';

const InfoResult = (props) => {
  const [dataResult, setDataResult] = useState([]);
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm();
  const [open, setOpen] = useState(true);
  const [pageSize, setPageSize] = useState(30);

  const onSubmit = _data => {
    setLoading(true);
    axios({
      method: 'post',
      // url: `${config.apiUrl}/api/v1/Additional/GetCountry`,
      url: `${config.nsiUrl}/api/v1/proxyPost`,
      params: { gatewayUrlEnd: `Additional/GetCountry` },
      data: _data,
      headers: { "Access-Control-Allow-Origin": "*" }
    }).then((response) => {
      if (typeof response?.data === 'string' || response?.data instanceof String)
        setDataResult(JSON.parse(response?.data));
      else
        setDataResult(response?.data);
      //
      setLoading(false);
      CommonAlertHolder.alert('success', 'Дані знайдено')
    }, (error) => {
      setLoading(false);
      setDataResult([]);
      CommonAlertHolder.alert('error', 'Даних не знайдено')
    });
  };

  const navigate = useNavigate();
  const backHandle = () => {
    navigate('/ekbs/mesplan/handbookMesplan');;
  }

  return (
    <>
      <CommonAlert showFunc={(ref) => {
        CommonAlertHolder.setAlert(ref)
      }} />
      <Card {...props}>
        <Accordion expanded={open}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="info-content"
            id="info-header"
            onClick={() => { setOpen(!open) }}
          >
            <Stack direction="row" spacing={1}>
              <IconButton aria-label="back" onClick={backHandle}>
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="h4" gutterBottom component="div">Країни</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Grid container spacing={1}>

                  <Grid item xs={12} md={7}>
                    <FormControl fullWidth>
                      <TextField id="Str" {...register("Str")} label="Назва" variant="outlined" size={'small'} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <FormControl fullWidth>
                      <TextField type="number" id="Kod" {...register("Kod")} label="Код" variant="outlined" size={'small'} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={7}>
                  <LoadingButton
                      fullWidth
                      color="primary"
                      type="primary"
                      loading={loading}
                      variant="contained"
                    >
                      Пошук
                    </LoadingButton>
                  </Grid>

                </Grid>
              </Box>
            </form>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', height: '58vh', minWidth: '1500px' }}>
          <DataGrid
            localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
            components={{ Toolbar: GridToolbar }}
            density="compact"
            rows={dataResult}
            columns={_headCells}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[30, 50, 100]}
            disableSelectionOnClick
            getRowId={(row) => `${row.CODE}${row.NAME}`}
            loading={loading}
          />
        </Box>
      </Card>
    </>
  )
};

export default InfoResult;
