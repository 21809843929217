export default function createStrSearcParams(data){

  const nomenklatArr = data.nomenklat ? data.nomenklat.map( (el) => {
    return el?.title;
  }) : null;
  const esrArr = data.esr_otpr ? data.esr_otpr.map((el) => {
    return el?.title;
  }) : null;
  const klientArr = data.klient ? data.klient.map((el) => {
    return el?.title;
  }) : null;

  let str = 'Параметри пошуку - ';
  str += data.vid_plan ? `Вид плану: '${data.vid_plan}'` : '';
  str += data.plan_status ? `, Статус: '${data.plan_status.title}'` : '';
  str += data.vid_perevez ? `, Вид перевезення: '${data.vid_perevez.title}'` : '';
  str += nomenklatArr && nomenklatArr?.length > 0 ? `, Номенклатура вантажу: '${nomenklatArr.join()}'` : '';
  str += esrArr && esrArr?.length > 0 ? `, Станція відправки: '${esrArr.join()}'` : '';
  str += klientArr && klientArr?.length > 0 ? `, Вантажовідправник: '${klientArr.join()}'` : '';
  str += data.elevator ? `, Елеватор: '${data.elevator}'` : '';
  str += data.dir_otpr ? `, Відділення відправлення: '${data.dir_otpr}'` : '';
  str += data.dor_otpr ? `, Залізниця відправлення: '${data.dor_otpr}'` : '';
  str += data.stran_otpr ? `, Держава відправлення: '${data.stran_otpr?.title}'` : '';
  str += data.plan_number ? `, Номер плану: '${data.plan_number}'` : '';
  str += data.plan_number_snd ? `, Номер плану СНД: '${data.plan_number_snd}'` : '';
  str += data.ozv_vl ? `, Рухомий склад: '${data.ozv_vl?.title}'` : '';

  return str;
}