import { findAction, clearAction, filterAction, headerAction } from "../constants/sogl-reestr-action-types"
import { headCellsSoglReestr as headCells } from '../../common/constants/headerReestrSogl';

const soglReestr = (state = {data: null, loading: false, searchVal: '', headerOptions: headCells,}, action) => {
    switch(action.type){
        case findAction:
            return {
                ...state,
                data: action.data,
                loading: action.loading,
                searchVal: '',
            }
        case clearAction:
            return {
                ...state,
                data: [],
                loading: false,
                searchVal: '',
            }
        case filterAction:
            return {
                ...state,                    
                searchVal: action.searchVal,
                } 
        case headerAction:
            return {
                ...state,                    
                headerOptions: action.headerOptions,
                }        
        default:
            return state
    }
}

export default soglReestr;