export const headCells = [
  { id: 'start_plan', numeric: false, disablePadding: true, label: 'Дата', show: true, order: 1, width: 100 },
  { id: 'number_sng', numeric: true, disablePadding: false, label: 'Ном СНД', show: true, order: 2, width: 130  },
  { id: 'vid_plan_name', numeric: true, disablePadding: false, label: 'Вид', show: true, order: 3, width: 100  },
  { id: 'esr_otpr_name', numeric: true, disablePadding: false, label: 'Станція відпр.', show: true, order: 4, width: 160  },
  { id: 'ozn_vl_name', numeric: true, disablePadding: false, label: 'Рух. склад', show: true, order: 5, width: 110  },
  { id: 'za_count', numeric: true, disablePadding: false, label: 'В-НИ-З', show: true, order: 6, width: 90  },
  { id: 'so_count', numeric: true, disablePadding: false, label: 'В-НИ-У', show: true, order: 7, width: 90  },
  { id: 'za_ves', numeric: true, disablePadding: false, label: 'Тони-З', show: true, order: 8, width: 80  },
  { id: 'so_ves', numeric: true, disablePadding: false, label: 'Тони-У', show: true, order: 9, width: 80  },
  { id: 'number_plan', numeric: true, disablePadding: false, label: 'Ном', show: true, order: 10, width: 80  },
  { id: 'type_plan_name', numeric: true, disablePadding: false, label: 'Перевез', show: true, order: 11, width: 100  },
  { id: 'soglas_code_name', numeric: true, disablePadding: false, label: 'Статус', show: true, order: 12, width: 80  },
  { id: 'klient_name', numeric: true, disablePadding: false, label: 'Вантажовідправник-1', show: true, order: 13, width: 130  },
  { id: 'date_last_op', numeric: true, disablePadding: false, label: ' Дата редаг.', show: true, order: 14, width: 120  },
  { id: 'nomenklat_name', numeric: true, disablePadding: false, label: 'Номенклатура вантажу', show: true, order: 15, width: 200  },
  { id: 'stran_otpr_name', numeric: true, disablePadding: false, label: 'Держава відпр.', show: true, order: 16, width: 150  },
  { id: 'dor_otpr_name', numeric: true, disablePadding: false, label: 'Зал.', show: true, order: 17, width: 100  },
  { id: 'nod_otpr', numeric: true, disablePadding: false, label: 'ДН.', show: true, order: 18, width: 50  },
  { id: 'prim_plan', numeric: true, disablePadding: false, label: 'Примітка заявки', show: true, order: 19, width: 150  },
];

export const headCellsReestr = [
  { field: 'start_plan', headerName: 'Дата', show: true, order: 1, width: 100 },
  { field: 'number_sng', headerName: 'Ном СНД', show: true, order: 2, width: 130  },
  { field: 'vid_plan_name', headerName: 'Вид', show: true, order: 3, width: 100  },
  { field: 'esr_otpr_name', headerName: 'Станція відпр.', show: true, order: 4, width: 160  },
  { field: 'ozn_vl_name',   headerName: 'Рух. склад', show: true, order: 5, width: 110  },
  { field: 'za_count', headerName: 'В-НИ-З', show: true, order: 6, width: 90  },
  { field: 'so_count', headerName: 'В-НИ-У', show: true, order: 7, width: 90  },
  { field: 'za_ves', headerName: 'Тони-З', show: true, order: 8, width: 80  },
  { field: 'so_ves', headerName: 'Тони-У', show: true, order: 9, width: 80  },
  { field: 'number_plan', headerName: 'Ном', show: true, order: 10, width: 80  },
  { field: 'type_plan_name', headerName: 'Перевез', show: true, order: 11, width: 100  },
  { field: 'soglas_code_name', headerName: 'Статус', show: true, order: 12, width: 80  },
  { field: 'klient_name', headerName: 'Вантажовідправник-1', show: true, order: 13, width: 130  },
  { field: 'date_last_op', headerName: ' Дата редаг.', show: true, order: 14, width: 120  },
  { field: 'nomenklat_name', headerName: 'Номенклатура вантажу', show: true, order: 15, width: 200  },
  { field: 'stran_otpr_name', headerName: 'Держава відпр.', show: true, order: 16, width: 150  },
  { field: 'dor_otpr_name', headerName: 'Зал.', show: true, order: 17, width: 100  },
  { field: 'nod_otpr', headerName: 'ДН.', show: true, order: 18, width: 50  },
  { field: 'prim_plan', headerName: 'Примітка заявки', show: true, order: 19, width: 150  },
];

export const headCellsEditApplication = [
  { id: 'esr_prib_name', numeric: false, disablePadding: true, label: 'Станція призначення', show: true, order: 1, width: 200 },
  { id: 'stran_prib_name', numeric: true, disablePadding: false, label: 'Кр.призн.', show: true, order: 2, width: 100  },
  { id: 'poluch_ukr_name', numeric: true, disablePadding: false, label: 'Одерж.в Укр', show: true, order: 3, width: 150  },
  { id: 'poluch_vnesh', numeric: true, disablePadding: false, label: 'Одерж.за корд', show: true, order: 4, width: 150  },
  { id: 'perevizn_name', numeric: true, disablePadding: false, label: 'Перевізник', show: true, order: 5, width: 100  },
  { id: 'q', numeric: true, disablePadding: false, label: 'К-сть конт.', show: true, order: 6, width: 120  },
  { id: 'w', numeric: true, disablePadding: false, label: 'Типорозмір', show: true, order: 7, width: 100  },
  { id: 'e', numeric: true, disablePadding: false, label: 'Приналежність', show: true, order: 8, width: 100  },
  { id: 'r', numeric: true, disablePadding: false, label: 'Озн.маршруту', show: true, order: 9, width: 100  },
  { id: 'soglas_code_name', numeric: true, disablePadding: false, label: 'Статус', show: true, order: 12, width: 100  },
  { id: 'za_ves', numeric: true, disablePadding: false, label: 'Тони(заяв.)', show: true, order: 13, width: 80  },
  { id: 'za_count', numeric: true, disablePadding: false, label: 'Вагони', show: true, order: 14, width: 80  },
  { id: 'so_ves', numeric: true, disablePadding: false, label: 'Тони(узг.)', show: true, order: 15, width: 80  },
  { id: 'so_count', numeric: true, disablePadding: false, label: 'Вагони', show: true, order: 16, width: 80  }
];