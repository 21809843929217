import React, { useEffect, useState } from 'react';
import {
    Box,
    Stepper,
    Typography,
    Step,
    StepLabel,
    Button,
    Modal,
    ListItemText,
    ListItemIcon,
    ListItem,
    Grid,
    List,
    ListItemButton,
    IconButton,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext, useFieldArray } from 'react-hook-form';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReactCountryFlag from "react-country-flag";
import axios from '../../../../../../../utils/axios';
import { CONFIG_MESPLAN as config } from '../../../../../../../config';
import { GetCounryCode } from "../../utils/getCountryCode";

const steps = ['Вибір адміністрації', 'Вибір станції', 'Додати'];

const PlanFormHelperComponent = ({ data, open, setOpen, _appendFormation, _removeFormation }) => {
    const { control: _controlPerevez, setValue: _setValuePerevez, register: _registerPerevez, watch: _watchPerevez, handleSubmit: _handleSubmitPerevez } = useFormContext();
    const { fields: newFormationVag, append: newAppendFormation, remove: newFormationRemove } = useFieldArray({ control: _controlPerevez, name: 'newFormationsArr' });
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [dense, setDense] = useState(false);
    const [secondary, setSecondary] = useState(false);
    const [planFormationHelperData, setPlanFormationHelperData] = useState(null);
    const [selectedEsr, setSelectedEsr] = useState(null);
    const [selectedAdm, setSelectedAdm] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        handleFormationHelper(data?.adm_prib);
    }, [data, open])

    const isStepOptional = (step) => {
        // return step === 1;
        return false;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const hanleNextFirstStep = (index) => {
        setActiveStep(1);
        setSelectedAdm(index);
    }

    const hanleNextSecondStep = (index) => {
        setActiveStep(2);
        setSelectedEsr(index);
    }

    const handleAdd = () => {
        const temp = {
            "idf_perevez": data?.idf_perevez,
            "por_index": "1",
            "k_esr": planFormationHelperData[selectedAdm][selectedEsr]?.esrCode,
            "k_adms": planFormationHelperData[selectedAdm][selectedEsr]?.kAdms,
            "eksped": "",
            "eksped_name": "",
            "code_perevizn": "",
            "name_perevizn": "",
            "soglas_code": "",
            "soglas_comment": "",
            "k_esr_name": planFormationHelperData[selectedAdm][selectedEsr]?.esrName,
            "k_adms_name": planFormationHelperData[selectedAdm][selectedEsr]?.StrnName,
            "eksped_name_dic": "",
            "perevizn_name_dic": ""
        };
        newAppendFormation(temp);
        setActiveStep(0);
        //
        handleFormationHelper(planFormationHelperData[selectedAdm][selectedEsr]?.kAdms);
    }

    const handleFormationHelper = (adm) => {
        setLoading(true);
        if (adm) {
            axios({
                method: 'post',
                url: `${config.nsiUrl}/api/v1/proxyPost`,
                data: {},
                params: { gatewayUrlEnd: `Reestr/inpReestrPlanFormHelper?codeAdm=${adm}` },
                headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(
                (response) => {
                    // alert(JSON.stringify(response));
                    if (typeof response?.data === 'string' || response?.data instanceof String)
                        setPlanFormationHelperData(JSON.parse(response?.data));
                    else
                        setPlanFormationHelperData(response?.data);
                    //
                    setLoading(false);
                }, () => {
                    setPlanFormationHelperData(null);
                    setLoading(false);
                }
            )
        }
    }

    const handleReset = () => {
        setActiveStep(0);
        setSelectedEsr(null);
        setSelectedAdm(null);
    }

    const handleDeleteLast = (index) => {
        handleFormationHelper(newFormationVag[index - 1]?.k_adms);
        newFormationRemove(index);
    }

    const matches = useMediaQuery('(min-width:720px)');
    const _width = matches ? '70vw' : '95vw';
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: _width,
        maxHeight: '90vh',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleConfirmFormation = () => {
        _removeFormation();
        //
        if (newFormationVag) {
            newFormationVag?.map((item) => (
                _appendFormation({
                    ...item,
                    k_esr_value: { title: item.k_esr_name, value: item.k_esr },
                    k_adms_value: { title: item.k_adms_name, value: item.k_adms },
                })
            ));
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {newFormationVag && newFormationVag !== [] && newFormationVag.length !== 0 ?
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Вихідний стик</TableCell>
                                        <TableCell>Адміністрація сусідка</TableCell>
                                        <TableCell>Видалити</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {newFormationVag?.map((row, index) => (
                                        <TableRow
                                            key={`${row.k_esr}${index}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {`${row?.k_esr}-${row?.k_esr_name}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {`${row?.k_adms}-${row?.k_adms_name}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton disabled={index !== newFormationVag.length - 1} aria-label="delete" onClick={() => handleDeleteLast(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Divider sx={{ mb: 2 }} />
                    </>
                    : <></>}
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </>
                ) : (
                    <>
                        {activeStep === 0 ?
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                    {planFormationHelperData ? `Оберіть адміністрацію` : `Даних не знайдено`}
                                </Typography>
                                {!loading ?
                                    <List
                                        dense={dense}
                                    >
                                        {planFormationHelperData?.map((item, index) => (
                                            <>
                                                <ListItemButton onClick={() => { hanleNextFirstStep(index) }}>
                                                    <ListItem
                                                        secondaryAction={
                                                            <IconButton edge="end" aria-label="next">
                                                                <ArrowForwardIosIcon />
                                                            </IconButton>
                                                        }>
                                                        <ListItemIcon>
                                                            <ReactCountryFlag
                                                                countryCode={GetCounryCode(item[0]?.kAdms)}
                                                                svg
                                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                cdnSuffix="svg"
                                                                title={`${item[0]?.StrnName}`}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={`${item[0]?.kAdms} ${item[0]?.StrnName}`}
                                                            secondary={secondary ? `${item[0]?.admsName}` : null}
                                                        />
                                                    </ListItem>
                                                </ListItemButton>
                                                <Divider variant="inset" component="li" />
                                            </>
                                        ))}
                                    </List>
                                    : <LinearProgress />}
                            </Grid>
                            : <></>}

                        {activeStep === 1 ?
                            <Grid item xs={12} md={12}>
                                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                    Оберіть вихідний стик
                                </Typography>
                                <List
                                    dense={dense}
                                >
                                    {planFormationHelperData[selectedAdm]?.map((item, index) => (
                                        <>
                                            <ListItemButton onClick={() => { hanleNextSecondStep(index) }}>
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="next">
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                    }>
                                                    <ListItemText
                                                        primary={`${item?.esrCode} ${item?.esrName}`}
                                                        secondary={secondary ? `${item?.admsName}` : null}
                                                    />
                                                </ListItem>
                                            </ListItemButton>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                            </Grid>
                            : <></>}

                        {activeStep === 2 ?
                            <Grid item xs={12} md={12}>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                        {`Додати:`}
                                    </Typography>
                                    <Typography>
                                        {`Додати: ${planFormationHelperData[selectedAdm][selectedEsr]?.kAdms} - ${planFormationHelperData[selectedAdm][selectedEsr]?.StrnName}, ${planFormationHelperData[selectedAdm][selectedEsr]?.esrCode} - ${planFormationHelperData[selectedAdm][selectedEsr]?.esrName}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                                    <Button onClick={handleAdd} fullWidth color="primary" type="primary" variant="contained">
                                        Додати
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                                    <Button onClick={handleReset} fullWidth type="text">
                                        Скинути
                                    </Button>
                                </Grid>
                            </Grid>
                            : <></>}

                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}

                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box> */}
                    </>
                )}
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={newFormationVag?.length === 0}
                            onClick={handleConfirmFormation}
                        >
                            Додати план формування
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            color="text"
                            variant="contained"
                            fullWidth
                            onClick={handleClose}
                        >
                            Назад
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default PlanFormHelperComponent;
