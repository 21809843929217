export function GetCounryCode(props) {
    let code = "";
    switch (props) {
        case "22":
            code = 'UA';
            break;
        case "21":
            code = 'BY';
            break;
        case "23":
            code = 'MD';
            break;
        case "20":
            code = 'RU';
            break;
        case "25":
            code = 'LB';
            break;
        case "24":
            code = 'LT';
            break;
        case "51":
            code = 'PL';
            break;
        case "52":
            code = 'BG';
            break;
        case "53":
            code = 'RO';
            break;
        case "28":
            code = 'GE';
            break;
        case "33":
            code = 'CN';
            break;
        case "30":
            code = 'KP';
            break;
        case "31":
            code = 'MN';
            break;
        case "57":
            code = 'AZ';
            break;
        case "27":
            code = 'KZ';
            break;
        case "75":
            code = 'TR';
            break;
        case "80":
            code = 'DE';
            break;
        case "10":
            code = 'FI';
            break;
        case "26":
            code = 'EE';
            break;
        default:
            code = 'UA';
    }
    return code;
};