import { findAction, clearAction, filterAction, headerAction } from "../constants/reestr-action-types"

const reestr = (state = [], action) => {
    switch(action.type){
        case findAction:
            return {
                ...state,
                reestr: action.reestrData,
                loading: action.loading,
                searchVal: '',
            }
        case clearAction:
            return {
                ...state,
                reestr: [],
                loading: false,
                searchVal: '',
            }
        case filterAction:
            return {
                ...state,                    
                searchVal: action.searchVal,
                } 
        case headerAction:
            return {
                ...state,                    
                headerOptions: action.headerOptions,
                }        
        default:
            return state
    }
}

export default reestr;