import {
    Box, Button, Grid, Modal, useMediaQuery
} from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
//
import { CommonAlertHolder } from '../../../../../common/CommonAlert/CommonAlertHolder';
import { modalStyle } from '../../../../../common/style/modalStyle';
import { CONFIG_MESPLAN as config } from '../../../../../config';
import AuctionUzgod from './elzTypeComponents/AuctionUzgod';
import PlanUzgod from './elzTypeComponents/PlanUzgod';
import createHead from './utils/createHead';
import createUzgod from './utils/createUzgod';
import updateElz from './utils/updateData';

export default function UzgodModal(props) {
    const checkedDay = props?._checkedDay;
    const row = props?.row;
    const handleOpen = () => props.setOpen(true);
    const handleClose = () => props.setOpen(false);
    const closeMenu = props?._closeMenu;
    // const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm();
    const methods = useForm();
    const control = methods.control;
    const { fields: fieldsAu, append: appendAuUzg, remove: removeAu } = useFieldArray({ name: 'auctionUzgod', control }); // normal
    const { fields: fieldsAuLong, append: appendAuLongUzg, remove: removeAuLong } = useFieldArray({ name: 'auctionLongUzgod', control }); // long
    // const { fields, append, remove } = useFieldArray({ name: 'normalAuction', control });
    const [checked, setChecked] = React.useState(false);
    const [auctions, setAuctions] = React.useState([]);
    const [auctionsLong, setAuctionsLong] = React.useState([]);
    //
    // const [auctionsByDay, setAuctionsByDay] = React.useState([]);
    // const [auctionsLongByDay, setAuctionsLongByDay] = React.useState([]);
    const [planByDay, setPlanByDay] = React.useState(null);
    //
    const matches = useMediaQuery('(min-width:720px)');
    //
    const selectedMonth = useSelector(state => state.userMesplan?.month);
    const dataElz = useSelector(state => state.elz?.data);
    //
    const dispatch = useDispatch();
    //
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // form submit handler
    const onSubmit = data => {

        const head = createHead(row, selectedMonth);
        const kalend = createUzgod(data, checkedDay);

        if (kalend?.kalends?.length === 0) {
            CommonAlertHolder.alert('warning', 'Нічого не було введено');
            return;
        }

        axios.post(`${config.apiUrl}/api/v1/handler/message1133Elz`, { ...head, ...kalend })
            .then((response) => {
                CommonAlertHolder.alert('success', 'Електронну заявку узгоджено');
                updateElz(row, dispatch, dataElz, handleClose, selectedMonth);
            })
            .catch((error) => {
                alert(error?.response?.data);
                updateElz(row, dispatch, dataElz, handleClose, selectedMonth);
            });
    };

    const ITEM_HEIGHT = 48;

    useEffect(() => {
        closeMenu();
        removeAu();
        removeAuLong();
        //
        if (checkedDay) {
            //
            let dayAu = row.daysElz.lot?.filter((val) => val?.day === checkedDay);
            let dayAuLong = row.daysElz.lotLong?.filter((val) => val?.day === checkedDay);
            let dayPlan = row.daysElz.plan?.filter((val) => val?.day === checkedDay);
            // to object or null
            if (dayAu && dayAu.length !== 0) { dayAu = dayAu[0] } else { dayAu = null };
            if (dayAuLong && dayAuLong.length !== 0) { dayAuLong = dayAuLong[0] } else { dayAuLong = null };
            if (dayPlan && dayPlan.length !== 0) { dayPlan = dayPlan[0] } else { dayPlan = null };

            // ordinary plan
            setPlanByDay(dayPlan);
            methods.setValue("dataPlan", dayPlan);
            // set value to long au
            if (dayAuLong) {
                for (let j = 0; j < dayAuLong?.auction_id.length; j += 1) {
                    if (dayAuLong?.soglasElz[j]) {
                        const auInfo = {
                            day: dayAuLong?.day,
                            auction_id: dayAuLong?.auction_id[j],
                            auction_num: dayAuLong.auction_num[j],
                            dop_count: dayAuLong?.dop_count[j],
                            k_count: dayAuLong?.k_count[j],
                            za_count: dayAuLong?.za_count[j],
                            na_count: dayAuLong?.na_count[j],
                            pe_count: dayAuLong?.pe_count[j],
                            po_count: dayAuLong?.po_count[j],
                            pr_invent: dayAuLong?.pr_invent[j],
                        }
                        const isRecipient = dayAuLong?.soglasElz[j]?.org_code === '40';
                        const isElevator = dayAuLong?.soglasElz[j]?.org_code !== '40';
                        // append data
                        appendAuLongUzg({ vagCount: dayAuLong?.za_count[j], recipientUzg: isRecipient, elevatorUzg: isElevator, soglasElz: dayAuLong?.soglasElz[j], info: auInfo });
                    }
                }
            }

            // set value to au 
            if (dayAu) {
                for (let j = 0; j < dayAu?.auction_id; j += 1) {
                    if (dayAu?.soglasElz[j]) {
                        const auInfo = {
                            day: dayAu?.day,
                            auction_id: dayAu?.auction_id[j],
                            auction_num: dayAu?.auction_num[j],
                            dop_count: dayAu?.dop_count[j],
                            k_count: dayAu?.k_count[j],
                            za_count: dayAu?.za_count[j],
                            na_count: dayAu?.na_count[j],
                            pe_count: dayAu?.pe_count[j],
                            po_count: dayAu?.po_count[j],
                            pr_invent: dayAu?.pr_invent[j],
                        }
                        // append data
                        const isRecipient = dayAu?.soglasElz[j]?.org_code === '40';
                        const isElevator = dayAu?.soglasElz[j]?.org_code !== '40';
                        appendAuUzg({ vagCount: dayAu?.za_count[j], recipientUzg: isRecipient, elevatorUzg: isElevator, soglasElz: dayAu?.soglasElz[j], info: auInfo });
                    }
                }
            }
        }
    }, [checkedDay, row])

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-uzgod-title"
            aria-describedby="modal-uzgod-description"
        >
            <Box sx={modalStyle}>
                <FormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        {planByDay && planByDay?.soglasElz ?
                            <PlanUzgod
                                _checkedDay={checkedDay}
                                _planByDay={planByDay}
                            />
                            : <></>}

                        {fieldsAu.map((item, index) => (
                            <AuctionUzgod
                                _checkedDay={checkedDay}
                                _item={item}
                                _index={index}
                                _isLong={false}
                                _name={"auctionUzgod"}
                            />
                        ))}

                        {fieldsAuLong.map((item, index) => (
                            <AuctionUzgod
                                _checkedDay={checkedDay}
                                _item={item}
                                _index={index}
                                _isLong
                                _name={"auctionLongUzgod"}
                            />
                        ))}

                        <Grid container spacing={matches ? 1 : 0.5}>
                            <Grid item xs={12} md={12} marginTop={3}>
                                <Button variant="contained" type="primary" fullWidth size={matches ? '' : 'small'}>Зберегти</Button>
                            </Grid>
                            <Grid item xs={12} md={12} marginTop={1}>
                                <Button onClick={handleClose} variant="contained" color="text" fullWidth size={matches ? '' : 'small'}>Закрити</Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </Box>
        </Modal>
    );
}