import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Enumerable from "linq";

import i18n from '../../locales/i18n';
import { HOST_API } from '../../config';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
    carrTrackersAll: [],
    carriages: [],
    pidprs: [],
    trackersWithoutOwner: [],
    configDownlink: {}
};

const slice = createSlice({
    name: 'sigfoxTrackers',
    initialState,
    reducers: {
        setCarrTrackersAll(state, action) {
            state.carrTrackersAll = action.payload;
        },

        setCarriages(state, action) {
            state.carriages = action.payload;
        },

        setConfig(state, action) {
            state.configDownlink = action.payload;
        },
        setPidprs(state, action) {
            state.pidprs = action.payload;
        },
        setCarrTrackersWithoutOwner(state, action) {
            state.trackersWithoutOwner = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setCarrTrackersAll,
    setCarriages,
    setConfig,
    setPidprs,
    setCarrTrackersWithoutOwner
  } = slice.actions;

// Загрузка информации трекерам, как привязанным к вагонам, так и непривязанным
export function getCarrTrackersAll(edrpou, translate, isAdmin = false) {
    return async() => {
        console.log(`edrpou=${edrpou} isAdmin=${isAdmin}`);
        
        await axios.get(`${HOST_API}/api-carrtracker/CarrTrackerLink/GetTrackersAll?EDRPOU=${edrpou}${ isAdmin ? '&isAdmin=true' : ''}`)
        // await axios.get(`http://localhost:5004/CarrTrackerLink/GetTrackersAll?EDRPOU=${edrpou}`)
        .then(result => {
            if (result.status === 200) {
                dispatch(slice.actions.setCarrTrackersAll(result.data));
            }
            else {
                toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-trackers')} StatusCode=${result.status}`));
            }
        })        
        .catch((error) => {
            toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-trackers')} 
                ${error.title ? error.title : error } 
                ${error.status ? error.status : ''}`)); 
        });            
        
    };     
}

// Загрузка информации о предприятиях
export function getPidprs(translate) {
    return async() => {
        
        // Информация о предприятиях
        await axios.get(`${HOST_API}/api-carrtracker/CarrTrackerLink/GetPidprs`)
        .then(result => {
            if (result.status === 200) {
  
                if (result.data.length === 0) {
                    dispatch(slice.actions.setPidprs([])); 
                    return;
                }  
                
                const data = result.data.map(p => ({
                    "value": p.edrpou,
                    "title": `${p.namePidpr} (${p.edrpou})`,
                    "idVsp": p.idVsp
                    })
                );
                dispatch(slice.actions.setPidprs(data));            
            }
            else {
                toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-pidprs')} StatusCode=${result.status}`));
            }
        })        
        .catch((error) => {
            toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-pidprs')} 
                ${error.title ? error.title : error } 
                ${error.status ? error.status : ''}`)); 
        });                 

    }
}

// Загрузка информации о вагонах
export function getCarriages(edrpou, translate) {
    return async() => {
        
        // Полная информация о вагонах предприятия
        await axios.get(`${HOST_API}/api-vagons/vagons/get_vagons?EDRPOU=${edrpou}`)
        .then(result => {
            if (result.status === 200) {
                const carriagesFull = result.data;    
                if (carriagesFull.length === 0) {
                    dispatch(slice.actions.setCarriages([])); 
                    return;
                }  
                
                const numCarriages = Enumerable.from(carriagesFull)
                    .select(x => x.nomer).orderBy(x => x.toString())./* distinct(). */toArray();
                
                dispatch(slice.actions.setCarriages(numCarriages));            
            }
            else {
                toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-carriages')} StatusCode=${result.status}`));
            }
        })        
        .catch((error) => {
            toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-carriages')} 
                ${error.title ? error.title : error } 
                ${error.status ? error.status : ''}`)); 
    });                 

    }
}

// Удаление привязки трекера к вагону
export function delCarrTrackerLink(idTracker, edrpou, translate) {
    return async() => {

        console.log(`remove link idTracker=${idTracker}`);
        
        // await axios.get(`http://localhost:5004/CarrTrackerLink/DelCarrTrackerLink?idTracker=${idTracker}`)
        await axios.get(`${HOST_API}/api-carrtracker/CarrTrackerLink/DelCarrTrackerLink?idTracker=${idTracker}`) 
            .then(result => {
                if (result.status === 200) {
                    dispatch(getCarrTrackersAll(edrpou));
                    toast.success(i18n.t('service.sigfox.fields-device.success-saved-del'));
                }
            })        
            .catch((error) => {
                // console.log(error.toJSON());
                if (error.status === 404) {
                    toast.error(i18n.t(`${translate('service.sigfox.errors.no-data-found')} (idTracker=${idTracker})`));
                    dispatch(getCarrTrackersAll(edrpou));
                }
                else {
                    toast.error(i18n.t(`${translate('service.sigfox.errors.unknown')} 
                        ${error.title ? error.title : error } 
                        ${error.status ? error.status : ''}`)); 
                    }
            });
       
    };     
}

// Добавление привязки трекера к вагону
export function addCarrTrackerLink(idTracker, numCarriage, edrpou, translate) {
    return async() => {

        console.log(`add link idTracker=${idTracker} numCarriage=${numCarriage}`);
        const data = {idTracker, numCarriage };

        // await axios.post(`http://localhost:5004/CarrTrackerLink/AddCarrTrackerLink`, data)
        await axios.post(`${HOST_API}/api-carrtracker/CarrTrackerLink/AddCarrTrackerLink`, data)
            
            .then(result => {
                if(result.status === 200) {
                    dispatch(getCarrTrackersAll(edrpou));
                    toast.success(i18n.t('service.sigfox.fields-device.success-saved'));
                }
            })        
            .catch((error) => {
                toast.error(i18n.t(`${translate('service.sigfox.errors.unknown')} 
                    ${error.title ? error.title : error } 
                    ${error.status ? error.status : ''}`)); 
            });
       
    };
}
    
// Загрузка информации по трекерам, у которых нет хозяина (нет привязки к ЕДРПОУ)
export function getTrackersWithoutOwner(translate) {
        return async() => {
        
        await axios.get(`${HOST_API}/api-carrtracker/CarrTrackerLink/GetTrackersWithoutOwner`)
        // await axios.get(`http://localhost:5004/CarrTrackerLink/GetTrackersAll?EDRPOU=${edrpou}`)
        .then(result => {
            if (result.status === 200) {
                dispatch(slice.actions.setCarrTrackersWithoutOwner(result.data));
            }
            else {
                toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-trackers')} StatusCode=${result.status}`));
            }
        })        
        .catch((error) => {
            toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-trackers')} 
                ${error.title ? error.title : error } 
                ${error.status ? error.status : ''}`)); 
        });            
        
    };     

}

// Добавление привязки трекера к собственнику
// isAdmin определяет, надо ли возвращать данные по всем трекерам (true) или только по трекерам предприятия (false, default)
export function addTrackersOwner(trackers, edrpou, idVsp, translate, isAdmin = false) {
    return async() => {

        console.log(`add new trackers [${trackers.join(',')}] edrpou=${edrpou} idVsp=${idVsp}`);
        const data = { trackers: trackers.join(','), edrpou, idVsp };

        // await axios.post(`http://localhost:5004/CarrTrackerLink/AddCarrTrackerLink`, data)
        await axios.post(`${HOST_API}/api-carrtracker/CarrTrackerLink/AddTrackersOwner`, data)
            
            .then(result => {
                if(result.status === 200) {
                    dispatch(getCarrTrackersAll(edrpou, translate, isAdmin));
                    toast.success(i18n.t('service.sigfox.fields-device.success-saved'));
                }
                else {
                    toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-trackers')} StatusCode=${result.status}`));
                }
            })        
            .catch((error) => {
                toast.error(i18n.t(`${translate('service.sigfox.errors.unknown')} 
                    ${error.title ? error.title : error } 
                    ${error.status ? error.status : ''}`)); 
            });
       
    };
}

// Удаление привязки трекера к вагону
export function delTrackerOwner(idTracker, edrpou, translate, isAdmin = false) {
    return async() => {

        console.log(`remove idTracker=${idTracker}`);
        
        // await axios.get(`http://localhost:5004/CarrTrackerLink/DelCarrTrackerLink?idTracker=${idTracker}`)
        await axios.get(`${HOST_API}/api-carrtracker/CarrTrackerLink/RemoveTrackerOwner?idTracker=${idTracker}`) 
            .then(result => {
                if (result.status === 200) {
                    dispatch(getCarrTrackersAll(edrpou, translate, isAdmin));
                    toast.success(i18n.t('service.sigfox.fields-device.success-saved-del'));                    
                }
                else {
                    toast.error(i18n.t(`${translate('service.sigfox.errors.bad-status-trackers')} StatusCode=${result.status}`));
                }
            })        
            .catch((error) => {
                // console.log(error.toJSON());
                // if (error.status === 404) {
                //     alert(`${translate('service.sigfox.errors.no-data-found')} (idTracker=${idTracker})`);
                //     dispatch(getCarrTrackersAll(edrpou));
                // }
                // else {
                    toast.error(i18n.t(`${translate('service.sigfox.errors.unknown')} 
                        ${error.title ? error.title : error } 
                        ${error.status ? error.status : ''}`)); 
                    // }
            });
       
    };     
}

// --- Отримання конфігурації трекера --- -----------------------
export function getConfigTracker(tracker) {
    return async() => {
        /*
        const d = {
            "idRec": 2,
            "idTracker": 2,
            "dateInfo": "2023-11-18T23:09:04",
            "dateUpdate": "2023-11-18T21:09:04",
            "dataIn": "0A05180003000008",
            "dataOut": null,
            "config": "0A05180003000008",
            "dateSet": "2023-11-18T23:01:50",
            "serial": "JKH6H7"
          };
          dispatch(setConfig(d));
          */
          
        await axios.get(`${HOST_API}/api-webhook/downlink/config?deviceId=${tracker.serial}`)
        .then(result => {
            if (result.status === 200) {
            dispatch(setConfig(result.data));
            }
        });
        
    }

};

    // --- Save config --- ------------------------------
    export function saveConfigTracker(config) {
        return async() => {
              // dispatch(setConfig(config));
              await axios.post(`${HOST_API}/api-webhook/downlink/config`, config)
              .then(result => {
                if (result.status === 200) {
                dispatch(setConfig(config));
                }
            });
    
              /*
            await axios.get(`${HOST_API}/api-webhook/downlink/config?deviceId=${tracker.series}`)
            .then(result => {
                if (result.status === 200) {
                dispatch(setConfig(result.data));
                }
            });
            */
        }
    };
