import { findAction, clearAction, filterAction, headerAction, totalDaysAction } from "../constants/elz-action-types"
import { headCells } from '../../common/constants/headerElz';

const elz = (state = { data: null, totalDays: null, loading: false, searchVal: '', headerOptions: headCells, }, action) => {
    switch (action.type) {
        case findAction:
            return {
                ...state,
                data: action.data,
                loading: action.loading,
                searchVal: '',
            }
        case totalDaysAction:
            return {
                ...state,
                totalDays: action.totalDays,
            }
        case clearAction:
            return {
                ...state,
                data: [],
                loading: false,
                searchVal: '',
            }
        case filterAction:
            return {
                ...state,
                searchVal: action.searchVal,
            }
        case headerAction:
            return {
                ...state,
                headerOptions: action.headerOptions,
            }
        default:
            return state
    }
}

export default elz;