import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  idName: PropTypes.string,
  opt: PropTypes.array, 
  vl: PropTypes.object, 
  title: PropTypes.string,
  clearValue: PropTypes.bool,
  selected: PropTypes.object
};

export default function RHFAutocomplete({ name, idName, opt, vl, title, children, required, onChange, selected, clearValue= false, optionLabelWithValue = false, ...other }) {
  const { control, setValue  } = useFormContext();

  const defaultProps = {
    getOptionLabel: (option) => option?.title || ' ',
    isOptionEqualToValue: (option, val) => option?.title === val?.title,
    filterOptions: createFilterOptions({
      matchFrom: 'any',
      stringify: (option) => option?.title,
    })
  };
  
  const [value, setMyValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setMyValue(vl);
    setInputValue(vl?.title || '');
  },[vl]);
  useEffect(() => {
    setOptions(opt);
  },[opt]);
  // console.log(name, opt, vl, label);

  const getOptionLabel = (option) => {
    if (!option?.title || !option?.value) {
      return '';
    }
    return optionLabelWithValue ? `${option?.value} - ${option?.title}` : option?.title;
  }

  return (
    <Controller
      render={({ field, fieldState, formState }) => (
        <Autocomplete
          value={selected || value}
          onChange={(event, newValue) => {
            // setOptions(newValue ? [newValue, ...options] : options);
            if (newValue) {
              onChange(newValue)
              setValue(name, newValue);
              setValue(idName, newValue?.value);
              if(!clearValue) setMyValue(newValue);
              field.value = newValue;
              return newValue;
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) => getOptionLabel(option)}
          isOptionEqualToValue= {(option, val) => Number(option?.value) === Number(val?.value)}
          // filterOptions={(x) => x}
          options={options}
          fullWidth
          autoComplete
          includeInputInList
          // filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label={title} required={required}/>
          )}
          {...other}
        />
      )}
      onChange={([event, data]) => data}
      name={name}
      control={control}
    />
  );

  /*
  return (
    <>
    {options &&
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            field.value = newValue.value;
            console.log('field', field);
            console.log('other', other);
            return newValue.value;
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) =>
            option?.title ? option?.title : '' 
          }
          isOptionEqualToValue= {(opt, val) => opt.value === val.value}
          filterOptions={(x) => x}
          options={options}
          fullWidth
          autoComplete
          includeInputInList
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label={title} />
          )}
          {...other}
        />
        
      )}
    />}
    </>
  );
  */
}
