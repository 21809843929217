import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, InputAdornment, ClickAwayListener, Box, List, ListSubheader, Stack, BottomNavigation, BottomNavigationAction } from '@mui/material';
// utils
import { Restore } from '@mui/icons-material';
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import useProfile from '../../../hooks/useProfile';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);
// ----------------------------------------------------------------------

export default function MenubarService() {
  const [isOpen, setOpen] = useState(false);

  const { profiles, currentProileInd, currentCabinetInd, vMenu } = useProfile();
  const isCollapse = false;
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const [value, setValue] = useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
        <Stack direction="row" alignItems="left" justifyContent="space-between">
          {vMenu?.map((group, index) => (
            index === 0 &&
            <BottomNavigation sx={{ width: 500 }} value={value} onChange={handleChange}>
              {group?.items?.map((list) => (

              <BottomNavigationAction
                label={list.title}
                value={list.title}
                icon={list.icon}
              />
              ))}
            </BottomNavigation>  
            
                        
          ))}
        </Stack>
      );
    }