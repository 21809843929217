import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
import { customDateFormat, customPointDateFormat} from '../../utils/formatTime';
//
import { dispatch } from '../store';
import { showConfirmationModal } from './confirmation';



const initialState = {
    isLoading: false,    
    error: null,
    cargoGroups: [],
    cargoGroup: null,
    dicStates: [],
    dicOznLLG: [],
    dicPidprAll: [], 
    dicPidprAgreement7: [],   
    dicContracts: [],
    dicCargoParts: [],
    dicTypeOwner: [],
    dicTypeDoc: [],
    dicRps: [],
    /*
    nextOrder: 0,    
    logisticChains: [],
    logisticChain: null,
    sections: [],
    section: null,
    */
    isOpenCargoGroupModal: false,
    isOpenCargoGroupViewModal: false,
    indRodVag: []
  };

  const slice = createSlice({
    name: 'cargoGroup',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
        state.isLoading = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
        setCargoGroups(state, action) {
            state.cargoGroups = action.payload;
        }, 
        setCargoGroup(state, action) {
            state.cargoGroup = action.payload;
        }, 
        setDicStates(state, action) {
          state.dicStates = action.payload;
        },
        setDicOznLLG(state, action) {
          state.dicOznLLG = action.payload;
        },
        setDicPidprAll(state, action) {
            state.dicPidprAll = action.payload;
        }, 
        setDicPidprAgreement7(state, action) {
          state.dicPidprAgreement7 = action.payload;
      },
        setDicContracts(state, action) {
            state.dicContracts = action.payload;
        }, 
        setDicCargoParts(state, action) {
        state.dicCargoParts = action.payload;
        }, 
        setDicTypeOwner(state, action) {
            state.dicTypeOwner = action.payload;
        }, 
        setDicTypeDoc(state, action) {
            state.dicTypeDoc = action.payload;
        }, 
        setDicRps(state, action) {
          state.dicRps = action.payload;
      }, 
        setOpenCargoGroupModal(state, action) {
            state.isOpenCargoGroupModal = action.payload;
        },               
        setOpenCargoGroupViewModal(state, action) {
            state.isOpenCargoGroupViewModal = action.payload;
        },
        /*
        setNextOrder(state, action) {
            state.nextOrder = action.payload;
        },                    
        setLogisticChains(state, action) {
        state.logisticChains = action.payload;
        },
        setLogisticChain(state, action) {
          state.logisticChain = action.payload;
          },           
        setSections(state, action) {
          state.sections = action.payload;
        },
        setSection(state, action) {
          state.section = action.payload;
        }
        */
        setIndRodVag(state, action) {
          state.indRodVag = action.payload;
      },
    }
});  

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,    
    hasError,  
    setCargoGroups,
    setCargoGroup,
    setDicPidprAll,  
    setDicPidprAgreement7,  
    setDicContracts, 
    setDicTypeOwner,
    setDicTypeDoc, 
    setDicStates,
    setDicOznLLG,
    setDicCargoParts,
    setDicRps,
    /* 
    setNextOrder,    
    setLogisticChains,
    setLogisticChain,
    setSections,
    setSection,
    */
    setOpenCargoGroupModal,
    setOpenCargoGroupViewModal,
    setIndRodVag
  } = slice.actions;

// --------класифікатор станів
export const getDicStates = async () => {  
    await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Status&titleOut=name&valueOut=status&all=true`)
    .then(res => {
      if(res.status === 200) {
        const newDataLabel = [];        
        res.data.forEach(item => { 
          newDataLabel.push({'value': item.value, 'label': item.title})                  
        });         
        dispatch(setDicStates(newDataLabel));
      }
      else {
        // console.log('error getDicStates:', res);
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });    
  };

  export const getDicOznLLG = async () => {  
    const dic = [];
    dic.push({'value': 1, 'label': '+'});
    dic.push({'value': 0, 'label': '-'});
    dispatch(setDicOznLLG(dic));
    
  };
// --------класифікатор роду вагона
export const getDicRps = async () => {  
  await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_ROD_VAGS&fieldOutOne=name_rps&fieldOutTwo=code_rps&fieldOutThree=max_carrying&all=true`)  
  .then(res => {
    if(res.status === 200) {
      const newData = [];        
      res.data.forEach(item => {            
        const newItem = {'value': item.field2, 'title': `${item.field1.trim()} (${item.field2})`, 'code': item.field3};
        if (!newData.includes(newItem)) {
          newData.push(newItem)
        }                 
      });  
      console.log('getDicRps:', newData);         
      dispatch(setDicRps(newData));
    }
    else {
      // console.log('error getDicRodVag:', res);
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {      
    toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });    
};
// --------НДІ Підприємства
export const getDicPidprAll = async () => {
    await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=views.V_Ekbs_Pidpr_All&titleOut=display_info&valueOut= id_vsp&all=true`)
      .then(res => {
        if(res.status === 200) {
          dispatch(setDicPidprAll(res.data));          
        }
        else {          
          throw new Error(res.statusText || res.status);          
        }  
      })
      .catch((error) => {        
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });        
    };
// --------НДІ Підприємства, з якими є рамкові договори з типом 7 (договір на транспортно-експедиторське обслуговування).
export const getDicPidprAgreement7 = async () => {
  /*
  const data = "\" select distinct p.id_vsp as value, p.display_info as title, pa.id_customer as idCustomer" +
                " from views.V_Ekbs_Pidpr_All p, " +
                " pidpr.Ekbs_Pidpr_Agreements pa " +
                " where pa.agree_type= 7 and pa.id_saller = p.id_vsp \"";            
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )  
  */
  await axios.get(`api-sys/pidpr/agreetype`)
    .then(res => {
      if(res.status === 200) {
        // console.log('getDicPidprAgreement7', res.data);        
        dispatch(setDicPidprAgreement7(res.data));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });  
};

// --------НДІ класифікатор "Вид перевізного документа" 
  export const getDicTypeDoc = async () => {
    const f = [
      {
        "value": 1,
        "title": "повагонний"      
      },
      {
        "value": 2,
        "title": "груповий"       
      },
      {
        "value": 3,
        "title": "маршрутний"       
      }
      ];
      dispatch(setDicTypeDoc(f));
  };
  
// --------НДІ класифікатор "Тип власника вагонів" 
export const getDicTypeOwner = async () => {
    const f = [
      {
        "value": 1,
        "title": "ЦТЛ (УЗ)",
        "label": "ЦТЛ (УЗ)"      
      },
      {
        "value": 2,
        "title": "власні", 
        "label": "власні"     
      }
      /*
      {
        "value": 3,
        "title": "арендований"       
      } */
      ];
      dispatch(setDicTypeOwner(f));
  };
  
// --------НДІ партії вантажу, які належать до контрактів в яких  Стороною 1(id_owner) або Стороною 2(id_customer) є enterpriseId
// ---------и статус контракта = 1 или 2 ??????????????????????????????????
  export const getDicCargoParts = async (  idGroup = 0 /* enterpriseId */ /* , idContract='' */ ) => {
    // console.log('getDicCargoParts idContract=', idContract);
/*   
      // await axios.get(`api-vantazh/contracts/Parts?enterpriseId=${enterpriseId}`)
      await axios.get(`api-vantazh/contracts/Parts`)
        .then(res => {
          if(res.status === 200) { 
            console.log('getDicCargoParts', res.data); 
            const newData = [];        
            res.data.forEach(item => {            
              const newItem = {'value': item.idPart, 'title': item.tidPart.trim(),   // tidPart
                               'weight': item.weight,
                               'dateBeg': item.dateBegin, 
                               'idElevator': item.idElevator,
                               "nameElevator": item.nameElevator, 
                               "edrpouElevator": item.edrpouElevator,
                               'idContract' : item.idContract
                              };
              if (!newData.includes(newItem)) {
                newData.push(newItem)
              }                 
            });         
            dispatch(setDicCargoParts(newData)); 
          }
      })   
      .catch((error) => {
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });  
    };
*/    
  
 
    // const strWhereContract = idContract ? `part.id_contract = ${idContract} and ` : ``;
/*
    const data = `" select distinct       ` +
    ` part.id_cargo as value, ` +
    ` CONCAT(cm.tid_contract, '_', e.name, ' ', a.NAME_SHORT, ' ', s1.name_station ) AS title, ` +
    // ` name_part as title,     ` +
    ` cargo_part_weight as weight,  ` +
    ` date_beg_cargo as dateBeg,    ` +
    ` date_end_cargo as dateEnd,    ` +
    ` id_elevator as idElevator,    ` +
    ` pall4.display_info as nameElevator, ` +    
    ` cm.id_contract as idContract, ` +
    ` cm.id_owner as idOwner,       ` +
    ` cm.id_customer as idCustomer, ` +
    ` cm.id_logist as idLogist     ` +
//    
//    ` last_value(cg.id_sender) over (partition by cg.id_cargo order by cg.id_group RANGE BETWEEN UNBOUNDED PRECEDING AND UNBOUNDED FOLLOWING) as lastSender,          ` +
//    ` last_value(cg.id_payer) over (partition by cg.id_cargo order by cg.id_group RANGE BETWEEN UNBOUNDED PRECEDING AND UNBOUNDED FOLLOWING) as lastPayer,            ` +
//    ` last_value(cg.type_owner_vag) over (partition by cg.id_cargo order by cg.id_group RANGE BETWEEN UNBOUNDED PRECEDING AND UNBOUNDED FOLLOWING) as lastTypeOwnerVag, ` +
//    ` last_value(cg.type_group) over (partition by cg.id_cargo order by cg.id_group RANGE BETWEEN UNBOUNDED PRECEDING AND UNBOUNDED FOLLOWING) as lastTypeGroup         ` +
//    
    ` from IC.Cargo_Part part ` +
    ` inner join IC.Cargo_Main cm on part.id_contract = cm.id_contract              ` +
    ` left outer join views.V_Ekbs_Pidpr_All pall4 on part.id_elevator = pall4.id_vsp ` +
    ` left outer join IC.Cargo_Group cg on cg.id_cargo = part.id_cargo              ` +
    ` left join nsi.E_TV_ETSNV e on cm.etsnv = e.CODE   ` +
//    ` left join views.V_Stations s on s.code_station = part.esr_otpr  ` +
    ` left join views.V_Stations s1 on s1.code_station = cm.esr_prib  ` +
//    ` left join nsi.E_D_ADM a on s1.code_adm = a.K_ADMI               ` +
    ` left join nsi.E_D_STRN a on s1.code_adm = a.K_ADMI               ` +
    ` where ` +
    // ${strWhereContract} ` + 
    // ` cm.id_owner = ${enterpriseId} or cm.id_customer = ${enterpriseId}"`; 
    ` cm.id_owner = ${enterpriseId} or cm.id_logist = ${enterpriseId}"`;

  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )  
  */
  await axios.get(`api-vantazh/contracts/PartsForSelect/${idGroup}`)
    .then(res => {
    if(res.status === 200) {
       console.log('getDicCargoParts', res.data);  
       let newItems = [...res.data];   
        newItems = newItems.map(item => {
            const newItem = {...item};
            newItem.title = `${item.title} (${item.value})`;
            return newItem;
        });      
       dispatch(setDicCargoParts(newItems));
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
    })
    .catch((error) => {
      // console.log(error);
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });  

/*
  await axios.get(`api-vantazh/contracts/Parts?enterpriseId=${enterpriseId}`)
        .then(res => {
          if(res.status === 200) {  
             
            const newData = [];        
            res.data.forEach(item => {            
              const newItem = {'value': item.idPart, 'title': item.tidPart.trim(), 
                               'dateBegin': item.dateBegin, 'dateEnd': item.dateEnd, 
                               'idContract': item.idContract,
                               'weight': item.weight
                              };
              if (!newData.includes(newItem)) {
                newData.push(newItem)
              }                 
            });         
            dispatch(setDicCargoParts(newData)); 
          }
        });
  */
 };

// --------Реєстр групи вагонів  (все по предприятию)

export const getCargoGroups = async ( /* enterpriseId */ ) => {
  
   dispatch(setLoading(true));    
   await axios.get(`api-vantazh/contracts/Group`)
   .then(res => {
     if(res.status === 200) {  
      
      console.log('getCargoGroups', res.data);
      /*
      let newItems = [...res.data];   
            newItems = newItems.map(item => {
                const newItem = {
                  codeCargoETSNV: item.codeCargoETSNV,
                  codeState: item.codeState,
                  codeStationFrom: item.codeStationFrom,
                  codeStationTo: item.codeStationTo,
                  codeTypeDoc: item.codeTypeDoc,
                  codeTypeOwner: item.codeTypeOwner,
                  countCar: item.countCar,
                  dateBegPlan: item.dateBegPlan,
                  dateContract: item.dateContract,
                  edrpouPayer: item.edrpouPayer,
                  edrpouSender: item.edrpouSender,
                  idCargoGroup: item.idCargoGroup,
                  idContract: item.idContract,
                  idPart: item.idPart,
                  idPayer: item.idPayer,
                  idRoute: item.idRoute,
                  idSender: item.idSender,
                  nameCargoETSNV: item.nameCargoETSNV,
                  nameContract:  item.nameContract,
                  nameGroup: item.nameGroup,
                  namePart: item.namePart,
                  namePayer: item.namePayer,
                  nameSender: item.nameSender,
                  nameStationFrom: item.nameStationFrom,
                  nameStationTo: item.nameStationTo,
                  nameTypeDoc: item.nameTypeDoc,
                  nameTypeOwner: item.nameTypeOwner,
                  weight: item.weight,

                  tidGroup: `${item.nameSender} - ${item.weight}т - ${customPointDateFormat(item.dateBegPlan)}`,

                  // tidPart: `${item.nameStationFrom} - ${item.weight}т - ${customDateFormat(item.dateBegin)}`,

                }
                return newItem;
            }); 
             */     
       dispatch(setCargoGroups( res.data /* newItems */));                               
     }
     else {
      // console.log('error getCargoGroups:', res);
      throw new Error(res.statusText || res.status);          
    }  
  })
  .catch((error) => {
    // console.log(error);
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  })    
  .finally(() => dispatch(setLoading(false)) );
};
  

  // --------Реєстр групи вагонів (по ID партии груза)

export const getCargoGroupsByPart = async (idPart) => {
    dispatch(setLoading(true));    
      await axios.get(`api-vantazh/contracts/Group?idCargo=${idPart}`)
      .then(res => {
        if(res.status === 200) { 
          // console.log('getCargoGroupsByPart', res.data);  
          /*  
          let newItems = [...res.data];   
            newItems = newItems.map(item => {
                const newItem = {
                  codeCargoETSNV: item.codeCargoETSNV,
                  codeState: item.codeState,
                  codeStationFrom: item.codeStationFrom,
                  codeStationTo: item.codeStationTo,
                  codeTypeDoc: item.codeTypeDoc,
                  codeTypeOwner: item.codeTypeOwner,
                  countCar: item.countCar,
                  dateBegPlan: item.dateBegPlan,
                  dateContract: item.dateContract,
                  edrpouPayer: item.edrpouPayer,
                  edrpouSender: item.edrpouSender,
                  idCargoGroup: item.idCargoGroup,
                  idContract: item.idContract,
                  idPart: item.idPart,
                  idPayer: item.idPayer,
                  idRoute: item.idRoute,
                  idSender: item.idSender,
                  nameCargoETSNV: item.nameCargoETSNV,
                  nameContract:  item.nameContract,
                  nameGroup: item.nameGroup,
                  namePart: item.namePart,
                  namePayer: item.namePayer,
                  nameSender: item.nameSender,
                  nameStationFrom: item.nameStationFrom,
                  nameStationTo: item.nameStationTo,
                  nameTypeDoc: item.nameTypeDoc,
                  nameTypeOwner: item.nameTypeOwner,
                  weight: item.weight,

                  tidGroup: `${item.nameSender} - ${item.weight}т - ${customPointDateFormat(item.dateBegPlan)}`,

                  // tidPart: `${item.nameStationFrom} - ${item.weight}т - ${customDateFormat(item.dateBegin)}`,

                }
                return newItem;
            }); 
             */  
          dispatch(setCargoGroups( res.data /* newItems */ ));                               
        }
        else {
          // console.log('error getCargoGroupsByPart:', res);
          throw new Error(res.statusText || res.status);          
        }  
      })
      .catch((error) => {
        // console.log(error);
        toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      })        
      .finally(() => dispatch(setLoading(false)) );          
  };

export const getCargoGroup = async (idCargoGroup) => {
  await axios.get(`api-vantazh/contracts/Group?idGroup=${idCargoGroup}`)
    .then(res => {
      if(res.status === 200) {    
        console.log('getCargoGroup', res.data);                  
        dispatch(setCargoGroup(res.data));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })            
};

// ---------------Створення нової групи на підставі існуючої
export const getNewCargoGroupByCopy = async (idCargoGroup) => {
  await axios.get(`api-vantazh/contracts/Group?idGroup=${idCargoGroup}`)
    .then(res => {
      if(res.status === 200) {    
        // console.log('getNewCargoGroupByCopy', res.data);
        const newItem = {...res.data}; 
        newItem.idCargoGroup = null;
        newItem.nameGroup = '';
        newItem.dateBegPlan = null;
        newItem.codeTypeDoc = 0;
        newItem.weight = null;
        newItem.countCar = null;
        newItem.codeState = 1;

        newItem.prLLG = null;
        newItem.idRoute = null;
        newItem.tidRoute = '';
                    
        dispatch(setCargoGroup( newItem));
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    })
    .catch((error) => {
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })            
};


export const getNewCargoGroup = async () => {
    dispatch(setCargoGroup(null));  
  };

// --------видалення групи вагонів
export const deleteCargoGroup = (idCargoGroup, name) => {
    showConfirmationModal({
      isOpen: true,
      title: i18n.t('service.cargoGroups.titleDelete'),
      content: `${i18n.t('service.cargoGroups.answerDelete')} ${name}?`,
      onSubmit: () => submitDelete(idCargoGroup),
      onCancel: () => showConfirmationModal({ isOpen: false })
    });
  };
  
  const submitDelete = async (idCargoGroup) => { 
    
    await axios.delete(`/api-vantazh/contracts/Group?idGroup=${idCargoGroup}`) 
    .then(res => {
      if(res.status === 200) {
        getCargoGroups();
        showConfirmationModal({ isOpen: false });
        toast.success(i18n.t('service.cargoGroups.deleted'));
        // toast.success(`Групу вагонів ${name} успішно видалено.`);
      }
      else {        
        throw new Error(res.statusText || res.status);          
      }  
    }) 
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });    
  };

// --------видалення групи вагонів для партії вантажу
export const deleteCargoGroupByIdPart = (idCargoGroup, name, idPart) => {
  showConfirmationModal({
    isOpen: true,
    title: i18n.t('service.cargoGroups.titleDelete'),
    content: `${i18n.t('service.cargoGroups.answerDelete')} ${name}?`,
    onSubmit: () => submitDeleteByIdPart(idCargoGroup, idPart),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
};

const submitDeleteByIdPart = async (idCargoGroup, idPart) => { 
  await axios.delete(`/api-vantazh/contracts/Group?idGroup=${idCargoGroup}`) 
  .then(res => {
    if(res.status === 200) {
      getCargoGroupsByPart(idPart);
      showConfirmationModal({ isOpen: false });
      toast.success(i18n.t('service.cargoGroups.deleted'));
    }
    else {      
      throw new Error(res.statusText || res.status);          
    }  
  }) 
  .catch((error) => {      
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
  });        
};


// --------збереження
export const saveCargoGroup = async (data) => {
  await axios.put(`api-vantazh/contracts/Group`, data)
    .then(res => {
      if(res.status === 200) {
        getDicCargoParts(res.data.idCargoGroup);
        dispatch(setCargoGroup(res.data));   
        toast.success(i18n.t('service.cargoGroups.saved'));
      }
      else {
        throw new Error(res.statusText || res.status);          
      }      
    })
    .catch((error) => {        
      toast.error(`${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};
  
// --------збереження  (NEW)
export const saveNewCargoGroup = async (data) => {
  await axios.post(`api-vantazh/contracts/Group`, data)
    .then(res => {
      if(res.status === 200) {
        getDicCargoParts(res.data.idCargoGroup);
        dispatch(setCargoGroup(res.data));   
        toast.success(i18n.t('service.cargoGroups.saved'));
      }
      else {
        throw new Error(res.statusText || res.status);
      }      
    })
    .catch((error) => {      
      // toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      toast.error(`${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });
};

// ---------------------------------
export const openCargoGroupViewModal = async (idCargoGroup) => {
    getCargoGroup(idCargoGroup);
    dispatch(setOpenCargoGroupViewModal(true));
  };

  export const closeCargoGroupViewModal = async () => { 
    dispatch(setOpenCargoGroupViewModal(false));
  };
/*
  export const getNextOrder = async (idPart) => { 
    dispatch(setNextOrder(1));
  };
*/
/*
// ---------------Получение переліку ЛЛГ по ID группы
export const getLogisticChains = async (idCargoGroup) => { 
  const data =  `" SELECT id_SLC as idSlc FROM IC.Slc_Main WHERE id_group = ${idCargoGroup} "`;  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
    .then(res => {
      if(res.status === 200) {  
        console.log('getLogisticChains', res.data);
        if (res.data && res.data.length > 0)
        {
          console.log('logisticChain[0]', res.data[0]);
          // ---------------Получение ЛЛГ по ID ланцюжка
          getSLC(res.data[0]);
        }
        else
        {
          console.log('NEW logisticChain');
          getNewSLC();
        }
        dispatch(setLogisticChains(res.data));
      }
    })  
    .catch((error) => {      
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });      
};

// ---------------Получение ЛЛГ по ID 
export const getSLC = async (idSlc) => {
  await axios.get(`api-vantazh/Slcs/${idSlc}?withChildren=true`)
    .then(res => {
      if(res.status === 200) {    
        console.log('getSLC', res.data);                  
        dispatch(setLogisticChain(res.data));
      }
    })
    .catch((error) => {
      // console.log(error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })            
};

// ---------------Створення нового ЛЛГ  
export const getNewSLC = async () => {
  dispatch(setLogisticChain(null));               
};
*/

// ---------------Получение рода вагона для груза
export const getIndRodVagByCodeCargo = async (codeCargo) => { 
  
  const data =  `" SELECT v.ROD_VAG FROM nsi_ekbs.Dic_Cargo_Group_Vag v WHERE v.CODE_GROUP = ` +
  ` (SELECT d.code_group FROM nsi_ekbs.Dic_Cargo_Group_Diapaz d WHERE ${codeCargo} BETWEEN d.min_cargo_code AND d.max_cargo_code) "`;  
  
  await axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } )
    .then(res => {
      if(res.status === 200) {  
        console.log('getIndRodVagByCodeCargo', res.data);        
        dispatch(setIndRodVag(res.data));
      }
      else 
      {
        dispatch(setIndRodVag([]));
        throw new Error(res.statusText || res.status);
      }
    })  
    .catch((error) => {      
      toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    });      
};

