import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import HandBookMainPage from '../../sections/@dashboard/services/mesplan/handBook/MainPage';

const CustomerList = () => (
  <>
    <Helmet>
      <title>Довідники</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <HandBookMainPage />
      </Container>
    </Box>
  </>
);

export default CustomerList;
