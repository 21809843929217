import React, { createContext, useEffect } from 'react';
// import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { HOST_WS } from '../config';
import { updateNotifyLog } from '../utils/ws_actions';
import { setNotifyItem } from '../redux/slices/ws_reducers';
import useProfile from '../hooks/useProfile';
import useAuth from '../hooks/useAuth';
// ==============================================================
const WebSocketContext = createContext(null);

export { WebSocketContext };

export default ({ children }) => {
  let socket;
  let ws;
  const { profiles, currentProileInd } = useProfile();
  const { user } = useAuth();

  const dispatch = useDispatch();

  /*
  const sendMessage = (roomId, message) => {
    socket.emit('event://send-message', JSON.stringify({ 'roomId': roomId, 'data': message }));
    dispatch(updateNotifyLog({ 'roomId': roomId, 'data': message }));
  };
  */
  const sendMessage = (roomId, message) => {
    const payload = { 'roomId': roomId, 'data': message };
    socket.send(JSON.stringify(payload));
    dispatch(updateNotifyLog(payload));
  };

  useEffect(() => {
    if (profiles && profiles.length>0 && !socket) {
      try {
        // создать подключение
        // console.log(profiles);
        const pId = profiles[currentProileInd].profileId;
        const uId = profiles[currentProileInd].userId;
        const oId = profiles[currentProileInd].officeId;
        const wserver = `${HOST_WS}`; // 'wss://localhost:55000/ws';
        // - `${HOST_WS}`
        socket = new WebSocket(wserver, ['jwt', '12345678901234567890', `p${pId}`,`u${uId}`,`o${oId}`]);

        // обработчик входящих сообщений
        socket.onmessage = function (event) {
          const incomingMessage = event.data;
          const payload = JSON.parse(incomingMessage);
          setNotifyItem(payload);
        };

        ws = {
          socket,
          sendMessage,
        };
      } catch (er) {
        console.log(er);
      }
    }
  }, [profiles]);

      /*
    socket = io.connect(`${HOST_WS}/ws`);

    socket.on('event://get-message', (msg) => {
      const payload = JSON.parse(msg);
      dispatch(updateNotifyLog(payload));
    });

    ws = {
      'socket': socket,
      sendMessage,
    };
    */
  /*
  if (!socket) {
   try
   {
   // создать подключение
    //
    
    // "ws://localhost:8020/";
    // HOST_WS
    const pId = profiles[currentProileInd].profileId;
    const uId = profiles[currentProileInd].userId;
    const oId = profiles[currentProileInd].officeId;
    const wserver = "wss://192.168.0.154:55000/ws"; 
    // - `${HOST_WS}`
    socket = new WebSocket( wserver, ["jwt", "12345678901234567890", `p${pId}/u${uId}/o${oId}`] );
   
    // обработчик входящих сообщений
    socket.onmessage = function(event) {
        const incomingMessage = event.data;
        const payload = JSON.parse(incomingMessage);
        setNotifyItem(payload);
      };
  
    ws = {
        'socket': socket,
        sendMessage,
      };
      
    }
    catch(er)
    {
      console.log(er);
    }
   
  }
  */

  return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
};
