import React, { useEffect } from "react";
import {
    Grid,
    FormControl,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    TextField,
    IconButton,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext, useFieldArray } from "react-hook-form";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import HelpIcon from '@mui/icons-material/Help';
import { useSelector } from 'react-redux';
import axios from "axios";
import {
    stranPribSettings,
    klientSettings,
    esrPribSettings,
} from '../../../../../../../common/settings/autocompleteSettings';
import AutocompleteVirtualizad from '../../../../../../../common/components/AutocompleteVirtualizad';
import { CONFIG_MESPLAN as config } from '../../../../../../../config';
import PlanFormationHelperComponent from './PlanFormationHelper';

const PlanFormComponent = ({ data }) => {
    const { control: _controlPerevez, setValue: _setValuePerevez, register: _registerPerevez, watch: _watchPerevez, handleSubmit: _handleSubmitPerevez } = useFormContext();
    const [expanded, setExpanded] = React.useState(false);
    const [showHelper, setShowHelper] = React.useState(false);
    const handleChangeExpanded = () => setExpanded(!expanded);
    const matches = useMediaQuery('(min-width:720px)');
    const { fields: formationVag, append: appendFormation, remove: removeFormation } = useFieldArray({ control: _controlPerevez, name: 'formationsArr' });
    const selectedMonth = useSelector(state => state.userMesplan?.month);

    const [openHelper, setOpenHelper] = React.useState(false);
    const handleOpenHelper = () => {
        setOpenHelper(true);
    }

    const commonAutocompleteSettings =
    {
        control: _controlPerevez,
        register: _registerPerevez,
        setValue: _setValuePerevez,
        watch: _watchPerevez,
        multiple: false,
        size: 'small',
    }

    useEffect(() => {
        if (data?.formir) {
            data?.formir?.map((item) => (
                appendFormation({
                    ...item,
                    // tip_vag_value: { title: item.tip_vag_name, value: item.tip_vag },
                    k_esr_value: { title: item.k_esr_name, value: item.k_esr },
                    k_adms_value: { title: item.k_adms_name, value: item.k_adms },
                    eksped_value: { title: item.eksped_name, value: item.eksped },
                    perevizn_value: { title: item.name_perevizn, value: item.perevizn },
                })
            ));
        }
    }, [data?.formir]);

    // handle delete 
    const handleDeleteFormation = (id) => {
        removeFormation(id);
    };

    const handleAppendFormation = () => {
        appendFormation({
            idf_perevez: "",
            por_index: "",
            k_esr: "",
            k_adms: "",
            eksped: "",
            eksped_name: "",
            code_perevizn: "",
            name_perevizn: "",
            soglas_code: "",
            soglas_comment: "",
            k_esr_name: "",
            k_adms_name: "",
            eksped_name_dic: "",
            perevizn_name_dic: ""
        });
    };

    const handleGenerateFormation = () => {
        const request = {
            esr_otpr: data?.esr_sd,
            esr_prib: data?.esr_prib,
            month: selectedMonth,
        }

        axios({
            method: 'post',
            url: `${config.nsiUrl}/api/v1/proxyPost`,
            params: {gatewayUrlEnd : 'Reestr/inpReestrPlanFormGeneration'},
            data: request,
            headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(
            (response) => {
                alert(JSON.stringify(response));
            }
        )
    };

    return (
        <>

            <Grid item xs={12} md={12}>
                <Accordion sx={{ m: 0 }} expanded={expanded} onChange={handleChangeExpanded}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>План формування</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            spacing={matches ? 1.3 : 1.1}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ mb: '8px' }}>
                            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAppendFormation}>
                                Додати
                            </Button>
                            <Button variant="outlined" startIcon={<CreateIcon />} onClick={handleGenerateFormation}>
                                Згенерувати
                            </Button>
                            <Button variant="outlined" startIcon={<HelpIcon />} onClick={handleOpenHelper}>
                                Помічник розробки
                            </Button>
                        </Grid>
                        <Divider sx={{ mb: '8px', mt: '8px' }} />
                        <Grid container spacing={matches ? 1.3 : 1.1}>
                            <FormControl fullWidth>
                                <TextField id="soglas_comment" name="soglas_comment" {..._registerPerevez("soglas_comment")} label="Додаткова інформація про маршрут" variant="outlined" size={'small'} />
                            </FormControl>
                        </Grid>
                        <Divider sx={{ mb: '8px', mt: '8px' }} />
                        {formationVag?.map((row, index) => (
                            <Grid key={index} container spacing={matches ? 1.3 : 1.1}>
                                <Grid item xs={12} md={1}>
                                    <IconButton aria-label="delete-formation" onClick={() => handleDeleteFormation(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...esrPribSettings, ...commonAutocompleteSettings, id: `formationsArr.${index}.k_esr_value`, label: `Вихідний стик` }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...stranPribSettings, ...commonAutocompleteSettings, id: `formationsArr.${index}.k_adms_value`, label: `Адміністрація-сусідка` }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...klientSettings, ...commonAutocompleteSettings, id: `formationsArr.${index}.eksped_value`, label: `Експедитор` }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...klientSettings, ...commonAutocompleteSettings, id: `formationsArr.${index}.perevizn_value`, label: `Перевізник` }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <PlanFormationHelperComponent
                data={data}
                open={openHelper}
                setOpen={setOpenHelper}
                _formationVag={formationVag}
                _appendFormation={appendFormation}
                _removeFormation={removeFormation}
            />
        </>
    );
}

export default PlanFormComponent;
