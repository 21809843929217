import { setMonthAction, setRightsAction } from "../constants/user-action-types"

const setMonth = (data) => {
    return {
        type: setMonthAction,
        month: data
    }
}

const setRights = (data) => {
    return {
        type: setRightsAction,
        rights: data
    }
}

export default {
    setMonth,
    setRights
}