export const headCells = [
  { id: 'esr_otpr_name', numeric: false, disablePadding: true, label: 'Стан. відпр.', show: true, order: 1, width: 120 },
  { id: 'nomenklat_name', numeric: false, disablePadding: false, label: 'Номенклатура вантажу', show: true, order: 2, width: 220  },
  { id: 'number_sng', numeric: false, disablePadding: false, label: 'Ном СНД', show: true, order: 3, width: 100  },
  { id: 'number_plan', numeric: true, disablePadding: false, label: 'Ном', show: true, order: 4, width: 80  },
  { id: 'etsng_name', numeric: true, disablePadding: false, label: 'ЄТСНВ', show: true, order: 5, width: 100  },
  { id: 'vid_plan_name', numeric: true, disablePadding: false, label: 'План', show: true, order: 6, width: 100  },
  { id: 'ozn_vl_name', numeric: true,  disablePadding: false, label: 'Тип вл.ваг', show: true, order: 7, width: 120  },
  { id: 'org_rule_name', numeric: true, disablePadding: false, label: 'Орг.узгодж.', show: true, order: 8, width: 100  },
  { id: 'org_uchst_name', numeric: true, disablePadding: false, label: 'Орг.розгл.', show: true, order: 9, width: 100  },
  { id: 'esr_prib_name', numeric: true, disablePadding: false, label: 'Стан. призн.', show: true, order: 10, width: 120  },
  { id: 'index', numeric: true, disablePadding: false, label: 'П.н.', show: true, order: 11, width: 100  },
  // { id: 'perevez_index', numeric: true, disablePadding: false, label: '№ призн.', show: true, order: 12, width: 100  },
  { id: 'perevez_index', numeric: true, disablePadding: false, label: 'Пор.інд.призн.', show: true, order: 13, width: 100  },
  { id: 'start_plan', numeric: true, disablePadding: false, label: 'День', show: true, order: 14, width: 100  },
  { id: 'esr_prib_osgd_name', numeric: true, disablePadding: false, label: 'Стан. призн. ОСЖД', show: true, order: 15, width: 200  },
  { id: 'esr_sd_name', numeric: true, disablePadding: false, label: 'Стик Укр.', show: true, order: 16, width: 100  },
  { id: 'poluch_port_name', numeric: true, disablePadding: false, label: 'Порт.', show: true, order: 17, width: 100  },
  { id: 'stran_otpr_name', numeric: true, disablePadding: false, label: 'Країна відпр.', show: true, order: 18, width: 140  },
  { id: 'stran_prib_name', numeric: true, disablePadding: false, label: 'Країна призн.', show: true, order: 19, width: 140  },
  { id: 'pr_marshrut', numeric: true, disablePadding: false, label: 'Маршрут', show: true, order: 20, width: 100  },
  { id: 'dor_otpr_name', numeric: true, disablePadding: false, label: 'Зал', show: true, order: 21, width: 100  },
  { id: 'klient_name', numeric: true, disablePadding: false, label: 'Відправник', show: true, order: 22, width: 100  },
  { id: 'za_count', numeric: true, disablePadding: false, label: 'В-НИ З', show: true, order: 23, width: 100  },
  { id: 'za_ves', numeric: true, disablePadding: false, label: 'ТОНИ-З', show: true, order: 24, width: 100  },
  { id: 'so_count', numeric: true, disablePadding: false, label: 'В-НИ У', show: true, order: 25, width: 100  },
  { id: 'so_ves', numeric: true, disablePadding: false, label: 'ТОНИ-У', show: true, order: 26, width: 100  },
  { id: 'kr_count', numeric: true, disablePadding: false, label: 'В-НИ КР', show: true, order: 27, width: 100  },
  { id: 'kr_ves', numeric: true, disablePadding: false, label: 'ТОНИ-КР', show: true, order: 28, width: 100  },
  { id: 'na_count', numeric: true, disablePadding: false, label: 'В-НИ ВИК', show: true, order: 29, width: 100  },
  { id: 'na_ves', numeric: true, disablePadding: false, label: 'ТОНИ-ВИК', show: true, order: 30, width: 120  },
  { id: 'poluch_ukr_name', numeric: true, disablePadding: false, label: 'Отримувач ЄЕКК ', show: true, order: 31, width: 150  },
  { id: 'elevator_name', numeric: true, disablePadding: false, label: 'Елеватор', show: true, order: 32, width: 100  },
  { id: 'eksped_ukr_name', numeric: true, disablePadding: false, label: 'Екпедитор України', show: true, order: 33, width: 170  },
  { id: 'type_perevez_name', numeric: true, disablePadding: false, label: 'Перевез', show: true, order: 34, width: 100  },
  { id: 'soglas_code_name', numeric: true, disablePadding: false, label: 'Статус', show: true, order: 35, width: 100  },
  { id: 'date_last_op', numeric: true, disablePadding: false, label: 'Дата редаг.', show: true, order: 36, width: 120  },
  { id: 'prim_plan', numeric: true, disablePadding: false, label: 'Прим.заявки зал.', show: true, order: 37, width: 170  },
  { id: 'prim_perevez', numeric: true, disablePadding: false, label: 'Прим.признач.зал.', show: true, order: 38, width: 100  },
  { id: 'prim_soglas', numeric: true, disablePadding: false, label: 'Прим.УЗ', show: true, order: 39, width: 100  },
  { id: 'eksped_formir_name', numeric: true, disablePadding: false, label: 'Експедитори', show: true, order: 40, width: 100  },
  { id: 'formir_sogl', numeric: true, disablePadding: false, label: 'Погодження УЗ', show: true, order: 41, width: 150  },
  { id: 'vag_id_name', numeric: true, disablePadding: false, label: 'Власник/оператор РС', show: true, order: 42, width: 200  },
  { id: 'vag_rps', numeric: true, disablePadding: false, label: 'РРС', show: true, order: 43, width: 100  },
  { id: 'vag_spv_rps', numeric: true, disablePadding: false, label: 'РРС Підзам.', show: true, order: 44, width: 120  },
  { id: 'perevizn_name', numeric: true, disablePadding: false, label: 'Перевізник', show: true, order: 45, width: 100  },
  { id: 'poluch_vnesh', numeric: true, disablePadding: false, label: 'Отримувач закордоном', show: true, order: 46, width: 200  },
  { id: 'sng_ident', numeric: true, disablePadding: false, label: 'Тип стр. СНД', show: true, order: 47, width: 150  },
  { id: 'pr_elz', numeric: true, disablePadding: false, label: 'ЕЛЗ', show: true, order: 48, width: 100  },
  { id: 'vag_type_name', numeric: true, disablePadding: false, label: 'Тип ваг.', show: true, order: 49, width: 100  },
  { id: 'type_sd', numeric: true, disablePadding: false, label: 'Тип колії', show: true, order: 50, width: 100  },
];

export const headCellsSoglReestr = [
  { field: 'esr_otpr_name', headerName: 'Стан. відпр.', show: true, order: 1, width: 120 },
  { field: 'nomenklat_name', headerName: 'Номенклатура вантажу', show: true, order: 2, width: 220  },
  { field: 'number_sng', headerName: 'Ном СНД', show: true, order: 3, width: 100  },
  { field: 'number_plan', headerName: 'Ном', show: true, order: 4, width: 80  },
  { field: 'etsng_name', headerName: 'ЄТСНВ', show: true, order: 5, width: 100  },
  { field: 'vid_plan_name', headerName: 'План', show: true, order: 6, width: 100  },
  { field: 'ozn_vl_name', headerName: 'Тип вл.ваг', show: true, order: 7, width: 120  },
  { field: 'org_rule_name', headerName: 'Орг.узгодж.', show: true, order: 8, width: 100  },
  { field: 'org_uchst_name', headerName: 'Орг.розгл.', show: true, order: 9, width: 100  },
  { field: 'esr_prib_name', headerName: 'Стан. призн.', show: true, order: 10, width: 120  },
  { field: 'index', headerName: 'П.н.', show: true, order: 11, width: 100  },
  { field: 'perevez_index', headerName: 'Пор.інд.призн.', show: true, order: 13, width: 100  },
  { field: 'start_plan', headerName: 'День', show: true, order: 14, width: 100  },
  { field: 'esr_prib_osgd_name', headerName: 'Стан. призн. ОСЖД', show: true, order: 15, width: 200  },
  { field: 'esr_sd_name', headerName: 'Стик Укр.', show: true, order: 16, width: 100  },
  { field: 'poluch_port_name', headerName: 'Порт.', show: true, order: 17, width: 100  },
  { field: 'stran_otpr_name', headerName: 'Країна відпр.', show: true, order: 18, width: 140  },
  { field: 'stran_prib_name', headerName: 'Країна призн.', show: true, order: 19, width: 140  },
  { field: 'pr_marshrut', headerName: 'Маршрут', show: true, order: 20, width: 100  },
  { field: 'dor_otpr_name', headerName: 'Зал', show: true, order: 21, width: 100  },
  { field: 'klient_name', headerName: 'Відправник', show: true, order: 22, width: 100  },
  { field: 'za_count', headerName: 'В-НИ З', show: true, order: 23, width: 100  },
  { field: 'za_ves', headerName: 'ТОНИ-З', show: true, order: 24, width: 100  },
  { field: 'so_count', headerName: 'В-НИ У', show: true, order: 25, width: 100  },
  { field: 'so_ves', headerName: 'ТОНИ-У', show: true, order: 26, width: 100  },
  { field: 'kr_count', headerName: 'В-НИ КР', show: true, order: 27, width: 100  },
  { field: 'kr_ves', headerName: 'ТОНИ-КР', show: true, order: 28, width: 100  },
  { field: 'na_count', headerName: 'В-НИ ВИК', show: true, order: 29, width: 100  },
  { field: 'na_ves', headerName: 'ТОНИ-ВИК', show: true, order: 30, width: 120  },
  { field: 'poluch_ukr_name', headerName: 'Отримувач ЄЕКК ', show: true, order: 31, width: 150  },
  { field: 'elevator_name', headerName: 'Елеватор', show: true, order: 32, width: 100  },
  { field: 'eksped_ukr_name', headerName: 'Екпедитор України', show: true, order: 33, width: 170  },
  { field: 'type_perevez_name', headerName: 'Перевез', show: true, order: 34, width: 100  },
  { field: 'soglas_code_name', headerName: 'Статус', show: true, order: 35, width: 100  },
  { field: 'date_last_op', headerName: 'Дата редаг.', show: true, order: 36, width: 120  },
  { field: 'prim_plan', headerName: 'Прим.заявки зал.', show: true, order: 37, width: 170  },
  { field: 'prim_perevez', headerName: 'Прим.признач.зал.', show: true, order: 38, width: 100  },
  { field: 'prim_soglas', headerName: 'Прим.УЗ', show: true, order: 39, width: 100  },
  { field: 'eksped_formir_name', headerName: 'Експедитори', show: true, order: 40, width: 100  },
  { field: 'formir_sogl', headerName: 'Погодження УЗ', show: true, order: 41, width: 150  },
  { field: 'vag_id_name', headerName: 'Власник/оператор РС', show: true, order: 42, width: 200  },
  { field: 'vag_rps', headerName: 'РРС', show: true, order: 43, width: 100  },
  { field: 'vag_spv_rps', headerName: 'РРС Підзам.', show: true, order: 44, width: 120  },
  { field: 'perevizn_name', headerName: 'Перевізник', show: true, order: 45, width: 100  },
  { field: 'poluch_vnesh', headerName: 'Отримувач закордоном', show: true, order: 46, width: 200  },
  { field: 'sng_ident', headerName: 'Тип стр. СНД', show: true, order: 47, width: 150  },
  { field: 'pr_elz', headerName: 'ЕЛЗ', show: true, order: 48, width: 100  },
  { field: 'vag_type_name', headerName: 'Тип ваг.', show: true, order: 49, width: 100  },
  { field: 'type_sd', headerName: 'Тип колії', show: true, order: 50, width: 100  },
];