import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

const initialState = {
  isLoading: false
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload
    }
  }
});

const { setLoading } = loaderSlice.actions;

export default loaderSlice.reducer;

export const loaderStart = () => {
  dispatch(setLoading(true));
}

export const loaderEnd = () => {
  setTimeout(() => {
    dispatch(setLoading(false));
  }, 100)
}