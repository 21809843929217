import { useSelector } from 'react-redux';
import * as React from 'react';
import { Box, CircularProgress, styled } from '@mui/material';

const LoaderWrapp = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255,255,255, 0.9)',
  zIndex: 1301
}));

export default function Loader() {

  const { isLoading } = useSelector(store => store.loader)

  return (
    isLoading ? <LoaderWrapp>
      <CircularProgress color="success"/>
    </LoaderWrapp> : ''
  );
}