import AddIcon from '@mui/icons-material/Add';
import {
    Box, Button, Grid, LinearProgress, Menu,
    MenuItem, Modal, Typography, useMediaQuery
} from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
//
import { CONFIG_MESPLAN as config } from '../../../../../config';
//
import { CommonAlertHolder } from '../../../../../common/CommonAlert/CommonAlertHolder';
import { modalStyle } from '../../../../../common/style/modalStyle';
import ShowXml from '../commonComponents/ShowXML';
import Auction from './elzTypeComponents/Auction';
import AuctionLong from './elzTypeComponents/LongAu';
import Plan from './elzTypeComponents/Plan';
import createHead from './utils/createHead';
import createKalend from './utils/createKalend';
import updateElz from './utils/updateData';

export default function EditModal(props) {
    const checkedDay = props?._checkedDay;
    const row = props?.row;
    const closeMenu = props?._closeMenu;
    const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm();
    const { fields, append, remove } = useFieldArray({ name: 'auction', control }); // normal
    const { fields: fieldsAuLong, append: appendAuLong, remove: removeAuLong } = useFieldArray({ name: 'auctionLong', control }); // long
    const [checked, setChecked] = React.useState(false);
    const [auctions, setAuctions] = React.useState([]);
    const [auctionsLong, setAuctionsLong] = React.useState([]);
    //
    const [auctionsByDay, setAuctionsByDay] = React.useState([]);
    const [auctionsLongByDay, setAuctionsLongByDay] = React.useState([]);
    const [planByDay, setPlanByDay] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [errorXml, setErrorXml] = React.useState(null);
    //
    const [editAu, setEditAu] = React.useState(false);
    const [editAuLong, setEditAuLong] = React.useState(false);
    const matches = useMediaQuery('(min-width:720px)');
    //
    const dispatch = useDispatch();
    //
    const selectedMonth = useSelector(state => state.userMesplan?.month);
    const dataElz = useSelector(state => state.elz?.data);
    //
    const handleOpen = () => props.setOpen(true);
    const handleClose = () => {
        props.setOpen(false);
        setErrorXml(null);
    }
    const handleCloseError = () => {
        setErrorXml(null);
    }
    //
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleOpenAuctionMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAuctionMenu = () => {
        setAnchorEl(null);
    };

    // handle au menu click
    const handleMenuItemClick = (event, index) => {
        append({
            id_auction: auctions[index]?.ID_PRZ_AUCTION,
            num: auctions[index]?.AUCTION_NUM,
            count: auctions[index]?.VAG_COUNT,
            all_info: `${auctions[index]?.VAG_COUNT} - ${auctions[index]?.ID_PRZ_AUCTION} - ${auctions[index]?.AUCTION_NUM}`,
            elz_type: auctions[index]?.LOT_TYPE,
            zdv: false
        });
        const temp = auctions;
        temp[index] = { ...auctions[index], disabled: true };
        setAuctions(temp);
        //
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    // handle au menu click
    const handleMenuItemClickAuLong = (event, index) => {
        appendAuLong({
            id_auction: auctionsLong[index]?.ID_PRZ_AUCTION,
            num: auctionsLong[index]?.AUCTION_NUM,
            count: auctionsLong[index]?.VAG_COUNT,
            all_info: `${auctionsLong[index]?.VAG_COUNT} - ${auctionsLong[index]?.ID_PRZ_AUCTION} - ${auctionsLong[index]?.AUCTION_NUM}`,
            elz_type: auctionsLong[index]?.LOT_TYPE,
            zdv: false
        });
        const temp = auctionsLong;
        temp[index] = { ...auctionsLong[index], disabled: true };
        setAuctionsLong(temp);
        //
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    // handle delete au click
    const handleDeleteAuction = (id, idAuction) => {
        remove(id);
        const tempId = auctions?.findIndex((el) => el?.ID_PRZ_AUCTION === parseInt(idAuction, 10));
        const temp = auctions;
        temp[tempId] = { ...auctions[tempId], disabled: false };
        setAuctions(temp);
    };

    // handle delete au long click
    const handleDeleteAuctionLong = (id, idAuction) => {
        removeAuLong(id);
        const tempId = auctionsLong?.findIndex((el) => el?.ID_PRZ_AUCTION === parseInt(idAuction, 10));
        const temp = auctionsLong;
        temp[tempId] = { ...auctionsLong[tempId], disabled: false };
        setAuctionsLong(temp);
    };

    // form submit handler
    const onSubmit = data => {
        const head = createHead(row, selectedMonth);
        const kalend = createKalend(row, data, checkedDay, planByDay, auctionsLongByDay, auctionsByDay)

        if (kalend?.kalends?.length === 0) {
            CommonAlertHolder.alert('warning', 'Нічого не було введено');
            return;
        }

        setLoading(true);
        axios.post(`${config.apiUrl}/api/v1/handler/message1133Elz`, { ...head, ...kalend })
            .then((response) => {
                CommonAlertHolder.alert('success', 'Електронну заявку відредаговано');
                updateElz(row, dispatch, dataElz, handleClose, selectedMonth);
                setLoading(false);
            })
            .catch((error) => {
                // alert(error?.response?.data);
                setErrorXml(error?.response?.data);
                updateElz(row, dispatch, dataElz, null, selectedMonth);
                setLoading(false);
            });
    };

    const ITEM_HEIGHT = 48;

    useEffect(() => {
        // clear list au
        setAuctions([]);
        setAuctionsLong([]);
        setEditAu(false);
        reset();
        // close menu on calendar
        closeMenu();      
        // setErrorXml(null);
        //
        if (checkedDay) {
            setLoading(true);
            // find auctions and plan for this day 
            let dayAu = row.daysElz.lot?.filter((val) => val?.day === checkedDay);
            let dayAuLong = row.daysElz.lotLong?.filter((val) => val?.day === checkedDay);
            let dayPlan = row.daysElz.plan?.filter((val) => val?.day === checkedDay);
            // to object or null
            if (dayAu && dayAu.length !== 0) { dayAu = dayAu[0] } else { dayAu = null };
            if (dayAuLong && dayAuLong.length !== 0) { dayAuLong = dayAuLong[0] } else { dayAuLong = null };
            if (dayPlan && dayPlan.length !== 0) { dayPlan = dayPlan[0] } else { dayPlan = null };
            // get auctions for this day from api
            axios.post(`${config.apiUrl}/api/v1/Elz/GetPrzLot`, {
                kodGrpBeg: props.row?.klient,
                rodVag: props.row?.rod_vag,
                dateOp: checkedDay,
            })
                .then((response) => {
                    // set enabled for list au
                    const au = response.data.filter((val) => val?.LOT_TYPE === 1).map(x => { return { ...x, disabled: false } });
                    const auLong = response.data.filter((val) => val?.LOT_TYPE === 2).map(x => { return { ...x, disabled: false } });

                    // set disable for au in day list if
                    for (let i = 0; i < au.length; i += 1) {
                        for (let j = 0; j < dayAu?.auction_id?.length; j += 1) {
                            if (au[i]?.ID_PRZ_AUCTION === parseInt(dayAu.auction_id[j], 10)) {
                                au[i] = { ...au[i], disabled: true }
                            }
                        }
                    }

                    // set disable for long au in day list
                    for (let i = 0; i < auLong.length; i += 1) {
                        for (let j = 0; j < dayAuLong?.auction_id?.length; j += 1) {
                            if (auLong[i]?.ID_PRZ_AUCTION === parseInt(dayAuLong.auction_id[j], 10)) {
                                auLong[i] = { ...auLong[i], disabled: true }
                            }
                        }
                    }

                    // can change au if api return some data
                    if (au && au.length > 0) {
                        setEditAu(true);
                    }
                    if (auLong && auLong.length > 0) {
                        setEditAuLong(true);
                    }
                    // set au and long au data
                    setAuctions(au);
                    setAuctionsLong(auLong);
                    //
                    setLoading(false);
                })
                .catch((error) => {
                    setAuctions([]);
                    setAuctionsLong([]);
                    //
                    setLoading(false);
                });

            // long au
            if (dayAuLong) {
                for (let j = 0; j < dayAuLong?.auction_num?.length; j += 1) {
                    let _zdv = false;
                    if (dayAuLong?.pr_invent[j] === '1') {
                        _zdv = true;
                    }
                    const zaCount = dayAuLong?.k_count[j] === '' ? "0" : dayAuLong?.k_count[j];
                    appendAuLong({
                        id_auction: dayAuLong?.auction_id[j],
                        num: dayAuLong?.auction_num[j],
                        k_count: dayAuLong?.k_count[j],
                        za_count: dayAuLong?.za_count[j],
                        na_count: dayAuLong?.na_count[j],
                        pe_count: dayAuLong?.pe_count[j],
                        po_count: dayAuLong?.po_count[j],
                        all_info: `${zaCount} - ${dayAuLong?.auction_id[j]} - ${dayAuLong?.auction_num[j]}`,
                        elz_type: '2',
                        zdv: _zdv
                    });
                }
            }

            // ordinary au
            if (dayAu) {
                for (let j = 0; j < dayAu?.auction_num?.length; j += 1) {
                    let _zdv = false;
                    if (dayAu?.pr_invent[j] === '1') {
                        _zdv = true;
                    }
                    const zaCount = dayAu?.k_count[j] === '' ? "0" : dayAu?.k_count[j];
                    append({
                        id_auction: dayAu?.auction_id[j],
                        num: dayAu?.auction_num[j],
                        k_count: dayAu?.k_count[j],
                        za_count: dayAu?.za_count[j],
                        na_count: dayAu?.na_count[j],
                        pe_count: dayAu?.pe_count[j],
                        po_count: dayAu?.po_count[j],
                        all_info: `${zaCount} - ${dayAu?.auction_id[j]} - ${dayAu?.auction_num[j]}`,
                        elz_type: '1',
                        zdv: _zdv
                    });
                }
            }
            // set value to plan number
            if (dayPlan) {
                setValue("plan_vag", dayPlan?.za_count);
                setPlanByDay(dayPlan);
            } else {
                setPlanByDay(null);
            }

            // if (dayAu?.length !== 0) { setAuctionsByDay(dayAu) } else { setAuctionsByDay(null) };
            // if (dayAuLong?.length !== 0) { setAuctionsLongByDay(dayAuLong) } else { setAuctionsLongByDay(null) };
            setAuctionsByDay(dayAu);
            setAuctionsLongByDay(dayAuLong);
        }

    }, [checkedDay, row])

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            { errorXml===null ?
                <Box sx={modalStyle}>
                    {loading ?
                        <> <LinearProgress /> </> :
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <Grid container spacing={matches ? 0.5 : 0.2}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h3" component="h2">
                                        {`План на ${checkedDay}`}
                                    </Typography>
                                </Grid>
                                <Plan
                                    _register={register}
                                    _control={control}
                                    data={planByDay}
                                />
                            </Grid>

                            <Grid container spacing={matches ? 1 : 0.5}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="baseline"
                                    marginTop={2}
                                    marginRight={2}
                                >

                                    {auctions && auctions.length > 0 ?
                                        <>
                                            {/* є аукціони */}
                                            <Button variant="contained" endIcon={<AddIcon />} onClick={handleOpenAuctionMenu}>
                                                Лот
                                            </Button>
                                            <Menu
                                                id="auction-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleCloseAuctionMenu}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '60ch',
                                                    },
                                                }}
                                            >
                                                {auctions.map((option, index) => (
                                                    <MenuItem
                                                        key={option?.AUCTION_NUM}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                        disabled={option?.disabled}
                                                    >
                                                        {`${option?.VAG_COUNT}-${option?.AUCTION_NUM}`}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                        : <></>}

                                    {/* аукціони є, але редагувати немона */}
                                    {(fields && auctions && fields.length > 0 && auctions.length === 0) ?
                                        <>
                                            <Typography variant="h3" component="h2">
                                                Лот
                                            </Typography>
                                        </>
                                        : <></>}

                                </Grid>
                                {/* перелік аукціонів */}
                                {fields && fields.length > 0 ?
                                    <>
                                        {fields.map((item, i) => (
                                            <Auction
                                                i={i}
                                                item={item}
                                                name={"auction"}
                                                handleDelete={handleDeleteAuction}
                                                edit={editAu}
                                                _register={register}
                                                _control={control}
                                            />
                                        ))}
                                    </>
                                    : <></>}
                            </Grid>

                            {/* Довгостроковий лот */}
                            <Grid container spacing={matches ? 1 : 0.5}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="baseline"
                                    marginTop={2}
                                    marginRight={2}
                                >

                                    {auctionsLong && auctionsLong.length > 0 ?
                                        <>
                                            {/* є аукціони */}
                                            <Button variant="contained" endIcon={<AddIcon />} onClick={handleOpenAuctionMenu}>
                                                Лот довгостроковий
                                            </Button>
                                            <Menu
                                                id="auction-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleCloseAuctionMenu}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '60ch',
                                                    },
                                                }}
                                            >
                                                {auctionsLong.map((option, index) => (
                                                    <MenuItem
                                                        key={option?.AUCTION_NUM}
                                                        onClick={(event) => handleMenuItemClickAuLong(event, index)}
                                                        disabled={option?.disabled}
                                                    >
                                                        {`${option?.VAG_COUNT}-${option?.AUCTION_NUM}`}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                        : <></>}

                                    {/* аукціони є, але редагувати немона */}
                                    {(fieldsAuLong && auctionsLong && fields.fieldsAuLong > 0 && auctionsLong.length === 0) ?
                                        <>
                                            <Typography variant="h3" component="h2">
                                                Лот довгостроковий
                                            </Typography>
                                        </>
                                        : <></>}

                                </Grid>
                                {/* перелік аукціонів */}
                                {fieldsAuLong && fieldsAuLong.length > 0 ?
                                    <>
                                        {fieldsAuLong.map((item, i) => (
                                            <AuctionLong
                                                i={i}
                                                item={item}
                                                name={"auctionLong"}
                                                handleDelete={handleDeleteAuctionLong}
                                                edit={editAuLong}
                                                _register={register}
                                                _control={control}
                                                _auLong={auctionsLongByDay}
                                            />
                                        ))}
                                    </>
                                    : <></>}
                            </Grid>

                            <Grid container spacing={matches ? 1 : 0.5}>
                                <Grid item xs={12} md={12} marginTop={3}>
                                    <Button variant="contained" type="primary" fullWidth size={matches ? '' : 'small'}>Зберегти</Button>
                                </Grid>
                                <Grid item xs={12} md={12} marginTop={1}>
                                    <Button onClick={handleClose} variant="contained" color="text" fullWidth size={matches ? '' : 'small'}>Закрити</Button>
                                </Grid>
                            </Grid>
                        </form>
                    }
                </Box>
                :
                <ShowXml
                    _xml={errorXml}
                    _handleCloseXml={handleCloseError}
                />}
        </Modal>
    )
}