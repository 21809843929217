import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import axios from '../../utils/axios';

const initialState = {
  itemsZona: [],
  // centerMap: null,
  currentPosition: null,
  selectZona: null,
  newMapZone: false
};

const slice = createSlice({
  name: 'mapZona',
  initialState,
  reducers: {
    setItemsZonaData(state, action) {
      state.itemsZona = action.payload;
    },
    addItemsZonaData(state, action) {
      state.itemsZona.push(action.payload);
    },
    delItemsZonaData(state, action) {
      state.itemsZona = action.payload;
    },
    setSelectZonaData(state, action) {
      state.selectZona = action.payload;
    },
    setCenterMapData(state, action) {
      state.currentPosition = action.payload;
    },
    setNewMapZone(state, action) {
      state.newMapZone = action.payload;
    }      
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setItemsZonaData,
  addItemsZonaData,
  setCenterMapData,
  delItemsZonaData,
  setSelectZonaData,
  setNewMapZone

} = slice.actions;

export const getItemsZona = async (t = 0) => {
  // -dispatch(setItemsZonaData([]));
  await axios.get(`api-webhook/zona?all=${t}`)
    .then(res => {
      if (res.status === 200) {
        console.log(`Кількість зон ${res.data.length}`);
        dispatch(setItemsZonaData(res.data));
        dispatch(setSelectZonaData({}));
      }
    })
}

export const setItemsZona = async (items) => {
  dispatch(setItemsZonaData(items));
}
export const getActivatedZona = async (id, actual, t = 0) => {
  // -dispatch(setItemsZonaData([]));
  await axios.post(`api-webhook/zona/active?id=${id}&actual=${actual}`)
    .then(res => {
      if (res.status === 200) {
        getItemsZona(t);
      }
    })
}

export const getSelectZona = async (obj) => {
  dispatch(setSelectZonaData(obj));
}

export const setCurrentPosition = async (obj) => {
  dispatch(setCenterMapData(obj));
}

export const setCenterZone = async (val) => {
  dispatch(setNewMapZone(val));
}

export const saveSelectZona = async (item, t = 0) => {
  await axios.post(`api-webhook/zona`, item)
  .then(res => {
    if (res.status === 200) {
      getItemsZona(t);
    }
  })
}

export const addSelectZona = async (obj) => {
  dispatch(addItemsZonaData(obj));
  dispatch(setSelectZonaData(obj));
}

export const deleteSelectZona = async (obj) => {
  dispatch(delItemsZonaData(obj));
  dispatch(setSelectZonaData(null));  
}

