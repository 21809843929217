import { items as _items } from './utils/items';
import MainLinkForm from '../../../../../common/components/MainLinkForm';
import { CommonAlertHolder } from '../../../../../common/CommonAlert/CommonAlertHolder';
import CommonAlert from '../../../../../common/CommonAlert/CommonAlert';

const HandbookMain = () => (
  <>
    <CommonAlert showFunc={(ref) => {
      CommonAlertHolder.setAlert(ref)
    }} />
    <MainLinkForm props={{ items: _items, title: "Довідники" }} />
  </>
);

export default HandbookMain;
