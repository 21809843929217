import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { showConfirmationModal } from './confirmation';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  cabinets: [],
  actions: [],
};

const slice = createSlice({
  name: 'cabinets',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCabinets(state, action) {
      state.cabinets = action.payload;
    },
    deleteEmptyCabinet(state, action) {
      const updatedCabinets = state.cabinets.filter((item) => item.cabinetId !== action.payload);
      state.cabinets = updatedCabinets;
    },
    setEmptyCabinet(state, action) {
      state.cabinets.push({
        cabinetId: 0 - action.payload,
        name: '',
        actions: [],
        users: [],
      });
    },
    setActions(state, action) {
      state.actions = action.payload;
    },
  },
});

export default slice.reducer;

export const { setCabinets, deleteEmptyCabinet, setEmptyCabinet } = slice.actions;

export const getCabinets = async (companyId, loadUsers, loadActions) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const response = await axios.get(`/api-admin/ekbsPidpr/${companyId}/cabinets`);
    if (response.status === 200) {
      await loadUsers();
      await loadActions();
      dispatch(slice.actions.setCabinets(response.data));
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data);
    else toast.error(error.message);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

export const getActions = async () => {
  try {
    const response = await axios.get(`/api-admin/user/cabinets-actions`);
    if (response.status === 200) {
      dispatch(slice.actions.setActions(response.data));
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data);
    else toast.error(error.message);
  }
};

export const createCabinet = async (companyId, cabinetInfo, loadCabinets) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const response = await axios.post(`/api-admin/ekbsPidpr/${companyId}/cabinets`, cabinetInfo);
    if (response.status === 204) {
      toast.success('Кабiнет успішно створено');
      loadCabinets();
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data);
    else toast.error(error.message);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

export const updateCabinet = async (companyId, cabinetInfo, loadCabinets) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const response = await axios.put(`/api-admin/ekbsPidpr/${companyId}/cabinets`, cabinetInfo);
    if (response.status === 204) {
      toast.success('Кабiнет успішно оновлено');
      loadCabinets();
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data);
    else toast.error(error.message);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

export const deleteCabinet = async (cabinetId, loadCabinets) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const response = await axios.delete(`/api-admin/ekbsPidpr/cabinets/${cabinetId}`);
    if (response.status === 204) {
      toast.success('Кабiнет успішно видалено');
      loadCabinets();
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data);
    else toast.error(error.message);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
