export default function contains(object, val) {
  // for (const [key, value] of Object.entries(object)) {
  // // if value empty return all
  // if(val === '' || val === null || val === undefined)
  //  {
  //    return true;
  //  }
  //  // filter values
  //   if(value.includes(val)){
  //     return true;  
  //   }
  // }
  // // not found
  // return false;


  // if value empty return all
  if (val === '' || val === null || val === undefined) {
    return true;
  }

  let find = false;
  Object.entries(object).forEach(([key, value]) => {
    // filter values
    if ((typeof value === 'string' || value instanceof String) && value?.toLowerCase()?.includes(val?.toLowerCase())) {
      find = true;
    }
  })
  //  
  return find;
};