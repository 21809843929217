import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="80%" height="80%" viewBox="0 0 246 246">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)" 
        fillRule="evenodd" stroke="none" strokeWidth="1">
<path d="M1254 2436 c-407 -78 -725 -310 -907 -661 -95 -184 -139 -366 -138
-576 1 -90 24 -275 35 -287 5 -5 70 8 84 17 9 5 8 25 -5 82 -24 107 -23 366 1
479 110 516 555 897 1084 926 175 10 379 -21 508 -76 26 -11 49 -19 51 -16 8
7 -180 75 -267 96 -110 27 -344 36 -446 16z"/>
<path d="M1436 2291 c-3 -5 10 -11 29 -13 24 -3 35 0 35 9 0 15 -56 18 -64 4z"/>
<path d="M1652 2268 c5 -15 56 -29 64 -17 10 16 -7 29 -37 29 -19 0 -29 -4
-27 -12z"/>
<path d="M1238 2263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1857 2206 c-8 -21 52 -44 64 -25 6 9 3 18 -8 27 -22 16 -50 15 -56
-2z"/>
<path d="M1080 2138 c-26 -14 -53 -37 -65 -58 -19 -34 -20 -56 -25 -590 -5
-577 -7 -583 -35 -110 -22 376 -27 440 -36 440 -20 0 -135 -154 -175 -235 -60
-121 -85 -225 -86 -350 -1 -229 88 -435 257 -593 60 -56 76 -78 84 -111 13
-60 37 -90 87 -112 41 -19 65 -20 377 -17 329 3 332 3 365 26 68 49 66 35 72
567 3 264 8 460 12 435 4 -25 17 -218 29 -430 11 -212 23 -387 25 -389 9 -9
117 106 159 169 125 188 169 427 116 635 -44 169 -154 342 -280 439 -61 47
-61 47 -61 99 0 106 -38 174 -108 195 -27 8 -142 12 -354 12 -306 0 -315 -1
-358 -22z m488 -75 c3 -10 -23 -13 -107 -13 -101 0 -131 6 -114 24 13 13 216
2 221 -11z m260 -95 c7 -9 9 -236 7 -693 -1 -374 -3 -683 -4 -687 -1 -5 -171
-8 -379 -8 -281 0 -381 3 -390 12 -13 13 -15 830 -3 1188 l6 185 110 6 c61 3
230 6 376 7 203 2 269 -1 277 -10z"/>
<path d="M1161 1001 c-21 -22 -22 -25 -6 -33 9 -5 20 -19 25 -31 7 -17 9 -18
9 -5 1 9 10 23 22 31 l20 15 -23 23 -23 24 -24 -24z"/>
<path d="M1270 861 c0 -30 -34 -64 -76 -77 -28 -9 -33 -12 -16 -13 45 -2 81
-29 88 -66 7 -37 24 -48 24 -16 0 30 34 64 76 76 l39 12 -42 12 c-43 12 -73
42 -73 73 0 10 -4 18 -10 18 -5 0 -10 -9 -10 -19z"/>
<path d="M2052 2110 c-16 -26 -16 -27 13 -46 24 -16 27 -16 40 2 19 26 19 27
-13 46 -26 16 -29 16 -40 -2z"/>
<path d="M2215 1970 c-17 -18 -17 -21 -1 -45 20 -30 28 -31 49 -7 22 24 21 35
-1 55 -23 21 -25 21 -47 -3z"/>
<path d="M2398 1759 c20 -82 10 -113 -39 -117 -43 -4 -53 7 -77 77 -10 29 -22
53 -27 54 -6 1 -14 -17 -19 -40 -12 -57 4 -92 74 -162 53 -53 57 -60 73 -136
25 -124 30 -211 17 -325 -37 -336 -235 -611 -545 -756 -146 -68 -241 -88 -410
-88 -169 1 -283 27 -428 98 -346 170 -562 541 -543 932 4 86 4 87 -5 29 -13
-74 -5 -228 15 -315 52 -232 187 -447 366 -585 537 -413 1307 -203 1568 427
80 193 99 412 54 614 -22 97 -23 104 -7 163 19 74 10 119 -31 163 -44 45 -53
37 -36 -33z"/>
<path d="M365 713 c-16 -8 -31 -17 -33 -18 -3 -3 25 -64 33 -73 4 -5 65 27 65
34 0 8 -29 74 -32 74 -2 -1 -16 -8 -33 -17z"/>
<path d="M491 496 l-22 -24 24 -27 c23 -27 24 -28 50 -10 l27 17 -26 34 c-15
19 -27 34 -28 34 -2 0 -13 -11 -25 -24z"/>
<path d="M659 314 c-11 -14 -10 -20 14 -40 34 -29 43 -30 56 -6 8 15 4 23 -19
40 -35 26 -34 26 -51 6z"/>
<path d="M866 174 c-3 -9 -6 -17 -6 -20 0 -9 61 -34 70 -29 19 12 9 32 -24 48
-31 14 -35 14 -40 1z"/>
<path d="M1090 72 c0 -12 68 -37 78 -28 10 10 -12 25 -46 32 -20 4 -32 2 -32
-4z"/>
<path d="M1340 20 c0 -6 17 -10 37 -10 43 0 41 5 -4 13 -19 4 -33 2 -33 -3z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
