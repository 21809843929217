import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { showConfirmationModal } from './confirmation';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  isModalOpen: false,
  pageIndex: 0,
  pageSize: 5,
  order: 'asc',
  orderBy: 'fullName',
  filterName: '',
  count: 0,
  user: {},
  users: [],
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setModalOpen(state, action) {
      state.isModalOpen = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
      state.pageIndex = 0;
    },
    setPageindex(state, action) {
      state.pageIndex = action.payload;
    },
    setOrder(state, action) {
      const isAsc = state.orderBy === action.payload && state.order === 'asc';
      const currOrder = isAsc ? 'desc' : 'asc';

      state.order = currOrder;
      state.orderBy = action.payload;
    },
    setFilterName(state, action) {
      state.filterName = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
  },
});

export default slice.reducer;

export const { setOrder, setPageindex, setPageSize, setFilterName, setModalOpen, setUser } = slice.actions;

export const getUsers = async (pageSize, pageIndex, order, orderBy, filterName, companyId) => {
  dispatch(slice.actions.setLoading(true));
  try {
    const response = await axios.get(
      `/api-admin/user/items/?pageSize=${pageSize}&pageIndex=${pageIndex}&order=${order}&orderBy=${orderBy}&filterName=${filterName}&companyId=${companyId}`
    );
    if (response.status === 200) {
      const { count, data } = response.data;
      dispatch(slice.actions.setCount(count));
      dispatch(slice.actions.setUsers(data));
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data);
    else toast.error(error.message);
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

export const createUser = async (companyId, userInfo, loadUsers) => {
  try {
    const response = await axios.post(`/api-admin/admin/companies/${companyId}/users`, userInfo);
    if (response.status === 204) {
      toast('Користувача успішно створено');
      loadUsers();
    } else {
      toast.error(response.data);
    }
  } catch (error) {
    toast.error(error.response.data);
  }
};

export const updateUser = async (userId, userInfo, loadUsers) => {
  try {
    const response = await axios.put(`/api-admin/admin/users/${userId}`, userInfo);
    if (response.status === 204) {
      toast('Користувача успішно оновлено');
      loadUsers();
    } else {
      toast.error(response.data);
    }
  } catch (error) {
    toast.error(error.response.data);
  }
};

export const deleteUser = async (companyId, userId, loadUsers) => {
  try {
    const response = await axios.delete(`/api-admin/admin/companies/${companyId}/users/${userId}`);
    if (response.status === 204) {
      toast('Користувача успішно видалено');
      loadUsers();
    } else {
      toast.error(response.data);
    }
  } catch (error) {
    toast.error(error.response.data);
  }
};
