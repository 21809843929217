import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
// ----------------------------------------------------------------------

// RHFAutocomplete.propTypes = {
//   children: PropTypes.node,
//   name: PropTypes.string,
//   idName: PropTypes.string,
//   opt: PropTypes.array, 
//   vl: PropTypes.object, 
//   title: PropTypes.string,
//   clearValue: PropTypes.bool,
//   selected: PropTypes.object
// };

export default function CustomAutoComplete({ name = '', onChange, options, defaultValue = '', title, onInputChange, optionLabelWithValue = false }) {

  const { control } = useFormContext();

  const defaultProps = {
    getOptionLabel: (option) => option?.title || ' ',
    isOptionEqualToValue: (option, val) => option?.title === val?.title,
    filterOptions: createFilterOptions({
      matchFrom: 'any',
      stringify: (option) => option?.title,
    })
  };
  
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
    setInputValue(defaultValue?.title || '');
  },[]);

  const getOptionLabel = (option) => {
    if (!option?.title || !option?.value) {
      return '';
    }
    return optionLabelWithValue ? `${option?.value} - ${option?.title}` : option?.title;
  }

  return (
    <Controller
      render={({ field, fieldState, formState }) => (
        <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          onChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          onInputChange(newInputValue)
        }}
        id="controllable-states-demo"
        options={options}
        getOptionLabel={(option) => getOptionLabel(option)}
        isOptionEqualToValue= {(option, val) => {
          if (Number(option?.value) === Number(val?.value) || !val) return true
        }}
        renderInput={(params) => <TextField {...params} label={title} />}
      />
      )}
      onChange={([event, data]) => data}
      name={name}
      control={control}
    />
  );

  /*
  return (
    <>
    {options &&
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            field.value = newValue.value;
            console.log('field', field);
            console.log('other', other);
            return newValue.value;
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) =>
            option?.title ? option?.title : '' 
          }
          isOptionEqualToValue= {(opt, val) => opt.value === val.value}
          filterOptions={(x) => x}
          options={options}
          fullWidth
          autoComplete
          includeInputInList
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label={title} />
          )}
          {...other}
        />
        
      )}
    />}
    </>
  );
  */
}
