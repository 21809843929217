import { createSlice } from '@reduxjs/toolkit';
import Enumerable from "linq";
import { HOST_API } from '../../config';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {

    params: {
        elevator: null, 
        stationFrom: null,
        stationTo: null, 
        ownCarr: '3', 
        codeRps: null,
        countAxis: 4,
        weightCarr: '',
        typeShpt: '1',
        codeEtsnv: null,
    },
    // params: {
    //     elevator: {"id":228,"elev":"ТОВ \"ПРИЛУЦЬКЕ ХПП\"","address":"Україна, 17500, Чернігівська обл., місто Прилуки, ПРОВУЛОК ЗАЛІЗНИЧНИЙ, будинок 19","esr":429002,"nameStation":"ПРИЛУКИ","lat":50.581742,"lon":32.383982,"value":429002,"title":"ТОВ \"ПРИЛУЦЬКЕ ХПП\" ПРИЛУКИ"}, 
    //     stationFrom: {id: 429200, title: '429200 ІЧНЯ', lat: 50.842199, lon: 32.408183, value: 429200},
    //     stationTo: {value: 382605, title: '382605 БАТЬОВО (ЕКСП. МАВ)', stationName: 'БАТЬОВО (ЕКСП. МАВ)', lon: 22.258149, lat: 48.374562}, 
    //     ownCarr: '3', 
    //     codeRps: {value: '60', title: 'Полувагоны (ПВ)(60)'},
    //     countAxis: 4,
    //     weightCarr: '60',
    //     typeShpt: '1',
    //     codeEtsnv: {value: '14003', title: 'ЯЧМЕНЬ (014003)'},
    // },
    routeServicesPrice: [],
    inputData: {
        sumTarif: '',
        countDays: '',
        weightCargo: '',
        weightCarr: '',
        countCarrs: '',
        addSum: '',
        sumAssociated: NaN
    },
    outputData: {
        sumTransport:  NaN,
        sumTransportOne: NaN
    },
    mapCenter: { lat: 49.081062, lng: 32.299804 },
    elevators: [],
    nearElevators: [],
    stationsTo: [],
    calcTariff: false,
    // calcTariff: true,
    isOpenCalcViewModal: false
};

const setOutputData = (state) => {
    console.log(`Recalc output data`);
    
    const outputData = {
        sumTransport:  NaN,
        sumTransportOne: NaN
    };

    const numberReg = /^\d{1,6}$/;
    let addSum = 0;
    if (state.inputData.addSum === '')
        addSum = 0;
    else if (!numberReg.test(state.inputData.addSum))
        addSum = NaN;
    else 
        addSum = parseInt(state.inputData.addSum, 10);


    // Супутні витрати
    const calculateSum = (arr) => 
        arr.reduce((total, current) => 
            total + getCostService(current, state.inputData)
        , 0);
    
    state.inputData.sumAssociated = 
        state.inputData.countDays !== undefined && state.inputData.countCarrs !== undefined
        ? calculateSum(state.routeServicesPrice.filter(row => row.checked)) 
        : NaN;
    console.log(`sumAssociated=${state.inputData.sumAssociated}`);

    // Вартість перевезення
    outputData.sumTransport = 
        !Number.isNaN(state.inputData.countCarrs) && 
        !Number.isNaN(state.inputData.sumTarif) && 
        !Number.isNaN(addSum) && 
        // !Number.isNaN(state.inputData.sumReturnCarrs) && 
        !Number.isNaN(state.inputData.sumAssociated)
        ? state.inputData.countCarrs * (state.inputData.sumTarif + addSum) + 
          state.inputData.sumAssociated
        : NaN;
   
    // Вартість перевезення на одну тону вантажу
    outputData.sumTransportOne = 
        !Number.isNaN(outputData.sumTransport) && 
        !Number.isNaN(state.inputData.weightCargo) && 
        state.inputData.weightCargo !== 0
        ? outputData.sumTransport / state.inputData.weightCargo
        : NaN;

    console.log(`outputData=${JSON.stringify(outputData)}`);
    return outputData;

}

const slice = createSlice({
    name: 'routeMap',
    initialState,
    reducers: {      

        setRouteParams(state, action) {
            const params = action.payload;
            state.params = params;
        },

        setParamElevator(state, action) {                                
            state.params.elevator = action.payload ? action.payload.newValue : null;
            if (action.payload)
                console.log(`nearGeolocations.length=${action.payload.nearGeolocations.length}`);
                
            state.params.stationFrom = action.payload
                ? Enumerable.from(action.payload.nearGeolocations)
                        .where(e => e.value === action.payload.newValue.value)
                        .select(e => e).firstOrDefault()
                : null;
            console.log(`state.params.stationFrom=${JSON.stringify(state.params.stationFrom)}`);
        },
        
        setParamStationFrom(state, action) {                                
            state.params.stationFrom = action.payload;
            if (state.params.stationFrom?.value !== state.params.elevator?.value)
                state.params.elevator = null;
        },
        
        setParamStationTo(state, action) {                                
            state.params.stationTo = action.payload;
        },

        setParamOwnCarr(state, action) {                                
            state.params.ownCarr = action.payload;
        },
 
        setParamCodeRps(state, action) {                                
            state.params.codeRps = action.payload;
        },

        setParamCountAxis(state, action) {                                
            state.params.countAxis = action.payload;
        },

        setParamWeightCarr(state, action) {                                
            state.params.weightCarr = action.payload;
        },

        setParamTypeShpt(state, action) {                                
            state.params.typeShpt = action.payload;
        },
        
        setParamCodeEtsnv(state, action) {                                
            state.params.codeEtsnv = action.payload;
        },
        
        setElevators(state, action) {
            state.elevators = action.payload;
        },

        setNearElevators(state, action) {
            const getDistance = (point1, point2) => {
                const R = 6371.0710; // Radius of the Earth in km
                const rlat1 = point1.lat * (Math.PI/180); // Convert degrees to radians
                const rlat2 = point2.lat * (Math.PI/180); // Convert degrees to radians
                const difflat = rlat2-rlat1; // Radian difference (latitudes)
                const difflon = (point2.lng - point1.lng) * (Math.PI/180); // Radian difference (longitudes)
        
                const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2) * Math.sin(difflat/2) +
                        Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon/2) * Math.sin(difflon/2)));
                return d;
                 
            }
            
            if (window.google && action.payload)
                state.mapCenter = { lat: action.payload.lat(), lng: action.payload.lng()};

            const nearElevators = Enumerable.from(state.elevators)
                    .where(e => getDistance({lat: e.lat, lng: e.lng}, {lat: state.mapCenter.lat, lng: state.mapCenter.lng}) < 80)
                    .select(e => e).toArray(); 
            state.nearElevators = nearElevators;              
            
            
        },

        setStationsTo(state, action) {
            state.stationsTo = action.payload;
        },

        setCalcTariff(state, action) {
            state.calcTariff = action.payload;
        },

        /* *************************************************************************************************************************************** */
        
        setOpenCalcViewModal(state, action) {
            console.log(`isOpenCalcViewModal=${action.payload.isOpenCalcViewModal}`);
            state.isOpenCalcViewModal = action.payload.isOpenCalcViewModal;
            if (state.isOpenCalcViewModal) {
                state.inputData.sumTarif = action.payload.sumTarif;
                state.inputData.countDays = action.payload.countDays;
                state.inputData.weightCarr = action.payload.weightCarr;
            }
            else {
                state.inputData.sumTarif = '';
                state.inputData.countDays = '';
                state.inputData.weightCarr = '';
                return;
            }
            // При пересчете тарифа возможно было изменение параметра грузоподъемности вагона => пересчитаем кол-во вагонов
            const intReg = /^\d{1,5}$/;
            state.inputData.countCarrs = 
                (state.inputData.weightCargo && intReg.test(state.inputData.weightCargo) && 
                 state.inputData.weightCarr !== '' && state.inputData.weightCarr !== '0') 
                ? Math.ceil(state.inputData.weightCargo / state.inputData.weightCarr)
                : undefined;
            
            state.outputData = setOutputData(state);  
            
        },

        setInputCountDays(state, action) {                                
            const intReg = /^\d{1,3}$/;
            state.inputData.countDays = 
                action.payload && intReg.test(action.payload) 
                ? parseInt(action.payload, 10)
                : undefined;
            state.outputData = setOutputData(state);                 
        },

        setInputWeightCargo(state, action) {                                
            state.inputData.weightCargo = action.payload;
            // При изменении веса груза пересчитаем кол-во вагонов
            const intReg = /^\d{1,5}$/;
            state.inputData.countCarrs = 
                (state.inputData.weightCargo && intReg.test(state.inputData.weightCargo) && 
                 state.inputData.weightCarr !== '' && state.inputData.weightCarr !== '0') 
                ? Math.ceil(state.inputData.weightCargo / state.inputData.weightCarr)
                : undefined;    

            state.outputData = setOutputData(state);         
        }, 
        
        setInputAddSum(state, action) {                                
            state.inputData.addSum = action.payload;
            state.outputData = setOutputData(state);  
        },

        setRouteServicesPrice(state, action) {                                
            state.routeServicesPrice = action.payload;
            state.outputData = setOutputData(state); 
        },
     
        setCheckedServicesPrice(state, action) {   
            for (let numItem = 0; numItem < state.routeServicesPrice.length; numItem += 1) {
                state.routeServicesPrice[numItem].checked = action.payload.has(state.routeServicesPrice[numItem].id);
                // console.log(`numItem=${numItem} id=${state.routeServicesPrice[numItem].id} checked=${state.routeServicesPrice[numItem].checked}`);
            }                           
            state.outputData = setOutputData(state); 
        },

        setServicesNewPrice(state, action) {  
            const id = action.payload.id;
            const findIndex = state.routeServicesPrice.findIndex(row => row.id === id);
            if (findIndex !== -1 && action.payload.newPrice !== state.routeServicesPrice[findIndex].price) {
                state.routeServicesPrice[findIndex].price = action.payload.newPrice;
                state.outputData = setOutputData(state); 
            }

        }
    }
});


// Reducer
export default slice.reducer;

// Actions
export const {
    setRouteParams,
    setElevators,
    setNearElevators,
    setStationsTo,
    setParamElevator,
    setParamStationFrom,
    setParamStationTo,
    setParamOwnCarr,
    setParamCodeRps,
    setParamCountAxis,
    setParamWeightCarr,
    setParamTypeShpt,
    setParamCodeEtsnv,
    setCalcTariff,
    setOpenCalcViewModal,
    setInputCountDays,
    setInputWeightCargo,
    setInputAddSum,
    setRouteServicesPrice,
    setCheckedServicesPrice,
    setServicesNewPrice
} = slice.actions;

// --------------------------------------------------------------------------------------------------------------
// Загрузка данных по ценам на сопутствующие услуги
export const getRouteServicesPrice = async(translate) => {

    console.log(`call getRouteServicesPrice`);
    await axios.get(`${HOST_API}/api-vantazh/RlcServices/RouteServicesPrice`)
    .then(result => {
        console.log(`getRouteServicesPrice: ${JSON.stringify(result.data)}`);
        if (result.status === 200) {
        
            if (result.data.length === 0) {
                    alert(`Відсутній перелік послуг для розрахунку супутніх витрат`);
                dispatch(slice.actions.setRouteServicesPrice([]));
                return;
            }
    
            const dbData = Enumerable.from(result.data)
                .select(x => ({
                    id: x.id, 
                    codeService: x.codeService, 
                    nameService: x.nameService,
                    codeUnit: x.codeUnit,
                    nameUnit: x.nameUnit,
                    price: x.priceNorm,
                    checked: true }))
                .toArray();

            dispatch(slice.actions.setRouteServicesPrice(dbData));
        }
        else {                       
            throw new Error(result.statusText || result.status);          
        }  
    })        
    .catch((error) => {
        console.log(`getRouteServicesPrice: error`);
        alert(`${translate('service.map.calculator.loadError')} 
        ${error.title ? error.title : error } 
        ${error.status ? error.status : ''}`); 
    }); 
    
}

// --------------------------------------------------------------------------------------------------------------
// Кількість одиниць
export const getCountService = (row, inputData) => {
    const codeUnit  = row.codeUnit;    
    let count = 1;       
    switch (codeUnit) {
        // вагон
        case 1: 
            count = Number.isInteger(inputData.countCarrs) ? inputData.countCarrs : NaN;   
            break;
        // вагоно/доба
        case 4:  
            count = Number.isInteger(inputData.countCarrs) && Number.isInteger(inputData.countDays) ? inputData.countCarrs * inputData.countDays : NaN;   
            break;
        default:
            count = 1;        
    }
    return (count);
}

// --------------------------------------------------------------------------------------------------------------
// Вартість послуги
export const getCostService = (row, inputData) => {
    const codeUnit  = row.codeUnit;   
    const price = Number.isInteger(row.price) ? row.price : 0;
    // Якщо не зазначена ціна => вартість = 0
    if (price === 0)
        return NaN;

    let cost = 0;       
    switch (codeUnit) {
        // вагон
        case 1: 
            cost = 
                Number.isInteger(inputData.countCarrs) 
                ? inputData.countCarrs * price 
                : NaN;   
            break;
        // вагоно/доба
        case 4:  
            cost = 
                Number.isInteger(inputData.countCarrs) && Number.isInteger(inputData.countDays) 
                ? inputData.countCarrs * inputData.countDays * price 
                : NaN;   
            break;
        
        default:
            cost = price;        
    }

    return (cost);
}
