import { createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { loaderEnd, loaderStart } from './loader';
import { reserveResource } from './service.rcalendar';
import { showConfirmationModal } from './confirmation';
// ----------------------------------------------------------------------
const data = [
  {
    value: 'general',
    title: 'Загальне',
    icon: 'eva:layers-fill'
  },
  {
    value: 'vantazh',
    title: 'Вантажі',
    icon: 'eva:shopping-cart-fill'
  },
  {
    value: 'vagony',
    title: 'Вагони',
    icon: 'eva:car-fill'
  }
];
const r = [
  {
    idPidpr: 153,
    namePidpr: 'ТОВ ІТХАБ',
    dateCreate: '2022-05-30 16:11:01',
    category: 2,
    typeRequest: 1,
    count: 10,
    typeResource: 1

  }
];

const initialState = {
  isLoading: false,
  rcError: null,
  category: data,
  request: r,
  myEnterpiseProposal: null,
  editableOrder: null,
  editableReserv: null,
  reservedEnterprisesRow: null,
  otherEnterpiseProposal: null,
  isCreateOrderModalOpen: false,
  isGetOrdersModalOpen: false,
  ndiValues: {},
  selectedOrder: null,
  supply: [],
  demand: [],
  searchParams: {
    dateBeg: '',
    dateEnd: '',
    esr: 0,
    type: '',
    status: '',
    page: 1,
    count: 10,
    query: 0
  },
  myReservesSearchParams: {
    dateBeg: '',
    dateEnd: '',
    page: 1,
    count: 10,
    idVag: 0
  },
  freeVagons: null,
  myReserves: [],
  tableToShow: 'my_orders',
};

export const slice = createSlice({
  name: 'auction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.rcError = action.payload;
    },
    setCategory(state, action) {
      state.category = action.payload
    },
    setRequest(state, action) {
      state.request = action.payload
    },
    setMyEnterpiseProposal(state, action) {
      state.myEnterpiseProposal = action.payload
    },
    setEditableOrder(state, action) {
      state.editableOrder = action.payload
    },
    setEditableReserv(state, action) {
      state.editableReserv = action.payload
    },
    setReservedEnterpisesRow(state, action) {
      state.reservedEnterprisesRow = action.payload
    },
    setOtherEnterpiseProposal(state, action) {
      state.otherEnterpiseProposal = action.payload
    },
    setCreateOrderModalOpen(state, action) {
      state.isCreateOrderModalOpen = action.payload
    },
    setGetOrdersModalOpen(state, action) {
      state.isGetOrdersModalOpen = action.payload
    },
    setNdiValues(state, action) {
      state.ndiValues[action.payload.name] = action.payload.values
    },
    setSelectedOrder(state, action) {
      state.selectedOrder = action.payload
    },
    setSupply(state, action) {
      state.supply = action.payload
    },
    setDemand(state, action) {
      state.demand = action.payload
    },
    setSearchParams: (state, action) => ({
      ...state,
      searchParams: { ...state.searchParams, ...action.payload }
    }),
    setMyReservesSearchParams: (state, action) => ({
      ...state,
      myReservesSearchParams: { ...state.myReservesSearchParams, ...action.payload }
    }),
    setFreeVagons: (state, action) => {
      state.freeVagons = action.payload
    },
    setMyReserves(state, action) {
      state.myReserves = action.payload
    },
    setTableToShow(state, action) {
      state.tableToShow = action.payload
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { 
  startLoading, 
  setCategory, 
  setRequest,
  setMyEnterpiseProposal,
  setEditableOrder,
  setEditableReserv,
  setReservedEnterpisesRow,
  setOtherEnterpiseProposal,
  setCreateOrderModalOpen,
  setGetOrdersModalOpen,
  setNdiValues,
  setSelectedOrder,
  setDemand,
  setSupply,
  setSearchParams,
  setMyReservesSearchParams,
  setFreeVagons,
  setMyReserves,
  setTableToShow
} = slice.actions;

export const getCategory = () => {
  dispatch(startLoading(true));
  const data = [
    {
      value: 'general',
      icon: 'eva:shopping-cart-fill'
    },
    {
      value: 'vantazh',
      icon: 'eva:shopping-cart-fill'
    },
    {
      value: 'vagony',
      icon: 'eva:car-fill'
    }
  ];
  dispatch(setCategory(data));
  dispatch(startLoading(false));
  /*
  dispatch(startLoading(true));
  await axios.get(`api-auction/auction/category`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setCategory(res.data));
        dispatch(startLoading(false));
      }
    })
    */
};

export const getRequest = async (officeId) => {
  dispatch(startLoading(true));
  await axios.get(`api-auction/auction/request?idp=${officeId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setRequest(res.data));
        dispatch(startLoading(false));
      }
    })
};

export const getNdiValues = async (ndiTable) => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=${ndiTable.table}&titleOut=${ndiTable.title}&valueOut=${ndiTable.value}&all=true`)
    .then(res => {
      if (res.status === 200) {
        const data = {
          name: ndiTable.name,
          values: res.data
        }
        dispatch(setNdiValues(data))
      }
    })
};

export const getAutocompleteStationsList = async (nameTable, nameField, keyField, searchValue, searchField) => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=${nameTable}&titleOut=${nameField}&valueOut=${keyField}&value=${searchValue}&searchField=${searchField}&all=false&limit=2000`)
    .then(res => {
      if (res.status === 200) {
        const data = {
          name: 'esr',
          values: res.data
        }
        dispatch(setNdiValues(data))
      }
    });
}

export const getSelectedStationsFromList = async (nameTable, nameField, keyField, searchValue) => {
  return axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=${nameTable}&titleOut=${nameField}&valueOut=${keyField}&value=${searchValue}&searchField=${keyField}&all=false&limit=2000`);  
}

export const getMyEnterpiseProposal = async (data) => {
  const {
    dateBeg,
    dateEnd,
    esr,
    type,
    status,
    page
  } = data;
  dispatch(setTableToShow('my_orders'))
  loaderStart();
  await axios.get(`api-vagons/auction/vlasni?dateBeg=${dateBeg}&dateEnd=${dateEnd}&esr=${esr}&page=${page}&count=10&type=${type}&status=${status}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setSearchParams(data))
        dispatch(setMyEnterpiseProposal(res.data));
        openGetOrdersModal(false);
      }
      loaderEnd();
    })
};

export const getReservedEnterpisesRow = (reserve) => {
  dispatch(setReservedEnterpisesRow(reserve));
}

// Приклад виклику по сокету
export const getOtherEnterpiseSocket = () => {

}

export const getOtherEnterpiseProposal = async (data) => {
  const {
    dateBeg,
    dateEnd,
    esr,
    type,
    status
  } = data;
  loaderStart();
  await axios.get(`api-vagons/auction/orders?dateBeg=${dateBeg}&dateEnd=${dateEnd}&esr=${esr}&page=1&count=10&type=${type}&status=${status}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setOtherEnterpiseProposal(res.data));
        openGetOrdersModal(false);
      }
    });
  loaderEnd();
};

export const getOtherEnterpiseProposalById = async (orderId) => {
  loaderStart();
  await axios.get(`api-vagons/auction/order?idRecord=${orderId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setOtherEnterpiseProposal(res.data));
        openGetOrdersModal(false);
      }
      loaderEnd();
    })
};

export const getSupply = async () => {
  loaderStart();
  await axios.get(`api-vagons/auction/offer`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setSupply(res.data));
      }
      loaderEnd();
    })
}

export const getDemand = async () => {
  await axios.get(`api-vagons/auction/demand`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setDemand(res.data));
      }
    })
}

export const openCreateOrderModal = (isOpen) => {
  if (!isOpen) {
    dispatch(setEditableOrder(null));
  }
  dispatch(setCreateOrderModalOpen(isOpen));
};

export const openGetOrdersModal = (isOpen) => {
  dispatch(setGetOrdersModalOpen(isOpen));
};

export const selectOrder = (order) => {
  dispatch(setSelectedOrder(order));
}

export const editOrder = async (order) => {
  await dispatch(setEditableOrder(order));
  dispatch(setCreateOrderModalOpen(true));
}

export const createOrder = async (data) => {
  dispatch(startLoading(true));
  await axios.post(`api-vagons/auction/order`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyEnterpiseProposal(res.data));
        toast.success('Замовлення створено!');
        openCreateOrderModal(false);
      }
    })
  dispatch(startLoading(false));
}

export const updateOrder = async (data) => {
  dispatch(startLoading(true));
  await axios.put(`api-vagons/auction/order`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyEnterpiseProposal(res.data));
        toast.success('Замовлення змінено!');
        dispatch(startLoading(false));
        openCreateOrderModal(false);
      }
    })
}

export const changeOrderStatus = async (id, status, searchParams, createEventData = {}, reservedEnterprisesRow, reserveCalendarData) => {
  loaderStart();
  await axios.put(`api-vagons/auction/order/status?idf_auction=${id}&ozn_status_vlas=${status}`, searchParams)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyEnterpiseProposal(res.data));
        if (reservedEnterprisesRow) {
          const updatedRow = res.data.data.find(item => item.idf_auction_vag === reservedEnterprisesRow.idf_auction_vag);
          dispatch(setReservedEnterpisesRow(updatedRow))
        }
        if (status === '2') {
          createEvent(createEventData);
          toast.success('Замовлення підтверджено!')
        }
        if (status === '3') {
          toast.success('Замовлення скасовано!');
        }
        if (status === '4') {
          toast.success('Резервування підтверджено!')
          reserveResource(reserveCalendarData);
        }
      }
    })
    loaderEnd();
}

const createEvent = async data => axios.post(`/api-sys/events/set_event_all`, data)

export const reserve = async (data) => {
  dispatch(startLoading(true));
  await axios.post(`api-vagons/auction/reserv`, data)
    .then(res => {
      if (res.status === 200) {
        openCreateOrderModal(false);
        toast.success('Резервування створено!')
        dispatch(startLoading(false));
      }
    })
};

export const changeReserveStatus = async (id, status, searchParams, reservedEnterprisesRow, ) => {
  loaderStart();
  await axios.put(`api-vagons/auction/reserv/status?idf_auction=${id}&ozn_status_vlas=${status}`, searchParams)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyEnterpiseProposal(res.data));
        const updatedRow = res.data.data.find(item => item.idf_auction_vag === reservedEnterprisesRow.idf_auction_vag);
        dispatch(setReservedEnterpisesRow(updatedRow))
        if (status === '3') {
          toast.success('Резервування скасовано!')
        }
      }
      loaderEnd();
    })
};

export const changeSearchParams = (params) => {
  dispatch(setSearchParams(params));
}

export const changeMyReservesSearchParams = (params) => {
  dispatch(setMyReservesSearchParams(params));
}

export const getFreeVagons = async (params) => {
  dispatch(startLoading(true));
  const { dateBeg, dateEnd, ridVag, koLvag, oznVag } = params;
  await axios.get(`/api-vagons/booking/getFreeVagons?dateBeg=${dateBeg}&dateEnd=${dateEnd}&ridVag=${ridVag}&oznVag=${oznVag}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setFreeVagons(res.data))
      }
    })
  dispatch(startLoading(false));
};

export const clearFreeVagons = () => {
  dispatch(setFreeVagons(null))
}

export const getMyReserves = async (params) => {
  const {
    dateBeg,
    dateEnd,
    page,
    count
  } = params;
  dispatch(setTableToShow('my_reserves'))
  loaderStart();
  await axios.get(`/api-vagons/auction/reserv?dateBeg=${dateBeg}&dateEnd=${dateEnd}&idVag=0&page=${page}&count=${count}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyReservesSearchParams(params));
        dispatch(setMyReserves(res.data));
      }
    })
  loaderEnd()
}

export const openEditReserveModal = (reserve) => {
  dispatch(setEditableReserv(reserve));
}

export const updateReserv = async (data) => {
  dispatch(startLoading(true));
  await axios.put(`/api-vagons/auction/reserv`, data)
    .then(res => {
      dispatch(setMyReserves(res.data));
      dispatch(setEditableReserv(null));
      toast.success('Резервування оновлено!')
    })
  dispatch(startLoading(false));
}

export const deleteReserv = async (data) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити це резервування?`,
    onSubmit: () => submitDelete(data),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
}

const submitDelete = async (data) => {
  const {
    id,
    dateBeg,
    dateEnd,
    page,
    count
  } = data;
  loaderStart();
  await axios.delete(`/api-vagons/auction/reserv?idRec=${id}&dateBeg=${dateBeg}&dateEnd=${dateEnd}&idVag=0&page=${page}&count=${count}&order=desc`, data)
  .then(res => {
    if(res.status === 200) {
      dispatch(setMyReserves(res.data));
      showConfirmationModal({ isOpen: false });
      toast.success('Резервування успішно видалено.')
    }
  });
  loaderEnd();
};

export const backupOrderStatus = async (id, searchParams, reservedEnterprisesRow) => {
  loaderStart();
  await axios.put(`api-vagons/auction/order/status?idf_auction=${id}&ozn_status_vlas=2`, searchParams)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyEnterpiseProposal(res.data));
        if (reservedEnterprisesRow) {
          const updatedRow = res.data.data.find(item => item.idf_auction_vag === reservedEnterprisesRow.idf_auction_vag);
          dispatch(setReservedEnterpisesRow(updatedRow))
        }
      }
    })
  loaderEnd();
}

export const backupReserveStatus = async (id, searchParams, reservedEnterprisesRow) => {
  loaderStart();
  await axios.put(`api-vagons/auction/reserv/status?idf_auction=${id}&ozn_status_vlas=1`, searchParams)
    .then(res => {
      if (res.status === 200) {
        dispatch(setMyEnterpiseProposal(res.data));
        const updatedRow = res.data.data.find(item => item.idf_auction_vag === reservedEnterprisesRow.idf_auction_vag);
        dispatch(setReservedEnterpisesRow(updatedRow))
        toast.success('Резервування відновлено!')
      }
    })
  loaderEnd();
}

// {
//   idVlasnyk: 145,
//   month: 1,
//   year: 2023,
//   days: [
//     {
//       dateFrom: '2023-01-10T00:00:00',
//       dateTo: '2023-01-18T00:00:00',
//       count: 15,
//       idPidpr: 153,
//       confirmation: 0,
//       idEventSet: 0,
//       idEventConfirm: 0,
//       code_resource: 1,
//       price: 0,
//       opers: 'add',
//       attributes: [
//         {
//           idCalendarAttr: 0,
//           idCalendarRow: 0,
//           idAttr: 7,
//           valueAttr: 452009
//         },
//         {
//           idCalendarAttr: 0,
//           idCalendarRow: 0,
//           idAttr: 8,
//           valueAttr: 310
//         }
//       ]
//     }
//   ],
//   code_resource: 1,
//   attributes: [
//     {
//       idCalendarAttr: 0,
//       idCalendarRow: 0,
//       idAttr: 7,
//       valueAttr: 452009
//     },
//     {
//       idCalendarAttr: 0,
//       idCalendarRow: 0,
//       idAttr: 8,
//       valueAttr: 310
//     }
//   ]
// }