export function RowColor(props) {
  let tempColor = "";

  if (props.pr_zacount === '0' && props.k_rach === '1') {
    tempColor = { light: "RGBA(255,153,255,1) !important", primary: "RGBA(202,104,204,0.9) !important" };
  }
  else if (props.pr_zacount === '2') {
    tempColor = { light: "RGBA(255,193,193,0.9) !important", primary: "#RGBA(203,144,145,0.9) !important" };
  }
  else if (props.k_rach === '1') {
    tempColor = { light: "RGBA(153,255,153,0.9) !important", primary: "RGBA(102,203,106,0.9) !important" };
  }
  else if (props.k_rach === '2') {
    tempColor = { light: "RGBA(153,255,255,0.9) !important", primary: "RGBA(100,204,204,0.9) !important" };
  }
  else if (props.k_rach === '5' && props.pr_zacount === '0') {
    tempColor = { light: "RGBA(255,255,204,0.9) !important", primary: "RGBA(203,204,155,0.9) !important" };
  }
  else if (props.k_rach === '5' && props.pr_zacount === '1') {
    tempColor = { light: "RGBA(204,204,204,0.9) !important", primary: "RGBA(155,155,155,0.9) !important" };
  }

  return tempColor;
};


const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? `${color}90 !important` : `${color} !important`;

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? `${color}80 !important` : `${color}90 !important`;

export const styleElz = {
  height: 660,
  margin: 0,
  padding: 0,
  '& .reestr-row-theme--pr_zacount0--k_rach1': {
    bgcolor: (theme) =>
      getBackgroundColor('#FF99FF', theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor('#FF99FF', theme.palette.mode),
    },
  },
  '& .reestr-row-theme--pr_zacount2': {
    bgcolor: (theme) =>
      getBackgroundColor('#FFC1C1', theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor('#FFC1C1', theme.palette.mode),
    },
  },
  '& .reestr-row-theme--k_rach1': {
    bgcolor: (theme) =>
      getBackgroundColor('#99FF99', theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor('#99FF99', theme.palette.mode),
    },
  },
  '& .reestr-row-theme--k_rach2': {
    bgcolor: (theme) =>
      getBackgroundColor('#99FFFF', theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor('#99FFFF', theme.palette.mode),
    },
  },
  '& .reestr-row-theme--k_rach5--pr_zacount0': {
    bgcolor: (theme) =>
      getBackgroundColor('#FFFFCC', theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor('#FFFFCC', theme.palette.mode),
    },
  },
  '& .reestr-row-theme--k_rach5--pr_zacount1': {
    bgcolor: (theme) =>
      getBackgroundColor('#CCCCCC', theme.palette.mode),
    '&:hover': {
      bgcolor: (theme) =>
        getHoverBackgroundColor('#CCCCCC', theme.palette.mode),
    },
  }
}

export function getRowClass(kRach, prZacount) {
  let className = "";

  if (prZacount === '0' && kRach === '1') {
    className = 'reestr-row-theme--pr_zacount0--k_rach1';
  }
  else if (prZacount === '2') {
    className = 'reestr-row-theme--pr_zacount2';
  }
  else if (kRach === '1') {
    className = 'reestr-row-theme--k_rach1';
  }
  else if (kRach === '2') {
    className = 'reestr-row-theme--k_rach2';
  }
  else if (kRach === '5' && prZacount === '0') {
    className = 'reestr-row-theme--k_rach5--pr_zacount0';
  }
  else if (kRach === '5' && prZacount === '1') {
    className = 'reestr-row-theme--k_rach5--pr_zacount1';
  }
  return className;
}
