import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Button,
    FormControl,
    TextField,
    MenuItem,
    InputLabel,
    Select
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext, useForm, FormProvider, Controller } from "react-hook-form";
import FormationAppointment from './perevezComponents/FormationAppointment';
import PrivateTraction from './perevezComponents/PrivateTraction';
import ConsignorConsignee from './perevezComponents/ConsignorConsignee';
import Containers from './perevezComponents/Containers';
import PlanFormation from './perevezComponents/PlanFormation';
import Coordination from './perevezComponents/Coordination';
import TrafficVolumes from './perevezComponents/TrafficVolumes';

const PerevezComponent = ({ handleClose, indexPerevez, _watch }) => {
    // const { control: _control, register: _register, setValue: _setValue, watch: _watch, formState: { errors } } = useFormContext();

    const methods = useForm();
    const _controlPerevez = methods.control;
    const _registerPerevez = methods.register;
    const _setValuePerevez = methods.setValue;
    const _watchPerevez = methods.watch;
    const _handleSubmitPerevez = methods.handleSubmit;

    const [perevez, setPerevez] = useState(null);
    //
    const matches = useMediaQuery('(min-width:720px)');

    useEffect(() => {
        if (indexPerevez || indexPerevez === 0) {
            const temp = _watch('perevezArr');
            setPerevez(temp[indexPerevez]);
            _setValuePerevez("prim_perevez", temp[indexPerevez]?.prim_perevez);
            //
            _setValuePerevez("soglas_comment", temp[indexPerevez]?.soglas_comment);
        }
    }, [indexPerevez]);

    const onSubmit = async data => { console.log(data); };

    return (
        <FormProvider {...methods} >
            <form onSubmit={_handleSubmitPerevez(onSubmit)}>
                <Grid container spacing={matches ? 1.3 : 1.1}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h6" component="h2">
                            {perevez?.idf_perevez ? 'Редагування перевезення' : 'Формування перевезення'}
                        </Typography>
                    </Grid>

                    <FormationAppointment
                        data={perevez}
                    />
                    {/* <PrivateTraction
                        data={perevez}
                    /> */}
                    <ConsignorConsignee
                        data={perevez}
                    />
                    {/* <Containers
                        data={perevez}
                    /> */}
                    <PlanFormation
                        data={perevez}
                    />
                    {perevez?.soglas_Org ?
                        <Coordination
                            data={perevez}
                        />
                        : <></>}
                    <TrafficVolumes
                        data={perevez}
                    />

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="ozn_marshrut-label">Тип заявки</InputLabel>
                            <Controller
                                render={
                                    ({ field }) =>
                                        <Select
                                            labelId="ozn_marshrut-label"
                                            id="ozn_marshrut"
                                            label="Тип заявки"
                                            size={'small'}
                                            {...field}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'0'}>заявлено</MenuItem>
                                            <MenuItem value={'1'}>узгоджено</MenuItem>
                                            <MenuItem value={'2'}>видалено</MenuItem>
                                            <MenuItem value={'3'}>частково узгоджено</MenuItem>
                                            <MenuItem value={'4'}>відкладено</MenuItem>
                                            <MenuItem value={'5'}>не узгоджено</MenuItem>
                                            <MenuItem value={'6'}>узгодження план. форм.</MenuItem>
                                            <MenuItem value={'98'}>підписана чернетка</MenuItem>
                                        </Select>
                                }
                                control={_controlPerevez}
                                name="ozn_marshrut"
                                defaultValue={null}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField id="soglas_comment" name="soglas_comment" {..._registerPerevez("soglas_comment")} label="Примітка призначення" variant="outlined" size={'small'} />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button variant="contained" type="primary" fullWidth size={matches ? '' : 'small'}>Додати призначення</Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button onClick={handleClose} variant="contained" color="text" fullWidth size={matches ? '' : 'small'}>Ні</Button>
                    </Grid>
                </Grid>
            </form >
        </FormProvider>
    );
}

export default PerevezComponent;
