import { createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
// import i18n from '../../locales/i18n';
// actions
import { showConfirmationModal } from './confirmation';
import { loaderEnd, loaderStart } from './loader';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  agreements: null,
  agreement: null,
  sallersAndCustomers: [],
  dicTypeAgreements: [],
  isAgreementsModalOpen: false,
  searchParams: {
    dateBeg: '',
    dateEnd: '',
    page: 1,
    count: 10,
  }
};

const slice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAgreements(state, action) {
      state.agreements = action.payload;
    },
    setDicTypeAgreements(state, action) {
      state.dicTypeAgreements = action.payload;
    },
    setSallersAndCustomers(state, action) {
      state.sallersAndCustomers = action.payload;
    },
    setAgreementsModal(state, action) {
      state.isAgreementsModalOpen = action.payload;
    },
    setAgreement(state, action) {
      state.agreement = action.payload;
    },
    setSearchParams: (state, action) => ({
      ...state,
      searchParams: { ...state.searchParams, ...action.payload }
    })
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setAgreements,
  setSallersAndCustomers,
  setDicTypeAgreements,
  setAgreementsModal,
  setEditableAgreementId,
  setAgreement,
  setSearchParams
} = slice.actions;

export const openAgreementsModal = async (isOpen, agreement = {}) => {
  await dispatch(setAgreement(agreement));
  dispatch(setAgreementsModal(isOpen));
};

export const changeSearchParams = params => {
  dispatch(setSearchParams(params));
}

export const getAgreements = async (enterpriseId, params) => {
  const { 
    page,
    count,
    dateBeg,
    dateEnd
  } = params;
  dispatch(setSearchParams({
    page,
    count,
    dateBeg,
    dateEnd
  }))
  loaderStart()
  await axios.get(`/api-sys/pidpr/get_agreements?pId=${enterpriseId}&page=${page}&count=${count}&dateBeg=${dateBeg}&dateEnd=${dateEnd}`)
    .then(res => {
      if(res.status === 200) {
        dispatch(setAgreements(res.data));
      }
    });
  loaderEnd()
};

export const getSallersAndCustomers = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=pidpr.Ekbs_Pidpr_All&titleOut=name_short&valueOut= id_vsp&all=true`)
    .then(res => {
      if(res.status === 200) {
        dispatch(setSallersAndCustomers(res.data));
      }
    })
};

export const getDicTypeAgreements = async () => {
  await axios.get(`/api-nsi/api/DataNsi/GetAllValues?table=nsi_ekbs.Dic_Type_Agreements&titleOut=name_short&valueOut=type_agree&all=true`)
    .then(res => {
      if(res.status === 200) {
        dispatch(setDicTypeAgreements(res.data));
      }
    })
};


export const createAgreement = async (data, loadPartners, clearData) => {
  dispatch(setLoading(true));
  await axios.post(`/api-sys/pidpr/set_agreement`, data)
    .then(res => {
      if(res.status === 200) {
        dispatch(setAgreements(res.data));
        dispatch(setAgreementsModal(false, null));
        loadPartners();
        if (data.idAgree) {
          toast.success('Угоду успішно відкориговано.')
        } else {
          toast.success('Угоду успішно створено.')
        }
        
      }
    });
    clearData();
  dispatch(setLoading(false));
};

export const deleteAgreement = (agreement, searchParams) => {
  const { idAgree, idSaller, numberAgreement } = agreement;
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити договір №${numberAgreement}`,
    onSubmit: () => submitDelete(idAgree, idSaller, searchParams),
    onCancel: () => showConfirmationModal({ isOpen: false })
  });
  
  const submitDelete = async (idAgree, pId, searchParams) => {
    const {
      dateBeg,
      dateEnd,
      page,
      count
    } = searchParams;
    loaderStart();
    await axios.get(`/api-sys/pidpr/del_agreement?idAgree=${idAgree}&pId=${pId}&dateBeg=${dateBeg}&dateEnd=${dateEnd}&page=${page}&count=${count}`)
    .then(res => {
      if(res.status === 200) {
        dispatch(setAgreements(res.data));
        showConfirmationModal({ isOpen: false });
        toast.success('Угоду успішно видалено.')
      }
    });
    loaderEnd();
  };

}