import { useTranslation } from 'react-i18next';
// '@mui
import { enUS, deDE, frFR, ukUA, plPL } from '@mui/material/locale';
import useProfile from './useProfile';
import { addHeaderHttp } from '../utils/jwt';
// import useAuth from './useAuth';
// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/icons/ic_flag_en.svg',
  },
  {
    label: 'Українська',
    value: 'uk',
    systemValue: ukUA,
    icon: '/icons/Flag_of_Ukraine.svg',
  },
  {
    label: 'Poland',
    value: 'pl',
    systemValue: plPL,
    icon: '/icons/Flag_of_Poland.svg',
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { setMenu, profiles } = useProfile();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];
  // console.log(currentLang);
  addHeaderHttp('i18Language', currentLang.value);
  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
    localStorage.setItem('i18nextLng', newlang);
    addHeaderHttp('i18Language', newlang); 
    setMenu();
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
