import React, { useEffect } from "react";
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext, Controller } from "react-hook-form";


const CoordinationComponent = ({ data }) => {
    const { control: _controlPerevez, setValue: _setValuePerevez, register: _registerPerevez, watch: _watchPerevez, handleSubmit: _handleSubmitPerevez } = useFormContext();
    const [expanded, setExpanded] = React.useState(false);
    const handleChangeExpanded = () => setExpanded(!expanded);
    const matches = useMediaQuery('(min-width:720px)');

    useEffect(() => {
        if (data && data?.soglas_Org) {
            _setValuePerevez("soglasOrg", data?.soglas_Org);
        }
    }, [data]);

    return (
        <Grid item xs={12} md={12}>
            <Accordion sx={{ m: 0 }} expanded={expanded} onChange={handleChangeExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Погодження організаціями</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={matches ? 1.3 : 1.1}>
                        <TableContainer component={Paper}>
                            <Table aria-label="Coordination table">
                                <TableBody>
                                    {data?.soglas_Org.map((row) => (
                                        <TableRow
                                            key={`${row.idf_soglas}${row.org_code_name}`}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.org_code_name}
                                            </TableCell>
                                            <TableCell align="right">{row.soglas_code}</TableCell>
                                            <TableCell align="right">{row.soglas_date}</TableCell>
                                            <TableCell align="right">{row.soglas_comment}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default CoordinationComponent;
