import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import SettingsNotifications from '../../sections/@dashboard/services/mesplan/settings/SettingMonth';
import CommonAlert from '../../common/CommonAlert/CommonAlert';
import { CommonAlertHolder } from '../../common/CommonAlert/CommonAlertHolder';

const SettingsView = () => (
  <>
    <Helmet>
      <title>Settings | Mesplan</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
      <CommonAlert showFunc={(ref) => {
          CommonAlertHolder.setAlert(ref)
        }} />
        <SettingsNotifications />
      </Container>
    </Box>
  </>
);

export default SettingsView;
