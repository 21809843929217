import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import ListResults from '../../sections/@dashboard/services/mesplan/elz/ElzListResults';
import ListToolbar from '../../sections/@dashboard/services/mesplan/elz/ElzListToolbar';
import CommonAlert from '../../common/CommonAlert/CommonAlert';
import { CommonAlertHolder } from '../../common/CommonAlert/CommonAlertHolder';

const ElzList = () => (
  <>
    <Helmet>
      <title>Замовлення на навантаження</title>
    </Helmet>

    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 0
      }}
    >
      <Container maxWidth={false}>
        <CommonAlert showFunc={(ref) => {
          CommonAlertHolder.setAlert(ref)
        }} />
        <ListToolbar />
        <Box sx={{ pt: 3 }}>
          <ListResults />
        </Box>
      </Container>
    </Box>

  </>
);

export default ElzList;
