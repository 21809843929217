import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

import { loaderEnd, loaderStart } from './loader';
import { showConfirmationModal } from './confirmation';

const initialState = {
  isLoading: false,
  error: null,
  vagons: [],
  myWagons: [],
  stats: null,
  carOperations: null,
  documentInfo: null,
  fileData: null,
  history: null,
  vagonsDislocation: [],
  selectedStation: null,
  vagonsDislocationByStation: [],
  vagonsStatus: '',
  isCreateWagModalOpened: false,
  ndiValues: {
    rodWag: [],
    stations: [],
  },
  editableWagon: null,
  uzParameters: null,
  uzData: null,
  uzHead: null,
};

const slice = createSlice({
  name: 'vagon',
  initialState,
  reducers: {
    // START LOADING
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setVagons(state, action) {
      state.vagons = action.payload;
    },
    setMyWagons(state, action) {
      state.myWagons = action.payload;
    },
    setStats(state, action) {
      state.stats = action.payload;
    },
    setCarOperations(state, action) {
      state.carOperations = action.payload;
    },
    setDocumentInfo(state, action) {
      state.documentInfo = action.payload;
    },
    setFileData(state, action) {
      state.fileData = action.payload;
    },
    setHistory(state, action) {
      state.history = action.payload;
    },
    setVagonsDislocation(state, action) {
      state.vagonsDislocation = action.payload;
    },
    setSelectedStation(state, action) {
      state.selectedStation = action.payload;
    },
    setVagonsDislocationByStation(state, action) {
      state.vagonsDislocationByStation = action.payload;
    },
    setVagonsStatus(state, action) {
      state.vagonsStatus = action.payload;
    },
    setCreateWagModalOpen(state, action) {
      state.isCreateWagModalOpened = action.payload;
    },
    setNdiValues: (state, action) => ({
      ...state,
      ndiValues: {
        ...state.ndiValues,
        [action.payload.name]: action.payload.values,
      },
    }),
    setEditableWagon(state, action) {
      state.editableWagon = action.payload;
    },
    setUzParameters(state, action) {
      state.uzParameters = action.payload;
    },
    setUzData(state, action) {
      state.uzData = action.payload;
    },
    setUzHead(state, action) {
      state.uzHead = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setVagons,
  setMyWagons,
  setStats,
  setCarOperations,
  setDocumentInfo,
  setFileData,
  setHistory,
  setVagonsDislocation,
  setSelectedStation,
  setVagonsDislocationByStation,
  setVagonsStatus,
  setCreateWagModalOpen,
  setNdiValues,
  setEditableWagon,
  setUzParameters,
  setUzData,
  setUzHead,
} = slice.actions;

export const clearNdiValues = () => {
  dispatch(setNdiValues({}));
};

export const openCreateWagModal = (isOpen) => {
  dispatch(setCreateWagModalOpen(isOpen));
};

export const editWagon = (wagon) => {
  dispatch(setEditableWagon(wagon));
  if (wagon) {
    const data = {
      name: 'stations',
      values: [
        {
          title: wagon.mnEsrOp,
          value: wagon.esrOp,
        },
      ],
    };
    dispatch(setNdiValues(data));
  }
};

// https://dev.vantazhplus.co.ua/api-events/vagons/get_vagons?edrpou=32908117
export const getVagons = async (edrpou) => {
  loaderStart();
  await axios.get(`/api-vagons/vagons/get_vagons?edrpou=${edrpou}`).then((res) => {
    if (res.status === 200) {
      res.data.map((vag, index) => (vag.id = index + 1));
      dispatch(setVagons(res.data));
    } else {
      dispatch(setVagons([]));
    }
  });
  await axios.get(`/api-vagons/vagons/get_stat?edrpou=${edrpou}`).then((res) => {
    if (res.status === 200) {
      dispatch(setStats(res.data));
    } else {
      dispatch(setStats([]));
    }
  });
  loaderEnd();
};

export const getMyWagons = async (edrpou) => {
  loaderStart();
  await axios.get(`/api-vagons/vagons/get_vlasni?edrpou=${edrpou}`).then((res) => {
    if (res.status === 200) {
      res.data.map((vag, index) => (vag.id = index + 1));
      dispatch(setMyWagons(res.data));
    } else {
      dispatch(setVagons([]));
    }
  });
  loaderEnd();
};

export const getCarOperations = async (carId) => {
  loaderStart();
  await axios
    .get(`/api-vagons/vagons/get_online?nomer=${carId}`)
    // await axios.get(`https://localhost:32768/vagons/get_online?nomer=${carId}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setCarOperations(res.data[0]));
      }
    });
  loaderEnd();
};

export const getDocumentInfo = async (vagNumber) => {
  loaderStart();
  await axios
    .get(`/api-register/api/Vagons/actualListByVag?vagNumber=${vagNumber}`)
    // await axios.get(`https://localhost:32770/api/Vagons/actualListByVag?vagNumber=${vagNumber}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setDocumentInfo(res.data[0]));
      }
    });
  loaderEnd();
};

export const getFileData = async (carId) => {
  loaderStart();
  await axios.get(`/api-vagons/vagons/get_kart?nomer=${carId}`).then((res) => {
    if (res.status === 200) {
      dispatch(setFileData(res.data[0]));
    }
  });
  loaderEnd();
};

export const getHistory = async (carId) => {
  loaderStart();
  await axios.get(`/api-vagons/vagons/get_history?nomer=${carId}`).then((res) => {
    if (res.status === 200) {
      res.data.map((vag, index) => (vag.id = index + 1));
      dispatch(setHistory(res.data));
    }
  });
  loaderEnd();
};

export const getVagonsDislocation = async (inDate) => {
  loaderStart();
  await axios.get(`/api-vagons/dislocation/vlasni?inDate=${inDate}`).then((res) => {
    if (res.status === 200) {
      dispatch(setVagonsDislocation(res.data));
      dispatch(setVagonsStatus(''));
    }
  });
  loaderEnd();
};

export const getSelectedStation = async (station) => {
  dispatch(setSelectedStation(station));
};

export const getVagonsDislocationByStation = async (stationId, inDate) => {
  loaderStart();
  await axios.get(`/api-vagons/dislocation/stantion?esr=${stationId}&inDate=${inDate}`).then((res) => {
    if (res.status === 200) {
      dispatch(setVagonsDislocationByStation(res.data));
      dispatch(setVagonsStatus(''));
    }
    loaderEnd();
  });
};

export const getDataByVagon = async (esr, inDate, vagon) => {
  loaderStart();
  await axios.get(`/api-vagons/dislocation/stantion?esr=${esr}&inDate=${inDate}&state=${vagon}`).then((res) => {
    if (res.status) {
      dispatch(setVagonsDislocationByStation(res.data));
      dispatch(setVagonsStatus(vagon));
    }
    loaderEnd();
  });
};

export const getNdiValues = async (ndiTable) => {
  await axios
    .get(
      `/api-nsi/api/DataNsi/GetAllValues?table=${ndiTable.table}&titleOut=${ndiTable.title}&valueOut=${ndiTable.value}&all=true`
    )
    .then((res) => {
      if (res.status === 200) {
        const data = {
          name: ndiTable.name,
          values: res.data,
        };
        dispatch(setNdiValues(data));
      }
    });
};

export const getAutocompleteValues = async (ndiTable, searchValue, searchField) => {
  const { table, name, key, nameAUC } = ndiTable;
  await axios
    .get(
      `/api-nsi/api/DataNsi/GetAllValues?table=${table}&titleOut=${name}&valueOut=${key}&value=${searchValue}&searchField=${searchField}&all=false&limit=2000`
    )
    .then((res) => {
      if (res.status === 200) {
        const data = {
          name: nameAUC,
          values: res.data,
        };
        dispatch(setNdiValues(data));
      }
    });
};

export const createWagon = async (data, edrpou, callback) => {
  dispatch(setLoading(true));
  await axios
    .post(`/api-vagons/vagons/set_vag_actual`, data)
    .then((res) => {
      if (res.status === 200) {
        getMyWagons(edrpou);
        callback();
        toast.success('Вагон додано!');
      }
      if (res.status === 400) {
        Object.keys(res.data.errors).forEach((key) => {
          toast.error(`${key}: ${res.data.errors[key][0]}`);
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
  dispatch(setLoading(false));
};

export const updateWagon = async (data, edrpou, callback) => {
  dispatch(setLoading(true));
  await axios.put(`/api-vagons/vagons/set_vag_actual`, data).then((res) => {
    if (res.status === 200) {
      getMyWagons(edrpou);
      callback();
      toast.success('Вагон оновлено!');
    }
  });
  dispatch(setLoading(false));
};

export const deleteWagon = (nomer, edrpou) => {
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити вагон №${nomer}`,
    onSubmit: () => confirmDeleteWagon(nomer, edrpou),
    onCancel: () => showConfirmationModal({ isOpen: false }),
  });
};

export const confirmDeleteWagon = async (nomer, edrpou) => {
  await axios.delete(`/api-vagons/vagons/vagon?nomer=${nomer}`).then((res) => {
    if (res.status === 200) {
      getMyWagons(edrpou);
      showConfirmationModal({ isOpen: false });
      toast.success('Вагон видалено!');
    }
  });
};
// =====================================================
export const getUzParameters = async () => {
  loaderStart();
  const p = { username: 'pktb_test_user', password: '3ya6e7F3RP' };
  // const data = { slice.uzParameters, ...p};
  // dispatch(setUzParameters(p));

  await axios.get(`/api-sys/pidpr/settings?sKey=uzInform`).then((res) => {
    if (res.status === 200) {
      if (res.data?.sValue !== '' && res.data?.sValue !== 'null') {
        dispatch(setUzParameters(JSON.parse(res.data.sValue)));
      } else {
        dispatch(setUzParameters(null));
      }
    }
  });
  loaderEnd();
};
export const setNewUzParameters = async (val) => {
  loaderStart();
  // dispatch(setUzParameters(val));
  const data = { idrec: 0, sKey: 'uzInform', sValue: JSON.stringify(val), idVsp: 0 };
  await axios.post(`/api-sys/pidpr/settings`, data).then((res) => {
    if (res.status === 200) {
      dispatch(setUzParameters(val));
    }
  });

  loaderEnd();
};
export const getTokenUz = async (val) => {
  loaderStart();
  // dispatch(setUzParameters(val));

  await axios.post(`/api-vagons/uzinform/gettoken`, val).then((res) => {
    if (res.status === 200) {
      dispatch(setUzParameters(res.data));
    }
  });

  loaderEnd();
};
export const getSpr8858 = async (val) => {
  loaderStart();
  // dispatch(setUzParameters(val));
  dispatch(setUzData(null));
  dispatch(setUzHead(null));
  await axios.post(`/api-vagons/uzinform/get8858`, val).then((res) => {
    if (res.status === 200) {
      dispatch(setUzData(res.data.disl_vag));
    }
  });

  loaderEnd();
};

export const getSprHistory = async (val) => {
  loaderStart();
  // dispatch(setUzParameters(val));
  dispatch(setUzData(null));
  dispatch(setUzHead(null));
  await axios
    .get(
      `/api-vagons/uzinform/history?date_beg=${new Date(val.date_beg).toISOString}&date_end=${new Date(
        val.date_end
      ).toISOString()}`,
      val
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(setUzData(res.data));
      }
    });

  loaderEnd();
};
export const getSpr2610 = async (val) => {
  loaderStart();
  // dispatch(setUzParameters(val));
  dispatch(setUzData(null));
  dispatch(setUzHead(null));
  await axios.post(`/api-vagons/uzinform/get2610`, val).then((res) => {
    if (res.status === 200) {
      const d = [];
      d.push(res.data);
      console.log(d);
      dispatch(setUzData(d));
    }
  });

  loaderEnd();
};
