import { useState } from 'react';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom'
// @mui
import { alpha } from '@mui/material/styles';
import { Typography, ListItemText, MenuItem, Stack, Divider } from '@mui/material';
// hooks
import useProfile from '../../../hooks/useProfile'
// actions
import { getEnterprise } from '../../../redux/slices/enterprise';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales from '../../../hooks/useLocales';
// ----------------------------------------------------------------------

const ITEM_HEIGHT = 34;

// ----------------------------------------------------------------------

export default function ContactsPopover() {

  const { partners } = useProfile();
  const [open, setOpen] = useState(null);
  const { translate } = useLocales();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClick = idPartner => {
    getEnterprise(idPartner);
    setOpen(null);
  }
  const MENU_OPTIONS = [
    {
      label: translate('account.agreements'),
      linkTo: PATH_DASHBOARD.user.agreements,
    },
    {
      label: translate('account.pidpr'),
      linkTo: PATH_DASHBOARD.user.enterprises,
    },
  ];
  // ----------------------------------------------------------------
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >

<Stack sx={{ p: 1 }}>
  {MENU_OPTIONS.map((option) => (
    <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
      {option.label}
    </MenuItem>
  ))}
</Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Typography variant="h6" sx={{ p: 1.5 }}>
          {translate('account.partners')}<Typography component="span">({partners.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * partners.length, maxHeight: '408px' }}>
          {partners.map((partner) => (
            <MenuItem key={partner.idPartner}>
              <Link
                onClick={() => handleClick(partner.idPartner)}
                to={`/ekbs/user/partner/${partner.idPartner}`} 
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <ListItemText
                  primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                  primary={partner.nameShort}
                />
              </Link>
            </MenuItem>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
