import React, { useEffect } from "react";
import {
    Grid,
    FormControl,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Divider,
    Select,
    MenuItem,
    InputLabel,
    IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import {
    vagRpsSettings,
    vagTypeSettings,
    klientSettings
} from '../../../../../../../common/settings/autocompleteSettings';
import AutocompleteVirtualizad from '../../../../../../../common/components/AutocompleteVirtualizad';
import setDefault from "../utils/setDefaultsFormationAppointment";

const TrafficVolumesComponent = ({ data }) => {
    const { control: _controlPerevez, setValue: _setValuePerevez, register: _registerPerevez, watch: _watchPerevez, handleSubmit: _handleSubmitPerevez } = useFormContext();
    const { fields: fieldsVag, append: appendVag, remove: removeVag } = useFieldArray({ control: _controlPerevez, name: 'vagsArr' });
    const [expanded, setExpanded] = React.useState(true);
    const handleChangeExpanded = () => setExpanded(!expanded);
    const matches = useMediaQuery('(min-width:720px)');

    const commonAutocompleteSettings =
    {
        control: _controlPerevez,
        register: _registerPerevez,
        setValue: _setValuePerevez,
        watch: _watchPerevez,
        multiple: false,
        size: 'small',
    }

    useEffect(() => {
        if (data?.vagon) {
            data?.vagon?.map((item) => (
                appendVag({
                    ...item,
                    // tip_vag_value: { title: item.tip_vag_name, value: item.tip_vag },
                    rod_vag_value: { title: item.rod_vag_name, value: item.rod_vag },
                    id_vl_value: { title: item.id_vl_name, value: item.id_vl },
                })
            ));
        }
    }, [data?.vagon]);

    // handle delete 
    const handleDeleteVag = (id) => {
        removeVag(id);
    };

    const handleAppendVag = () => {
        appendVag({
            idf_volume: "",
            por_index: "",
            rod_vag: "",
            id_vl: "",
            tip_vag: "",
            za_count: "",
            so_count: "",
            kr_count: "",
            za_ves: "",
            so_ves: "",
            rod_vag_name: "",
            id_vl_name: ""
        });
    };

    return (
        <Grid item xs={12} md={12}>
            <Accordion sx={{ m: 0 }} expanded={expanded} onChange={handleChangeExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Об'єми перевезення</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <IconButton aria-label="add" onClick={() => handleAppendVag}>
                        <AddIcon />
                    </IconButton> */}
                    <Grid container spacing={matches ? 1.3 : 1.1} sx={{ mb: '4px' }}>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField type="number" id="za_count" name="za_count" {..._registerPerevez("za_count")} label="Тони заявлені" variant="outlined" size={'small'} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField type="number" id="so_count" name="so_count" {..._registerPerevez("so_count")} label="Тони узгоджені" variant="outlined" size={'small'} />
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <TextField type="number" id="kr_count" name="kr_count" {..._registerPerevez("kr_count")} label="Тони узгоджені" variant="outlined" size={'small'} />
                            </FormControl>
                        </Grid> */}
                    </Grid>
                    <Divider sx={{ mb: '8px', mt: '8px' }} />

                    {fieldsVag?.map((row, index) => (
                        <Grid key={index} container spacing={matches ? 1.3 : 1.1}>
                            {/* <Grid item xs={12} md={1}>
                                <IconButton aria-label="delete-vag" onClick={() => handleDeleteVag(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid> */}
                            <Grid item xs={12} md={1}>
                                <FormControl fullWidth>
                                    <TextField id={`vagsArr.${index}.za_count`} name={`vagsArr.${index}.za_count`} {..._registerPerevez(`vagsArr.${index}.za_count`)} label="Тони заявлені" variant="outlined" size={'small'} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <FormControl fullWidth>
                                    <TextField id={`vagsArr.${index}.so_count`} name={`vagsArr.${index}.so_count`} {..._registerPerevez(`vagsArr.${index}.so_count`)} label="Тони узгоджені" variant="outlined" size={'small'} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <AutocompleteVirtualizad props={{ ...vagRpsSettings, ...commonAutocompleteSettings, id: `vagsArr.${index}.rod_vag_value` }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <AutocompleteVirtualizad props={{ ...klientSettings, ...commonAutocompleteSettings, id: `vagsArr.${index}.id_vl_value`, label: `Власник рухомого складу` }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <FormControl fullWidth>
                                    <InputLabel id={`$vagsArr.${index}.replacement_ozn_marshrut-label`}>Підзаміна</InputLabel>
                                    <Controller
                                        render={
                                            ({ field }) =>
                                                <Select
                                                    labelId="replacement-label"
                                                    id={`vagsArr.${index}.replacement`}
                                                    label="Підзаміна"
                                                    size={'small'}
                                                    {...field}
                                                >
                                                    <MenuItem value={''}>
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={'0'}>Ні</MenuItem>
                                                    <MenuItem value={'1'}>Так</MenuItem>
                                                </Select>
                                        }
                                        control={_controlPerevez}
                                        name={`vagsArr.${index}.replacement`}
                                        defaultValue=''
                                    />
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} md={2}>
                                <FormControl fullWidth>
                                    <AutocompleteVirtualizad props={{ ...vagRpsSettings, ...commonAutocompleteSettings, id: `vagsArr.${index}.rod_spec_vag_value`, label: `Рід спецвагона` }} />
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <AutocompleteVirtualizad props={{ ...vagTypeSettings, ...commonAutocompleteSettings, id: `vagsArr.${index}.tip_vag_value` }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default TrafficVolumesComponent;
