export default function setDefault(data, setValue) {

  if (data?.poluch_ukr && data?.poluch_ukr !== '') {
    setValue("poluch_ukr", { title: data?.poluch_ukr_name, value: data?.poluch_ukr });
  }
  setValue("poluch_vnesh", data?.poluch_vnesh);

  if (data?.poluch_port && data?.poluch_port !== '') {
    setValue("poluch_port", { title: data?.poluch_port_name, value: data?.poluch_port });
  }

  if (data?.eksped_ukr && data?.eksped_ukr !== '') {
    setValue("eksped_ukr", { title: data?.eksped_ukr_name, value: data?.eksped_ukr });
  }
  setValue("code_perevizn", data?.code_perevizn);
}