import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession, setUserSession, getUserSession, addHeaderHttp } from '../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  refreshToken: '',
  user: null,
  timerId: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getToken: () => Promise.resolve()
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {

        const storageHeader = window.localStorage.getItem('storageHeader');
        if (storageHeader)
        {
            const objectHeader = JSON.parse(storageHeader);
            addHeaderHttp('edrpouVsp', objectHeader?.edrpouVsp);  
            addHeaderHttp('userId', objectHeader?.userId);
            addHeaderHttp('profileId',objectHeader?.profileId);
            addHeaderHttp('idPidpr', objectHeader?.idPidpr);
            addHeaderHttp('idVsp', objectHeader?.idVsp);
        }

        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          // const response = await axios.get('/api-sys/auth/getUser');
          // const { user } = response.data;
          const user = JSON.parse(getUserSession());
          console.log(user);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } 
        /*
        else 
        if (accessToken && refreshToken())
        {
          const user = JSON.parse(getUserSession());
          console.log(user);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } */
        else
        
        {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);
  // ---------------------------------------------------------------------------
  const getToken = async () => {
    const RefreshToken = state.refreshToken;
    const response = await axios.post('/api-sys/auth/GetToken', {
      refresh_token: RefreshToken
    });
    const { accessToken, refreshToken } = {accessToken: response.data.access_token, refreshToken: response.data.refresh_token};
    if (refreshToken) {
      setSession(accessToken);
      state.refreshToken = refreshToken;
      return true;
    }
      return false;
  };

  const login = async (email, password) => {
    const p = {email,
      password};
    setUserSession(p);
    const response = await axios.post('/api-sys/auth/getUser', {
      username: email,
      password,
    });
    const { accessToken, refreshToken, user } = {accessToken: response.data.access_token, refreshToken: response.data.refresh_token, user: response.data.user};
    user.password = password;
    setUserSession(user);
    setSession(accessToken);
    state.refreshToken = refreshToken;

    state.timerId = setInterval(() => getToken(), 180000);

    /*
    const u = await axios.get(['/api-sys/users/get_user_info?guid=',user.sub].join(''));

    const e = await axios.post(['/api-sys/events/set_event',''].join(''), {
      type_events_id: 1,
      type_source_id: 1,
      event_date: '2022-01-08 22:21:16',
      parent_object_id: 0,
      event_text: 'React',
      opers_id: 0,
      profile_id: 1,
      is_public: 0,
      keys:[{ event_id: 3, event_date: '2022-01-08 22:21:16', event_key: '{"a": 1, "b": 2}', event_result: '{"a": 1, "b": 2}', event_extended: '{"a": 1, "b": 2}' }],
      objects: [{ object_id: 111, object_name: 'qwdadsasd', object_type: 3 }]
    });
    */
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, phone, edrpou, firstName, lastName) => {
    // console.log('register', email, password, phone, firstName, lastName);
    const response = await axios.post('/api-sys/auth/register', {
      email,
      password,
      phone,
      edrpou,
      firstName,
      lastName,
    });

    setSession(null);
    setUserSession(null);
    dispatch({ type: 'LOGOUT' });
    return response.data;
    
  };

  const logout = async () => {
    setSession(null);
    setUserSession(null);
    clearInterval(state.timerId);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
