import React, { useEffect } from "react";
import {
    Grid,
    FormControl,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFormContext, Controller } from "react-hook-form";
import {
    poluchUkrSettings,
    idEekkSettings
} from '../../../../../../../common/settings/autocompleteSettings';
import AutocompleteVirtualizad from '../../../../../../../common/components/AutocompleteVirtualizad';
import AutocompleteVirtualizadConsignee from '../../../../../../../common/components/AutocompleteVirtualizadConsignee';
import setDefault from "../utils/setDefaultsConsignorConsignee";

const PrivateTractionComponent = ({ data }) => {
    const { control: _controlPerevez, setValue: _setValuePerevez, register: _registerPerevez, watch: _watchPerevez, handleSubmit: _handleSubmitPerevez } = useFormContext();
    const [expanded, setExpanded] = React.useState(false);
    const handleChangeExpanded = () => setExpanded(!expanded);
    const matches = useMediaQuery('(min-width:720px)');
    const commonAutocompleteSettings =
    {
        control: _controlPerevez,
        register: _registerPerevez,
        setValue: _setValuePerevez,
        watch: _watchPerevez,
        multiple: false,
        size: 'small',
    }

    useEffect(() => {
        if (data) {
            setDefault(data, _setValuePerevez);
        }
    }, [data]);

    return (
        <Grid item xs={12} md={12}>
            <Accordion sx={{ m: 0 }} expanded={expanded} onChange={handleChangeExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Одержувачі та платники</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={matches ? 1.3 : 1.1}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizadConsignee props={{ ...idEekkSettings, ...commonAutocompleteSettings, label: 'Одержувач в Україні (ЄЕКК)', id: "poluch_ukr" }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField id="poluch_vnesh" name="poluch_vnesh" {..._registerPerevez("poluch_vnesh")} label="Одержувач за кордоном" variant="outlined" size={'small'} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizad props={{ ...poluchUkrSettings, ...commonAutocompleteSettings, id: 'eksped_ukr', label: 'Експедитор України' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <AutocompleteVirtualizad props={{ ...poluchUkrSettings, ...commonAutocompleteSettings, id: 'poluch_port', label: 'Одержувач в порту' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField id="code_perevizn" name="code_perevizn" {..._registerPerevez("code_perevizn")} label="Перевізник" variant="outlined" size={'small'} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default PrivateTractionComponent;
