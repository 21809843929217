import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button, Collapse, Divider, IconButton, LinearProgress, Menu, MenuItem, Paper, Table,
  TableBody,
  TableCell, TableContainer, TableHead,
  TablePagination,
  TableRow, Tooltip
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
//
import { headCellsDayBalance, headCellsTotalDays } from '../../../../../common/constants/headerDayBalance';
import { headCells } from '../../../../../common/constants/headerElz';
import { headCellsElzHistory } from '../../../../../common/constants/headersHistory';
import compare from '../../../../../common/functions/comapre';
import contains from '../../../../../common/functions/customFilter';
import { CONFIG_MESPLAN as config } from '../../../../../config';
import { getUserSession } from '../../../../../utils/jwt';
import InfoModal from '../commonComponents/InfoModalComponent';
import ElzCalendar from './ElzCalendar';
import { RowColor } from './utils/colorsElz';
import { defaultStorage } from './utils/defaultStorage';
import searchData from './utils/search';
// import { headCellsGetScheduledTransportation } from 'src/common/constants/headersAnaliticInfo';

function CellKlient(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'klient_code' && headCell.show === true) ?
      <Tooltip title={`${row?.klient_code} ${row?.klient_name}`} followCursor>
        <TableCell key={`klient${row.idf_obj + row.klient + row.klient_code}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
          {row?.klient_name} <Divider /> {row?.klient_code} <Divider /> {`${row?.dor_otpr_name}/${row?.dor_otpr}`}
        </TableCell>
      </Tooltip> : null)
}

function CellElevator(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'elevator' && headCell.show === true) ?
      <TableCell key={`elevator${row.idf_obj + row.klient + row.elevator}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
        {`${row?.elevator}/${row?.elevator_name}`}
      </TableCell> : null)
}

function CellEsrOtpr(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'esr_otpr' && headCell.show === true ?
      <TableCell key={`esr_otpr_name${row.idf_obj + row.klient + row.esr_otpr}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
        {row?.esr_otpr_name} <Divider /> {row?.esr_otpr}
      </TableCell> : null)
  )
}

function CellEtsng(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'etsng' && headCell.show === true ?
      <Tooltip title={`${row?.etsng} ${row?.etsng_name}`} followCursor>
        <TableCell key={`etsng_name${row.idf_obj + row.klient + row.etsng}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
          {row?.etsng_name} <Divider /> {row?.etsng}
        </TableCell>
      </Tooltip> : null)
  )
}

function CellEsrPrib(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'esr_prib' && headCell.show === true ?
      <TableCell key={`esr_prib_name${row.idf_obj + row.klient + row.esr_prib}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
        {row?.esr_prib_name} <Divider /> {row?.esr_prib}
      </TableCell> : null)
  )
}

function CellPoluch(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'poluch_code' && headCell.show === true ?
      <Tooltip title={`${row?.poluch_code} ${row?.poluch_name}`} followCursor>
        <TableCell key={`poluch_name${row.idf_obj + row.klient + row.poluch_code}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
          {row?.poluch_name} <Divider /> {row?.poluch_code}
        </TableCell>
      </Tooltip> : null)
  )
}

function CellOznVl(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'ozn_vl_name' && headCell.show === true ?
      <TableCell key={`ozn_vl_name${row.idf_obj + row.klient + row.ozn_vl_name}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
        {row?.ozn_vl_name}
      </TableCell> : null)
  )
}

function CellIdVl(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'id_vl' && headCell.show === true ?
      <TableCell key={`id_vl_name${row.idf_obj + row.klient + row.id_vl}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
        {row?.id_vl_name} <Divider /> {row?.id_vl}
      </TableCell> : null)
  )
}

function CellRodVag(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'rod_vag' && headCell.show === true ?
      <TableCell key={`rod_vag_name${row.idf_obj + row.klient + row.rod_vag}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
        {row?.rod_vag_name} <Divider /> {row?.rod_vag}
      </TableCell> : null)
  )
}

function CellSoCount(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'so_count' && headCell.show === true ?
      <TableCell key={`so_count${row.idf_obj + row.klient + row.so_count}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', }} onClick={(event) => { _handleClick(event, row); }}>
        {row?.so_count}
      </TableCell> : null)
  )
}

function CellElz(props) {
  const { row, headCell, _handleClick } = props;
  return (
    (headCell.id === 'elz' && headCell.show === true ?
      <TableCell key={`elz${row.idf_obj + row.klient + row.so_count}`} sx={{ maxWidth: headCell.width, whiteSpace: 'nowrap', overflow: 'hidden', p: 0, m: 0 }} onClick={(event) => { _handleClick(event, row); }}>
        {`${row?.plan_so_all}/${row?.plan_za_all}/*${row.plan_na_all}`}<Divider />{`${row?.lot_so_all}/${row?.lot_za_all}/*${row?.lot_na_all}`}
      </TableCell> : null)
  )
}

function Row(props) {
  const { row, headCellsReestr, _handleClick } = props;
  const [open, setOpen] = useState(false);
  const [isOnRow, setIsOnRow] = useState(false);
  const [isOnCalend, setIsOnCalend] = useState(false);
  const rowRef = useRef();
  const calendRef = useRef();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isOnRow && !open) {
      setOpen(true);
    }
    if (event.key === 'Enter' && isOnRow && open) {
      setOpen(false);
    }
  }

  const handleKeyEsc = (event) => {
    if (event.key === 'Escape' && isOnCalend) {
      setOpen(false);
    }
  }

  useEffect(() => {
    if (isOnRow) {
      rowRef.current.focus();
    }
  }, [isOnRow])

  useEffect(() => {
    if (isOnCalend) {
      calendRef.current.focus();
    }
  }, [isOnCalend])

  return (
    <>
      <TableRow
        ref={rowRef}
        onMouseEnter={() => setIsOnRow(true)}
        onMouseLeave={() => setIsOnRow(false)}
        onKeyDown={handleKeyPress}
        tabIndex={-1}
        key={`headRow${row.idf_obj + row.klient + row.esr_otpr}`}
        sx={{
          '& > *': { borderBottom: 'unset' },
          backgroundColor: RowColor(row)?.light,
          '&:hover': {
            background: RowColor(row)?.primary,
          },
          margin: 0,
          padding: 0,
        }}
      >
        <TableCell
          key={`arrow${row.idf_obj + row.klient + row.esr_otpr}`}
          sx={{
            margin: 0,
            padding: 0
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {headCellsReestr?.map(headCell => (
          <>
            <CellKlient
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellElevator
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellEsrOtpr
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellEtsng
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellEsrPrib
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellPoluch
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellOznVl
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellIdVl
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellRodVag
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellSoCount
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

            <CellElz
              headCell={headCell}
              row={row}
              _handleClick={_handleClick}
            />

          </>

        ))}

      </TableRow >
      <TableRow>
        <TableCell
          key={`Calendar${row.idf_obj + row.klient + row.etsng + row.esr_otpr}`} sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCellsReestr.length + 1}
        // onMouseEnter={() => setIsOnCalend(true)}
        // onMouseLeave={() => setIsOnCalend(false)}
        // onKeyDown={handleKeyEsc}
        // ref={calendRef}
        // tabIndex={-1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ElzCalendar
              row={row}
              setIsOnCalend={setIsOnCalend}
              handleKeyEsc={handleKeyEsc}
              calendRef={calendRef}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const RowElzListResults = () => {
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [dense, setDense] = React.useState(true);
  const [reestr, setReestr] = React.useState([]);
  const dataRedux = useSelector(state => state.elz);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  //
  const [headCellsReestr, setheadCellsReestr] = useState(headCells);
  const reestrHeaderRedux = useSelector(state => state?.elz?.headerOptions);
  const totalDaysRedux = useSelector(state => state?.elz?.totalDays);
  const dispatch = useDispatch();
  const user = JSON.parse(getUserSession());
  const selectedMonth = useSelector(state => state.userMesplan?.month);
  //
  const [openHistory, setOpenHistory] = React.useState(false);
  const [openBalance, setOpenBalance] = React.useState(false);
  const [openTotalDays, setOpenTotalDays] = React.useState(false);
  //
  const openMenu = Boolean(anchorEl);
  const handleClick = (event, row) => {
    if (row.idf_obj) {
      //  setAnchorEl(event.target);
      // setAnchorEl(event.jsEvent?.target);   
      setAnchorEl(event.currentTarget);
      setCurrentRow(row);
    }
  };
  // close menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // open history
  const handleHistory = () => {
    setOpenHistory(true);
  }
  // open balance
  const handleBalance = () => {
    setOpenBalance(true);
  }
  // open total days info
  const handleTotalDays = () => {
    setOpenTotalDays(true);
  }
  // update reestr data if redux change value
  useEffect(() => {
    if (dataRedux.data) { setReestr(dataRedux.data) } else { setReestr([]) };
  }, [dataRedux.data]);

  // filter reestr data
  useEffect(() => {
    if (dataRedux.data) {
      setReestr(dataRedux.data.filter((el) => { return contains(el, dataRedux.searchVal) }))
    } else {
      setReestr([])
    };
    setPage(0);
  }, [dataRedux.searchVal]);

  // hook call one time or when month changed
  useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem(defaultStorage));
    if (dataRedux.data) {
      setReestr(dataRedux.data)
    }
    else {
      setReestr([])
    };
    // find data if cached search values and plan is empty or month changed
    if (cachedData && (!reestr || !dataRedux.data || cachedData.month !== selectedMonth)) {
      searchData(cachedData, dispatch, user?.sub, null, null, 1, selectedMonth);
    }
    // find last search data in bd and search values
    else if (!dataRedux.data) {
      axios({
        method: 'get',
        url: `${config.nsiUrl}/api/v1/DefaultValues/GetValues?keycloakGuid=${user?.sub}&sKey=${defaultStorage}`,
      }).then((response) => {
        // and find reestr data
        searchData(response.data, dispatch, user?.sub, null, null, 1, selectedMonth);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [selectedMonth]);

  // use this hook to update order and visibility
  React.useEffect(() => {
    if (reestrHeaderRedux) {
      const temp = [];
      for (let i = 0; i < headCellsReestr.length; i += 1) {
        const row = { ...headCellsReestr[i], ...reestrHeaderRedux[i] };
        temp.push(row);
      }
      setheadCellsReestr(temp.sort(compare));
    }
  }, [reestrHeaderRedux]);


  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * limit - reestr.length) : 0;

  return (
    // <ThemeProvider theme={theme}>
    <Paper sx={{ width: '100%', overflow: 'hidden', margin: 0, padding: 0, }}>
      {(reestr && reestr?.length !== 0) ?
        < Button onClick={handleTotalDays} sx={{ m: 0, p: 0 }} variant="text">Сумарні подобові дані ЕЛЗ</Button>
        : null}
      <TableContainer sx={{ maxHeight: 560, margin: 0, padding: 0, }}>
        {!dataRedux.loading ? <>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader
          >
            <TableHead>
              <TableRow key={"fuckingRow"} >
                <TableCell key={"fuckingCell"} />
                {headCellsReestr?.map(headCell => (
                  headCell?.show ? <TableCell key={headCell.label} style={{ minWidth: headCell.width }}>{headCell.label}</TableCell> : null
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reestr && reestr !== [] ? reestr.slice(page * limit, page * limit + limit).map((row) => (
                <Row
                  row={row}
                  headCellsReestr={headCellsReestr}
                  _handleClick={handleClick}
                />
              )) : <> </>}
            </TableBody>

          </Table>
        </> : <> <LinearProgress /> </>}
      </TableContainer>

      <TablePagination
        component="div"
        sx={{ marginTop: 0, paddingTop: 0 }}
        count={reestr.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        labelRowsPerPage={<span>Рядків відображено:</span>}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 150, 200, 250, 300]}
      />
      <ReactTooltip multiline />

      <Menu
        id="modal-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'modal-button'
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {currentRow?.idf_obj ? <MenuItem onClick={handleHistory}>Історія роботи з заявкою</MenuItem> : null}
        <MenuItem onClick={handleBalance}>Залишок на поточну добу</MenuItem>
      </Menu>

      < InfoModal props={{
        open: openHistory,
        setOpen: setOpenHistory,
        _data: { planIdf: currentRow?.idf_obj, month: selectedMonth },
        _handleMenuClose: handleMenuClose,
        path: 'Elz/elzHistory',
        tableCell: headCellsElzHistory,
        title: 'Історія роботи'
      }}
      />

      < InfoModal props={{
        open: openTotalDays,
        setOpen: setOpenTotalDays,
        _data: null,
        _showData: totalDaysRedux,
        _handleMenuClose: handleMenuClose,
        path: null,
        tableCell: headCellsTotalDays,
        title: 'Сумарні подобові дані ЕЛЗ',
        smallTable: true
      }}
      />

      < InfoModal props={{
        open: openBalance,
        setOpen: setOpenBalance,
        _data: {
          k_rach: currentRow?.k_rach,
          exp_type: currentRow?.exp_type,
          klient: currentRow?.klient,
          elevator: currentRow?.elevator,
          etsng: currentRow?.etsng,
          esr_otpr: currentRow?.esr_otpr,
          pr_marshrut: currentRow?.pr_marshrut,
          id_eekk: currentRow?.poluch,
          esr_prib: currentRow?.esr_prib,
          ozn_vl: currentRow?.ozn_vl,
          pr_spv: '',
          rod_vag_spv: currentRow?.rod_vag,
          tip_vag: currentRow?.tip_vag
        },
        _handleMenuClose: handleMenuClose,
        path: 'Elz/currentDayBalance',
        tableCell: headCellsDayBalance,
        title: 'Залишок на поточну добу'
      }}
      />
    </Paper >
    // </ThemeProvider>
  );
};

export default RowElzListResults;
