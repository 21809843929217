import React, { useEffect, useState } from "react";
import {
    Grid,
    FormControl,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CalculateIcon from '@mui/icons-material/Calculate';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ukLocale from 'date-fns/locale/uk';
import { useForm, Controller, useFieldArray, FormProvider } from "react-hook-form";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { headCellsSumVag } from '../../../../../../common/constants/headerSumVag';
import InfoModal from '../../commonComponents/InfoModalComponent';
import VidPlanSelect from '../../../../../../common/components/select/VidPlan';
import {
    elevatorSettings,
    nomenklatSettings,
    esrOtprSettings,
    ozvVlSettings,
    idEekkSettings,
    stranOtprSettings
} from '../../../../../../common/settings/autocompleteSettings';
import AutocompleteVirtualizad from '../../../../../../common/components/AutocompleteVirtualizad';
import AutocompleteVirtualizadConsignee from '../../../../../../common/components/AutocompleteVirtualizadConsignee';
import { headCellsEditApplication } from '../../../../../../common/constants/headerReestr';
import Perevez from './Perevez';
import setDefault from "./utils/setDefaultsHead";
// import axios from '../../../../utils/axios';
import { CONFIG_MESPLAN as config } from '../../../../../../config';

const HeadComponent = ({ data, handleClose }) => {
    // const { control: _control, register: _register, reset: _reset, setValue: _setValue, watch: _watch, formState: { errors } } = useForm();
    const methods = useForm();
    const _control = methods.control;
    const _register = methods.register;
    const _reset = methods.reset;
    const _setValue = methods.setValue;
    const _watch = methods.watch;
    const { fields: fieldsPerevez, append: appendPerevez, remove: removePerevez } = useFieldArray({ control: _control, name: 'perevezArr' });
    const [showPerevez, setShowPerevez] = useState(false);
    const [indexPerevez, setIndexPerevez] = useState(null);
    const showPerevezComponent = (id) => {
        setShowPerevez(true)
        setIndexPerevez(id);
    };
    const hidePerevezComponent = () => setShowPerevez(false);
    const [sumInfo, setSumInfo] = useState([]);
    const [openSumInfo, setOpenSumInfo] = React.useState(false);
    // open total days info
    const handleOpenSumInfo = () => {
        setOpenSumInfo(true);
    }

    const matches = useMediaQuery('(min-width:720px)');
    const commonAutocompleteSettings =
    {
        control: _control,
        register: _register,
        setValue: _setValue,
        watch: _watch,
        multiple: false,
        size: 'small',
    }

    useEffect(() => {
        if (data) {
            _reset();
            setDefault(data, _setValue);
            //
            let zaCountSum = 0;
            let soCountSum = 0;
            let krCountSum = 0;
            let zaVesSum = 0;
            let soVesSum = 0;
            let krVesSum = 0;
            //
            data?.perevez?.forEach((parevezItem) => {
                appendPerevez(parevezItem);
                zaCountSum += parevezItem?.za_count !== '' ? parseInt(parevezItem?.za_count, 10) : 0;
                soCountSum += parevezItem?.so_count !== '' ? parseInt(parevezItem?.so_count, 10) : 0;
                krCountSum += parevezItem?.kr !== '' ? parseInt(parevezItem?.kr_count, 10) : 0;
                zaVesSum += parevezItem?.za_ves !== '' ? parseInt(parevezItem?.za_ves, 10) : 0;
                soVesSum += parevezItem?.so_ves !== '' ? parseInt(parevezItem?.so_ves, 10) : 0;
                krVesSum += parevezItem?.kr_ves !== '' ? parseInt(parevezItem?.kr_ves, 10) : 0;
            });

            setSumInfo([
                {
                    type: 'Заявлені',
                    vags: zaCountSum,
                    tons: zaVesSum
                },
                {
                    type: 'Узгоджені',
                    vags: soCountSum,
                    tons: soVesSum
                },
                {
                    type: 'Кореговані',
                    vags: krCountSum,
                    tons: krVesSum
                }
            ]);
        }
    }, [data]);

    // handle delete 
    const handleDeletePerevez = (id) => {
        removePerevez(id);
    };

    const handleCopyPerevez = (id) => {
        appendPerevez(fieldsPerevez[id]);
    };

    const handleAppendPerevez = () => {
        appendPerevez({
            id_vl: '',
            id_vl_name: '',
            idf_volume: '',
            kr_count: '',
            por_index: '',
            rod_vag: '',
            rod_vag_name: '',
            so_count: '',
            so_ves: '',
            tip_vag: '',
            za_count: '',
            za_ves: '',
        });
    }

    const selectedMonth = useSelector(state => state.userMesplan?.month);
    // form submit handler
    const onSubmit = dataReestr => {
        const _perevez = {
            "perevezList": []
        }

        dataReestr.perevezArr.forEach(el => {
            const tempPerevez = {
                "UID": el?.idf_obj_perevez,
                "por_index": el?.por_index,
                "link_plan": data?.idf_plan,
                "type_perevez": el?.type_perevez,
                "date_pogr": el?.date_pogr,
                "etsng": el?.etsng,
                "esr_prib": el?.esr_prib,
                "esr_prib_pr": el?.esr_prib_pr,
                "esr_sd": el?.esr_sd,
                "esr_sd_pr": el?.esr_sd_pr,
                "smesh_pr": el?.smesh_pr,
                "stran_prib": el?.stran_prib,
                "poluch_ukr": el?.poluch_ukr,
                "eksped_ukr": el?.eksped_ukr,
                "idf_perevez": el?.idf_perevez,
                "form_izm_pr": el?.form_izm_pr,
                "soglas_code": el?.soglas_code,
                "rule_code": el?.rule_code,
                "org_rule": el?.org_rule,
                "idf_soglas": el?.idf_soglas,
                "pr_el_zayvki": el?.pr_el_zayvki,
                "idf_volume": el?.idf_volume,
                "za_ves": el?.za_ves,
                "so_ves": el?.so_ves,
                "za_count": el?.za_count,
                "so_count": el?.so_count,
                "kr_count": el?.kr_count
            }
            _perevez.perevezList.push(tempPerevez);
        });

        const plan = {
            "UID": data?.idf_plan,
            "idf_tid": data?.idf_tid,
            "month_plan": selectedMonth,
            "number_plan": dataReestr?.plan_number,
            "number_sng": data?.number_sng,
            "type_plan": data?.type_plan,
            "dor_otpr": data?.dor_otpr,
            "nomenklat": dataReestr?.nomenklat?.value,
            "vid_plan": dataReestr?.vid_plan,
            "start_plan": dataReestr?.startPlan?._i,
            "klient": dataReestr?.klient?.value,
            "esr_otpr": dataReestr?.esr_otpr?.value,
            "stran_otpr": dataReestr?.strn_otpr?.value,
            "ozn_vl": data?.ozn_vl,
            "soglas_code": data?.soglas_code,
            "za_ves": data?.za_ves,
            "so_ves": data?.so_ves,
            "za_count": data?.za_count,
            "so_count": data?.so_count,
            "dsig_flag": '0',
            "perevez": _perevez
        }

        const request = {
            "month_plan": selectedMonth,
            "plan": plan
        }

        axios.post(`${config.apiUrl}/api/v1/handler/message1133Application`, request)
            .then((response) => {
                alert("ok");
            })
            .catch((error) => {
                alert(error);
            });

    };

    return (
        <>
            {!showPerevez ?
                <>
                    <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Grid container spacing={matches ? 1.5 : 1}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6" component="h2">
                                        Редагування заявки {data?.number_sng}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <TextField id="plan_number" {..._register("plan_number")} label="Номер плану" variant="outlined" size={'small'} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <VidPlanSelect control={_control} size={'small'} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={_control}
                                            name="startPlan"
                                            render={({ field }) => (
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ukLocale}>
                                                    <Controller
                                                        name="startPlan"
                                                        control={_control}
                                                        render={({ field: { ref, ...rest } }) => (
                                                            <DatePicker
                                                                id="startPlan"
                                                                label="Дата"
                                                                mask='__.__.____'
                                                                views={['day']}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                }}
                                                                renderInput={(params) => <TextField size={'small'} {...params} />}
                                                                {...rest}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                {/*  */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...esrOtprSettings, ...commonAutocompleteSettings }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...stranOtprSettings, ...commonAutocompleteSettings }} />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        {data?.stran_otpr_name}
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizadConsignee props={{ ...idEekkSettings, ...commonAutocompleteSettings, id: 'klient' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...elevatorSettings, ...commonAutocompleteSettings }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...nomenklatSettings, ...commonAutocompleteSettings }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <AutocompleteVirtualizad props={{ ...ozvVlSettings, ...commonAutocompleteSettings }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField id="plan_info" name="plan_info" {..._register("plan_info")} label="Приматка заявки" variant="outlined" size={'small'} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField id="elz_info" name="elz_info" {..._register("elz_info")} label="Інформація про ЕЦП" variant="outlined" size={'small'} />
                                    </FormControl>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Typography variant="h6" component="h2">
                                        Призначення - {fieldsPerevez ? fieldsPerevez?.length : 0}
                                    </Typography>
                                    <Button variant="text" startIcon={<AddIcon />} aria-label="add" onClick={() => handleAppendPerevez()}>
                                        Додати призначення
                                    </Button>
                                    <Button variant="text" startIcon={<CalculateIcon />} aria-label="all" onClick={() => handleOpenSumInfo()}>
                                        Сумарна інформація
                                    </Button>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="EditApplicationTable">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell key={'edit'} />
                                                    <TableCell key={'copy'} />
                                                    <TableCell key={'delete'} />
                                                    {headCellsEditApplication?.map((headCell) => (
                                                        <TableCell key={headCell.id} style={{ minWidth: headCell.width }}>{headCell.label}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {fieldsPerevez?.map((row, index) => (
                                                    <TableRow
                                                        hover
                                                        key={`TableRow${row?.idf_perevez}${index}${Math.random()}`}
                                                        style={{
                                                            height: 33,
                                                        }}
                                                    >
                                                        <TableCell key={`edit${row?.idf_perevez}${index}${Math.random()}`} onClick={() => showPerevezComponent(index)} sx={{ pr: matches ? 1 : 0.8, pl: matches ? 1 : 0.8, m: 0, pt: matches ? 1 : 0.8, pb: matches ? 1 : 0.8 }}>
                                                            <IconButton aria-label="edit">
                                                                <EditIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell key={`copy${row?.idf_perevez}${index}${Math.random()}`} sx={{ pr: matches ? 1 : 0.8, pl: matches ? 1 : 0.8, m: 0, pt: matches ? 1 : 0.8, pb: matches ? 1 : 0.8 }}>
                                                            <IconButton aria-label="copy" onClick={() => handleCopyPerevez(index)}>
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell key={`delete${row?.idf_perevez}${index}${Math.random()}`} sx={{ pr: matches ? 1 : 0.8, pl: matches ? 1 : 0.8, m: 0, pt: matches ? 1 : 0.8, pb: matches ? 1 : 0.8 }}>
                                                            <IconButton aria-label="delete" onClick={() => handleDeletePerevez(index)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        {headCellsEditApplication?.map(headCell => (
                                                            <TableCell key={`${headCell.id}${Math.random()}`} sx={{ pr: matches ? 1 : 0.8, pl: matches ? 1 : 0.8, m: 0, pt: matches ? 1 : 0.8, pb: matches ? 1 : 0.8 }}> {row ? row[headCell.id] : null} </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="contained" type="primary" fullWidth size={matches ? '' : 'small'}>Зберегти</Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button onClick={handleClose} variant="contained" color="text" fullWidth size={matches ? '' : 'small'}>Закрити</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                    < InfoModal props={{
                        open: openSumInfo,
                        setOpen: setOpenSumInfo,
                        _data: null,
                        _showData: sumInfo,
                        _handleMenuClose: null,
                        path: null,
                        tableCell: headCellsSumVag,
                        title: 'Сумарна інформація',
                        smallTable: true
                    }}
                    />
                </>
                : <Perevez
                    handleClose={hidePerevezComponent}
                    indexPerevez={indexPerevez}
                    idfPlamn={data?.idf_}
                    _watch={_watch}
                />}
        </>
    );
}

export default HeadComponent;
