export const headCellsGetShippers = [
    { field: 'KLIENT_ID', headerName: 'ID',  width: 130, editable: false,  },
    { field: 'NAME', headerName: 'Назва', width: 300 },
    { field: 'CODE', headerName: 'Код(4зн)', width: 120  },
    { field: 'KOD_PL', headerName: 'Код платн.', width: 150  },
    { field: 'KOD_EDRPOU', headerName: 'Код ЕДРПОУ',  width: 120  },
    { field: 'ESR', headerName: 'Станція',  width: 100  },
    { field: 'KLI_OTD', headerName: 'ДН', width: 100  },
    { field: 'N_DORS', headerName: 'Зал.', width: 100  },
    { field: 'ACTORS_FLAGS', headerName: 'Ознака клієнта', width: 200  },
    { field: 'BRANCH_CODE', headerName: 'Код філії', width: 100  },
  ];

  export const headCellsGetConsignees = [
    { field: 'KLIENT_ID', headerName: 'ID',  width: 150  },
    { field: 'NAME', headerName: 'Назва', width: 300 },
    { field: 'CODE', headerName: 'Код(4зн)', width: 100  },
    { field: 'KOD_EDRPOU', headerName: 'Код ЕДРПОУ', width: 150  },
    { field: 'KLI_ZAL', headerName: 'Зал.(код)',  width: 120  },
    { field: 'ACTORS_FLAGS', headerName: 'Ознака клієнта',  width: 150  },
    { field: 'BRANCH_CODE', headerName: 'Код філії', width: 100  },
  ];

  export const headCellsGetStationNazn = [
    { field: 'CODE', headerName: 'Код',  width: 100  },
    { field: 'NAME', headerName: 'Назва', width: 250 },
    { field: 'NAME_DIV', headerName: 'Зал.', width: 100  },
    { field: 'STRAN_ESR', headerName: 'Держава принал.(код)', width: 220  },
    { field: 'STRAN_NAZN', headerName: 'Держава признач.(код)',  width: 220  },
    { field: 'STRAN_NAZN_NAME', headerName: 'Держава признач.',  width: 250  },
    { field: 'KOD_ADM', headerName: 'Адм.(код)', width: 100  },
    { field: 'TYPE_STYK', headerName: 'Тип стика', width: 120  },
  ];

  export const headCellsGetZD = [
    { field: 'CODE', headerName: 'Код',  width: 150  },
    { field: 'NAME', headerName: 'Назва', width: 600 },
  ];

  export const headCellsGetCountry = [
    { field: 'CODE', headerName: 'Код',  width: 150  },
    { field: 'NAME', headerName: 'Назва', width: 600 },
  ];

  export const headCellsGetAdm = [
    { field: 'CODE', headerName: 'Код',  width: 150  },
    { field: 'NAME', headerName: 'Назва', width: 600 },
  ];

  export const headCellsGetNomenklat = [
    { field: 'CODE', headerName: 'Код',  width: 150  },
    { field: 'NAME', headerName: 'Назва', width: 600 },
  ];

  export const headCellsGetETSNV = [
    { field: 'CODE', headerName: 'Код',  width: 100  },
    { field: 'NAME', headerName: 'Назва', width: 220 },
    { field: 'NOMEN_KOD', headerName: 'Номен(код)',  width: 100  },
    { field: 'NOMEN_NAME', headerName: 'Номен', width: 320 },
  ];

  export const headCellsGetVagRod = [
    { field: 'ROD_VAG', headerName: 'Код',  width: 100  },
    { field: 'MNKOD_ROD_VAG', headerName: 'Назва', width: 150 },
    { field: 'NAIM_ROD_VAG', headerName: 'Повна назва',  width: 550  },
  ];

  export const headCellsGetSobstnvVag = [
    { field: 'CODE', headerName: 'ID',  width: 100  },
    { field: 'NAME', headerName: 'Назва', width: 350 },
    { field: 'KOD_PREDP', headerName: 'Код пiдприємства',  width: 200  },
  ];

  export const headCellsGetPort = [
    { field: 'PORT_ID', headerName: 'ID',  width: 150  },
    { field: 'NAME', headerName: 'Назва', width: 350 },
    { field: 'KOD_ESR', headerName: 'Станція(код)',  width: 100  },
  ];

  export const headCellsGetPoluchatelPort = [
    { field: 'POL_ID', headerName: 'ID',  width: 170  },
    { field: 'NAME', headerName: 'Назва', width: 350 },
    { field: 'PORT_ID', headerName: 'ID порта',  width: 170  },
  ];

  export const headCellsGetElevator = [
    { field: 'CODE', headerName: 'Код',  width: 100  },
    { field: 'NAME', headerName: 'Назва', width: 370 },
    { field: 'ESR_CODE', headerName: 'Код станції',  width: 120  },
    { field: 'ESR_NAME', headerName: 'Назва', width: 200 },
  ];

  export const headCellsGetNZagr = [
    { field: 'CODE', headerName: 'ID',  width: 100  },
    { field: 'NAME', headerName: 'Назва', width: 500 },
  ];

  export const headCellsGetEkspedUkr = [
    { field: 'ID_KLIENT', headerName: 'ID',  width: 100  },
    { field: 'NAME', headerName: 'Назва', width: 350 },
    { field: 'KODPL', headerName: 'Код платника',  width: 150  },
    { field: 'EDRPOU', headerName: 'Код ЕДРПОУ', width: 120 },
  ];

  export const headCellsGetEkspedFormir = [
    { field: 'KOD', headerName: 'ID',  width: 200  },
    { field: 'NAME', headerName: 'Назва', width: 300 },
    { field: 'K_ADM', headerName: 'Код адмiнiстрацiї-сусiдки',  width: 400  },
  ];

  export const headCellsGetPerevizn = [
    { field: 'CODE', headerName: 'Код перевізника',  width: 100  },
    { field: 'NAME_FULL', headerName: 'Повна назва', width: 550 },
    { field: 'NAME', headerName: 'Коротка назва',  width: 220  },
    { field: 'ADMI', headerName: 'Код адміністрації',  width: 100  },
  ];

  export const headCellsGetTransPlan = [
    { field: 'ID_UPPV_TRANSPLAN', headerName: 'ІД',  width: 70  },
    { field: 'KLIENT', headerName: 'Одержувач', width: 220 },
    { field: 'EDRPOU', headerName: 'ЄДРПОУ',  width: 110  },
    { field: 'ESR', headerName: 'Станція призн. навант. рейсу',  width: 220  },
    { field: 'DATE_PS', headerName: 'Дата постановки судна',  width: 150  },
    { field: 'DTN', headerName: 'Початок дії запису', width: 100 },
    { field: 'DTK', headerName: 'Кінець дії запису',  width: 100  },
    { field: 'DOC', headerName: 'Нормативний документ',  width: 220  },
  ];

  export const headCellsGetPpMesplan = [
    { field: 'KD', headerName: 'Код дороги',  width: 100  },
    { field: 'K_RP', headerName: 'Код станції', width: 100 },
    { field: 'PK_ID', headerName: 'Код ПК',  width: 100  },
    { field: 'PK_NAME', headerName: 'Назва ПК',  width: 250  },
    { field: 'PK_TYPE', headerName: 'Тип ПК',  width: 100  },
    { field: 'PK_VAG_MAX', headerName: 'Переробна спроможність ПК', width: 170 },
    { field: 'CLIENT_ID', headerName: 'Код власника ПК',  width: 170  },
    { field: 'CLIENT_NAME', headerName: 'Назва власника ПК',  width: 250  },
    { field: 'CLIENT_VAG_MAX', headerName: 'Переробна спроможність власника ПК',  width: 250  },
  ];

  export const headCellsGetNomenPEK = [
    { field: 'K_RP', headerName: 'Код станції', width: 150 },
    { field: 'N_RPU', headerName: 'Назва', width: 100 },
    { field: 'PK_ID', headerName: 'Код ПК',  width: 100  },
    { field: 'OPER', headerName: 'Тип операції',  width: 100  },
    { field: 'CONTRACT_TYPE', headerName: 'Тип договору',  width: 100  },
    { field: 'VAG_MAX', headerName: 'Переробна спроможність ПК для маршруту', width: 100 },
    { field: 'VAG_MAX_REAL', headerName: 'Переробна спроможність ПК (реальна)',  width: 100  },
    { field: 'CLIENT_ID', headerName: 'Код власника ПК',  width: 100  },
    { field: 'CLIENT_NAME', headerName: 'Назва власника ПК',  width: 100  },
    { field: 'CARGO', headerName: 'Перелік номенклатур',  width: 100  },
  ];

  export const headCellsGetProzzoroLot = [
    { field: 'NAME', headerName: 'Переможець', width: 270 },
    { field: 'KOD_EDRPOU', headerName: 'ЄДРПОУ', width: 100 },
    { field: 'NUM', headerName: 'Номер EA',  width: 250  },
    { field: 'ID', headerName: 'ІД ЕА',  width: 100  },
    { field: 'RATE', headerName: 'Ставка',  width: 70  },
    { field: 'VAG_ROD', headerName: 'РРС', width: 70 },
    { field: 'VAG_COUNT', headerName: 'К-сть ваг',  width: 100  },
    { field: 'D_BEG', headerName: 'Нав-ння з',  width: 100  },
    { field: 'D_END', headerName: 'по  ',  width: 70  },
    { field: 'POLIGON_OTPR_TYPE', headerName: 'Озн нав-ння',  width: 100  },
    { field: 'POLIGON_OTPR_NAME', headerName: 'Полігон нав-ння', width: 250 },
    { field: 'POLIGON_PRIB_TYPE', headerName: 'Озн вив-ння', width: 120 },
    { field: 'PROTOCOL_DATE', headerName: 'Дата форм прот',  width: 150  },
    { field: 'ELZ_DATE_TO', headerName: 'Кінцева дата ЕЛЗ',  width: 120  },
    { field: 'ELZ_USED_PR', headerName: 'Озн вик лоту',  width: 100  },
  ];

  export const headCellsGetProzzoroLotDlg = [
    { field: 'NAME', headerName: 'Переможець', width: 270 },
    { field: 'KOD_EDRPOU', headerName: 'ЄДРПОУ', width: 100 },
    { field: 'KLIENT_ID', headerName: 'Ід. користувача', width: 150 },
    { field: 'NUM', headerName: 'Номер EA',  width: 250  },
    { field: 'ID', headerName: 'ІД ЕА',  width: 70  },
    { field: 'VAG_ROD', headerName: 'РРС', width: 70 },
    { field: 'INMONTH', headerName: 'К-сть ваг',  width: 100  },
    { field: 'INMONTHMAX', headerName: 'Нав-ння з',  width: 100  },
    { field: 'POLIGON_OTPR_TYPE', headerName: 'Озн нав-ння',  width: 120  },
    { field: 'POLIGON_OTPR_NAME', headerName: 'Полігон нав-ння', width: 120 },
    { field: 'POLIGON_PRIB_TYPE', headerName: 'Озн вив-ння', width: 120 },
    { field: 'PROTOCOL_DATE', headerName: 'Дата форм прот',  width: 120  },
  ];

  export const headCellsGetMarshrutEsr = [
    { field: 'ESR', headerName: 'Код ЄМР', width: 100 },
    { field: 'N_RPU', headerName: 'Назва', width: 200 },
    { field: 'KD', headerName: 'Код залізниці', width: 100 },
    { field: 'N_DORU', headerName: 'Назва',  width: 200  },
    { field: 'KATEG', headerName: 'Категорія',  width: 100  },
  ];