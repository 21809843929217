export default function createHead(row, selectedMonth) {
    const head = {
        "month": selectedMonth,
        "uid": row?.idf_obj,
        "idfGrup": row?.klient + row?.esr_otpr,
        "esrOtpr": row?.esr_otpr,
        "klient": row?.klient,
        "elevatorK": row?.elevator,
        "poluchUkr": row?.poluch,
        "etsng": row?.etsng,
        "esrPrib": row?.esr_prib,
        "soCount": row?.so_count,
        "soCountK": row?.so_count,
        "naCount": row?.na_count,
        "naCountK": row?.na_count,
        "kRach": row?.k_rach,
        "dorOtpr": row?.dor_otpr,
        "oznVlK": row?.ozn_vl,
        "idVlK": row?.id_vl,
        "idVl": row?.id_vl,
        "rodVagK": row?.rod_vag,
        "tipVagR": row?.tip_vag,
        "prMarshrutK": row?.pr_marshrut,
        "expType": row?.exp_type,
        "prSpv": '',
        "rodVagSpv": ''
    }
    return head;
}