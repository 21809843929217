import React, { useEffect, useState } from 'react';
import {
    Box,
    Modal,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
// import PerfectScrollbar from 'react-perfect-scrollbar';
//
import { modalStyle } from '../../../../../../common/style/modalStyle';
import { CONFIG_MESPLAN as config } from '../../../../../../config';
import Header from './Header';

export default function EditApplicationModal({ setOpen, open, uidPlan }) {
    const [data, setData] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    }
    const selectedMonth = useSelector(state => state.userMesplan?.month);
    // const methods = useForm();

    useEffect(() => {
        if (uidPlan) {
            axios({
                method: 'post',
                url: `${config.nsiUrl}/api/v1/proxyPost`,
                params: { gatewayUrlEnd : `Reestr/editPlan` },
                // url: `${config.apiUrl}/api/v1/Reestr/editPlan`,
                data: {
                    month: selectedMonth,
                    planIdf: uidPlan
                },
                headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(
                (response) => {
                    setData(response.data);
                })
        }
    }, [uidPlan])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-application-title"
            aria-describedby="modal-application-description"
        >
            <Box sx={modalStyle}>
                {/* <FormProvider {...methods} > */}
                    {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
                        {/* <PerfectScrollbar> */}
                            <Header
                                data={data}
                                handleClose={handleClose}
                            />
                        {/* </PerfectScrollbar> */}
                    {/* </form> */}
                {/* </FormProvider> */}
            </Box>
        </Modal >
    );
}