import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Typography, Link } from '@mui/material';
import useProfile from '../hooks/useProfile';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
import { HEADER } from '../config';
// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  color: theme.palette.text.secondary,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));
// ----------------------------------------------------------------------

LogoProfile.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function LogoProfile({ isCollapse }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const { profiles, currentProileInd, currentCabinetInd } = useProfile();

  return (
    <>
    {profiles && (
    <Link underline="none" color="inherit" component={RouterLink} to={'/ekbs/user/profile'}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >

        <Box
          sx={{
            ml: 0,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {profiles[currentProileInd]?.name.length > 27 ? 
              [profiles[currentProileInd]?.name.substring(0,27),'...'].join('') :
              profiles[currentProileInd]?.name}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {profiles[currentProileInd]?.cabinets[currentCabinetInd]?.name.length > 27 ? 
              [profiles[currentProileInd]?.cabinets[currentCabinetInd]?.name.substring(0,27),'...'].join('') :
              profiles[currentProileInd]?.cabinets[currentCabinetInd]?.name }
          </Typography>
        </Box>
      </RootStyle>
    </Link>
    )}
    </>
  );
}
