import { findAction, clearAction, filterAction, headerAction } from "../constants/reestr-action-types";

const setReestr = (reestrObj, isLoading) => {
    return {
        type: findAction,
        reestrData: reestrObj,
        loading: isLoading,
        searchVal: ''
    };
}

const clearReestr = () => {
    return {
        type: clearAction,
        reestrData: [],
        loading: false,
        searchVal: ""  
    };
}

const filterReestr = (_searchVal) => {
    return {
        type: filterAction,      
        searchVal: _searchVal 
    };
}

const setHeaderReestr = (_headerOptions) => {
    return {
        type: headerAction,      
        headerOptions: _headerOptions 
    };
}

export default {
    setReestr,
    clearReestr,
    filterReestr,
    setHeaderReestr
}