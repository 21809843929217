import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// ----------------------------------------------------------------------

const initialState = {
    ganntChartData: null,
    planChartData: null,
    gu12Data: null,
    uzPlanListData: [],
    uzPlanElementData: null
};

const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    setGanntChartData(state, action) {
      state.ganntChartData = action.payload;
    },
    setPlanChartData(state, action) {
      state.planChartData = action.payload;
    },
    setGU12Data(state, action) {
      state.gu12Data = action.payload;
    },
    setUzPlanListData(state, action) {
      state.uzPlanListData = action.payload;
    },
    setUzPlanElementData(state, action) {
      state.uzPlanElementData = action.payload;
    }
  }
});

export const { 
  setGanntChartData, 
  setPlanChartData, 
  setGU12Data,
  setUzPlanListData,
  setUzPlanElementData
} = chartsSlice.actions;

export default chartsSlice.reducer;

export const getGanntChartData = async (id) => {
  await axios.get(`api-vantazh/KeyServices/v2/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setGanntChartData(res.data));
      }
    })
}

export const getPlanChartData = async (idVsp) => {
  await axios.get(`api-vantazh/visual/plan/route?p_idVsp=${idVsp}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setPlanChartData(res.data));
      }
    })
}

export const clearPlanChartData = () => {
  dispatch(setPlanChartData(null));
}

export const getGU12Data = async (id = '33334') => {
  await axios.get(`api-vantazh/api/v1/Kspp/KsppElement?id_element=${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setGU12Data(res.data));
      }
    })
}

export const getUzPlanList = async (idVsp) => {
  await axios.get(`api-vantazh/api/v1/UzPlan/UzPlanList?klient=${idVsp}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setUzPlanListData(res.data));
      }
    })
}

export const getUzPlanElement = async (id) => {
  await axios.get(`api-vantazh/api/v1/UzPlan/UzPlanElement?id_element=${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setUzPlanElementData(res.data));
      }
    })
}

export const clearUzPlanElementData = () => {
  dispatch(setUzPlanElementData(null));
}