export const headCellsPlanHistory = [
    { id: 'NAME_S', numeric: false, disablePadding: false, label: 'Cтатус',  width: 100  },
    { id: 'DATE_OP', numeric: false, disablePadding: false, label: 'Дата редагування', width: 200 },
    { id: 'NAME_ABONENT', numeric: false, disablePadding: false, label: 'Користувач редагування', width: 320  },
    { id: 'IDF_ABONENT', numeric: false, disablePadding: false, label: 'IDF користувача', width: 150  },
    { id: 'ZA_COUNT', numeric: false, disablePadding: false, label: 'ВАГ-З',  width: 100  },
    { id: 'ZA_VES', numeric: false, disablePadding: false, label: 'ТОНИ-З',  width: 100  },
    { id: 'SO_COUNT', numeric: false,  disablePadding: false, label: 'ВАГ-У', width: 100  },
    { id: 'SO_VES', numeric: false, disablePadding: false, label: 'ТОНИ-У', width: 200  },
    { id: 'SOGLAS_CODE', numeric: false, disablePadding: false, label: 'Код узгодження', width: 100  },
  ];

  export const headCellsElzHistory = [
    { id: 'DATE_BEG', numeric: false, disablePadding: false, label: 'Дата редагування', width: 300 },
    { id: 'NAME_ABONENT', numeric: false, disablePadding: false, label: 'Користувач', width: 300  },
    { id: 'IDF_ABONENT', numeric: false, disablePadding: false, label: 'IDF користувача', width: 150  },
    { id: 'DATE_LIST', numeric: false, disablePadding: false, label: 'Дати ел.заявки, які редагувались', width: 400 },
  ];
