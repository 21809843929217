import moment from 'moment';

export default function setDefault(data, _setValue) {
    _setValue("plan_number", data?.number_plan);
    _setValue("vid_plan", data?.vid_plan);
    _setValue("startPlan", moment(data?.start_plan));
    _setValue("esr_otpr", { title: data?.esr_otpr_name, value: data?.esr_otpr });
    _setValue("elevator", { title: data?.elevator_name, value: data?.elevator });
    _setValue("nomenklat", { title: data?.nomenklat_name, value: data?.nomenklat });
    _setValue("ozv_vl", { title: data?.ozn_vl_name, value: data?.ozn_vl });
    _setValue("strn_otpr", { title: data?.stran_otpr_name, value: data?.stran_otpr });
    _setValue("id_eekk", { title: data?.klient_name, value: data?.klient });
  }