import { decrementAction, incrementAction } from "../constants/action-types"

const increment = () => {
    return {
        type: incrementAction
    }
}

const decrement = () => {
    return {
        type: decrementAction
    }
}

export default {
    increment,
    decrement
}