import React, { useEffect } from 'react';
import { useFormContext, Controller } from "react-hook-form";
import {
    Grid,
    FormControl,
    TextField,
    Alert,
    Typography,
    Checkbox,
    Paper,
    FormGroup,
    FormControlLabel,
    Divider,
    RadioGroup,
    Radio,
    FormHelperText
} from '@mui/material';

const stylePaper = {
    p: 1,
};

export default function PlanUzgod(props) {
    const { control, setValue, formState: { errors } } = useFormContext();
    //
    const checkedDay = props._checkedDay;
    const planByDay = props._planByDay;
    //
    useEffect(() => {
        setValue("vagCountPlan", planByDay?.za_count);
    }, []);
    //
    if (planByDay?.soglasElz?.org_code === '40') {
        setValue("planRecipientUzg", true)
    } else {
        setValue("planElevatorUzgod", false);
    }
    //
    return (
        <>
            <Paper variant="outlined" sx={stylePaper}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h5" component="h5">
                        {`Узгодження звичайного плану на ${checkedDay}`}
                    </Typography>
                    <FormGroup row>
                        <FormControlLabel
                            value="start"
                            control={
                                <Controller
                                    name={`planRecipientUzg`}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            checked={field.value}
                                            disabled
                                        />
                                    )}
                                />
                            }
                            label="Отримувачем"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="start"
                            control={
                                <Controller
                                    name={`planElevatorUzgod`}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            checked={field.value}
                                            disabled
                                        />
                                    )}
                                />
                            }
                            label="Елеватором"
                            labelPlacement="end"
                        />
                    </FormGroup>
                    <Divider />
                    <Grid pt={1}>
                        <FormControl row>
                            <Controller
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="uzgodPlan"
                                            {...field}>
                                            <FormControlLabel value="1" control={<Radio />} label="Так" />
                                            <FormControlLabel value="0" control={<Radio />} label="Ні" />
                                        </RadioGroup>
                                        {!!error && (
                                            // <FormHelperText error sx={{ px: 2 }}>
                                            //     Оберіть статус узгодження
                                            // </FormHelperText>
                                            <Alert severity="warning">Оберіть статус узгодження</Alert>
                                        )}</>
                                )}
                                name="uzgodPlan"
                                control={control}
                                // rules={{ required: true }}
                            />
                        </FormControl>
                        {/* {errors?.uzgodPlan && <Alert severity="warning">Оберіть статус узгодження</Alert>} */}
                    </Grid>
                    <Divider />
                    <Grid pt={1}>
                        <Controller
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <TextField label="Кількість вагонів" size={'small'} variant="outlined" {...field} />
                                    {!!error && (
                                        // <FormHelperText error sx={{ px: 2 }}>
                                        //     Введіть кількість узгоджених вагонів
                                        // </FormHelperText>
                                        <Alert severity="warning">Введіть кількість узгоджених вагонів</Alert>
                                    )}
                                </>
                            )}
                            name="vagCountPlan"
                            control={control}
                            // rules={{ required: true }}
                        />
                        {/* {errors?.vagCountPlan && <Alert severity="warning">Введіть кількість узгоджених вагонів</Alert>} */}
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}