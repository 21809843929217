import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import axios from '../../utils/axios'
import i18n from '../../locales/i18n'
import { dispatch } from '../store'

const initialState = {
  isLoading: false,
  servicesPlan: [],
}

const slice = createSlice({
  name: 'provisionServices',
  initialState,
  reducers: {
      setLoading(state, action) {
        state.isLoading = action.payload;
      },
      setServicesPlan(state, action) {
        state.servicesPlan = action.payload;
      }
  }
})

export default slice.reducer

export const {
  setLoading,
  setServicesPlan,
} = slice.actions

export const resetServicesPlan = () => {
  dispatch(setServicesPlan([])) 
}

export const getServicesPlan = async (dateBeg, dateEnd, status) => {
  dispatch(setLoading(true))
  await axios.get(`api-vantazh/Executors/ServicesPlan?dateBeg=${dateBeg}&dateEnd=${dateEnd}&status=${status}`)
      .then(res => {
        if(res.status === 200) {  
          console.log('getServicesPlan', res.data.servicesPlan)
          dispatch(setServicesPlan(res.data.servicesPlan))                             
        }
        else {                       
          throw new Error(res.statusText || res.status)    
        }  
  }) 
  .catch((error) => {
    toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true})
  })
  .finally(() => dispatch(setLoading(false)))
}