import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuItem,
  TableContainer,
  Paper
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { CONFIG_MESPLAN as config } from '../../../../../config';
import RowColorStyle from './utils/colorReestrStyle';
import contains from '../../../../../common/functions/customFilter';
import { headCellsReestr } from '../../../../../common/constants/headerReestr';
import { headCellsPlanHistory } from '../../../../../common/constants/headersHistory';
import { headCellsPlanImplementation } from '../../../../../common/constants/headersPlanImplementation';
import compare from '../../../../../common/functions/comapre';
import searchReestr from './utils/searchReestr';
import { defaultStorage } from './utils/defaultStorage';
import { getUserSession } from '../../../../../utils/jwt';
import HistoryModalElz from '../commonComponents/InfoModalComponent';
import PlanImplementationModal from '../commonComponents/PlanImplementationModal';
import EditApplicationModal from './editModal/EditApplicationModal';

const ReestrListResults = () => {
  const [reestr, setReestr] = useState([]);
  const reestrData = useSelector(state => state.reestr);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [columns, setColumns] = useState(headCellsReestr);
  const reestrHeaderRedux = useSelector(state => state?.reestr?.headerOptions);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  //
  const dispatch = useDispatch();
  //
  const user = JSON.parse(getUserSession());
  const selectedMonth = useSelector(state => state.userMesplan?.month);
  //
  const openMenu = Boolean(anchorEl);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [openImplementation, setOpenImplementation] = React.useState(false);
  const [openEditApplication, setOpenEditApplication] = React.useState(false);

  // row click
  const handleClick = (event, row) => {
    setAnchorEl(event.target);
    setCurrentRow(row);
  };

  // close menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // open history
  const handleHistory = () => {
    setOpenHistory(true);
  }

  // open implementation
  const handleImplementation = () => {
    setOpenImplementation(true);
  }

  // open edit application
  const handleEditApplication = () => {
    setOpenEditApplication(true);
  }

  // navigate to more info page
  const navigate = useNavigate();
  const handleMoreInfo = (row) => {
    navigate('/app/moreInfoEditPlan', {
      // state: { uid_plan: currentUid, scode: currentStatus }
    });
  };

  // update reestr data if redux change value
  useEffect(() => {
    if (reestrData.reestr && reestrData.reestr?.plan) {
      setReestr(reestrData.reestr?.plan)
    }
    else {
      setReestr([]);
    }
  }, [reestrData.reestr]);

  // filter reestr data
  useEffect(() => {
    if (reestrData.reestr && reestrData.reestr?.plan) {
      const filteredData = Array.from(reestrData.reestr?.plan).filter((el) => {
        return contains(el, reestrData.searchVal);
      })
      setReestr(filteredData);
    }
  }, [reestrData.searchVal]);

  // hook call one time or when month changed
  useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem(defaultStorage));
    // find data if cached search values and plan is empty or month changed
    if (cachedData && (!reestrData.reestr?.plan || cachedData.month !== selectedMonth)) {
      searchReestr(cachedData, dispatch, user?.sub, null, null, 1, selectedMonth);
    }
    else if (!reestrData.reestr?.plan) {
      // find last search data in bd and search values
      axios({
        method: 'get',
        url: `${config.nsiUrl}/api/v1/DefaultValues/GetValues?keycloakGuid=${user?.sub}&sKey=${defaultStorage}`,
      }).then((response) => {
        // and find reestr data
        searchReestr(response.data, dispatch, user?.sub, null, null, 1, selectedMonth);
      }).catch((error) => {
        console.log(error);
      });
    }

  }, [selectedMonth]);

  // use this hook to update order and visibility
  React.useEffect(() => {
    if (reestrHeaderRedux) {
      const tempInVisible = reestrHeaderRedux.sort(compare).filter((row) => !row.order).map((row) => row.field);      
      const objInVisible = tempInVisible.reduce((a, v) => ({ ...a, [v]: false }), {});
      setColumnVisibilityModel(objInVisible);
      //
      const tempVisible = reestrHeaderRedux.sort(compare).filter((row) => row.order);
      setColumns(tempVisible);     
    }
  }, [reestrHeaderRedux]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', margin: 0, padding: 0, }}>
      <Menu
        id="modal-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'modal-button'
        }}
      >
        {currentRow?.uid_plan ? <MenuItem onClick={handleHistory}>Історія роботи</MenuItem> : null}
        {currentRow?.uid_plan ? <MenuItem onClick={handleImplementation}>Виконання плану</MenuItem> : null}
        <MenuItem onClick={handleEditApplication}>Редагування заявки</MenuItem>
        {/* <MenuItem onClick={handleMoreInfo}>
          <NavItem
            href="/app/allInfoReestr"
            key="Більше інформації"
            title="Більше інформації"

          />
          {currentStatus === '99' ? 'Корегування заявки' : 'Перегляд заявки'}
        </MenuItem> */}
      </Menu>

      < HistoryModalElz props={{
        open: openHistory,
        setOpen: setOpenHistory,
        _data: { planIdf: currentRow?.uid_plan, month: selectedMonth },
        _perevezIdf: null,
        _handleMenuClose: handleMenuClose,
        path: 'Reestr/inpReestrHistory',
        tableCell: headCellsPlanHistory,
        title: 'Історія роботи'
      }}
      />
      < PlanImplementationModal props={{
        open: openImplementation,
        setOpen: setOpenImplementation,
        _planIdf: currentRow?.uid_plan,
        _perevezIdf: null,
        _handleMenuClose: handleMenuClose,
        path: 'Reestr/inpReestrPlanImplementation',
        tableCell: headCellsPlanImplementation,
      }}
      />
      <EditApplicationModal
        open={openEditApplication}
        setOpen={setOpenEditApplication}
        uidPlan={currentRow?.uid_plan}
      />

      <TableContainer sx={RowColorStyle}>
        <DataGrid
          rows={reestr}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          pageSize={30}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowId={(row) => row.uid_plan}
          loading={reestrData.loading}
          rowHeight={33}
          getRowClassName={(params) => `reestr-row-theme--${params.row.soglas_code}`}
          onRowClick={(params, event) => handleClick(event, params.row)}
        />
      </TableContainer >
    </Paper>
  );
};

export default ReestrListResults;
