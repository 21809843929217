// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/ekbs';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  trackers: '/trackers',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  publications: '/publications',
  singlepublication: '/publications/:id'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    bugs: path(ROOTS_DASHBOARD, '/user/bugs'),
    agreements: path(ROOTS_DASHBOARD, '/user/agreements'),
    partners: path(ROOTS_DASHBOARD, '/user/partner/:id'),
    enterprises: path(ROOTS_DASHBOARD, '/user/enterprises'),
    enterprise: path(ROOTS_DASHBOARD, '/user/enterprise/:id'),
    mypublications: path(ROOTS_DASHBOARD, '/user/mypublications'),
    newpublication: path(ROOTS_DASHBOARD, '/user/mypublications/new'),
    editpublication: path(ROOTS_DASHBOARD, '/user/mypublications/edit/:id'),
    mypublication: path(ROOTS_DASHBOARD, '/user/mypublications/:id'),
    allpublications: path(ROOTS_DASHBOARD, '/user/all-publications'),
    allpublicationsitem: path(ROOTS_DASHBOARD, '/user/all-publications/:id'),
  },
  pidpr: {
    root: path(ROOTS_DASHBOARD, '/pidpr'),
    agreements: path(ROOTS_DASHBOARD, '/pidpr/agreements'),
    partners: path(ROOTS_DASHBOARD, '/pidpr/partner/:id'),
    enterprises: path(ROOTS_DASHBOARD, '/pidpr/enterprises'),
    enterprise: path(ROOTS_DASHBOARD, '/pidpr/enterprise/:id'),
  },
  posts: {
    root: path(ROOTS_DASHBOARD, '/posts'),
    mypublications: path(ROOTS_DASHBOARD, '/posts/mypublications'),
    newpublication: path(ROOTS_DASHBOARD, '/posts/mypublications/new'),
    editpublication: path(ROOTS_DASHBOARD, '/posts/mypublications/edit/:id'),
    mypublication: path(ROOTS_DASHBOARD, '/posts/mypublications/:id'),
    allpublications: path(ROOTS_DASHBOARD, '/posts/all-publications'),
    allpublicationsitem: path(ROOTS_DASHBOARD, '/posts/all-publications/:id'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  eToloka: {
    root: path(ROOTS_DASHBOARD, '/services/e-toloka'),
    main: path(ROOTS_DASHBOARD, '/services/e-toloka/main'),
    shop: path(ROOTS_DASHBOARD, '/services/e-toloka/shop'),
    product: path(ROOTS_DASHBOARD, '/services/e-toloka/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/services/e-toloka/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/services/e-toloka/list'),
    order: path(ROOTS_DASHBOARD, '/services/e-toloka/order'),
    newProduct: path(ROOTS_DASHBOARD, '/services/e-toloka/product/new'),
    editById: path(ROOTS_DASHBOARD, '/services/e-toloka/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/services/e-toloka/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/services/e-toloka/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:idrec'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  mesplan: {
    root: path(ROOTS_DASHBOARD, '/ekbs/mesplan'),
    reestr: path(ROOTS_DASHBOARD, '/ekbs/mesplan/reestr'),
    soglReestr: path(ROOTS_DASHBOARD, '/ekbs/mesplan/soglReestr'),
    elzReestr: path(ROOTS_DASHBOARD, '/ekbs/mesplan/elzReestr'),
    mesplanSettings: path(ROOTS_DASHBOARD, '/ekbs/mesplan/mesplanSettings'),
    getShippers: path(ROOTS_DASHBOARD, '/ekbs/handBook/getShippers'),
    getStationNazn: path(ROOTS_DASHBOARD, '/ekbs/handBook/getStationNazn'),
  }
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   post: path(ROOTS_DASHBOARD, '/blog/post/:idrec'),
  //   postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  //   newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  // }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
