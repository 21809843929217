import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  InputLabel,
  Divider,
  FormControl,
  Grid,
  Select,
  MenuItem
} from '@mui/material';
import React from 'react';
import moment from 'moment';
import 'moment/locale/uk';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../../../redux/actions/index';
import useLocales from '../../../../../hooks/useLocales';

function SettingsMonth() {
  const { translate } = useLocales();
  const [month, setMonth] = React.useState('');
  // const [language, setLanguage] = React.useState("ua");
  const selectedMonth = useSelector(state => state.userMesplan.month);
  const header = translate('mesplan.settings');
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setMonth(event.target.value);
  };

  // const handleChangeLanguage = (event) => {
  //   i18n.changeLanguage(event.target.value);
  //   setLanguage(event.target.value);
  // };

  React.useEffect(() => {
    setMonth(selectedMonth);
  }, []);

  // form submit handler
  const onSubmit = data => {
    // alert(selectedMonth);
    dispatch(allActions.userActions.setMonth(month));
  }

  // month data
  const monthNow = moment().format("MMMM YYYY");
  const monthPast = moment().subtract(1, 'month').format("MMMM YYYY");
  const monthFutureOne = moment().add(1, 'month').format("MMMM YYYY");
  const monthFutureTwo = moment().add(2, 'month').format("MMMM YYYY");
  const monthFutureThree = moment().add(3, 'month').format("MMMM YYYY");
  const monthFutureFour = moment().add(4, 'month').format("MMMM YYYY");
  const monthFutureFive = moment().add(5, 'month').format("MMMM YYYY");
  const monthFutureSix = moment().add(6, 'month').format("MMMM YYYY");
  //
  const monthNowValue = moment().format('YYYYMM');
  const monthPastValue = moment().subtract(1, 'month').format("YYYYMM");
  const monthFutureOneValue = moment().add(1, 'month').format("YYYYMM");
  const monthFutureTwoValue = moment().add(2, 'month').format("YYYYMM");
  const monthFutureThreeValue = moment().add(3, 'month').format("YYYYMM");
  const monthFutureFourValue = moment().add(4, 'month').format("YYYYMM");
  const monthFutureFiveValue = moment().add(5, 'month').format("YYYYMM");
  const monthFutureSixValue = moment().add(6, 'month').format("YYYYMM");
  //
  return (
    <>
      <Card>
        <CardHeader
          title={header}
        />
        <Divider />
        <CardContent>
          <Grid
            container
          >
            <FormControl fullWidth>
              <InputLabel id="month-label"/>
              <Select
                labelId="month-label"
                id="month-select"
                value={month}
                label="Month"
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value={monthPastValue}>       {monthPast}</MenuItem>
                <MenuItem value={monthNowValue}>        {monthNow}</MenuItem>
                <MenuItem value={monthFutureOneValue}>  {monthFutureOne}</MenuItem>
                <MenuItem value={monthFutureTwoValue}>  {monthFutureTwo}</MenuItem>
                <MenuItem value={monthFutureThreeValue}>{monthFutureThree}</MenuItem>
                <MenuItem value={monthFutureFourValue}> {monthFutureFour}</MenuItem>
                <MenuItem value={monthFutureFiveValue}> {monthFutureFive}</MenuItem>
                <MenuItem value={monthFutureSixValue}>  {monthFutureSix}</MenuItem>

              </Select>
            </FormControl>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="primary"
            onClick={() => onSubmit()}
          >
            Зберегти
          </Button>
        </Box>
      </Card>

      {/* <Card sx={{ marginTop: '8px' }}>
        <CardHeader
          title={t("language_choose")}
        />
        <Divider />
        <CardContent>
          <Grid
            container
          >
            <FormControl fullWidth>
              <InputLabel id="language-label">{t("language_choose")}</InputLabel>
              <Select
                labelId="language-label"
                id="language-select"
                value={language}
                label="Мова"
                onChange={handleChangeLanguage}             
              >
                <MenuItem value="ua"> Українська</MenuItem>
                <MenuItem value="ru"> Русский </MenuItem>
                <MenuItem value="en"> English </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </CardContent>
        <Divider />
      </Card> */}
    </>
  )
};

export default SettingsMonth;
