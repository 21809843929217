import axios from 'axios';
import { CONFIG_MESPLAN as config } from '../../../../../../config';
import allActions from '../../../../../../redux/actions/index';

export default function updateElz(searchData, dispatch, elzData, handleClose, defaultMonth) {

    let requestData = null;

    if (searchData.idf_obj !== '') {
        requestData =
        {
            month: defaultMonth,
            elz_id: searchData.idf_obj
        }
    }
    else {
        requestData =
        {
            month: defaultMonth,
            block: 1,
            klient: [
                searchData.klient
            ],
            esr_otpr: [
                searchData.esr_otpr
            ],
            // zal_otpr: searchData.dor_otpr,
            nomenklat: [
                searchData.nomenklat
            ],
            id_eekk: [
                searchData.poluch
            ],
            esr_prib: [
                searchData.esr_prib
            ],
            ozn_vl: searchData.ozn_vl,
        }
    }

    axios({
        method: 'post',
        url: `${config.nsiUrl}/api/v1/proxyPost`,
        params: { gatewayUrlEnd : `Elz/ElzReestrList` },
        // url: `${config.apiUrl}/api/v1/Elz/ElzReestrList`,
        data: requestData,
        headers: { "Access-Control-Allow-Origin": "*" }
    }).then((response) => {
        //
        let index = -1; 
        index = elzData.findIndex(x => (x.idf_obj === response?.data?.elzList[0]?.idf_obj));
        if (index === -1) {
            index = elzData.findIndex(x => (
                x.klient === response?.data?.elzList[0]?.klient &&
                x.elevator === response?.data?.elzList[0]?.elevator &&
                x.esr_otpr === response?.data?.elzList[0]?.esr_otpr &&
                x.nomenklat === response?.data?.elzList[0]?.nomenklat &&
                x.poluch === response?.data?.elzList[0]?.poluch &&
                x.esr_prib === response?.data?.elzList[0]?.esr_prib &&
                x.ozn_vl === response?.data?.elzList[0]?.ozn_vl &&
                x.rod_vag === response?.data?.elzList[0]?.rod_vag &&
                x.tip_vag === response?.data?.elzList[0]?.tip_vag &&
                x.etsng === response?.data?.elzList[0]?.etsng &&
                x.poluch_code === response?.data?.elzList[0]?.poluch_code               
            ));
        }
        //
        if (index !== -1) {
            const dataCopy = elzData;
            dataCopy[index] = response?.data?.elzList[0];
            dispatch(allActions.elzActions.setElz(dataCopy, false));
        }
        if (handleClose) {
            handleClose();
        }
    });
}