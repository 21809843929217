import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { toast } from 'react-toastify';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { loaderEnd, loaderStart } from './loader';
import { showConfirmationModal } from './confirmation';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: { data: [] },
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    quantity: 0,
    dateCheckout: new Date(),
    idPidpr: 0,
    accept: [],
  },
  dicPidprServices: [],
  dicPidprTypeResources: [],
  productsPageFilters: {
    page: 1,
    count: 20,
    search: '',
    dateBeg: '',
    dateEnd: '',
    category: '',
    idResource: '',
    cntItems: '',
    priceMin: '',
    priceMax: '',
    activeStep: '',
  },
  recourceInfo: [],
  nsiSelectData: {},
  // nsiAutocompleteData: {
  //   '3': [
  //     { value: '161005', title: 'УГОЛЬ КАМЕННЫЙ' }
  //   ],
  //   '8': [
  //     { value: '22', title: 'Акционерное общество "Украинская железная дорога"' }
  //   ],
  //   '7': [
  //     { value: '450304', title: 'ДНІПРО-ВАНТАЖНИЙ (450304)' }
  //   ]
  // }
  nsiAutocompleteData: {},
  currencies: [],
};

const slice = createSlice({
  name: 'tolokaProduct',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getPidprServicesSuccess(state, action) {
      state.isLoading = false;
      state.dicPidprServices = action.payload;
    },
    getPidprTypeResourcesSuccess(state, action) {
      state.isLoading = false;
      state.dicPidprTypeResources = action.payload;
    },
    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },

    // marukhno.v

    _setProductsPageFilters: (state, action) => ({
      ...state,
      productsPageFilters: { ...state.mainPageFilters, ...action.payload },
    }),
    setResourceInfo(state, action) {
      state.recourceInfo = action.payload;
    },
    // setNsiSelectData(state, action) {
    //   console.log(action)
    //   // state.nsiData = action.payload
    // },
    setNsiSelectData: (state, action) => ({
      ...state,
      nsiSelectData: {
        ...state.nsiSelectData,
        [action.payload.nameSelect]: action.payload.data,
      },
    }),
    setNsiAutocompleteData: (state, action) => ({
      ...state,
      nsiAutocompleteData: {
        ...state.nsiAutocompleteData,
        [action.payload.nameAUC]: action.payload.data,
      },
    }),
    setCurrencies: (state, action) => ({
      ...state,
      currencies: action.payload,
    }),
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  getProductSuccess,
  _setProductsPageFilters,
  setResourceInfo,
  setNsiSelectData,
  setNsiAutocompleteData,
  setCurrencies,
} = slice.actions;

// ----------------------------------------------------------------------

export const setProductsPageFilters = (params) => {
  dispatch(_setProductsPageFilters(params));
};

const getSearchCondition = (params) => {
  const last = Object.keys(params)[Object.keys(params).length - 1];
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}${key === last ? '' : '&'}`)
    .join()
    .split(',')
    .join('');
};

export const getProducts = async (params) => {
  loaderStart();
  await axios
    .get(`/api-toloka/toloka/products?${getSearchCondition(params)}`)
    .then((res) => {
      if (res.status === 200) {
        setProductsPageFilters(params);
        dispatch(slice.actions.getProductsSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
  loaderEnd();
};

// -----------------------------------------------------
export function getPidprServices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api-toloka/toloka/pidpr/services`);
      // const data = [ d1 ];
      dispatch(slice.actions.getPidprServicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPidprTypeResources() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api-toloka/toloka/pidpr/resources`);
      // const data = [ d1 ];
      dispatch(slice.actions.getPidprTypeResourcesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

// export function getProduct(id) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/api-toloka/toloka/products/${id}`);
//       dispatch(slice.actions.getProductSuccess(response.data));
//     } catch (error) {
//       console.error(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export const getProduct = async (id) => {
  loaderStart();
  await axios
    .get(`/api-toloka/toloka/products/${id}`)
    .then(async (res) => {
      if (res.status === 200) {
        if (res.data.resourceAttrs.length) {
          await res.data.resourceAttrs.forEach(async (r) => {
            if (r.type_attr === 'AUTOCOMPLETE') {
              const existOption = [
                {
                  value: r.value_attr,
                  title: r.name_value,
                },
              ];
              dispatch(setNsiAutocompleteData({ data: existOption, nameAUC: r.name_prop }));
            }
          });
        }
        dispatch(slice.actions.getProductSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
  loaderEnd();
};

export const dropProduct = () => {
  dispatch(slice.actions.getProductSuccess(null));
  dispatch(setNsiSelectData([]));
  dispatch(setResourceInfo([]));
  dispatch(setNsiAutocompleteData({ data: null, nameAUC: null }));
};

// const blobToBase64 = blob => new Promise((resolve, reject) => {
//   const reader = new FileReader();
//   reader.readAsDataURL(blob);
//   reader.onload = () => resolve(reader.result);
//   reader.onerror = error => reject(error);
// });

// // eslint-disable-next-line arrow-body-style
// const convertBlobToBase64 = async (blob) => {
//   // eslint-disable-next-line no-return-await
//   return await blobToBase64(blob);
// }

export const createProduct = async (data, callBack) => {
  loaderStart();
  await axios
    .post(`/api-toloka/toloka/product`, data)
    .then((res) => {
      if (res.status === 200) {
        callBack();
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
  loaderEnd();
};

export const updateProduct = async (data, callBack) => {
  loaderStart();
  await axios
    .put(`/api-toloka/toloka/product`, data)
    .then((res) => {
      if (res.status === 200) {
        callBack();
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
  loaderEnd();
};

export const changeStatus = async (idProduct, status, callBack) => {
  loaderStart();
  await axios
    .put(`/api-toloka/toloka/product/${idProduct}/${status}`)
    .then((res) => {
      if (res.status === 200) {
        callBack();
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
  loaderEnd();
};

// export const deleteProduct = async (id) => {
//   await axios.delete(`/api-toloka/toloka/product/idrec=${id}`)
//   .then(res => {
//     if(res.status === 200) {

//     }
//   })
// }

export const getResourceInfo = async (id) => {
  loaderStart();
  dispatch(setNsiSelectData([]));
  await axios
    .get(`/api-toloka/toloka/product/resources/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setResourceInfo(res.data));
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
  loaderEnd();
};

export const getNsiAutocompleteData = async (select, searchValue) => {
  const { name_table: table, name_field: name, key_field: key, name_prop: nameAUC } = select;
  const searchField = Number(searchValue) ? key : name;
  await axios
    .get(
      `/api-nsi/api/DataNsi/GetAllValues?table=${table}&titleOut=${name}&valueOut=${key}&value=${searchValue}&searchField=${searchField}&all=false&limit=2000`
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(setNsiAutocompleteData({ data: res.data, nameAUC }));
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
};

// export const getNsiData = async (tableName, titleOut, valueOut, searchValue, urlType = 'vagons') => {
//   const url = urlType === 'vantazh' ?
//   `/api-nsi/api/DataNsi/GetAllValues?table=${tableName}&titleOut=${titleOut}&valueOut=${valueOut}&value=${searchValue}&searchField=name&all=false&limit=2000`
//   : `/api-nsi/api/DataNsi/GetAllValues?table=${tableName}&titleOut=${titleOut}&valueOut=${valueOut}&all=true`;
//   await axios.get(url)
//   .then(res => {
//     if(res.status === 200) {
//       dispatch(setNsiData(res.data))
//     }
//   }).catch(error => {
//     dispatch(slice.actions.hasError(error));
//   })
// }

export const getSelectNsiData = async (select) => {
  const { name_table: table, name_field: name, key_field: key, name_prop: nameSelect } = select;
  await axios
    .get(`/api-nsi/api/DataNsi/GetAllValues?table=${table}&titleOut=${name}&valueOut=${key}&all=true`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setNsiSelectData({ data: res.data, nameSelect }));
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
};

export const deleteProduct = async (id, callBack) =>
  showConfirmationModal({
    isOpen: true,
    title: 'Підтвердження видалення',
    content: `Ви дійсно хочете видалити оголошеня?`,
    onSubmit: () => submitDelete(id, callBack),
    onCancel: () => showConfirmationModal({ isOpen: false }),
  });

const submitDelete = async (id, callBack) => {
  await axios.delete(`/api-toloka/toloka/product?idrec=${id}`).then((res) => {
    if (res.status === 200) {
      callBack();
      showConfirmationModal({ isOpen: false });
      toast.success('Оголошення успішно видалено.');
    }
  });
};

export const getCurrencies = async () => {
  await axios
    .get(`/api-nsi/api/DataNsi/GetAllValues?table=E_D_VALUT&titleOut=N_VALU&valueOut=K_VALN&all=true`)
    .then((res) => {
      if (res.status === 200) {
        dispatch(setCurrencies(res.data));
      }
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
    });
};
