import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Enumerable from "linq";

import i18n from '../../locales/i18n';
import { HOST_API } from '../../config';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
    mapOptions: {
        center: null,
        zoom: 6,
        minZoom: 6,
        maxZoom: 19,
        mapTypeId: "OSM",
        overlayLayers: [true, false, false]
    },
    geolocations: [],
    nearGeolocations: [],
    searchLocation: null,
    // searchInputLocation: ""
}

const slice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setFullMapOptions(state, action) {
            const newMapOptions = action.payload;
            state.mapOptions = {
                center: newMapOptions.center ? newMapOptions.center : state.mapOptions.center,
                zoom: newMapOptions.zoom ? newMapOptions.zoom : state.mapOptions.zoom,
                mapTypeId: newMapOptions.mapTypeId,
                overlayLayers: newMapOptions.overlayLayers,
                minZoom: state.mapOptions.minZoom,
                maxZoom: state.mapOptions.maxZoom
            };
        },
    
        setTypeMapOptions(state, action) {
            const newMapOptions = action.payload;
            state.mapOptions = {
                mapTypeId: newMapOptions.mapTypeId,
                overlayLayers: newMapOptions.overlayLayers,
                center: state.mapOptions.center,
                zoom: state.mapOptions.zoom,
                minZoom: state.mapOptions.minZoom,
                maxZoom: state.mapOptions.maxZoom
            };
        },

        setGeolocations(state, action) {
            state.geolocations = action.payload;
            console.log(`geolocations=${state.geolocations.length}`);
        },

        setSearchLocation(state, action) {
            state.searchLocation = action.payload;
            // state.searchInputLocation = state.searchLocation?.title ? state.searchLocation.title : "";
        },

        setNearGeolocations(state, action) {
            const getDistance = (point1, point2) => {
                const R = 6371.0710; // Radius of the Earth in km
                const rlat1 = point1.lat * (Math.PI/180); // Convert degrees to radians
                const rlat2 = point2.lat * (Math.PI/180); // Convert degrees to radians
                const difflat = rlat2-rlat1; // Radian difference (latitudes)
                const difflon = (point2.lng - point1.lng) * (Math.PI/180); // Radian difference (longitudes)
        
                const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2) * Math.sin(difflat/2) +
                        Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon/2) * Math.sin(difflon/2)));
                return d;
                 
            }           
            
            if (window.google) {

                const { data = state.geolocations, center = state.mapOptions.center } = action.payload;
                // console.log(`setNearGeolocations mapCenter=${center ? JSON.stringify(center) : null}`);
                // console.log(`setNearGeolocations geolocations=${state.geolocations.length}`);
                    
                if (center) {
                    // const lat = () => !Number.isNaN(center.lat) ? center.lat : center.lat();
                    // const lng = () => !Number.isNaN(center.lng) ? center.lng : center.lng();                
                    // console.log(`lat=${lat()} lng=${lng()}`);

                    const nearGeolocations = Enumerable.from(data)
                        .where(e => getDistance({lat: e.lat, lng: e.lng}, JSON.parse(JSON.stringify(center))) < 80)
                        .select(e => e).toArray(); 
                    state.nearGeolocations = nearGeolocations;                 
                }             
                // console.log(`state.nearGeolocations=${state.nearGeolocations.length}`);
            }

        },
        
        // setSearchInputLocation(state, action) {
        //     state.searchInputLocation = action.payload;
        // }        
       
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setTypeMapOptions,
    setSearchLocation,
    setSearchInputLocation,
    setNearGeolocations
} = slice.actions;

// Сохранить настройки карты
// если вызвана с параметром isSetCenter = true => запомнить новые координаты центра
// иначе - только доп. слои и текущую подложку
export function setFullMapOptions(map, isSetCenter = true) {
    
    return () => {
        if (map) {

            const newMapOptions = {
                zoom: isSetCenter ? map.getZoom() : null,
                center: isSetCenter ? map.getCenter() : null,
                minZoom: map.minZoom,
                maxZoom: map.maxZoom,
                mapTypeId: map.getMapTypeId(),
                overlayLayers: [map.tracksLayer === true, map.railwayLayer === true, map.zonesLayer === true]

            }                 
            dispatch(slice.actions.setFullMapOptions(newMapOptions));

        }
    }
}

export function getGeolocations(translate) {
    return async() => {

        await axios.get(`${HOST_API}/api-map/api/ControlPoints`)
        // await axios.get(`https://localhost:44313/api/ControlPoints`)
        .then(result => {
            if (result.status === 200) {
            
                if (result.data.length === 0) {
                    toast.error(i18n.t(translate('service.map.layers.carriages.errors.no-points')));
                    dispatch(slice.actions.setGeolocations([])); 
                    dispatch(slice.actions.setNearGeolocations(null)); 
                    return;
                }

                const sortData = Enumerable.from(result.data)
                .where(x => x.nameTypeStation === "СТ" || x.nameTypeStation === "РЗД")
                .orderBy(x => x.stationName)
                .select(x => ({
                    id: x.esr, title: x.displayExpr, 
                    lat: x.lat, lng: x.lon, value: x.esr }))
                .toArray(); 
                
                dispatch(slice.actions.setGeolocations(sortData)); 
                dispatch(slice.actions.setNearGeolocations({data: sortData})); 
            }
        })        
        .catch((error) => {
            toast.error(i18n.t(`${translate('service.map.layers.carriages.errors.bad-status-points')}  
                ${error.title ? error.title : error } 
                ${error.status ? error.status : ''}`)); 
        });   
    }  
}