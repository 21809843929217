import React, { useCallback, useMemo, useEffect } from "react";
import {
    Box,
    Button, LinearProgress,
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography,
    Modal,
    Grid,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { isArray } from "lodash";
import { CONFIG_MESPLAN as config } from '../../../../../config';

const InfoModal = ({ props }) => {
    const { open, setOpen, _data, _showData, _handleMenuClose, path, tableCell, title, smallTable } = props;
    const handleCloseHist = () => {
        setOpen(false);
        if (_handleMenuClose) {
            _handleMenuClose();
        }
    };
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const selectedMonth = useSelector(state => state.userMesplan?.month);
    //
    const matches = useMediaQuery('(min-width:720px)');
    // modal style

    let _width = matches ? '70vw' : '95vw';
    if (smallTable) {
        _width = matches ? '40vw' : '95vw'
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: _width,
        maxHeight: '90vh',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if (_data && open === true) {
            setLoading(true);
            axios({
                method: 'post',
                url: `${config.nsiUrl}/api/v1/proxyPost`,
                params: { gatewayUrlEnd: `${path}` },
                // url: `${config.apiUrl}/api/v1/${path}`,
                // data: {
                //     planIdf: _planIdf,
                //     perevezIdf: _perevezIdf,
                //     month: selectedMonth
                // },
                data: _data,
                headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(
                (response) => {
                    //
                    // console.log('response?.data ', response?.data);
                    if (typeof response?.data === 'string' || response?.data instanceof String)
                        setData(JSON.parse(response?.data));
                    else
                        setData(response?.data);
                    //
                    setLoading(false);
                },
                (error) => {
                    //
                    console.log(error);
                    setLoading(false);
                }
            );
        }

        if (_showData && open === true) {
            if (!isArray(_showData)) {
                setData(JSON.parse(_showData));
            }
            else {
                setData(_showData);
            }
            setLoading(false);
        }
    }, [_data, open]);

    return (
        <Modal
            open={open}
            onClose={handleCloseHist}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {!loading ? <>
                    <Typography id="modal-modal-title" variant="h3" component="h3" sx={{ marginBottom: '8px' }}>
                        {title}
                    </Typography>
                    <form>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={12}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableHead>
                                        <TableRow
                                            key={`TableRowHead`}
                                        >
                                            {tableCell?.map(item => (
                                                <TableCell align="center" key={`TableCellHead${item?.label}`}>{item?.label}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map((row) => (
                                            <TableRow
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 }
                                                }}
                                                key={`TableRow${row[Object.keys(row)[0]]}`}
                                            >
                                                {tableCell?.map(item => (
                                                    <TableCell align="center" key={`TableCell${row[item?.id]}`}>{row[item?.id]}</TableCell>
                                                ))}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button onClick={handleCloseHist} fullWidth color="text" variant="contained">Закрити</Button>
                            </Grid>

                        </Grid>
                    </form>
                </> : <> <LinearProgress /> </>}
            </Box>
        </Modal>
    );
}

export default InfoModal;
