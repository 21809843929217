import { useContext } from 'react';
//
import { ProfileContext } from '../contexts/ProfileContext';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/AwsCognitoContext';

// ----------------------------------------------------------------------

const useProfile = () => {
  const context = useContext(ProfileContext);

  if (!context) throw new Error('Profile context must be use inside ProfileProvider');

  return context;
};

export default useProfile;
