import numeral from 'numeral';

// ----------------------------------------------------------------------

const currenciesIcons = {
  756: '₣',
  840: '$',
  978: '€',
  980: '₴',
  985: 'zł',
};

export function fCurrency(number) {
  return `${numeral(number).format(Number.isInteger(number) ? '00' : '00.00')} грн.`;
}
export function fCustomCurrency(number, code, title) {
  return `${numeral(number).format(Number.isInteger(number) ? '00' : '00.00')} ${currenciesIcons[code]} (${title})`;
}
export function fGryvnya(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
