
import cloneDeep from 'lodash/cloneDeep';
import { CONFIG_MESPLAN as config } from '../../../../../../config';
import axios from '../../../../../../utils/axios';
import allActions from '../../../../../../redux/actions/index';
import { defaultStorage } from './defaultStorage';
// import { CommonAlertHolder } from '../../../common/commonAlert/commonAlertHolder';

export default function searchSoglReestr(data, dispatch, keycloak, handleClose, oldData = null, loadBlock = 1, defaultMonth = 202201) {
    const radix = 10;

    const vidPerevezArr = data.vid_perevez && data.vid_perevez?.length > 0 ? data.vid_perevez.map((el) => {
        return parseInt(el?.value, radix);
    }) : null;

    const planStatusArr = data.plan_status && data.plan_status?.length > 0 ? data.plan_status.map((el) => {
        return parseInt(el?.value, radix);
    }) : null;

    const nomenklatArr = data.nomenklat && data.nomenklat?.length > 0 ? data.nomenklat.map( (el) => {
        return el?.value;
    }) : null;

    const etsnvArr = data.etsnv && data.etsnv?.length > 0 ? data.etsnv.map( (el) => {
        return el?.value;
    }) : null;

    const esrArr = data.esr_otpr && data.esr_otpr?.length > 0 ? data.esr_otpr.map( (el) => {
        return el?.value;
    }) : null;

    const klientArr = data.klient && data.klient?.length > 0 ? data.klient.map( (el) => {
        return el?.value;
    }) : null;

    const esrPribArr = data.esr_prib && data.esr_prib?.length > 0 ? data.esr_prib.map( (el) => {
        return el?.value;
    }) : null;

    const esrOsgdArr = data.esr_osgd && data.esr_osgd?.length > 0 ? data.esr_osgd.map( (el) => {
        return el?.value;
    }) : null;

    const poluchUkrArr = data.poluch_ukr && data.poluch_ukr?.length > 0 ? data.poluch_ukr.map( (el) => {
        return el?.value;
    }) : null;

    const ekspedUkrArr = data.eksped_ukr && data.eksped_ukr?.length > 0 ? data.eksped_ukr.map( (el) => {
        return el?.value;
    }) : null;

    const ekspedFormirArr = data.eksped_formir && data.eksped_formir?.length > 0 ? data.eksped_formir.map( (el) => {
        return el?.value;
    }) : null;

    const soglasOrgArr = data.soglas_org && data.soglas_org?.length > 0 ? data.soglas_org.map( (el) => {
        return el?.value;
    }) : null;

    const vagIdArr = data.vag_id && data.vag_id?.length > 0 ? data.vag_id.map( (el) => {
        return el?.value;
    }) : null;

    const vagRpsArr = data.vag_rps && data.vag_rps?.length > 0 ? data.vag_rps.map( (el) => {
        return el?.value;
    }) : null;

    let _order = '';
    if (data.sort) {
        data.sort.map((el) => (
            _order += `${Object?.keys(el)[0]}, `
        ))
    }

    if (oldData) {
        if (oldData?.length % 300 === 0) {
            loadBlock = oldData?.length / 300 + 1;
        }
    }

    const requestData =
    {
        month: defaultMonth,
        block: loadBlock,
        vid_plan: data.vid_plan,
        plan_status: planStatusArr,
        vid_perevez: vidPerevezArr,
        ozv_vl: data.ozv_vl ? data.ozv_vl?.value : null,
        perevez_smech: data.perevez_smech ? data.perevez_smech?.value : null,
        perevez_marshrut: data.perevez_marshrut ? data.perevez_marshrut?.value : null,
        elevator: data.elevator ? data.elevator?.value : null,
        nomenklat: nomenklatArr,
        etsnv: etsnvArr,
        esr_otpr: esrArr,
        dir_otpr: data.dir_otpr ? data.dir_otpr : null,
        zal_otpr: data.zal_otpr ? data.zal_otpr?.value : null,
        strn_otpr: data.strn_otpr ? data.strn_otpr?.value : null,
        klient: klientArr,
        esr_prib: esrPribArr,
        dir_prib: data.dir_prib ? data.dir_prib : null,
        zal_prib: data.zal_prib ? data.zal_prib?.value : null,
        strn_prib: data.strn_prib ? data.strn_prib?.value : null,
        esr_osgd: esrOsgdArr,
        poluch_ukr: poluchUkrArr,
        eksped_ukr: ekspedUkrArr,
        eksped_formir: ekspedFormirArr,
        soglas_code: data.soglas_code ? data.soglas_code?.value : null,
        soglas_org: soglasOrgArr,
        soglas_uz: data.soglas_uz ? data.soglas_uz?.value : null,
        pereviznyk: data.pereviznyk ? data.pereviznyk?.value : null,
        vag_id: vagIdArr,
        vag_rps: vagRpsArr,
        vag_type: data.vag_type ? data.vag_type?.value : null,
        plan_number: data.plan_number ? data.plan_number : null,
        plan_number_snd: data.plan_number_snd ? data.plan_number_snd : null,
        loc_pr: data.loc_pr ? data.loc_pr?.value : null,
        loc_id: data.loc_id ? data.loc_id?.value : null,
        order: _order,
    }

    // 
    if (loadBlock === 1) {
        dispatch(allActions.soglReestrActions.setSoglReestr([], true))
    }
    else {
        dispatch(allActions.soglReestrActions.setSoglReestr(oldData, true))
    }

    axios({
        method: 'post',
        url: `${config.nsiUrl}/api/v1/proxyPost`,
        params: { gatewayUrlEnd : `SoglReestr/sogl_reestr` },
        // url: `${config.apiUrl}/api/v1/SoglReestr/sogl_reestr`,
        data: requestData,
        headers: { "Access-Control-Allow-Origin": "*" }
    }).then((response) => {
        //
        if (loadBlock === 1) {
            dispatch(allActions.soglReestrActions.setSoglReestr(response?.data, false))
            // write to session storage
            // searh values
            sessionStorage.setItem(defaultStorage, JSON.stringify({ ...data, month: defaultMonth, block: loadBlock }));
            // write default values to bd
            axios({
                method: 'post',
                url: `${config.nsiUrl}/api/v1/DefaultValues/SetValues?keycloakGuid=${keycloak}&sKey=${defaultStorage}`,
                data: { ...data, month: defaultMonth, block: loadBlock },
                headers: { "Access-Control-Allow-Origin": "*" },
            })
        }
        else if (oldData && response?.data) {
                let tempReestr = cloneDeep(oldData);
                tempReestr = [...oldData, ...response?.data];
                dispatch(allActions.soglReestrActions.setSoglReestr(tempReestr, false));
            
        }

        if (handleClose) {
            handleClose();
        }
        // CommonAlertHolder.alert('success', 'Дані знайдено! (реєстр узгодження)')
    }, (error) => {
        console.log(error);
        dispatch(allActions.soglReestrActions.setSoglReestr([], false))
        // CommonAlertHolder.alert('error', 'Помилка пошуку за заданими параметрами')
    });
}