import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { loaderStart, loaderEnd } from './loader';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: { data: [] },
  orders: { data: [] },
  order: null,
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    quantity: 0,
    dateCheckout: new Date(), 
    idPidpr: 0,
    accept: [],
  },
  cart: {},
  dicPidprServices: [],
  dicPidprTypeResources: [],
  ordersPageFilters: {
    page: 1,
    count: 20,
    search: ''
  },
  myOrdersPageFilters: {
    page: 1,
    count: 20,
    search: ''
  }
};

const slice = createSlice({
  name: 'tolokaOrder',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },
    
    getLoadCartSuccess(state, action) {
      state.isLoading = false;
      state.checkout = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },

    getPidprServicesSuccess(state, action) {
      state.isLoading = false;
      state.dicPidprServices = action.payload;
    },    
    getPidprTypeResourcesSuccess(state, action) {
      state.isLoading = false;
      state.dicPidprTypeResources = action.payload;
    },
    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload || [];

      const subtotal = cart.length === 0 ? 0 : sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal; // - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },

    // marukhno.v

    _setOrdersPageFilters: (state, action) => ({
      ...state,
      ordersPageFilters: { ...state.ordersPageFilters, ...action.payload }
    }),
    _setMyOrdersPageFilters: (state, action) => ({
      ...state,
      myOrdersPageFilters: { ...state.myOrdersPageFilters, ...action.payload }
    })
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  _setOrdersPageFilters,
  _setMyOrdersPageFilters
} = slice.actions;

// ----------------------------------------------------------------------


export const setOrdersPageFilters = (params) => {
  dispatch(_setOrdersPageFilters(params))
}

export const setMyOrdersPageFilters = (params) => {
  dispatch(_setMyOrdersPageFilters(params))
}

export const getOrders = async (params, ordersToShow = 'vlasni') => {
  const {
    page,
    count,
    search
  } = params;
  loaderStart();
  const url = `/api-toloka/toloka/checkout/${ordersToShow === 'vlasni' ? 'vlasni' : 'query'}?page=${page}&count=${count}&search=${search || ''}`
  await axios.get(url)
  .then(res => {
    if(res.status === 200) {
      if (ordersToShow === 'vlasni') {
        setMyOrdersPageFilters(params)
      } else {
        setOrdersPageFilters(params)
      }
      dispatch(slice.actions.getOrdersSuccess(res.data))
    }
  }).catch(error => {
    dispatch(slice.actions.hasError(error));
  })
  loaderEnd();
}

// -----------------------------------------------------
export function getLoadCart(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api-toloka/toloka/checkout/${id}`);
      // const data = [ d1 ];
      dispatch(slice.actions.getLoadCartSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// -----------------------------------------------------
export function getPidprServices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api-toloka/toloka/pidpr/services`);
      // const data = [ d1 ];
      dispatch(slice.actions.getPidprServicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getPidprTypeResources() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api-toloka/toloka/pidpr/resources`);
      // const data = [ d1 ];
      dispatch(slice.actions.getPidprTypeResourcesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getProduct(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api-toloka/toloka/products/${id}`, {
        params: { id },
      });
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
