export default function createKalend(row, data, checkedDay, planByDay, auctionsLongByDay, auctionsByDay) {
    const kalend = {
        "kalends": []
    }

    // if plan does not exist and set data in vag count
    if (!planByDay && data?.plan_vag && data?.plan_vag !== '') {
        kalend.kalends.push({
            "elzType": "0",
            "change": "1",
            "prInvent": data?.plan_zdv && data?.plan_zdv === true ? '1' : '0',
            "kCount": "",
            "auctionId": "",
            "auctionNum": "",
            "naCount": "",
            "dayD": checkedDay,
            "datePrib": "",
            "zaCount": data?.plan_vag
        })
    }

    // if plan exist and set data in vag count and za count different and sogl doesn't exist
    if (planByDay && data?.plan_vag && data?.plan_vag !== '') {
        let _soglas = null;
        if (planByDay?.soglasElz) {
            _soglas = {
                "AuctionId": "",
                "ElzType": "0",
                "OrgCode": planByDay?.soglasElz?.org_code,
                "SoglasCode": planByDay?.soglasElz?.soglas_code,
                "SoglasDate": planByDay?.soglasElz?.soglas_date,
                "DayD": planByDay?.day,
            }
        }
        const dopCount = planByDay?.dop_count !== '' ? parseInt(planByDay?.dop_count, 10) : 0;
        const kCount = planByDay?.k_count !== '' ? parseInt(planByDay?.k_count, 10) : 0;
        const vagCount = kCount + dopCount;
        kalend.kalends.push({
            "elzType": "0",
            "change": "2",
            "prInvent": data?.plan_zdv && data?.plan_zdv === true ? '1' : '0',
            "kCount": vagCount !== 0 ? `${vagCount}` : '',
            "auctionId": "",
            "auctionNum": "",
            "naCount": planByDay?.na_count,
            "dayD": checkedDay,
            "datePrib": '',
            "zaCount": data?.plan_vag,
            "soglas": _soglas
        })
    }

    // if lot exist and set data in vag 
    if (auctionsByDay || (data?.auction && data?.auction?.length !== 0)) {
        // exist in day list 
        for (let i = 0; i < data?.auction.length; i += 1) {
            let auInList = auctionsByDay?.auction_id?.findIndex((el) => el === data?.auction[i]?.id_auction);
            if (!auInList && typeof auInList !== 'number') {
                auInList = -1;
            }
            if (auInList === -1) {
                kalend.kalends.push({
                    "elzType": "1",
                    "change": "1",
                    "prInvent": data?.auction[i]?.zdv ? '1' : '0',
                    "kCount": ``,
                    "auctionId": data?.auction[i]?.id_auction,
                    "auctionNum": data?.auction[i]?.num,
                    "naCount": ``,
                    "dayD": checkedDay,
                    "datePrib": "",
                    "zaCount": `${data?.auction[i]?.count}`,
                    "soglas": null,
                })
            }
        }
        // in day list does not exist but add on form
        for (let i = 0; i < auctionsByDay?.auction_id?.length; i += 1) {
            let auInList = data?.auction?.findIndex((el) => el?.id_auction === auctionsByDay?.auction_id[i]);
            if (!auInList && typeof auInList !== 'number') {
                auInList = -1;
            }
            if (auInList === -1) {
                let _soglas = null;
                if (auctionsByDay?.soglasElz[i]) {
                    _soglas = {
                        "AuctionId": auctionsByDay?.soglasElz?.auction_id[i],
                        "ElzType": "1",
                        "OrgCode": auctionsByDay?.soglasElz?.org_code[i],
                        "SoglasCode": auctionsByDay?.soglasElz?.soglas_code[i],
                        "SoglasDate": auctionsByDay?.soglasElz?.soglas_date[i],
                        "DayD": auctionsByDay?.day,
                    }
                }
                const dopCount = auctionsByDay?.dop_count[i] !== '' ? parseInt(auctionsByDay?.dop_count[i], 10) : 0;
                const kCount = auctionsByDay?.k_count[i] !== '' ? parseInt(auctionsByDay?.k_count[i], 10) : 0;
                const vagCount = dopCount + kCount;
                kalend.kalends.push({
                    "elzType": "1",
                    "change": "2",
                    "prInvent": auctionsByDay?.pr_invent[i],
                    "kCount": '',
                    "auctionId": auctionsByDay?.auction_id[i],
                    "auctionNum": auctionsByDay?.auction_num[i],
                    "naCount": auctionsByDay?.na_count[i] !== '' ? `-${auctionsByDay?.na_count[i]}` : '',
                    "dayD": checkedDay,
                    "datePrib": "",
                    "zaCount": vagCount !== 0 ? `-${vagCount}` : '',
                })
            }
        }
    }

    // if long lot exist and set data in vag 
    if (auctionsLongByDay || (data?.auctionLong && data?.auctionLong?.length !== 0)) {
        // exist in day list 
        for (let i = 0; i < data?.auctionLong.length; i += 1) {
            let auInList = auctionsLongByDay?.auction_id?.findIndex((el) => el === data?.auctionLong[i]?.id_auction);
            // not undefined and not number
            if (!auInList && typeof auInList !== 'number') {
                auInList = -1;
            }
            if (auInList === -1) {
                kalend.kalends.push({
                    "elzType": "2",
                    "change": "1",
                    "prInvent": data?.auctionLong[i]?.zdv ? '1' : '0',
                    "kCount": ``,
                    "auctionId": data?.auctionLong[i]?.id_auction,
                    "auctionNum": data?.auctionLong[i]?.num,
                    "naCount": ``,
                    "dayD": checkedDay,
                    "datePrib": "",
                    "zaCount": data?.auctionLong[i]?.za_count,
                    "soglas": null,
                })
            }

            if (auInList !== -1 && auctionsLongByDay?.za_count[auInList] !== data?.auctionLong[i]?.za_count && data?.auctionLong[i]?.za_count !== '') {
                let _soglas = null;
                if (auctionsLongByDay?.soglasElz[auInList]) {
                    _soglas = {
                        "AuctionId": auctionsLongByDay?.soglasElz[auInList]?.auction_id,
                        "ElzType": "2",
                        "OrgCode": auctionsLongByDay?.soglasElz[auInList]?.org_code,
                        "SoglasCode": auctionsLongByDay?.soglasElz[auInList]?.soglas_code,
                        "SoglasDate": auctionsLongByDay?.soglasElz[auInList]?.soglas_date,
                        "DayD": auctionsLongByDay?.day,
                    }
                }
                const dopCount = auctionsLongByDay?.dop_count[auInList] !== '' ? parseInt(auctionsLongByDay?.dop_count[auInList], 10) : 0;
                const kCount = auctionsLongByDay?.k_count[auInList] !== '' ? parseInt(auctionsLongByDay?.k_count[auInList], 10) : 0;
                const vagCount = dopCount + kCount;
                kalend.kalends.push({
                    "elzType": "2",
                    "change": "1",
                    "prInvent": auctionsLongByDay?.pr_invent[auInList],
                    "kCount": vagCount !== 0 ? `${vagCount}` : '',
                    "auctionId": auctionsLongByDay?.auction_id[auInList],
                    "auctionNum": auctionsLongByDay?.auction_num[auInList],
                    "naCount": auctionsLongByDay?.na_count[auInList] !== '' ? `${auctionsLongByDay?.na_count[auInList]}` : '',
                    "dayD": checkedDay,
                    "datePrib": "",
                    "zaCount": data?.auctionLong[i]?.za_count !== '' ? `${data?.auctionLong[i]?.za_count}` : '',
                    "soglas": _soglas
                })
            }
        }
        //
        // in day list does not exist but add on form
        for (let i = 0; i < auctionsLongByDay?.auction_id?.length; i += 1) {
            let auInList = data?.auctionLong?.findIndex((el) => el?.id_auction === auctionsLongByDay?.auction_id[i]);
            if (!auInList && typeof auInList !== 'number') {
                auInList = -1;
            }
            if (auInList === -1) {
                let _soglas = null;
                if (auctionsLongByDay?.soglasElz[i]) {
                    _soglas = {
                        "AuctionId": auctionsLongByDay?.soglasElz[i]?.auction_id,
                        "ElzType": "2",
                        "OrgCode": auctionsLongByDay?.soglasElz[i]?.org_code,
                        "SoglasCode": auctionsLongByDay?.soglasElz[i]?.soglas_code,
                        "SoglasDate": auctionsLongByDay?.soglasElz[i]?.soglas_date,
                        "DayD": auctionsLongByDay?.day,
                    }
                }
                const dopCount = auctionsLongByDay?.dop_count[i] !== '' ? parseInt(auctionsLongByDay?.dop_count[i], 10) : 0;
                const kCount = auctionsLongByDay?.k_count[i] !== '' ? parseInt(auctionsLongByDay?.k_count[i], 10) : 0;
                const vagCount = dopCount + kCount;
                kalend.kalends.push({
                    "elzType": "2",
                    "change": "2",
                    "prInvent": auctionsLongByDay?.pr_invent[i] ? '1' : '0',
                    "kCount": "",
                    "auctionId": auctionsLongByDay?.auction_id[i],
                    "auctionNum": auctionsLongByDay?.auction_num[i],
                    "naCount": auctionsLongByDay?.na_count[i] !== '' ? `-${auctionsLongByDay?.na_count[i]}` : '',
                    "dayD": checkedDay,
                    "datePrib": "",
                    "zaCount": vagCount !== 0 ? `-${vagCount}` : '',
                    "soglas": _soglas
                })
            }
        }
    }
    return kalend;
}