import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { CONFIG_MESPLAN as config } from '../../config';
import { getUserSession } from '../../utils/jwt';
import axios from '../../utils/axios';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  // if (dataSet.hasOwnProperty('group')) {
  if (Object.prototype.hasOwnProperty.call(dataSet, "group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].value} - ${dataSet[1].title}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    // if (child.hasOwnProperty('group')) {
    if (Object.prototype.hasOwnProperty.call(child, "group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function Virtualize({ props }) {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { id, label, multiple, size, setValue, control, table, title, value, storage, watch } = props;
  const [defaultVal, setDefaultVal] = React.useState(multiple ? [] : null);

  React.useEffect(() => {
    const user = JSON.parse(getUserSession());
    const _url = `https://dev.vantazhplus.co.ua/api-nsi/api/DataNsi/GetAllValues?table=${table}&titleOut=${title}&valueOut=${value}&all=true`;
    axios({
      method: 'get',
      url: _url,
    })
      .then(res => {
        if (res?.data) {
          // set options to autocomplete          
          setOptions(res.data);
          setLoading(false);
          // find default value              
          const cachedData = JSON.parse(sessionStorage.getItem(storage));
          if (cachedData) {
            if (multiple && Array.isArray(cachedData[id]) && cachedData[id]) {
              setDefaultVal(cachedData[id]);
              setValue(id, cachedData[id]);
            }
            if (!multiple && cachedData[id]) {
              setDefaultVal(cachedData[id]);
              setValue(id, cachedData[id]);
            }
          }
          else if (storage) {
            axios({
              method: 'get',
              url: `https://dev.vantazhplus.co.ua/api-nsi/api/v1/DataNsi/api/v1/DefaultValues/ReestrValues?keycloakGuid=${user?.sub}`,
            }).then((response) => {
              if (multiple && Array.isArray(response.data[id]) && response.data[id]) {
                setDefaultVal(response.data[id]);
                setValue(id, response.data[id]);
              }
              if (!multiple && response.data[id]) {
                setDefaultVal(response.data[id]);
                setValue(id, response.data[id]);
              }
              sessionStorage.setItem(storage, response.data);

            }).catch(() => {
              console.log("default data not found");
            });
          }
        }
        else {
          setOptions([]);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setOptions([]);
      })

  }, []);

  const defaultData = watch(id);
  React.useEffect(() => {
    if (defaultData) {
      setDefaultVal(defaultData);
    }
    else if (multiple) { setDefaultVal([]) } else {
      setDefaultVal(null)
    }
  }, [defaultData]);

  const _filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option?.value + option?.title,
  });


  return (
    <Autocomplete
      id={id}
      name={id}
      value={defaultVal}
      multiple={multiple}
      size={size}
      fullWidth
      disableListWrap
      loading={loading}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      filterOptions={_filterOptions}
      onChange={(event, val) => {
        if (val !== null && val !== undefined) {
          setValue(id, val);
          setDefaultVal(val);
        }
        else {
          setValue(id, null);
          if (multiple) { setDefaultVal([]) } else { setDefaultVal(null) };
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') {
          if (multiple) { setDefaultVal([]) } else { setDefaultVal(null) };
          setValue(id, null);
        }
      }}
      getOptionLabel={(option) => option && option.title}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={(props, option) => [props, option]}
    />
  );
}