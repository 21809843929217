import {
    Alert, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Paper, Radio, RadioGroup, TextField, Typography
} from '@mui/material';
import { Controller, useFormContext } from "react-hook-form";

const stylePaper = {
    p: 1,
};

export default function AuctionUzgod(props) {
    const { control, formState: { errors } } = useFormContext();
    //
    const checkedDay = props._checkedDay;
    const item = props._item;
    const index = props._index;
    const isLong = props._isLong;
    const name = props._name;
    //
    const auName = isLong ? "довгострокового лоту" : "звичайного лоту";
    //
    return (
        <>
            <Paper variant="outlined" sx={stylePaper}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h5" component="h5">
                        {`Узгодження ${auName} на ${checkedDay}`}
                        {` номер: ${item?.soglasElz.auction_num}`}
                    </Typography>

                    <FormGroup row>
                        {/* <FormLabel marginTop={1}>Узгодження отримувачем</FormLabel> */}
                        <FormControlLabel
                            value="start"
                            control={
                                <Controller
                                    name={`${name}.${index}.recipientUzg`}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            checked={field.value}
                                            disabled
                                        />
                                    )}
                                />
                            }
                            label="Отримувачем"
                            labelPlacement="end"
                        />
                        {/* <FormLabel>Узгодження елеватором</FormLabel> */}
                        <FormControlLabel
                            value="start"
                            control={
                                <Controller
                                    name={`${name}.${index}.elevatorUzg`}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            checked={field.value}
                                            disabled
                                        />
                                    )}
                                />
                            }
                            label="Елеватором"
                            labelPlacement="end"
                        />
                    </FormGroup>
                    <Divider />
                    <Grid pt={1}>
                        <FormControl>
                            <Controller
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <RadioGroup row aria-labelledby={`row-radio-buttons-${name}`} {...field}>
                                            <FormControlLabel value="1" control={<Radio />} label="Так" />
                                            <FormControlLabel value="0" control={<Radio />} label="Ні" />
                                        </RadioGroup>
                                        {!!error && (
                                            // <FormHelperText error sx={{ px: 2 }}>
                                            //     Оберіть статус узгодження
                                            // </FormHelperText>
                                            <Alert severity="warning">Оберіть статус узгодження</Alert>
                                        )}
                                    </>
                                )}
                                name={`${name}.${index}.uzgod`}
                                control={control}
                                // rules={{ required: true }}
                            />
                        </FormControl>
                        {/* <RHFRadioGroup name={`${name}.${index}.uzgod`} options={['Так', 'Ні']} row /> */}
                    </Grid>
                    <Divider />
                    <Grid pt={1}>
                        <FormControl>
                            <Controller
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <TextField disabled={!isLong} number label="Кількість вагонів" size={'small'} variant="outlined" {...field} />
                                        {!!error && (
                                            // <FormHelperText error sx={{ px: 2 }}>
                                            //     Введіть кількість узгоджених вагонів
                                            // </FormHelperText>
                                            <Alert severity="warning">Введіть кількість узгоджених вагонів</Alert>
                                        )}
                                    </>
                                )}
                                name={`${name}.${index}.vagCount`}
                                control={control}
                                // rules={{ required: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}