import React, { useEffect } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Grid,
    Typography,
    Menu,
    MenuItem,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import ukLocale from '@fullcalendar/core/locales/uk';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import EditModal from './EditElzModal';
import UzgodModal from './UzgodElzModal';
import createEventList from './utils/createEventList';
import { CommonAlertHolder } from '../../../../../common/CommonAlert/CommonAlertHolder';

function ElzCalendar(props) {
    // const { row, headCellsReestr, key } = props;
    const [open, setOpen] = React.useState(false);
    const [dayInfo, setDayInfo] = React.useState(null);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openUzgod, setOpenUzgod] = React.useState(false);
    //
    const [eventList, setEventList] = React.useState(null);
    // const [eventListEdited, setEventListEdited] = React.useState(null);
    const [checkedDay, setCheckedDay] = React.useState(null);
    const [editDays, setEditDays] = React.useState([]);

    // date for calendar initial
    const selectedMonth = useSelector(state => state.userMesplan?.month);
    const checkedDate = moment(selectedMonth, 'YYYYMM').format('YYYY-MM-DD');
    //
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        // setAnchorEl(event.currentTarget);
        setAnchorEl(event.jsEvent?.target);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    //
    function checkDate(all, date) {
        return all?.day_d === date;
    }

    useEffect(() => {
        setEventList(createEventList(props.row?.daysElz));
    }, [props.row])

    const handleOpenEdit = (data, info) => {
        if (info) {
            const now = moment().format('MM/DD/YYYY');
            const calendDate = moment(info.date).format('MM/DD/YYYY');
            //
            const hour = moment().hour();
            const nowFormat = moment(selectedMonth);
            const calendDateFormat = moment(info.date);
            //
            if (calendDate < now) {
                CommonAlertHolder.alert('warning', 'Дата менша за поточну');
                // alert('Дата менша за поточну');
                return;
            }

            if (calendDate === now) {
                if (hour >= 20) {
                    CommonAlertHolder.alert('warning', 'Залізнична доба завершилась');
                    // alert('Залізнична доба завершилась');
                    return;
                }
            }

            if (calendDateFormat.month() !== nowFormat.month()) {
                return;
            }
            // const selectedDay = moment(info.date).format('D');
            const selectedDay = moment(info.date).format('DD.MM.YYYY');
            setCheckedDay(selectedDay);
            // setCheckedDate(info.date);
            setOpenEdit(true);
        }
    }

    const handleOpenUzgod = (data, info) => {
        if (info) {
            const now = moment().format('MM/DD/YYYY');
            const calendDate = moment(info.date).format('MM/DD/YYYY');
            //
            const hour = moment().hour();
            const nowFormat = moment(selectedMonth);
            const calendDateFormat = moment(info.date);
            //
            if (calendDate < now) {
                CommonAlertHolder.alert('warning', 'Дата менша за поточну');
                // alert('Дата менша за поточну');
                return;
            }

            if (calendDate === now) {
                if (hour >= 20) {
                    CommonAlertHolder.alert('warning', 'Залізнична доба завершилась');
                    // alert('Залізнична доба завершилась');
                    return;
                }
            }

            if (calendDateFormat.month() !== nowFormat.month()) {
                return;
            }
            // const selectedDay = moment(info.date).format('D');
            const selectedDay = moment(info.date).format('DD.MM.YYYY');
            setCheckedDay(selectedDay);
            // setCheckedDate(info.date);
            setOpenUzgod(true);
        }
    }

    const UzgodExist = (row, info) => {
        if (info) {
            if (row?.pr_zacount !== "1") {
                return false;
            }
            //
            const checkedDay = moment(info.date).format('DD.MM.YYYY')
            let dayAu = row.daysElz.lot?.filter((val) => val?.day === checkedDay);
            let dayAuLong = row.daysElz.lotLong?.filter((val) => val?.day === checkedDay);
            let dayPlan = row.daysElz.plan?.filter((val) => val?.day === checkedDay);
            // to object or null
            if (dayAu && dayAu.length !== 0) { dayAu = dayAu[0] } else { dayAu = null };
            if (dayAuLong && dayAuLong.length !== 0) { dayAuLong = dayAuLong[0] } else { dayAuLong = null };
            if (dayPlan && dayPlan.length !== 0) { dayPlan = dayPlan[0] } else { dayPlan = null };

            if (dayAu) {
                for (let j = 0; j < dayAu?.auction_id.length; j += 1) {
                    if (dayAu?.soglasElz[j]) {
                        return true;
                    }
                }
            }
            if (dayAuLong) {
                for (let j = 0; j < dayAuLong?.auction_id.length; j += 1) {
                    if (dayAuLong?.soglasElz[j]) {
                        return true;
                    }
                }
            }
            if (dayPlan?.soglasElz) {
                return true;
            }
        } else {
            return false;
        }
    };

    return (
        <>
            <Box
                sx={{ margin: 1 }}
                // onMouseEnter={() => props.setIsOnCalend(true)}
                // onMouseLeave={() => props.setIsOnCalend(false)}
                // onKeyDown={props.handleKeyEsc}
                // ref={props.calendRef}
                tabIndex={-1}>
                <Grid container direction="row">
                    <Typography variant="h4" gutterBottom component="div" pr={2}>
                        Календар {props.row?.idf_obj}
                    </Typography>
                    {/* <Button variant="contained" pr={2}>Зберегти графік</Button>
                    <Button variant="contained" color="secondary">Скинути</Button> */}
                </Grid>
                <>
                    {/* <PerfectScrollbar> */}
                    <FullCalendar
                        defaultView="dayGridMonth"
                        headerToolbar={{
                            left: "",
                            center: "",
                            right: "",
                        }}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        locale={ukLocale}
                        events={eventList}
                        initialDate={checkedDate}
                        eventDidMount={(info) => {
                            info.el.setAttribute("data-tip", info.event._def?.extendedProps?.description);
                            ReactTooltip.rebuild();
                        }}
                        dateClick={(info) => {
                            // handleOpen(props.row, info);
                            setDayInfo(info);
                            handleOpenMenu(info);
                        }}
                        contentHeight='auto'
                        height='auto'
                    />
                    {/* </PerfectScrollbar> */}
                </>
                <EditModal
                    open={openEdit}
                    setOpen={setOpenEdit}
                    row={props.row}
                    _checkedDay={checkedDay}
                    _closeMenu={handleCloseMenu}
                />
                <UzgodModal
                    open={openUzgod}
                    setOpen={setOpenUzgod}
                    row={props.row}
                    _checkedDay={checkedDay}
                    _closeMenu={handleCloseMenu}
                />
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => { handleOpenEdit(props.row, dayInfo) }}>Редагувати</MenuItem>
                    {UzgodExist(props.row, dayInfo) ? <MenuItem onClick={() => { handleOpenUzgod(props.row, dayInfo) }}>Узгодження</MenuItem> : null}
                    {/* <MenuItem onClick={handleClose}>Історія роботи</MenuItem> */}
                </Menu>
            </Box>
        </>
    );
}

export default ElzCalendar;
