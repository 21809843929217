import * as React from 'react';
import {
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@mui/material';
import { Controller } from "react-hook-form";
import PropTypes from 'prop-types';

function VidPlanSelect( props ) {
    const id = props.id;
    return (
        <>
            <FormControl fullWidth size={props.size}>
                <InputLabel id={`${id}-label-input`}>Вид плану</InputLabel>
                <Controller
                    render={
                        ({ field }) =>
                            <Select
                                labelId={`${id}-label`}
                                id={id}
                                label="Вид плану"
                                {...field}                              
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={1}>Основний</MenuItem>
                                <MenuItem value={2}>Додатковий</MenuItem>
                            </Select>
                    }
                    control={props.control}
                    name={id}
                    defaultValue=''
                />
            </FormControl>
        </>
    );
}

VidPlanSelect.propTypes = {
    id: PropTypes.string,
    control: PropTypes.object,
    size: PropTypes.string,
};

VidPlanSelect.defaultProps = {
    id: 'vid_plan',
    size: '',
};

export default VidPlanSelect;