import { findAction, clearAction, filterAction, headerAction, totalDaysAction } from "../constants/elz-action-types";

const setElz = (_data, isLoading) => {
    return {
        type: findAction,
        data: _data,
        loading: isLoading,
        searchVal: ''
    };
}

const setTotalDays = (_totalDays) => {
    return {
        type: totalDaysAction,
        totalDays: _totalDays,
    };
}

const clearElz = () => {
    return {
        type: clearAction,
        data: [],
        loading: false,
        searchVal: ''  
    };
}

const filterElz = (_searchVal) => {
    return {
        type: filterAction,      
        searchVal: _searchVal 
    };
}

const setHeaderElz = (_headerOptions) => {
    return {
        type: headerAction,      
        headerOptions: _headerOptions 
    };
}

export default {
    setElz,
    clearElz,
    filterElz,
    setHeaderElz,
    setTotalDays
}