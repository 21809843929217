import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
// import { applyMiddleware, createStore } from 'redux';
// eslint-disable-next-line import/no-unresolved
import { createLogger } from 'redux-logger';
// import logger from 'redux-logger'
import { rootPersistConfig, rootReducer } from './rootReducer';
// ----------------------------------------------------------------------
const actionTransformer = action => 
  // console.log(action);
  // можно сделать вывод на сервер
   action
;
const level = 'info';
const logger = createLogger(
  {
    level,
    actionTransformer
  }
);
  /*  
  {
    predicate, // if specified this function will be called before each action is processed with this middleware.
    collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
    duration: false, // : Boolean, // print the duration of each action?
    timestamp: true, // : Boolean, // print the timestamp with each action?
   
    level: 'log', // : 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
    colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
    titleFormatter, // Format the title used when logging actions.
   
    stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
    actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
    errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.
   
    logger: console, // : LoggerObject, // implementation of the `console` API.
    logErrors: true, //: Boolean, // should the logger catch, log, and re-throw errors?
   
    diff: false, // : Boolean, // (alpha) show diff between states?
    diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
  }
);
*/

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(),
}); // logger

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
