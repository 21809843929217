import { createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
// import i18n from '../../locales/i18n';
// actions
import { showConfirmationModal } from './confirmation';
import { loaderEnd, loaderStart } from './loader';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  tariffList: null,
  selectedTarif: null,
  billingStatus: null,
  billingOpers: null,
  searchParams: {
    page: 1,
    count: 20,
    dtBegin: '',
    dtEnd: ''
  },
  isReplenishmentModalOpen: false
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTariffList(state, action) {
      state.tariffList = action.payload
    },
    setSelectedTariff(state, action) {
      state.selectedTarif = action.payload
    },
    setBillingStatus(state, action) {
      state.billingStatus = action.payload
    },
    setBillingOpers(state, action) {
      state.billingOpers = action.payload
    },
    setSearchParams: (state, action) => ({
      ...state,
      searchParams: { ...state.searchParams, ...action.payload }
    }),
    setReplenishmentModalOpen(state, action) {
      state.isReplenishmentModalOpen = action.payload
    }
  }
});

export default slice.reducer;

export const {
  setLoading,
  setTariffList,
  setSelectedTariff,
  setBillingStatus,
  setBillingOpers,
  setSearchParams,
  setReplenishmentModalOpen
} = slice.actions;

export const changeSearchParams = (params) => {
  dispatch(setSearchParams(params));
}

export const getTariffList = async () => {
  // loaderStart();
  await axios.get(`/api-billing/billing/tariff`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setTariffList(res.data));
      }
    })
    .catch(err => {
      console.log(err);
    });
  // loaderEnd();
}

export const changeTariff = async (tariffId) => {
  // loaderStart();
  await axios.put(`/api-billing/billing/pidpr/tariff?id_tariff=${tariffId}`)
    .then(async res => {
      if (res.status === 200) {
        await dispatch(setBillingStatus(res.data));
        await getTariffList();
        toast.success('Тариф успішно змінено');
      }
    })
    .catch(err => {
      console.log(err);
    });
  // loaderEnd();
}

export const selectTariff = (tariff) => {
  dispatch(setSelectedTariff(tariff))
}

export const getBillingStatus = async () => {
  loaderStart();
  await axios.get(`/api-billing/billing/status`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setBillingStatus(res.data));
      }
    })
    .catch(err => {
      console.log(err);
    });
  loaderEnd();
}

export const getBillingOpers = async (idProfile, params) => {
  const {
    page,
    count,
    dtBegin,
    dtEnd
  } = params;
  await axios.get(`/api-billing/billing/opers/${idProfile}?page=${page}&count=${count}&dtBegin=${dtBegin}&dtEnd=${dtEnd}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(setBillingOpers(res.data));
        dispatch(setSearchParams(params));
      }
    })
    .catch(err => {
      console.log(err);
    });
}

export const openReplenishmentModal = (isOpen) => {
  dispatch(setReplenishmentModalOpen(isOpen));
}

export const updateBalance = async (summ) => {
  await axios.put(`/api-billing/billing/pidpr/balance?add_balance=${summ}`)
    .then(async res => {
      if (res.status === 200) {
        await dispatch(setBillingStatus(res.data));
        toast.success('Баланс успішно поповнено!')
      }
    })
    .catch(err => {
      console.log(err);
    });
  dispatch(setLoading(false));
  dispatch(setReplenishmentModalOpen(false));
}