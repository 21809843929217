/* eslint-disable */
// -------------------------------------------------------
export function TimestampToHHMISS(diffSeconds) {
    const hours = Math.floor(diffSeconds / 3600);
    const minutes = Math.floor(diffSeconds / 60) - (hours * 60);
    const seconds = diffSeconds % 60;
    return [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
}

// -------------------------------------------------------
export function TimestampToHHMI(diffMinutes) {
    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;
    return [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0')
    ].join(':');
}
// -------------------------------------------------------
export function TimestampToDDHHMI(diffMinutes) {
    const days = Math.floor(diffMinutes / 24 / 60);
    const hours = Math.floor(diffMinutes / 60) - (days * 24); 
    const minutes = diffMinutes % 60;
    return (days > 0 ? days.toString() + "d " : "") + [
        // days.toString().padStart(2, '0'),
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0')
    ].join(':');
}

// -------------------------------------------------------
export function UTCToLocalTime(dateTime) {
    const dateTimeUTC = new Date(dateTime);
    const dateLocal = dateTimeUTC.toLocaleString();
    return dateLocal;

}

// -------------------------------------------------------
export function getUTCDate(dateTime) {
    return new Date(Date.UTC(
        dateTime.getUTCFullYear(), 
        dateTime.getUTCMonth(), 
        dateTime.getUTCDate(),
        dateTime.getUTCHours(),
        dateTime.getUTCMinutes(),
        dateTime.getUTCSeconds())); 

}

// -------------------------------------------------------
export const DateFormat = {
    ddmmyyyy: (dateTime) => {
        const yyyy = dateTime.getFullYear();
        const mm = dateTime.getMonth() < 9 ? "0" + (dateTime.getMonth() + 1) : (dateTime.getMonth() + 1); // getMonth() is zero-based
        const dd = dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate();
        return `${dd.toString()}.${mm.toString()}.${yyyy.toString()}`;    
    },
    ddmmyyyyhhmi: (dateTime) => {        
        const yyyy = dateTime.getFullYear();
        const mm = dateTime.getMonth() < 9 ? "0" + (dateTime.getMonth() + 1) : (dateTime.getMonth() + 1); // getMonth() is zero-based
        const dd = dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate();
        const hh = dateTime.getHours() < 10 ? "0" + dateTime.getHours() : dateTime.getHours();
        const mi = dateTime.getMinutes() < 10 ? "0" + dateTime.getMinutes() : dateTime.getMinutes();
        return `${dd.toString()}.${mm.toString()}.${yyyy.toString()} ${hh.toString()}:${mi.toString()}`;    
    },
    ddmmyyyyhhmiss: (dateTime) => {        
        const yyyy = dateTime.getFullYear();
        const mm = dateTime.getMonth() < 9 ? "0" + (dateTime.getMonth() + 1) : (dateTime.getMonth() + 1); // getMonth() is zero-based
        const dd = dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate();
        const hh = dateTime.getHours() < 10 ? "0" + dateTime.getHours() : dateTime.getHours();
        const mi = dateTime.getMinutes() < 10 ? "0" + dateTime.getMinutes() : dateTime.getMinutes();
        const ss = dateTime.getSeconds() < 10 ? "0" + dateTime.getSeconds() : dateTime.getSeconds();
        return `${dd.toString()}.${mm.toString()}.${yyyy.toString()} ${hh.toString()}:${mi.toString()}:${ss.toString()}`;
    },  
    yyyymmmddhhmiss_Utc: (dateTime) => {        
            const yyyy = dateTime.getUTCFullYear();
            const mm = dateTime.getUTCMonth() < 9 ? "0" + (dateTime.getUTCMonth() + 1) : (dateTime.getUTCMonth() + 1); // getMonth() is zero-based
            const dd = dateTime.getUTCDate() < 10 ? "0" + dateTime.getUTCDate() : dateTime.getUTCDate();
            const hh = dateTime.getUTCHours() < 10 ? "0" + dateTime.getUTCHours() : dateTime.getUTCHours();
            const mi = dateTime.getUTCMinutes() < 10 ? "0" + dateTime.getUTCMinutes() : dateTime.getUTCMinutes();
            const ss = dateTime.getUTCSeconds() < 10 ? "0" + dateTime.getUTCSeconds() : dateTime.getUTCSeconds();
            return `${yyyy.toString()}-${mm.toString()}-${dd.toString()} ${hh.toString()}:${mi.toString()}:${ss.toString()}`;        
    }
}
  
// -------------------------------------------------------
export const DateDiff = {
    inHours: (d1, d2) => {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2-t1)/(3600*1000));
    },

    inMinutes: function(d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2-t1)/(60*1000));
    },

    inDays: (d1, d2) => {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2-t1)/(24*3600*1000));
    },

    inWeeks: (d1, d2) => {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2-t1)/(24*3600*1000*7));
    },

    inMonths: (d1, d2) => {
        const d1Y = d1.getFullYear();
        const d2Y = d2.getFullYear();
        const d1M = d1.getMonth();
        const d2M = d2.getMonth();

        return (d2M+12*d2Y)-(d1M+12*d1Y);
    },

    inYears: (d1, d2) => {
        return d2.getFullYear()-d1.getFullYear();
    }
}
